import { CloudTemplate, fetchComponent, fetchStylesheet } from './cloud.js'
import { Datascopes, renderDOMContent, renderDOMElement } from './dom.js'


declare global {
  namespace JSX {
    interface IntrinsicElements {
      'feaas-component': {
        data?: any,
        'component-id': string,
        'variant-id': string,
        'version'?: string,
        'hostname'?: string,
        'template'?: string,
        children?: any
      }
      'feaas-stylesheet': {
        'library-id'?: string,
        'hostname'?: string,
        children?: any
      }
    }
  }
}

// Shim in case it's required in node.js environment
var WebComponent = typeof HTMLElement == 'undefined' ? (class {}) as unknown as typeof HTMLElement : HTMLElement;

export class HTMLFeaasComponent extends WebComponent {
  static get observedAttributes() {
    return ['data', 'component-id', 'variant-id', 'version', 'hostname', 'template']
  }
  connected = false

  template: string = null
  data: any = undefined
  isFetching: boolean = false

  connectedCallback() {
    this.connected = true
    this.render()
  }

  disconnectedCallback() {}

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (!this.connected) return
    if (name == 'template') {
      this.template = newValue
    }
    if (name == 'data' && this.children.length > 0) {
      this.update()
    } else {
      this.render()
    }
  }

  update() {
    renderDOMElement(this, this.getProps().data)
  }

  setData(data: any) {
    this.data = data
    if (this.children.length > 0) {
      this.update()
    } else {
      this.render()
    }
  }

  getOriginal() {
    const scope = this.getAttribute('data-path-scope')
    if (!scope) return
    for (var current = this as Element; (current = current.previousElementSibling); ) {
      if (current.getAttribute('data-path-scope') == scope) {
        var last = current
      } else {
        break
      }
    }
    return last as HTMLFeaasComponent
  }

  getData(): any {
    if (this.data) {
      return this.data
    }
    if (this.getAttribute('data')) {
      const dataValue = this.getAttribute('data')
      return typeof dataValue == 'string' ? JSON.parse(dataValue) : {}
    }
    return this.getOriginal()?.getData() || {}
    return {}
  }

  render() {
    // defer rendering to original element
    if (this.getOriginal()) {
      return
    }
    const newProps = this.getProps()
    if (this.template == null) {
      if (!this.isFetching) {
        if (newProps.componentId != null && newProps.variantId != null) {
          this.isFetching = true
          fetchComponent(newProps, (template) => {
            this.template = template
            this.isFetching = false
            renderDOMContent(this, this.template, newProps.data)
          }).catch(() => {
            console.error(`FEAAS: Could not fetch component: ${newProps.componentId}/${newProps.variantId}/${newProps.version}`)
          })
        }
      }
    } else {
      renderDOMContent(this, this.template, newProps.data)
    }
  }

  getProps(): CloudTemplate & { data: Datascopes } {
    return {
      data: this.getData(),
      componentId: (this.getAttribute('component-id') || undefined) as string,
      variantId: (this.getAttribute('variant-id') || undefined) as string,
      version: (this.getAttribute('version') || undefined) as string,
      hostname: (this.getAttribute('hostname') || undefined) as string,
    }
  }
}

export class HTMLFeaasStylesheet extends WebComponent {
  static get observedAttributes() {
    return ['library-id', 'version', 'hostname']
  }
  connected = false
  connectedCallback() {
    this.connected = true
    this.render()
  }

  attributeChangedCallback() {
    if (this.connected) this.render()
  }

  render() {
    const props = this.getProps();
    fetchStylesheet(props, (cssText) => {
      this.innerHTML = '<style>' + cssText + '</style>'
    }).catch((e) => {
      console.error(`FEAAS: Could not fetch stylesheet for library ${props.libraryId}`)
    })
  }

  getProps() {
    return {
      libraryId: (this.getAttribute('library-id') || undefined) as string,
      version: (this.getAttribute('version') || undefined) as string,
      hostname: (this.getAttribute('hostname') || undefined) as string,
    }
  }
}

if (typeof window != 'undefined' && !window.customElements.get('feaas-stylesheet')) {
  window.customElements.define('feaas-stylesheet', HTMLFeaasStylesheet)
  window.customElements.define('feaas-component', HTMLFeaasComponent)
}
