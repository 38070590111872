import { API } from './api.js'
import APIAdapter from './core/adapter.js'
import { APIModel } from './core/model.js'
import Transport from './core/transport.js'
export * from './core/transport.js'
export * from './models/collections.js'
export * from './models/components.js'
export * from './models/datasources.js'
export * from './models/libraries.js'
export * from './models/stylesheets.js'
export * from './models/variants.js'
export * from './api.js'
export * from './core/model.js'
export { APIAdapter }
export * from './core/collection.js'
export * from './utils/fetch.js'
export * from './utils/decorate.js'

API.instance = new API({
  apiKey: typeof process != 'undefined' ? process.env.FEAAS_API_KEY : null,
  accessToken: typeof process != 'undefined' ? process.env.FEAAS_API_ACCESS_TOKEN : null,
  hostname: typeof process != 'undefined' ? process.env.FEAAS_API_HOSTNAME : null
})
Transport.instance = API.instance
APIModel.prototype.api = API.instance

export default API
