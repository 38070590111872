import { normalize } from 'path'
import { Unformatted } from 'models/style/types/props.js'
import { Length } from 'models/style/types/length.js'

export const SceneDimensionsLimits = (props: any = {}) => ({
  minWidth: Length(props.minWidth),
  maxWidth: Length(props.maxWidth),
  minHeight: Length(props.minHeight),
  maxHeight: Length(props.maxHeight)
})

export type SceneDimensions = ReturnType<typeof SceneDimensionsLimits>
export const SceneDimensions = (props: Unformatted<SceneDimensions> = {}) => SceneDimensionsLimits(props)
export default SceneDimensions
