import { mdiArrowExpandAll, mdiArrowExpandHorizontal, mdiViewColumnOutline } from '@mdi/js'
import {
  Box,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from '@sitecore-ui/design-system'
import { EditorContextProps, ImplicitBorders } from './EditorChrome.js'
import { DialogMenuItem, EditorDialogMenu } from './EditorDialog.js'
import EditorDialogDimensions from './EditorDialogDimensions.js'
import EditorDialogLayout from './EditorDialogLayout.js'
import EditorDialogSpacing from './EditorDialogSpacing.js'

export default function EditorChromeContextLayout({
  context,
  editor,
  onPopoverClose,
  onPopoverOpen,
  onPopoverRegister
}: EditorContextProps) {
  if (!['section', 'card', 'button', 'component'].includes(context?.name)) {
    return null
  }
  const items: DialogMenuItem[] = [
    context.name != 'button' && {
      icon: mdiViewColumnOutline,
      label: 'Layout & alignment',
      id: 'layout',
      component: EditorDialogLayout
    },
    {
      icon: mdiArrowExpandAll,
      label: 'Dimensions',
      id: 'dimensions',
      component: EditorDialogDimensions
    },
    {
      icon: mdiArrowExpandHorizontal,
      label: 'Spacing',
      id: 'Spacing',
      component: EditorDialogSpacing
    }
  ].filter(Boolean)
  return (
    <>
      <Popover
        placement='bottom-start'
        isLazy={true}
        lazyBehavior='unmount'
        onOpen={() => onPopoverOpen('layout')}
        onClose={() => onPopoverClose('layout')}
      >
        {({ isOpen, onClose }) => (
          <>
            {onPopoverRegister('layout', onClose)}
            <PopoverTrigger>
              <IconButton
                isActive={isOpen}
                css={ImplicitBorders}
                aria-label='Set alignments'
                icon={
                  <Icon
                    path={
                      'M6 6.75V16.5H18.75V6.75H6ZM13.5 8.25V15H11.25V8.25H13.5ZM7.5 8.25H9.75V15H7.5V8.25ZM17.25 15H15V8.25H17.25V15Z'
                    }
                    height='24px'
                    width='24px'
                  />
                }
              ></IconButton>
            </PopoverTrigger>
            <PopoverArrow />
            {/*@ts-ignore */}
            <PopoverContent width='450px'>
              <EditorDialogMenu items={items} editor={editor} context={context} />
            </PopoverContent>
          </>
        )}
      </Popover>
    </>
  )
}
