import { FC, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const AppSwitcher: FC = () => {
  const { getAccessTokenSilently: getAccessToken, loginWithRedirect, user } = useAuth0()
  // Init app switcher
  useEffect(() => {
    const initialize = () => {
      const appSwitcher = document.querySelector('app-switcher')
      if (!appSwitcher) return

      // @ts-ignore
      appSwitcher.componentOnReady().then(() => {
        // @ts-ignore
        appSwitcher.init({
          getAccessToken,
          loginWithRedirect,
          environment: import.meta.env.VITE_APP_SWITCHER_ENV,
          organizationId: user?.['https://auth.sitecorecloud.io/claims/org_id'] ?? '',
          tenantId: user?.['https://auth.sitecorecloud.io/claims/tenant_id']
        })
      })
    }

    initialize()
  }, [loginWithRedirect, getAccessToken, user])

  return (
    <div style={{ zIndex: 1 }}>
      <app-switcher></app-switcher>
    </div>
  )
}
