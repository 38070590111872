/** Forked from fast-deep-equals to avoid comparing constructors
 * as it breaks comparing model with raw data. Addition it adds ability to ignore props */

export function isDeepEquals(a: any, b: any, ignoreProps?: string[]) {
  if (a === b) return true

  if (a && b && typeof a == 'object' && typeof b == 'object') {
    //if (a.constructor !== b.constructor) return false

    var length, i, keys
    if (Array.isArray(a)) {
      length = a.length
      if (length != b.length) return false
      for (i = length; i-- !== 0; ) if (!isDeepEquals(a[i], b[i], ignoreProps)) return false
      return true
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf()
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString()

    keys = Object.keys(a)
    length = keys.length
    if (length !== Object.keys(b).length) return false

    for (i = length; i-- !== 0; ) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false

    for (i = length; i-- !== 0; ) {
      var key = keys[i]
      if (ignoreProps?.includes(key)) continue

      if (!isDeepEquals(a[key], b[key], ignoreProps)) return false
    }

    return true
  }

  // true if both NaN, false otherwise
  return a !== a && b !== b
}
