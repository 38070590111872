import { AddIcon, Flex, IconButton, Skeleton, VStack } from '@sitecore-ui/design-system'
import Variant from 'components/builder/EditorCanvasVariant.js'

import { CollectionModel, ComponentModel, DatasourceModel, VariantModel } from '@sitecore-feaas/api'
import { useApi, useApiData, useModelObserver } from 'hooks/useApiData.js'
import type { Editor } from 'hooks/useEditor.js'
import { useEditor } from 'hooks/useEditor.js'
import { Style } from 'models/style/index.js'
import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import * as CSS from 'models/stylesheet/css.js'
import EditorStyles from '../styler/Styler.js'
import EditorChrome from './EditorChrome.js'
import EditorHeader from './EditorHeader.js'
import EditorPanels from './EditorPanels.js'
import { EditorSidebar } from './EditorSidebar.js'
import * as sdk from '@sitecore-feaas/sdk'

interface EditorCanvasProps {
  component: ComponentModel
  collection: CollectionModel
  datasources: DatasourceModel[]
  styles: Style[]
}

export default function EditorCanvas({ component, collection, datasources, styles }: EditorCanvasProps) {
  const [node, setRef] = useState<HTMLElement>(null)
  const { api } = useApi()
  const apiEditor = useApiData('editor')
  const variants = useModelObserver(component?.variants)

  const editorConfig = useMemo(() => {
    return {
      modules: {
        api,
        sdk
      },

      autosave: {
        waitingTime: 5000,
        save(editor: Editor) {
          saveAllVariants(editor)
          return component.saveVariants()
        }
      }
    }
  }, [])
  const { editor, focus, blur } = useEditor(editorConfig, node, component, datasources, () => {
    component.findVariant(apiEditor.focusedEditableName).getDraft()
  })
  const variantElements = useMemo(() => {
    if (!component) return []
    return component.reduceVariantVersions().map((snapshot) => {
      return (
        <Variant
          editor={editor}
          focusVariant={focus}
          key={snapshot.head.refId}
          variant={snapshot.head}
          snapshot={snapshot}
        />
      )
    })
  }, [component, variants, editor])

  const addNewVariant = () => {
    component.addVariant()
    focus(undefined, true)
  }

  const onMouseDown: MouseEventHandler = (e) => {
    if (!(e.target instanceof HTMLElement)) return
    if (!e.target.closest('[contenteditable], .ui, macro-carousel')) {
      if (editor && document.activeElement instanceof HTMLElement) {
        blur()
        e.preventDefault()
      }
      //} else if (e.target instanceof HTMLElement && e.target.closest('.ck-content')) {
      //  editor.focus()
    }
  }

  const onClick: MouseEventHandler = (e) => {
    if (!(e.target instanceof HTMLElement)) return
    if (e.target.closest('[contenteditable]')) {
      if (e.target.closest('a, button')) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  // Commit current data to variants and cut "saved" versions
  const saveAllVariants = (editor: Editor) => {
    editor.model.document.getRootNames().forEach((rootName) => {
      component.findVariant(rootName)?.saveData({
        view: editor.getData({ rootName }),
        model: ''
      })
    })
  }

  // schedule auto save on variant change
  useEffect(() => {
    if (editor && component && variants.length > 0) {
      editor.scheduleSave()
    }
  }, [editor, variants])

  // save on status change
  useEffect(() => {
    if (editor && component && variants.length > 0) {
      editor.save()
    }
  }, [editor, variants.filter((r) => r.status != 'draft').length, variants.filter((r) => r.deletedAt != null).length])

  // save on leaving editor
  useEffect(() => {
    return () => {
      if (editor) {
        saveAllVariants(editor)
        component.saveVariants()
        component.aggregateVariantData()
        api.library.components.replaceItem(component)
      }
    }
  }, [editor])

  const cssText = useMemo(() => CSS.toText(styles), [styles])

  const [readyEditor, setReadyEditor] = useState<Editor>(null)

  useEffect(() => {
    if (editor && component && variants.length != 0) {
      setReadyEditor(editor)
      // Something needs to be focused initially in order for editor initialize context
      setTimeout(() => {
        const focusable = document.querySelector('.ck-content') as HTMLInputElement
        focusable?.focus()
        requestAnimationFrame(() => {
          blur()
        })
      }, 100)
      setTimeout(() => {
        document.body.classList.remove('loading')
      }, 300)
    }
  }, [editor, component?.variants.length == 0])

  return (
    <>
      <VStack alignItems={'stretch'} spacing={0} tabIndex={-1} flexGrow={1}>
        {editor && <EditorChrome editor={editor} />}
        <Flex
          direction='column'
          css={`
            position: relative;
            height: 100%;
          `}
          className='editor'
          background='gray.100'
          onMouseDownCapture={onMouseDown}
          onClickCapture={onClick}
        >
          <style>{[cssText, apiEditor.styles].join('\n')}</style>
          <EditorPanels editor={readyEditor} addNewVariant={addNewVariant} />
          <EditorHeader
            editor={readyEditor}
            component={component}
            collection={collection}
            onSave={() => editor.save()}
          />
          <VStack
            position={'absolute'}
            top={140}
            zIndex={100}
            transition='opacity 0.3s'
            opacity={readyEditor ? 0 : 1}
            mx={'auto'}
            width={'768px'}
            px={0}
            left={0}
            right={0}
            pointerEvents='none'
          >
            <Skeleton {...{ width: 'full', height: '40px' }} isLoaded={false}></Skeleton>
            <Skeleton {...{ width: '320px', height: '100px' }} isLoaded={false}></Skeleton>
          </VStack>
          <VStack
            opacity={readyEditor ? 1 : 0}
            transition='opacity 0.3s'
            pointerEvents={readyEditor ? 'all' : 'none'}
            className={`${apiEditor.isDataDisplayed ? 'data-displayed' : 'data-not-displayed'} ${
              apiEditor.isHiddenDisplayed ? 'hidden-displayed' : 'hidden-not-displayed'
            }`}
            onContextMenu={(e) => e.preventDefault()}
            key='editor'
            pt={10}
            spacing='60px'
            flexGrow={1}
          >
            <VStack spacing={'60px'} {...{ ref: setRef }} mb={20}>
              {variantElements}
            </VStack>
            <VStack
              onClick={addNewVariant}
              _hover={{ bg: 'blackAlpha.100' }}
              mt={'auto !important'}
              mb={'16px !important'}
              spacing='3'
              align='center'
              justify='center'
              border='1px dashed'
              borderRadius={'4px'}
              borderColor='primary.200'
              h='220px'
              w='768px'
            >
              <IconButton
                variant='secondary'
                borderColor={'transparent'}
                bgColor='purple.200'
                color='purple.600'
                aria-label='Add new variant'
                icon={<AddIcon />}
              />
              <span>Add new variant</span>
            </VStack>
          </VStack>
        </Flex>

        <EditorSidebar>{readyEditor && <EditorStyles editor={editor} />}</EditorSidebar>
      </VStack>
    </>
  )
}
