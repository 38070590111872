import { RuleChoice, StyleFor } from 'models/style/index.js'
import { normalizeFloat, normalizeNumber } from './number.js'
import { Unformatted } from './props.js'

export interface ColorDefinition {
  color?: Color
  name: string
  markedForDeletion?: boolean
}
export const ColorRGB = (props: any = {}) => ({
  red: Math.max(0, Math.min(255, normalizeNumber(props.red, 0))),
  green: Math.max(0, Math.min(255, normalizeNumber(props.green, 0))),
  blue: Math.max(0, Math.min(255, normalizeNumber(props.blue, 0))),
  alpha: Math.max(0, Math.min(1, normalizeFloat(props.alpha, 0)))
})
export type Color = ReturnType<typeof ColorRGB>
export const Color = (props: Unformatted<Color>) => ColorRGB(props)

Color.isValid = (color: Color) => {
  return (
    color &&
    color.red >= 0 &&
    color.red <= 255 &&
    color.green >= 0 &&
    color.green <= 255 &&
    color.blue >= 0 &&
    color.blue <= 255 &&
    color.alpha >= 0 &&
    color.alpha <= 1
  )
}
export const rgbaToString = (color) => {
  if (!color) return

  return `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`
}

export const gradientToBackgroundColor = (backgroundGradient) =>
  backgroundGradient && backgroundGradient.stops.length > 0 ? backgroundGradient.stops[0].color : emptyColorReference

export const backgroundColorToGradient = (backgroundColor) => {
  if (backgroundColor) {
    return {
      stops: [
        { color: backgroundColor, start: null, end: null },
        { color: backgroundColor, start: null, end: null }
      ],
      angle: 0
    }
  }

  return {
    stops: [],
    angle: 0
  }
}

export const emptyColorReference = { id: null, name: null, alpha: null }

export const findColor = (
  colorReference,
  colors: StyleFor<'color'>[]
): { colorGroup: StyleFor<'color'>; colorDefinition: ColorDefinition } => {
  const colorGroup = colors.find((stylesColor: StyleFor<'color'>) => stylesColor.details.id === colorReference.id)

  if (!colorGroup) return { colorGroup: null, colorDefinition: null }

  const colorDefinition = colorGroup.props.colors.find(
    (colorDefinition: ColorDefinition) => colorDefinition.name === colorReference.name
  )

  return { colorGroup, colorDefinition }
}

export const findAlpha = (colorReference: ColorReference, colors: StyleFor<'color'>[]) => {
  if (colorReference.alpha != null) {
    return colorReference.alpha
  }

  const { colorGroup, colorDefinition } = findColor(colorReference, colors)

  if (colorGroup && colorDefinition) {
    return colorDefinition.color.alpha
  }
}

export interface ColorReference {
  name: string
  alpha: number | null
  id: RuleChoice
}

export const ColorReference = (color?: any): ColorReference => {
  if (!color) {
    return {
      id: null,
      name: null,
      alpha: null
    }
  }

  // Fixme: ColorReference accepting Colors is not type-safe concept.
  // Need to figure out how to deal with it another way.
  if (Color.isValid(color as any)) {
    return color
  }

  let id = color.id || null
  let name = color.name || null
  let alpha = color.alpha ?? null

  return {
    id,
    name,
    alpha
  }
}

ColorReference.isValid = (color: ColorReference) => {
  return (color && color.id != null && color.name != null) || Color.isValid(color as any)
}
