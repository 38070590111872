import { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import FormError from 'components/FormError.js'
import { StyledSelect } from 'components/StyledSelect.js'
import useConfirmation from 'hooks/useConfirmation.js'
import { JSONPath } from 'jsonpath-plus'
import { useApi } from 'hooks/useApiData.js'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { OnEdit, OnToggle } from '../../hooks/useEntities.js'
import useEntity from '../../hooks/useEntity.js'
import { Entry } from 'types/index.js'
import { arrayToOptions } from 'utils/array.js'
import { datasourceSchema } from 'utils/schemas/datasourceSchema.js'
import { isValidHttpUrl, isValidJSON, stringify } from 'utils/string.js'
import { hasNoEmptyValues } from 'utils/tuples.js'
import ButtonGroupSwitch from '../ButtonGroupSwitch.js'
import Edit from '../Edit.js'
import { StyledTabPanel } from '../StyledTabPanel.js'
import DatasourceKeyValue from './DatasourceKeyValue.js'
import DatasourceTree from './DatasourceTree.js'
import { DatasourceModel } from '@sitecore-feaas/api'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useToast
} from '@sitecore-ui/design-system'

const Headers = [
  'A-IM',
  'Accept',
  'Accept-Charset',
  'Accept-Encoding',
  'Accept-Language',
  'Accept-Datetime',
  'Access-Control-Request-Method',
  'Access-Control-Request-Headers',
  'Authorization',
  'Cache-Control',
  'Connection',
  'Content-Length',
  'Content-Type',
  'Cookie',
  'Date',
  'Expect',
  'Forwarded',
  'From',
  'Host',
  'If-Match',
  'If-Modified-Since',
  'If-None-Match',
  'If-Range',
  'If-Unmodified-Since',
  'Max-Forwards',
  'Origin',
  'Pragma',
  'Proxy-Authorization',
  'Range',
  'Referer',
  'TE',
  'User-Agent',
  'Upgrade',
  'Via',
  'Warning'
]

enum FetchContentIndexes {
  PARAMS = 0,
  HEADER = 1,
  BODY = 2
}

enum SampleUpdateTechniqueIndexes {
  PASTE = 0,
  FETCH = 1,
  GRAPHQL = 2
}

interface Props {
  datasource: DatasourceModel
  activeId: string
  editedId: string
  onEdit: OnEdit
  onToggle: OnToggle
}

const DatasourceCard: FunctionComponent<Props> = ({
  datasource,
  onToggle,
  activeId,
  onEdit,
  editedId
}: Props): ReactElement => {
  const [currentDatasource, setCurrentDatasource] = useState(() => datasource.clone().snapshot())
  const [sampleUpdateTechniqueIndex, setSampleUpdateTechniqueIndex] = useState(0)
  const [fetchContentIndex, setFetchContentIndex] = useState(0)
  const [endpointURL, setEndpointURL] = useState('')
  const [method, setMethod] = useState('GET')
  const [headers, setHeaders] = useState<Entry[]>([])
  const [body, setBody] = useState<Entry[]>([])
  const [params, setParams] = useState<Entry[]>([])
  const [graphQLVariables, setGraphQLVariables] = useState<string>('')
  const [graphQLQuery, setGraphQLQuery] = useState('')
  const [fetchingLoading, setFetchingLoading] = useState(false)

  const invalidGraphQLVariables = useMemo(
    () => Boolean(graphQLVariables && !isValidJSON(graphQLVariables)),
    [graphQLVariables]
  )

  const toast = useToast()
  const { api } = useApi()

  // Receives form data from location.state, and erases it
  const location = useLocation()
  const navigate = useNavigate()

  const [currentSample, setCurrentSample] = useState(() => {
    return currentDatasource.sample ? JSON.stringify(currentDatasource) : ''
  })

  const getFormValuesForDatasource = (datasource: DatasourceModel) => {
    return {
      ...datasource.export(),
      sample: datasource.sample ? JSON.stringify(datasource.sample) : ''
    }
  }

  const {
    register,
    formState: { errors, isValid },
    reset,
    watch,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: getFormValuesForDatasource(datasource),
    resolver: zodResolver(datasourceSchema)
  })

  const { hasChanges, isNew, isActive, editedExists } = useEntity({
    id: datasource.id,
    hasChanges: !datasource.isEqual(currentDatasource),
    isNew: currentDatasource.isNew,
    onEdit,
    activeId,
    editedId
  })
  useEffect(() => {
    if (typeof location.state == 'object' && isActive) {
      setCurrentDatasource(currentDatasource.change(location.state))
      navigate(location.pathname, { replace: true, state: null })
    }
  }, [location.state])

  const enhancedIndexes = useMemo(() => {
    if (sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.FETCH && method === 'POST') {
      return {
        0: {
          required: true,
          keys: ['Content-Type'],
          values: ['application/json', 'multipart/form-data', 'application/x-www-form-urlencoded']
        }
      }
    }

    if (sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.GRAPHQL) {
      return {
        0: {
          required: true,
          keys: ['Content-Type'],
          values: ['application/json']
        }
      }
    }

    return {}
  }, [method, sampleUpdateTechniqueIndex])

  useEffect(() => {
    const subscription = watch(({ name, description, sample, jsonpath, sampledAt }) => {
      setCurrentDatasource((currentDatasource) => {
        var objectSample: DatasourceModel['sample']
        if (isValidJSON(sample)) {
          objectSample = JSON.parse(sample)
          if (!JSONPath({ path: jsonpath.split('.'), json: objectSample })?.length) {
            jsonpath = '$'
          }
        } else {
          objectSample = {}
        }
        return currentDatasource.change({ sample: objectSample, description, name, jsonpath, sampledAt })
      })
    })

    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.GRAPHQL) {
      setFetchContentIndex(FetchContentIndexes.BODY)

      setMethod('POST')
    }

    if (sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.FETCH) {
      if (fetchContentIndex === FetchContentIndexes.BODY) {
        setFetchContentIndex(FetchContentIndexes.HEADER)
      }

      setMethod('GET')
    }
  }, [sampleUpdateTechniqueIndex])

  const onDelete = useConfirmation(
    useCallback(() => {
      currentDatasource.delete()

      onToggle(currentDatasource.id, true)
    }, [currentDatasource, onToggle]),
    {
      title: 'Deleting datasource',
      button: 'Delete',
      body: (
        <Text>
          Are you sure you want to delete <strong>{currentDatasource.name || 'this new datasource'}</strong>? Components
          that use it may stop working.
        </Text>
      )
    },
    [onToggle]
  )

  const onSave = useCallback(() => {
    currentDatasource.save()
  }, [currentDatasource, datasource])

  const fetchData = async (): Promise<void> => {
    setFetchingLoading(true)

    let computedBody

    if (sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.GRAPHQL) {
      if (invalidGraphQLVariables) {
        setFetchingLoading(false)
        return
      }

      computedBody = JSON.stringify({
        query: graphQLQuery,
        variables: graphQLVariables ? JSON.parse(graphQLVariables) : {}
      })
    }

    if (sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.FETCH && method === 'POST') {
      const contentType = headers[0][1]

      if (contentType === 'application/json') {
        computedBody = JSON.stringify(Object.fromEntries(body.filter(hasNoEmptyValues)))
      }

      if (contentType === 'multipart/form-data') {
        computedBody = new FormData()
        for (const entry of body) {
          computedBody.append(entry[0], entry[1])
        }
      }

      if (contentType === 'application/x-www-form-urlencoded') {
        computedBody = new URLSearchParams(Object.fromEntries(body.filter(hasNoEmptyValues))).toString()
      }
    }

    let paramsToSend = ''
    if (params.filter(hasNoEmptyValues).length) {
      paramsToSend = `?${new URLSearchParams(Object.fromEntries(params))}`
    }

    const options = {
      method,
      headers: Object.fromEntries(headers.filter(hasNoEmptyValues)),
      body: computedBody
    }

    try {
      const sample = await api.proxy(`${endpointURL}${paramsToSend}`, options)

      setValue('sample', stringify(sample), { shouldValidate: true })
      setValue('sampledAt', new Date())
    } catch (e) {
      toast({
        isClosable: false,
        duration: 4000,
        status: 'error',
        title: 'Data is not valid JSON',
        description: 'Please specify a correct JSON resource'
      })
    } finally {
      setFetchingLoading(false)
    }
  }

  return (
    <Edit
      isActive={isActive}
      hasChanges={hasChanges}
      onToggle={() => onToggle(datasource.id)}
      onDiscard={() => reset(getFormValuesForDatasource(datasource))}
      onSave={onSave}
      hasErrors={!isValid}
      onDelete={onDelete}
      isNew={isNew}
      entity='Datasource'
      otherEditedExists={editedExists}
      id={datasource.id}
      dark={true}
      Top={
        <Box h='66px'>
          <Text fontSize='3xl' fontWeight='semibold'>
            {currentDatasource.name || 'New data source'}
          </Text>

          {datasource.description && (
            <Text fontSize='sm' mt='3'>
              {currentDatasource.description}
            </Text>
          )}
        </Box>
      }
      Content={
        <Flex mt='6' w='full' pb='6' px='6'>
          <Box flex='1'>
            <Box maxW='80'>
              <FormControl>
                <FormLabel>Data source title</FormLabel>
                <Input isInvalid={!!errors.name} placeholder='New data source' {...register('name')} />
                {errors.name && <FormError error={errors.name.message} />}
              </FormControl>
            </Box>

            <Box maxW='80'>
              <FormControl mt='6'>
                <FormLabel>Description</FormLabel>
                <Textarea isInvalid={!!errors.description} placeholder='Description' {...register('description')} />
                {errors.description && <FormError error={errors.description.message} />}
              </FormControl>
            </Box>

            <FormControl maxWidth='80' mt='6'>
              <FormLabel>Add data source</FormLabel>

              <ButtonGroupSwitch onChange={setSampleUpdateTechniqueIndex} index={sampleUpdateTechniqueIndex}>
                <Button>Paste JSON</Button>
                <Button>Fetch from URL</Button>
                <Button>GraphQL</Button>
              </ButtonGroupSwitch>
            </FormControl>

            {sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.PASTE && (
              <FormControl mt='6'>
                <FormLabel>JSON</FormLabel>
                <Textarea
                  rows={10}
                  isInvalid={!!errors.sample}
                  placeholder='Paste JSON'
                  {...register('sample', {
                    value: currentSample,
                    onChange: setCurrentSample
                  })}
                />
                {errors.sample && <FormError error={errors.sample.message} />}
              </FormControl>
            )}

            {(sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.FETCH ||
              sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.GRAPHQL) && (
              <Box>
                <Flex wrap='wrap' alignItems='center' mt='6'>
                  <FormControl flex='1' minW='40'>
                    <FormLabel>Method</FormLabel>

                    <StyledSelect
                      isDisabled={sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.GRAPHQL}
                      value={{ label: method }}
                      options={[
                        { value: 'GET', label: 'GET' },
                        { value: 'POST', label: 'POST' }
                      ]}
                      onChange={({ value }) => setMethod(value)}
                    />
                  </FormControl>

                  <FormControl mx='3' flex='6'>
                    <FormLabel>Retrieve Data</FormLabel>

                    <Input
                      value={endpointURL}
                      onChange={(e) => setEndpointURL(e.target.value)}
                      placeholder='URL like https://example.com/data.json'
                    />
                  </FormControl>

                  <FormControl flex='1'>
                    <FormLabel>&nbsp;</FormLabel>

                    <Button
                      display='flex'
                      ml='auto'
                      variant='primary'
                      isLoading={fetchingLoading}
                      isDisabled={!isValidHttpUrl(endpointURL) || invalidGraphQLVariables}
                      onClick={fetchData}
                      aria-label='fetch'
                    >
                      Fetch
                    </Button>
                  </FormControl>
                </Flex>

                <Tabs mt='6' index={fetchContentIndex} onChange={setFetchContentIndex}>
                  <TabList>
                    <Tab>Params</Tab>
                    <Tab>Headers</Tab>
                    <Tab hidden={method !== 'POST'}>Body</Tab>
                  </TabList>

                  <TabPanels>
                    <StyledTabPanel>
                      <Box bg='white' p='6' w='full'>
                        <DatasourceKeyValue entries={params} onChange={setParams} />
                      </Box>
                    </StyledTabPanel>

                    <StyledTabPanel>
                      <Box bg='white' p='6' w='full'>
                        <DatasourceKeyValue
                          enhancedIndexes={enhancedIndexes}
                          keysOptions={arrayToOptions(Headers)}
                          entries={headers}
                          onChange={setHeaders}
                        />
                      </Box>
                    </StyledTabPanel>

                    <StyledTabPanel>
                      {sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.FETCH && (
                        <Box bg='white' p='6' w='full'>
                          <DatasourceKeyValue entries={body} onChange={setBody} />
                        </Box>
                      )}

                      {sampleUpdateTechniqueIndex === SampleUpdateTechniqueIndexes.GRAPHQL && (
                        <Box mt='6'>
                          <FormControl>
                            <FormLabel>Query</FormLabel>

                            <Textarea
                              name='query'
                              rows={10}
                              value={graphQLQuery}
                              onChange={(e) => setGraphQLQuery(e.target.value)}
                            />
                          </FormControl>

                          <FormControl mt='6'>
                            <FormLabel mb='2'>Variables</FormLabel>

                            <Textarea
                              isInvalid={invalidGraphQLVariables}
                              name='variables'
                              rows={10}
                              value={graphQLVariables}
                              onChange={(e) => setGraphQLVariables(e.target.value)}
                            />

                            {invalidGraphQLVariables && <FormError error='Not valid JSON' />}
                          </FormControl>
                        </Box>
                      )}
                    </StyledTabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            )}
          </Box>

          <Flex ml='8' flexDirection='column' width='80'>
            <Flex>
              <FormControl>
                <Flex align={'base'}>
                  <FormLabel>Data tree root</FormLabel>
                  {currentDatasource.sampledAt && (
                    <Text ml='auto' fontWeight='bold' fontSize='xs' variant='microcopy' textTransform='uppercase'>
                      Sampled on: {currentDatasource.sampledAt?.toDateString()}
                    </Text>
                  )}
                </Flex>
              </FormControl>
            </Flex>

            <Box
              flex='1'
              bg='white'
              w='full'
              borderRadius='base'
              border='1px'
              borderColor='blackAlpha.400'
              overflowY='auto'
              py='3'
              px='5'
              pos='relative'
            >
              <DatasourceTree
                isVerbose={true}
                intent='trimming'
                prefix='$'
                data={currentDatasource.sample}
                path={currentDatasource.jsonpath || '$'}
                onConfigure={(path) => setValue('jsonpath', path)}
              />
            </Box>
          </Flex>
        </Flex>
      }
    />
  )
}

export default DatasourceCard
