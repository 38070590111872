export * from './dom.js'
export * from './webcomponent.js'

import * as DOM from './dom.js'
import * as WEB from './webcomponent.js'

export default {
  ...DOM,
  ...WEB
}
