import { Box, Button, FormControl, FormLabel, HStack, Select, VStack } from '@sitecore-ui/design-system'
import ButtonGroupSwitch from 'components/ButtonGroupSwitch.js'
import { reusable } from 'components/styles/settings.js'
import { useApiData } from 'hooks/useApiData.js'
import type { Editor, ModelElement } from 'hooks/useEditor.js'
import useEditorStyles, {
  getAspectStyles,
  getCurrentStyle,
  getElementActiveStyles,
  getElementEffectiveStyles,
  getElementStyle
} from 'hooks/useEditorStyles.js'
import { StyleFor } from 'models/style/index.js'
import { useEffect, useRef, useState } from 'react'
import { stringifyLength } from 'models/stylesheet/css.js'
import { Unformatted } from 'models/style/types/props.js'
import SceneSpacing from 'models/style/spacing.js'
import { deepMerge } from 'utils/object.js'

interface Props {
  editor: Editor
  context: ModelElement
}

export default function EditorDialogSpacing({ editor, context }: Props) {
  const styles = useApiData('library.stylesheet.styles')
  // get all custom styles existing inside variant
  const [customStyles, setElementStyle] = useEditorStyles(editor, context)
  // merge them with style guides styles
  const effectiveStyles = getElementEffectiveStyles(context, customStyles, styles)
  // determine which of them are active on the given element
  const activeStyles = getElementActiveStyles(context, effectiveStyles)
  // get the definition of context style
  const elementStyle = getElementStyle(context, effectiveStyles)
  // get spacing options for selected element style
  const allowedSpacingStyles = getAspectStyles(elementStyle, effectiveStyles, reusable.spacing)
  const allowedInitialSpacingStyles = getAspectStyles(elementStyle, styles, reusable.spacing)
  // get currently active spacing rule
  const style = getCurrentStyle(allowedSpacingStyles, activeStyles) as StyleFor<'spacing'>

  const [index, setInidex] = useState(() => {
    const stringified: Unformatted<typeof style.props> = Object.keys(style.props).reduce((obj, key) => {
      return Object.assign(obj, { [key]: stringifyLength(style.props[key]) })
    }, SceneSpacing())

    if (
      stringified.paddingTop == stringified.paddingBottom &&
      stringified.paddingTop == stringified.paddingLeft &&
      stringified.paddingTop == stringified.paddingRight &&
      stringified.paddingTop == stringified.columnGap &&
      stringified.paddingTop == stringified.rowGap
    ) {
      return 0
    }
    if (
      stringified.paddingTop == stringified.paddingBottom &&
      stringified.paddingTop == stringified.rowGap &&
      stringified.paddingLeft == stringified.paddingRight &&
      stringified.paddingLeft == stringified.columnGap
    ) {
      return 1
    }
    return 2
  })

  const prevTabRef = useRef(-1)
  useEffect(() => {
    setElementStyle(
      context,
      deepMerge(style, {
        props: {
          paddingBottom: style.props.paddingTop,
          paddingLeft: prevTabRef.current == 0 ? style.props.paddingTop : style.props.paddingLeft,
          paddingRight: prevTabRef.current == 0 ? style.props.paddingTop : style.props.paddingLeft,
          columnGap:
            prevTabRef.current == 0
              ? style.props.paddingTop
              : prevTabRef.current == 2
              ? style.props.paddingLeft
              : style.props.columnGap,
          rowGap:
            prevTabRef.current == 0
              ? style.props.paddingTop
              : prevTabRef.current == 2
              ? style.props.paddingTop
              : style.props.rowGap
        }
      })
    )
    prevTabRef.current = index
  }, [index])

  function aggregateValues(properties, includeZero) {
    return properties
      .reduce((values, property) => {
        return values.concat(
          ...allowedInitialSpacingStyles.map((spacingStyle) => {
            return spacingStyle.props[property] || []
          })
        )
      }, [])
      .concat(includeZero ? { value: 0, unit: 'px' } : [])
      .map((value) => {
        return stringifyLength(value)
      })
      .filter(Boolean)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort()
  }

  function SelectFor(properties, ...props) {
    const options = aggregateValues(properties, properties[0].match('Gap')).map((value) => {
      return { label: value, value: value }
    })
    const current = stringifyLength(style.props[properties[0]])
    var value = options.find((opt) => current == opt.value)
    if (value == null) {
      value = { value: current, label: current }
      options.push(value)
    }
    return (
      <Select
        {...props}
        options={options}
        value={value}
        onChange={({ value }) => {
          setElementStyle(
            context,
            deepMerge(style, {
              props: properties.reduce((obj, key) => {
                return Object.assign(obj, { [key]: stringifyLength(value) })
              }, {})
            })
          )
        }}
      />
    )
  }

  return (
    <VStack spacing={4} alignItems={'stretch'}>
      <Box>
        <ButtonGroupSwitch index={index} onChange={setInidex}>
          <Button>Sync</Button>
          <Button>Sides</Button>
          <Button>All custom</Button>
        </ButtonGroupSwitch>
      </Box>

      {index == 0 &&
        SelectFor(['paddingTop', 'paddingBottom', 'paddingLeft', 'paddingRight', 'rowGap', 'horizontalGap'])}

      {index == 1 && (
        <HStack spacing='4'>
          <FormControl>
            <FormLabel>Vertical</FormLabel>
            {SelectFor(['paddingTop', 'paddingBottom', 'rowGap'])}
          </FormControl>
          <FormControl>
            <FormLabel>Horizontal</FormLabel>
            {SelectFor(['paddingLeft', 'paddingRight', 'columnGap'])}
          </FormControl>
        </HStack>
      )}

      {index == 2 && (
        <>
          <HStack spacing='4'>
            <FormControl>
              <FormLabel>Top</FormLabel>
              {SelectFor(['paddingTop'])}
            </FormControl>
            <FormControl>
              <FormLabel>V-gap</FormLabel>
              {SelectFor(['rowGap'])}
            </FormControl>
            <FormControl>
              <FormLabel>Bottom</FormLabel>
              {SelectFor(['paddingBottom'])}
            </FormControl>
          </HStack>
          <HStack spacing='4'>
            <FormControl>
              <FormLabel>Left</FormLabel>
              {SelectFor(['paddingLeft'])}
            </FormControl>
            <FormControl>
              <FormLabel>H-gap</FormLabel>
              {SelectFor(['columnGap'])}
            </FormControl>
            <FormControl>
              <FormLabel>Right</FormLabel>
              {SelectFor(['paddingRight'])}
            </FormControl>
          </HStack>
        </>
      )}
    </VStack>
  )
}
