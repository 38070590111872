import { Length } from 'models/style/types/length.js'
import { ColorReference } from 'models/style/types/color.js'
import { Keyword } from 'models/style/types/keyword.js'
import { Gradient } from 'models/style/types/gradient.js'
import { emptyIfInvalid, Unformatted } from 'models/style/types/props.js'
import { normalizeFloat } from 'models/style/types/number.js'

// Color/Gradient fill
export const ReusableFillColor = (props: any = {}) => ({
  backgroundColor: ColorReference(props.backgroundColor),
  backgroundGradient: Gradient(props.backgroundGradient)
})
ReusableFillColor.isValid = ({ backgroundColor, backgroundGradient }: ReusableFillColor) =>
  'red' in backgroundColor || ColorReference.isValid(backgroundColor) || Gradient.isValid(backgroundGradient)
export type ReusableFillColor = ReturnType<typeof ReusableFillColor>

// CSS effects
export const ReusableFillEffects = (props: any = {}) => ({
  blur: Length(props.blur),
  opacity: normalizeFloat(props.opacity)
})
ReusableFillEffects.isValid = ({ blur, opacity }: ReusableFillEffects) => blur.value != 0 || opacity != 1
export type ReusableFillEffects = ReturnType<typeof ReusableFillEffects>

// Background images
enum BackgroundRepeat {
  'repeat' = 'repeat',
  'no-repeat' = 'no-repeat',
  'repeat-x' = 'repeat-x',
  'repeat-y' = 'repeat-y'
}
enum BackgroundSize {
  'cover' = 'cover',
  'contain' = 'contain'
}

function LengthOrValue<T>(value) {
  switch (value) {
    case 'left':
    case 'top':
      var nextValue = '0%'
      break
    case 'right':
    case 'bottom':
      var nextValue = '100%'
      break
    case 'center':
      var nextValue = '50%'
  }
  return Length(nextValue ?? value)
}

export const ReusableFillImage = (props: any = {}) => ({
  backgroundImage: props.backgroundImage ?? '',
  backgroundPositionX: LengthOrValue(props.backgroundPositionX),
  backgroundPositionY: LengthOrValue(props.backgroundPositionY),
  backgroundRepeat: Keyword<BackgroundRepeat>(props.backgroundRepeat, BackgroundRepeat, 'repeat'),
  backgroundSize: Keyword<BackgroundSize>(props.backgroundSize, BackgroundSize, 'cover')
})
ReusableFillImage.isValid = ({ backgroundImage }: ReusableFillImage) => backgroundImage != null
export type ReusableFillImage = ReturnType<typeof ReusableFillImage>

// Combination of three above
export type ReusableFill = ReusableFillColor & ReusableFillEffects & ReusableFillImage
export const ReusableFill = (props: Unformatted<ReusableFill> = {}) => ({
  ...emptyIfInvalid(props, ReusableFillColor),
  ...emptyIfInvalid(props, ReusableFillEffects),
  ...emptyIfInvalid(props, ReusableFillImage)
})
export default ReusableFill
