import { MutableRefObject, useCallback, useEffect, useRef } from 'react'

export default function useCapturedFocus(
  explicitRef?: MutableRefObject<HTMLElement>
): [ref: MutableRefObject<HTMLElement>, setFocus: () => void] {
  const ref = explicitRef || useRef<HTMLElement>()
  const setFocus = useCallback(() => {
    if (!ref.current) return
    ;(ref.current.closest('[role="dialog"]') || ref.current)['focus']?.()
  }, [])

  useEffect(() => {
    if (!ref.current) return
    ref.current.addEventListener('focus', setFocus)
    if (ref.current.getAttribute('tabindex') == null) ref.current.setAttribute('tabindex', '-1')
    setFocus()
  }, [])

  return [ref, setFocus]
}
