import Transport, { nanoid } from '../core/transport.js'
import { APIModel, Atleast, RequiredKeys } from '../core/model.js'
import { Library, LibraryModel } from './libraries.js'
import APIAdapter from '../core/adapter.js'
import { API } from '../api.js'

export interface Datasource {
  id: string
  name: string
  description: string
  libraryId: Library['id']
  sample: object
  jsonpath: string
  createdAt: Date
  modifiedAt: Date
  sampledAt: Date | null
}
export class Datasources extends APIAdapter<Datasource> {
  fetch(datasource: Pick<DatasourceParams, 'libraryId'>): Promise<Datasource[]> {
    const { libraryId } = datasource
    return this.api.fetch(`/libraries/${libraryId}/datasources`)
  }
  get(datasource: Pick<DatasourceParams, 'libraryId' | 'id'>): Promise<Datasource> {
    const { libraryId, id } = datasource
    return this.api.fetch(`/libraries/${libraryId}/collections/${id}`)
  }
  post(datasource: Atleast<DatasourceParams, 'libraryId'>): Promise<Datasource> {
    const { libraryId } = datasource
    return this.api.fetch(`/libraries/${libraryId}/datasources`, {
      method: 'POST',
      body: JSON.stringify({
        ...datasource
      })
    })
  }
  put(datasource: Atleast<DatasourceParams, 'libraryId' | 'id'>): Promise<Datasource> {
    return this.post(datasource)
  }
  delete(datasource: Pick<DatasourceParams, 'libraryId' | 'id'>): Promise<Datasource> {
    const { libraryId, id } = datasource
    return this.api.fetch(`/libraries/${libraryId}/datasources/${id}`, { method: 'DELETE' })
  }
}

function isValidJSON(value: string) {
  try {
    const json = JSON.parse(value)
    return typeof json === 'object'
  } catch (e) {
    return false
  }
}

export function getDatasourceDefaults(api: API) {
  return {
    id: nanoid(10),
    jsonpath: '$',
    sampledAt: null as Date,
    createdAt: new Date(),
    modifiedAt: new Date()
  }
}
export type DatasourceMinimal<T extends keyof Datasource = never> = RequiredKeys<
  Datasource,
  typeof getDatasourceDefaults,
  T
>
export interface DatasourceImplicit {
  libraryId: Library['id']
  library?: LibraryModel
}
export interface DatasourceParams extends Datasource, DatasourceImplicit {}
export interface DatasourceModel extends DatasourceParams {}
export class DatasourceModel
  extends APIModel<Datasource, DatasourceMinimal, DatasourceImplicit>
  implements DatasourceParams
{
  get adapter() {
    return this.api.Datasources
  }
  getHiddenProps(): string[] {
    return ['library']
  }
  getDefaults(api: API) {
    return getDatasourceDefaults(api)
  }
}
