import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  Textarea
} from '@sitecore-ui/design-system'
import { StyledSelect } from 'components/StyledSelect.js'
import type { Collection, CollectionModel, ComponentModel } from '@sitecore-feaas/api'
import { useApi } from 'hooks/useApiData.js'
import { FunctionComponent, ReactElement, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { componentSchema } from 'utils/schemas/componentSchema.js'
import FormError from '../FormError.js'

interface Props {
  collection: CollectionModel
  component: ComponentModel
  isOpen: boolean
  onClose: () => void
  collections: Collection[]
}

const ComponentDrawer: FunctionComponent<Props> = ({
  collection,
  component,
  isOpen,
  onClose,
  collections
}: Props): ReactElement | null => {
  const title = component ? 'Edit Component' : 'Add component'
  const action = component ? 'Update Component' : 'Add component'

  const defaultValues = {
    name: component?.name,
    description: component?.description,
    collectionId: (function () {
      if (component) {
        return collections.find((collection: Collection) => collection.id === component.collectionId)?.id
      } else if (collection) {
        return collections.find((c) => c.id === collection.id)?.id
      }

      return collections.find((collection: Collection) => collection.isDefault)?.id
    })()
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    watch
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(componentSchema)
  })

  watch('collectionId')

  const { collectionId } = getValues()

  useEffect(() => {
    reset(defaultValues)
  }, [component, collection, isOpen, collections])

  const onSubmit = async (data) => {
    collection.components.post({
      ...component,
      ...data
    })

    onClose()
  }

  return (
    <Drawer preserveScrollBarGap isOpen={isOpen} placement='right' onClose={onClose} size='xs' blockScrollOnMount>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader>{title}</DrawerHeader>

        <DrawerBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel>Collection</FormLabel>
              <StyledSelect
                value={collections.find((c) => c.id === collectionId)}
                getOptionValue={(collection: Collection) => collection.id}
                getOptionLabel={(collection: Collection) => collection.name}
                onChange={(collection: Collection) => setValue('collectionId', collection.id)}
                options={collections}
                name='collectionId'
              />
            </FormControl>

            <FormControl mt='5'>
              <FormLabel>Name</FormLabel>

              <Input placeholder='Choose unique name' {...register('name')} />
              {errors.name && <FormError error={errors.name.message} />}
            </FormControl>

            <FormControl mt='5'>
              <FormLabel>Description (max 140 characters)</FormLabel>

              <Textarea placeholder='Description' {...register('description')} />
              {errors.description && <FormError error={errors.description.message} />}
            </FormControl>
          </form>
        </DrawerBody>

        <DrawerFooter mr='auto'>
          <Button mr='3' variant='primary' isDisabled={!isValid} onClick={handleSubmit(onSubmit)}>
            {action}
          </Button>

          <Button colorScheme='blue' onClick={onClose}>
            Dismiss
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default ComponentDrawer
