import { mdiPencilOutline, mdiTrashCan } from '@mdi/js'
import {
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  IconButton,
  PlusSquareIcon,
  Text,
  Tooltip
} from '@sitecore-ui/design-system'
import FlexGrid from 'components/FlexGrid.js'
import { CollectionModel, ComponentModel } from '@sitecore-feaas/api'
import useConfirmation from 'hooks/useConfirmation.js'
import { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Component from './Component.js'

interface Props {
  collection: CollectionModel
  components: ComponentModel[]
}

const Collection: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { collection, components } = props

  const [showAll, setShowAll] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const getComponentCount = () => {
    return Math.floor((window.innerWidth - 120) / (320 + 24))
  }
  const [componentCount, setComponentCount] = useState(getComponentCount)

  useEffect(() => {
    const listener = () => setComponentCount(getComponentCount)
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  const Icon = showAll ? ChevronUpIcon : ChevronDownIcon

  const navigate = useNavigate()

  const handleEditCollection = () => {
    navigate(collection.getPath())
  }
  const handleDeleteCollection = useConfirmation(
    () => {
      collection.delete()
    },
    {
      title: 'Delete Collection',
      button: 'Delete',
      body: (
        <Text>
          Are you sure you want to delete <strong>{collection.name}</strong>?
        </Text>
      )
    }
  )

  const moreCount = components.length - componentCount

  return (
    <Box bg='gray.100' borderRadius='base' p='6' mt='8' mx='0' w='full'>
      <Flex
        align='center'
        display='inline-flex'
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <Text fontSize='xl' fontWeight='600'>
          {collection.name}
        </Text>

        <Tooltip label='EDIT NAME'>
          <IconButton
            w='5'
            h='5'
            aria-label='edit'
            icon={<Icon path={mdiPencilOutline} w='5' h='5' />}
            style={{ opacity: showEdit ? 1 : 0 }}
            onClick={handleEditCollection}
          />
        </Tooltip>

        <Tooltip label='DELETE'>
          <IconButton
            w='5'
            h='5'
            aria-label='delete'
            icon={<Icon path={mdiTrashCan} w='5' h='5' />}
            onClick={handleDeleteCollection}
            style={{ opacity: showEdit ? 1 : 0 }}
          />
        </Tooltip>
      </Flex>

      <Box pt={4}>
        <FlexGrid spacing='24'>
          {components.map((component, index) => {
            return (showAll || index < componentCount) && <Component key={component.id} component={component} />
          })}
        </FlexGrid>
      </Box>

      <Box mt='4'>
        {components.length > componentCount ? (
          <Button variant='subtle' onClick={(e: any) => setShowAll(!showAll)}>
            <Icon w='4' h='4' mr='2' mt='-1px' />

            <Text>
              {showAll ? `Hide all components` : `View ${moreCount} more component${moreCount == 1 ? '' : 's'}`}
            </Text>
          </Button>
        ) : null}

        <Button variant='subtle' onClick={() => navigate(`${collection.getPath()}/components/new`)}>
          <PlusSquareIcon w='4' h='4' mr='2' mt='-1px' />
          Add component
        </Button>
      </Box>
    </Box>
  )
}

export default Collection
