import { useEffect } from 'react'

const useEntity = ({ id, onEdit, hasChanges, isNew, activeId, editedId }) => {
  const isActive = id === activeId
  const editedExists = editedId && editedId !== id

  useEffect(() => {
    onEdit(hasChanges || isNew ? id : null)
  }, [hasChanges])

  return { hasChanges, isNew: Boolean(isNew), isActive, editedExists }
}

export default useEntity
