import { Button, Grid, GridItem } from '@sitecore-ui/design-system'
import { useApiData } from 'hooks/useApiData.js'
import { ReactElement, useEffect, useState } from 'react'
import { ColorReference } from 'models/style/types/color.js'
import { Unformatted } from 'models/style/types/props.js'
import { ReusableDecorationBoxShadow } from 'models/style/decoration.js'
import { StyleFor } from 'models/style/index.js'
import { findAlpha } from 'models/style/types/color.js'
import { deepMerge } from 'utils/object.js'
import FlexGrid from '../../FlexGrid.js'
import ColorField from '../fields/ColorField.js'
import NumberField from '../fields/NumberField.js'
import OpacityField from '../fields/OpacityField.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import { StyleFormProps } from '../settings.js'

const DecorationBoxShadowFieldset = ({
  onChange,
  currentStyle,
  style,
  isActive
}: StyleFormProps<'decoration'>): ReactElement => {
  const {
    boxShadowInset,
    boxShadowOffsetX,
    boxShadowColor,
    boxShadowOffsetY,
    boxShadowBlurRadius,
    boxShadowSpreadRadius
  } = currentStyle.props

  const colors = useApiData('library.stylesheet.styles').filter((s) => s.type == 'color') as StyleFor<'color'>[]

  const onUpdate = (changes: Unformatted<ReusableDecorationBoxShadow>) => {
    onChange(deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) }))
  }

  const getIndex = () => {
    if (!ReusableDecorationBoxShadow.isValid(currentStyle.props)) {
      return 0
    }

    if (boxShadowInset === false) {
      return 1
    }

    if (boxShadowInset === true) {
      return 2
    }

    return 0
  }

  const [index, setIndex] = useState(getIndex)
  useEffect(() => setIndex(getIndex), [style, isActive])

  useEffect(() => {
    if (index === 0) {
      onUpdate(ReusableDecorationBoxShadow())

      return
    }

    if (index === 1) {
      onUpdate({ boxShadowInset: false })
      return
    }

    if (index === 2) {
      onUpdate({ boxShadowInset: true })
    }
  }, [index])
  return (
    <>
      <SwitchGroupField value={index} onChange={setIndex} mb={6}>
        <Button>None</Button>
        <Button>Outer</Button>
        <Button>Inner</Button>
      </SwitchGroupField>

      {index !== 0 && (
        <FlexGrid>
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem colSpan={1}>
              <NumberField
                label='Horizontal offset'
                value={boxShadowOffsetX.value}
                min={-200}
                max={200}
                onChange={(value: number) => onUpdate({ boxShadowOffsetX: { value } })}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <NumberField
                label='Vertical offset'
                min={-200}
                max={200}
                value={boxShadowOffsetY.value}
                onChange={(value: number) => onUpdate({ boxShadowOffsetY: { value } })}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <NumberField
                label='Spread'
                value={boxShadowSpreadRadius.value}
                onChange={(value: number) => onUpdate({ boxShadowSpreadRadius: { value } })}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <NumberField
                label='Blur'
                value={boxShadowBlurRadius.value}
                onChange={(value: number) => onUpdate({ boxShadowBlurRadius: { value } })}
              />
            </GridItem>
          </Grid>

          <Grid templateColumns='repeat(1, 1fr)' gap={6}>
            <GridItem colSpan={1}>
              <ColorField
                label='Color'
                colorReference={boxShadowColor}
                onChange={(color: ColorReference) => onUpdate({ boxShadowColor: color })}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <OpacityField
                label='Opacity'
                value={findAlpha(boxShadowColor, colors)}
                onChange={(alpha: number) => onUpdate({ boxShadowColor: { alpha } })}
              />
            </GridItem>
          </Grid>
        </FlexGrid>
      )}
    </>
  )
}
export default DecorationBoxShadowFieldset
