import { css } from '@emotion/react'
import {
  mdiApplicationOutline,
  mdiCardMultipleOutline,
  mdiFileDocument,
  mdiFormatLetterCase,
  mdiGestureTapButton,
  mdiImage,
  mdiLink,
  mdiTable,
  mdiToyBrick,
  mdiVariable,
  mdiVideo
} from '@mdi/js'
import { default as useResizeObserver } from '@react-hook/resize-observer'
import { useApiData } from 'hooks/useApiData.js'
import { Editor, ModelElement } from 'hooks/useEditor.js'
import { useEditorBatch } from 'hooks/useEditorStyles.js'
import { useEditorValue } from 'hooks/useEditorValue.js'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import EditorChromeBlockToolbar from './EditorChromeBlockToolbar.js'
import EditorChromeContextToolbar from './EditorChromeContextToolbar.js'
import EditorChromeRangeToolbar from './EditorChromeRangeToolbar.js'
import EditorChromeVariableConfigure from './EditorChromeVariableConfigure.js'
export function getIconPathByElementType(type: string) {
  switch (type) {
    case '$root':
      return mdiFileDocument
    case 'paragraph':
    case 'heading1':
    case 'heading2':
    case 'heading3':
    case 'heading4':
    case 'heading5':
    case 'heading6':
    case 'listItem':
      return mdiFormatLetterCase
    case 'card':
      return mdiCardMultipleOutline
    case 'section':
      return mdiApplicationOutline
    case 'button':
      return mdiGestureTapButton
    case 'imageBlock':
      return mdiImage
    case 'video':
      return mdiVideo
    case 'link':
      return mdiLink
    case 'var':
      return mdiVariable
    case 'table':
      return mdiTable
    case 'embed':
      return mdiToyBrick
  }
}

// height of button is 24px
// clickable area is increased by 6px
// no focus ring, instead it has increased background size
export const ImplicitBorders = css`
  height: 24px;
  min-width: 24px;
  border-radius: 24px;
  position: relative;

  &:not(.primary) {
    color: var(--chakra-colors-blackAlpha-600);
    &:not([disabled]) {
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: #000;
      }
      &[data-active],
      &:active {
        color: var(--chakra-colors-primary-600);
        background-color: var(--chakra-colors-primary-100);
      }
    }
  }
  &:before {
    border: 4px solid transparent;
    transition: 0.2s border-color;
    border-radius: inherit;
    position: absolute;
    content: '';
    left: -4px;
    right: -4px;
    top: -4px;
    bottom: -4px;
  }
`

export type Rect = {
  top: number
  left: number
  width: number
  height: number
}

interface Measurement {
  rect: Rect
  box: Rect
  element: HTMLElement
  styles: CSSStyleDeclaration
}

function getBox(element) {
  const box: Rect = {
    width: element.offsetWidth,
    height: element.offsetHeight,
    top: 0,
    left: 0
  }
  for (var p = element; element && !element.classList.contains('editor'); element = element.offsetParent) {
    box.top += element.offsetTop
    box.left += element.offsetLeft
  }
  return box
}

function measure(element: HTMLElement): Measurement {
  if (!element) return null
  return {
    element,
    rect: element.getBoundingClientRect(),
    box: getBox(element),
    styles: window.getComputedStyle(element)
  }
}

function adjust(measurement: Measurement, rect: Rect): Measurement {
  if (!measurement) return null
  return {
    ...measurement,
    box: {
      left: measurement.box.left + (rect.left - measurement.rect.left),
      top: measurement.box.top + (rect.top - measurement.rect.top),
      width: rect.width,
      height: rect.height
    },
    rect
  }
}

export function getRectStyle(rect: Rect) {
  return rect || { left: -1000, top: -1000 }
}

type Inputs<T> = {
  selection: T
  focusable: T
  variable: T
  context: T
  block: T
}

export interface EditorContextProps {
  editor: Editor
  onCommand: (name: string, ...values) => void | (() => void)
  popover: string
  onPopoverOpen?: (string) => void
  onPopoverClose?: (string) => void
  onPopoverRegister?: (string, callback: any) => void
  context: ModelElement
  block: ModelElement
  variable: ModelElement
  dragged: ModelElement
}

const emptyBox = { left: 0, top: 0, width: 0, height: 0 }
const emptyInputs = {
  selection: null,
  focusable: null,
  variable: null,
  context: null,
  block: null
}

// Chrome is a set of all UI elements
// It attempts to render and reposition them at the same time, to avoid janky movement
export default function EditorChrome({ editor }: { editor: Editor }) {
  const focusable: HTMLElement = useEditorValue(editor.ui, 'lastFocusedEditableElement')
  const apiEditor = useApiData('editor')

  const [isVisible, setIsVisible] = useState(apiEditor.isFocused)
  const ref = useRef()

  useEffect(() => {
    if (!isVisible && apiEditor.isFocused) {
      var timeout = setTimeout(() => {
        setIsVisible(true)
      }, 150)
    } else if (!apiEditor.isFocused) {
      setIsVisible(false)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [apiEditor.isFocused])

  const [batch, startBatch, finishBatch] = useEditorBatch(editor, null)
  const batchFlushRef = useRef<number>()
  const onCommand = useCallback<EditorContextProps['onCommand']>(
    (name, ...values) => {
      editor.model.enqueueChange(startBatch(), () => {
        if (typeof name == 'function') {
          //@ts-ignore
          name()
        } else {
          editor.execute(name, ...values)
        }
        editor.focus()
      })

      cancelAnimationFrame(batchFlushRef.current)
      batchFlushRef.current = requestAnimationFrame(finishBatch)
    },
    [editor]
  )

  const [popover, setPopover] = useState(null)

  const contextProps: typeof editor.current = useEditorValue(editor, 'current')
  const dragged = useEditorValue(editor.draggerPlugin, 'dragged')
  const selectionIndex: number = useEditorValue(editor.contextPlugin, 'selection')

  const [measurements, setMeasured] = useState<Inputs<Measurement>>(() => emptyInputs)
  const [positions, setPositions] = useState<Inputs<Rect>>(() => emptyInputs)

  const recomputing = useRef(null)
  const inputValues = { ...contextProps, dragged, focusable, selectionIndex }
  // values that will be measured within recompute hook
  const inputsRef = useRef(inputValues)
  Object.assign(inputsRef.current, inputValues)
  const currentRef = useRef(null as typeof inputValues)
  if (currentRef.current == null) currentRef.current = { ...inputValues }
  const current = currentRef.current

  const recompute = useCallback(() => {
    cancelAnimationFrame(recomputing.current)
    recomputing.current = requestAnimationFrame(() => {
      try {
        // throw on hot page reload
        var selectionRect: any = editor.contextPlugin.getSelectionRect()
      } catch (e) {}
      Object.assign(current, inputsRef.current)
      setMeasured({
        selection: selectionRect ? adjust(measure(current.focusable), selectionRect) : null,
        focusable: measure(current.focusable),
        variable: measure(editor.utils.modelToDOMElement(current.variable)),
        context: measure(editor.utils.modelToDOMElement(current.context)),
        block: measure(editor.utils.modelToDOMElement(current.block))
      })
    })
  }, [editor])
  useLayoutEffect(recompute, Object.values(inputsRef.current))
  useResizeObserver(document.body, recompute)
  useResizeObserver(editor.utils.modelToDOMElement(current.block), recompute)
  useResizeObserver(editor.utils.modelToDOMElement(current.context), recompute)
  useResizeObserver((focusable?.closest('.variant') || document.body) as HTMLElement, recompute)

  const toolbarConditions = useRef({
    variable: false,
    range: false,
    block: false,
    dragged: false,
    outline: false,
    context: false
  })
  toolbarConditions.current.context = isVisible && !dragged
  toolbarConditions.current.outline = isVisible
  toolbarConditions.current.variable = isVisible && !!current.variable
  toolbarConditions.current.range =
    isVisible &&
    measurements.selection?.box.width > 5 &&
    current.block &&
    isVisible &&
    selectionIndex != null &&
    !current.object
  toolbarConditions.current.block = isVisible && !dragged && !!measurements.block?.box
  toolbarConditions.current.dragged = isVisible && !!dragged

  const setNextPopover = useCallback(
    (closingPopover?: string) => {
      setPopover((popover) => {
        if (closingPopover && closingPopover != popover) {
          return popover
        }
        var nextPopover = popover
        for (const type of ['dragged', 'variable', 'range']) {
          if (toolbarConditions.current[type] && closingPopover != type) {
            nextPopover = type
            break
          } else if (popover == type) {
            nextPopover = null
          }
        }
        if (popover && nextPopover != popover) {
          openPopovers.current[popover]?.()
          openPopovers.current[popover] = null
        }
        return nextPopover
      })
    },
    [toolbarConditions]
  )
  useEffect(setNextPopover, [
    isVisible,
    toolbarConditions.current.dragged,
    toolbarConditions.current.block,
    toolbarConditions.current.range,
    toolbarConditions.current.variable
    // selectionIndex - was causing issues, closing popovers
  ])

  const openPopovers = useRef<Record<string, any>>({})
  const registerPopover = (string, callback) => {
    openPopovers.current[string] = callback
  }

  const setTriggeredPopover = useCallback((nextPopover) => {
    setPopover((popover) => {
      if (popover && nextPopover != popover) {
        openPopovers.current[popover]?.()
        openPopovers.current[popover] = null
      }
      return nextPopover
    })
  }, [])

  const props: EditorContextProps = {
    editor,
    onCommand,
    popover,
    onPopoverOpen: setTriggeredPopover,
    onPopoverClose: setNextPopover,
    onPopoverRegister: registerPopover,
    ...inputsRef.current,
    dragged
  }

  useEffect(() => {
    const selectionOrBlock = measurements.selection?.box // || measurements.block?.box;
    if (selectionOrBlock && current.block) {
      var blockHeight = 30
      var blockWidth = 30
      var blockBox = {
        top: selectionOrBlock.top + selectionOrBlock.height / 2 - blockHeight / 2,
        left: measurements.context?.box.left - blockWidth,
        width: blockWidth,
        height: blockHeight
      }
    }
    console.log('set positions')
    setPositions((previous = emptyInputs) => ({
      ...previous,
      context: measurements.context?.box || previous.context,
      variable: measurements.variable?.box || previous.variable,
      selection: toolbarConditions.current.range ? measurements.selection.box : previous.selection,
      block: (toolbarConditions.current.block && blockBox) || previous.block
    }))
  }, [
    toolbarConditions.current.block,
    toolbarConditions.current.range,
    current.context,
    ...Object.values(measurements)
      .map((m) => Object.values(m?.box || emptyBox))
      .flat()
  ])

  useEffect(() => {
    const headerHeight = 62
    if (
      measurements.context?.rect.top > window.innerHeight - headerHeight ||
      measurements.context?.rect.top + measurements.context?.rect.height < headerHeight
    ) {
      if (isVisible)
        editor.utils.modelToDOMElement(current.context)?.scrollIntoView({
          behavior: 'smooth',
          block: current.context.root == current.context ? 'start' : 'center'
        })
    }
  }, [...Object.values(positions.context || emptyBox)])

  const rangeToolbar = useMemo(
    () => (
      <EditorChromeRangeToolbar {...props}>
        <div
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            ...getRectStyle(positions.selection)
          }}
        ></div>
      </EditorChromeRangeToolbar>
    ),
    [popover == 'range', ...Object.values(positions.selection || emptyBox)]
  )

  const variableConfigure = useMemo(
    () => (
      <EditorChromeVariableConfigure {...props}>
        <div
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            ...getRectStyle(positions.variable)
          }}
        ></div>
      </EditorChromeVariableConfigure>
    ),
    [popover == 'variable', ...Object.values(positions.variable || emptyBox)]
  )

  const contextToolbar = useMemo(() => <EditorChromeContextToolbar {...props} />, [current.context, current.dragged])
  const blockToolbar = useMemo(() => <EditorChromeBlockToolbar {...props} />, [popover == 'block', current.block?.name])

  useMemo(() => {
    console.info('Render Var')
  }, [variableConfigure])
  useMemo(() => {
    console.info('Render Range')
  }, [rangeToolbar])
  useMemo(() => {
    console.info('Render Context: ' + current.context?.name)
  }, [contextToolbar])
  useMemo(() => {
    console.info('Render Block: ' + current.block?.name)
  }, [blockToolbar])

  return useMemo(() => {
    const { top, left } = getRectStyle(positions.context)
    return (
      <div
        className={isVisible ? 'ui ui-visible' : 'ui'}
        style={{
          position: 'relative',
          pointerEvents: isVisible ? 'all' : 'none'
        }}
        ref={ref}
      >
        {rangeToolbar}
        {variableConfigure}
        <div
          style={{
            outline: '1px dashed var(--chakra-colors-primary-500)',
            outlineOffset: '2px',
            position: 'absolute',
            zIndex: 3,
            pointerEvents: 'none',
            borderRadius: '4px',
            opacity: toolbarConditions.current.outline ? 1 : 0,
            transition: 'opacity 0.2s',
            ...getRectStyle(positions.context)
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            zIndex: 5,
            opacity: toolbarConditions.current.context ? 1 : 0,
            transition: 'opacity 0.2s',
            pointerEvents: isVisible ? 'all' : 'none',
            top,
            left
          }}
        >
          {contextToolbar}
        </div>
        <div
          style={{
            position: 'absolute',
            marginLeft: '-5px',
            ...getRectStyle(positions.block),
            height: '30px',
            width: '30px',
            zIndex: 4,
            pointerEvents: toolbarConditions.current.block ? 'all' : 'none',
            transition: 'opacity 0.2s',
            opacity: toolbarConditions.current.block ? 1 : 0
          }}
        >
          {blockToolbar}
        </div>
        <input
          style={{
            position: 'fixed',
            top: 0,
            left: -1000,
            opacity: 0.01
          }}
          id='upload-images'
          type='file'
          accept='.png,.gif,.jpg,.jpeg'
          multiple={true}
          onChange={(e) => {
            if (e.target.files?.[0]) {
              onCommand('moveToApplicableContext', 'imageBlock')
              onCommand('moveToContextEnd')
              onCommand('uploadImage', { file: e.target.files[0] })
            }
            e.target.value = null
          }}
        />
      </div>
    )
  }, [isVisible, popover, positions])
}
