import { useAuth0, User } from '@auth0/auth0-react'
import { useCallbackRef, useToast } from '@sitecore-ui/design-system'
import { API, APIUser, LibraryModel } from '@sitecore-feaas/api'
import { internalStyles } from 'models/style/index.js'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { QueryStringContext } from './QueryStringProvider.js'

type APIStatus = 'loading' | 'authenticating' | 'fetching' | 'ready'
export const APIContext = createContext({
  api: null as API,
  library: null as LibraryModel,
  user: null as User,
  status: null as APIStatus
})
const getLibraryIdFromURL = (): string => {
  const match = location.pathname.match(/libraries\/([^\/?]+)/)
  if (match) return match[1]
  else return null
}
export default function APIProvider({ children }) {
  const { isAuthenticated, isLoading: isAuthenticating, user, getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const toast = useToast()
  const libraryId = getLibraryIdFromURL()

  const api = useMemo(
    () =>
      new API({
        cdnHostname: `https://${import.meta.env.VITE_AZURE_BLOB_STORAGE_ACCOUNT_NAME}.blob.core.windows.net`,
        hostname: import.meta.env.VITE_BACKEND_ENDPOINT_URL,
        onError: (e: Error, message: string) => {
          requestAnimationFrame(() => {
            toast({
              isClosable: false,
              duration: 4000,
              status: 'error',
              title: message,
              description: e.message
            })
          })
        }
      }),
    []
  )
  const [status, setStatus] = useState<APIStatus>('loading')
  const [library, setLibrary] = useState<LibraryModel>()
  const navigate = useNavigate()
  const [query] = useContext(QueryStringContext)

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      loginWithRedirect()
    }
  }, [isAuthenticating, isAuthenticated])

  // ?redirectPath allows redirecting back to desired after login
  // as auth0 redirects app back to homepage
  useEffect(() => {
    if (query.redirectPath) {
      requestAnimationFrame(() => {
        navigate(query.redirectPath)
      })
    }
  }, [query.redirectPath])

  // Preflight runs on every API request allowing to refresh token
  useMemo(() => {
    if (isAuthenticated && user?.sub) {
      api.setUserFromAuth0(user)

      api.preflight = async (url, options) => {
        api.accessToken = await getAccessTokenSilently({
          audience: import.meta.env.VITE_AUTH0_AUDIENCE
        })
        return options
      }
    }
  }, [getAccessTokenSilently, user?.sub, isAuthenticated])

  // get Tenant and Project data
  useEffect(() => {
    if (isAuthenticated) {
      const tenantName = query?.tenantName || localStorage.getItem('lastTenantName')
      // if no tenant provided or stored, gets first tenant available
      try {
        getAccessTokenSilently({
          audience: import.meta.env.VITE_AUTH0_AUDIENCE
        }).then((accessToken) => {
          api
            .proxy(
              `${import.meta.env.VITE_INVENTORY_API}/api/inventory/v1/tenants?${
                tenantName ? `name=${tenantName}&` : ''
              }state=Active&pageSize=1`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`
                }
              }
            )
            .then(async ({ data }) => {
              let tenantData = data

              // fallback mechanism: if user has no longer access to tenant,
              // or tenant doesn't exist anymore, bring first tenant that user has access to
              if (!data.length) {
                const fallbackRes = await api.proxy(
                  `${import.meta.env.VITE_INVENTORY_API}/api/inventory/v1/tenants?state=Active&pageSize=1`,
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken}`
                    }
                  }
                )
                tenantData = fallbackRes.data
              }

              if (tenantData.length) localStorage.setItem('lastTenantName', tenantData[0].name)

              // if no tenant for user, then return null and assign default demo library
              const projectId =
                tenantData.length && tenantData[0].annotations
                  ? LibraryModel.generateProjectIdFromTenant(tenantData[0])
                  : null

              api.library = new api.Library()
              api.library.id = libraryId || projectId || 'demo-site1'
              decorateLibrary()
              setLibrary(api.library)
            })
        })
      } catch {
        api.library = new api.Library()
        api.library.id = 'demo-site1'
        decorateLibrary()
        setLibrary(api.library)
      }
    }
  }, [isAuthenticated])

  // Display loading splash screen until all data is preloaded
  useEffect(() => {
    if (library && isAuthenticated) {
      setStatus('fetching')

      library
        // Need to load library first, in case it's a new tenant it will provision default collection & stylesheets
        .get()
        .then(({ apiKey }) => {
          api.apiKey = apiKey
          return Promise.all([library.collections.fetch(), library.datasources.fetch(), library.stylesheet.get()])
        })
        .then(() => {
          setStatus('ready')
          // builder needs some extra time to load, so we allow it
          if (!location.pathname.startsWith(`${library.getPath()}/components`))
            document.body.classList.remove('loading')
        })
    }
  }, [library, isAuthenticated])

  const contextValues = useMemo(
    () => ({
      api,
      library,
      user,
      status
    }),
    [api, library, user, status]
  )

  const decorateLibrary = () => {
    api.utils.decorate(api.Library.prototype, 'error', 'get', (e) => api.reportError(e, 'Could not fetch library'))

    api.utils.decorate(api.library.collections, 'success', 'fetch', function (collections) {
      api.library.components.setItems(
        collections.reduce((components, collection) => components.concat(collection.components), [])
      )
    })
    api.utils.decorate(api.library.collections, 'error', 'fetch', (e) =>
      api.reportError(e, 'Could not fetch collections')
    )

    api.utils.decorate(api.Collection.prototype, 'success', 'put', function (collection) {
      api.library.collections.updateItem(collection)
    })
    api.utils.decorate(api.Collection.prototype, 'error', 'put', (e) =>
      api.reportError(e, 'Could not update collection')
    )

    api.utils.decorate(api.Collection.prototype, 'success', 'post', function () {
      api.library.collections.addItem(this)
    })
    api.utils.decorate(api.Collection.prototype, 'error', 'post', (e) =>
      api.reportError(e, 'Could not create collection')
    )

    // Deletion of components is optimistic
    api.utils.decorate(api.Collection.prototype, 'before', 'delete', function () {
      api.library.collections.removeItem(this)
    })
    api.utils.decorate(api.Collection.prototype, 'success', 'delete', function () {
      api.library.collections.fetch()
    })
    api.utils.decorate(api.Collection.prototype, 'error', 'delete', (e) =>
      api.reportError(e, 'Could not delete collection')
    )

    api.utils.decorate(api.Component.prototype, 'success', 'put', function () {
      api.library.components.updateItem(this)
    })
    api.utils.decorate(api.Component.prototype, 'error', 'put', (e) => api.reportError(e, 'Could not update component'))

    api.utils.decorate(api.Component.prototype, 'success', 'post', function () {
      api.library.components.addItem(this)
    })
    api.utils.decorate(api.Component.prototype, 'error', 'post', (e) =>
      api.reportError(e, 'Could not create component')
    )

    api.utils.decorate(api.library.collections, 'error', 'fetch', (e) =>
      api.reportError(e, 'Could not fetch collections')
    )

    api.utils.decorate(api.Variant.prototype, 'error', 'post', (e) =>
      api.reportError(e, 'Could not create variant version')
    )
    api.utils.decorate(api.Variant.prototype, 'error', 'put', (e) => api.reportError(e, 'Could not update variant'))

    api.utils.decorate(api.Component.prototype, 'success', 'delete', function () {
      api.library.components.updateItem({ ...this.export(), status: 'deleted' })
    })
    api.utils.decorate(api.Component.prototype, 'error', 'delete', (e) =>
      api.reportError(e, 'Could not delete component')
    )
    api.utils.decorate(api.library.datasources, 'error', 'fetch', (e) =>
      api.reportError(e, 'Could not fetch datasources')
    )

    // Datasources update their content optimistically
    api.utils.decorate(api.Datasource.prototype, 'before', 'put', function () {
      api.library.datasources.updateItem(this)
    })
    api.utils.decorate(api.Datasource.prototype, 'error', 'put', function (e) {
      api.library.datasources.updateItem(this.snapshotted)
      navigate(`${library.getPath()}/datasources/${this.id}`, {
        state: this,
        replace: true
      })
      api.reportError(e, 'Could not update datasource')
    })
    api.utils.decorate(api.Datasource.prototype, 'before', 'post', function () {
      this.isNew = false
      api.library.datasources.addItem(this)
    })
    api.utils.decorate(api.Datasource.prototype, 'error', 'post', function (e) {
      this.isNew = true
      api.library.datasources.updateItem(this.snapshotted)
      navigate(`${library.getPath()}/datasources/${this.id}`, {
        state: this,
        replace: true
      })
      api.reportError(e, 'Could not create datasource')
    })

    api.utils.decorate(api.Datasource.prototype, 'before', 'delete', function () {
      api.library.datasources.removeItem(this)
    })
    api.utils.decorate(api.Datasource.prototype, 'error', 'delete', function (e) {
      api.library.datasources.addItem(this)
      api.reportError(e, 'Could not delete datasource')
    })

    api.utils.decorate(api.Stylesheet.prototype, 'success', 'get', (stylesheet) => {
      stylesheet.styles.setItems(internalStyles.concat(stylesheet.source))
    })
    api.utils.decorate(api.library.stylesheets, 'error', 'fetch', (e) => api.reportError(e, 'Could not fetch styles'))

    api.utils.decorate(api.Stylesheet.prototype, 'error', 'put', (e) => api.reportError(e, 'Could not save styles'))
  }

  if (status == 'loading' || status == 'fetching') {
    return null
  }

  return <APIContext.Provider value={contextValues}>{children}</APIContext.Provider>
}
