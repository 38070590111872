import { useToast } from '@sitecore-ui/design-system'
import EditorCanvas from 'components/builder/EditorCanvas.js'
import { useApi, useApiData } from 'hooks/useApiData.js'
import { Editor as EditorModel } from 'models/editor/index.js'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
export default function Editor() {
  const navigate = useNavigate()
  const { componentId } = useParams()
  const { api, status: appStatus } = useApi()
  const toast = useToast()

  api.editor = useMemo(() => new EditorModel().setDefaults(), [])
  useEffect(() => () => (api.editor = null), [])

  const library = useApiData('library')
  const component = useApiData('library.components').find((c) => c.id == componentId)
  const [editedComponent, setEditedComponent] = useState(component)
  const collection = useApiData('library.collections').find((c) => c.id == component?.collectionId)
  const datasources = useApiData('library.datasources')
  const styles = useApiData('library.stylesheet.styles')
  useEffect(() => {
    editedComponent?.variants.fetch().then((variants) => {
      if (!variants.length) {
        editedComponent.addVariant()
      }
    })
  }, [editedComponent])

  useEffect(() => {
    if (appStatus == 'ready' && !component) {
      toast({
        isClosable: false,
        duration: 4000,
        status: 'warning',
        title: `Component was not found`,
        description: `Returning you back to the library`
      })
      document.body.classList.remove('loading')
      navigate(`${library.getPath()}/collections`)
    } else if (appStatus === 'ready' && !editedComponent) {
      setEditedComponent(component)
    }
  }, [appStatus, component])

  return <EditorCanvas collection={collection} component={editedComponent} datasources={datasources} styles={styles} />
}
