import React, { FunctionComponent, ReactElement } from 'react'
import { Flex, Text } from '@sitecore-ui/design-system'

interface InfoModel {
  title: string
  value: string
  passedProps: any
}

const ComponentInfo: FunctionComponent<InfoModel> = (props: InfoModel): ReactElement => {
  const { title, value, passedProps } = props

  return (
    <Flex {...passedProps}>
      <Text fontSize='sm' whiteSpace='nowrap'>
        {title}:
      </Text>

      <Text ml={1} fontSize='sm' fontWeight='500' noOfLines={0} display='block' whiteSpace={'nowrap'}>
        {value}
      </Text>
    </Flex>
  )
}

export default ComponentInfo
