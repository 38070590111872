import { parse, ParsedQuery, ParseOptions, stringify, StringifyOptions } from 'query-string'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

export type QueryStringResult = [ParsedQuery, Dispatch<SetStateAction<Record<string, any>>>]

const defaultStringifyOptions: StringifyOptions = {
  skipNull: true
}
const defaultParseOptions: ParseOptions = {
  parseNumbers: false,
  parseBooleans: false
}

// forked from https://github.com/trevorblades/use-query-string/blob/main/src/index.ts
// rewritten to always parse query string, to play well with next.js
// original version only parsed one and stuck with internal state

export function useQueryString(location: Location, navigate: (path: string) => void): QueryStringResult {
  const state: ParsedQuery = useMemo(() => {
    return parse(location.search, defaultParseOptions)
  }, [location.search])

  const setQuery = useCallback(
    (values: ParsedQuery | ((ParsedQuery) => ParsedQuery)): void => {
      const nextState = typeof values === 'function' ? values(state) : values
      navigate(
        location.pathname +
          '?' +
          stringify(
            {
              ...state,
              ...nextState
            },
            defaultStringifyOptions
          )
      )
    },
    [state]
  )

  return [state, setQuery]
}

const useWindowQueryUpdate = () => {
  const navigate = useNavigate()
  const [query, setQuery] = useQueryString(window.location, (path) => navigate(path))
  return [query, setQuery]
}

export default useWindowQueryUpdate
