import { Flex, Icon, Text } from '@sitecore-ui/design-system'
import { mdiAlertCircleOutline } from '@mdi/js'

const FormError = ({ error }) => {
  return (
    <Flex alignItems='center' color='red' mt='2'>
      <Icon path={mdiAlertCircleOutline} aria-label='error' mr='1' />
      <Text variant='microcopy' color='red' fontSize='xs'>
        {error}
      </Text>
    </Flex>
  )
}
export default FormError
