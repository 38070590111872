import FlexGrid from '../../FlexGrid.js'
import { StyledSelect } from '../../StyledSelect.js'
import { FunctionComponent, ReactElement } from 'react'
import { StyleFor } from 'models/style/index.js'
import { StyleFormProps } from '../settings.js'
import { Grid, GridItem, NumberInputField } from '@sitecore-ui/design-system'
import NumberField from '../fields/NumberField.js'
import { Unformatted } from 'models/style/types/props.js'
import { deepMerge } from 'utils/object.js'
import ReusableFill from 'models/style/fill.js'
import OpacityField from '../fields/OpacityField.js'

interface Props {
  currentStyle: StyleFor<'fill'>
  onChange: (style: StyleFor<'fill'>) => void
}

const FillEffectsFieldset = ({ currentStyle, onChange }: StyleFormProps<'effects'>) => {
  const onUpdate = (changes: Unformatted<ReusableFill>) => {
    onChange(deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) }))
  }

  const { blur, opacity } = currentStyle.props

  return (
    <FlexGrid>
      <Grid templateColumns='repeat(1, 1fr)' gap={6}>
        <GridItem colSpan={1}>
          <NumberField value={blur.value} label='Blur' onChange={(value) => onUpdate({ blur: { value } })} />
        </GridItem>
        <GridItem colSpan={1}>
          <OpacityField value={opacity} label='Blur' onChange={(opacity) => onUpdate({ opacity })} />
        </GridItem>
      </Grid>
    </FlexGrid>
  )
}

export default FillEffectsFieldset
