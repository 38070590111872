import { Box, Button, HStack, VStack } from '@sitecore-ui/design-system'
import ButtonGroupSwitch from 'components/ButtonGroupSwitch.js'
import SizeField from 'components/styles/fields/SizeField.js'
import { reusable } from 'components/styles/settings.js'
import type { Editor, ModelElement } from 'hooks/useEditor.js'
import useEditorStyles, {
  getAspectStyles,
  getCurrentStyle,
  getElementActiveStyles,
  getElementEffectiveStyles,
  getElementStyle
} from 'hooks/useEditorStyles.js'
import { useApiData } from 'hooks/useApiData.js'
import { useState } from 'react'
import { StyleFor } from 'models/style/index.js'
import { stringifyLength } from 'models/stylesheet/css.js'
import { deepMerge } from 'utils/object.js'

interface Props {
  editor: Editor
  context: ModelElement
}

export default function EditorDialogDimensions({ editor, context }: Props) {
  const styles = useApiData('library.stylesheet.styles')
  // get all custom styles existing inside variant
  const [customStyles, setElementStyle] = useEditorStyles(editor, context)
  // merge them with style guides styles
  const effectiveStyles = getElementEffectiveStyles(context, customStyles, styles)
  // determine which of them are active on the given element
  const activeStyles = getElementActiveStyles(context, effectiveStyles)
  // get the definition of context style
  const elementStyle = getElementStyle(context, effectiveStyles)
  // get spacing options for selected element style
  const allowedSpacingStyles = !elementStyle
    ? activeStyles
    : getAspectStyles(elementStyle, effectiveStyles, reusable.dimensions)
  // get currently active spacing rule
  const style = (getCurrentStyle(allowedSpacingStyles, activeStyles) || {
    type: 'dimensions',
    props: {},
    details: {
      title: 'dimensions'
    }
  }) as StyleFor<'dimensions'>

  const [index, setIndex] = useState(() => {
    return stringifyLength(style.props.minHeight, null) == stringifyLength(style.props.maxHeight, null) &&
      stringifyLength(style.props.minWidth, null) == stringifyLength(style.props.maxWidth, null)
      ? 0
      : 1
  })

  const onChangeIndex = (index) => {
    setIndex(index)

    if (index == 0) {
      setProperties({
        maxWidth: style.props.maxWidth,
        maxHeight: style.props.maxHeight
      })
    } else {
      setProperties({
        maxWidth: style.props.minWidth,
        maxHeight: style.props.minHeight
      })
    }
  }

  function setProperties(props: Partial<typeof style.props>) {
    setElementStyle(
      context,
      deepMerge(style, {
        props: props
      })
    )
  }

  return (
    <VStack spacing={4} alignItems={'stretch'}>
      <Box>
        <ButtonGroupSwitch index={index} onChange={onChangeIndex}>
          <Button>Simple</Button>
          <Button>Advanced</Button>
        </ButtonGroupSwitch>
      </Box>

      {index == 0 && (
        <HStack spacing={4}>
          <SizeField
            label='Width'
            length={style.props.minWidth}
            allowEmpty={true}
            onChange={(value) => {
              setProperties({
                minWidth: value,
                maxWidth: value
              })
            }}
          />
          <SizeField
            label='Height'
            allowEmpty={true}
            length={style.props.minHeight}
            onChange={(value) => {
              setProperties({
                minHeight: value,
                maxHeight: value
              })
            }}
          />
        </HStack>
      )}

      {index == 1 && (
        <VStack alignItems={'stretch'} spacing='4'>
          <HStack spacing={4}>
            <SizeField
              label='Min-width'
              allowEmpty={true}
              length={style.props.minWidth}
              onChange={(value) => {
                setProperties({
                  minWidth: value
                })
              }}
            />
            <SizeField
              label='Min-height'
              allowEmpty={true}
              length={style.props.minHeight}
              onChange={(value) => {
                setProperties({
                  maxHeight: value
                })
              }}
            />
          </HStack>
          <HStack spacing={4}>
            <SizeField
              label='Max-width'
              allowEmpty={true}
              length={style.props.maxWidth}
              onChange={(value) => {
                setProperties({
                  maxWidth: value
                })
              }}
            />
            <SizeField
              allowEmpty={true}
              label='Max-height'
              length={style.props.maxHeight}
              onChange={(value) => {
                setProperties({
                  maxHeight: value
                })
              }}
            />
          </HStack>
        </VStack>
      )}
    </VStack>
  )
}
