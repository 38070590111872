import React, { useRef } from 'react'
import { default as styled } from '@emotion/styled'
import { StyleFor } from 'models/style/index.js'
import { rgbaToString } from 'models/style/types/color.js'
import { Box, Flex, H3, SimpleGrid, Text } from '@sitecore-ui/design-system'

const StyledSimpleGrid = styled<any>(SimpleGrid)``

interface Props {
  style: StyleFor<'color'>
}

const Wrapper = styled.div`
  padding-top: 3rem;
  display: flex;
  margin: 0 -6px;
`

const ColorsPreview = (props: Props) => {
  const { style } = props

  const colors = style.props.colors || []

  const ref = useRef()

  // @ts-ignore
  const cardsLength = Math.floor((ref?.current?.offsetWidth - 48 - 40) / 182)
  // deduct paddings and number

  const colorGroupsLength = colors.length

  return (
    <Wrapper ref={ref}>
      {colors.map(
        (color, index) =>
          index < cardsLength && (
            <Box key={index} mx='6px'>
              <Box
                bg={rgbaToString(color.color)}
                borderRadius='base'
                h='70px'
                w='176px'
                border='1px solid'
                borderColor='blackAlpha.400'
              />

              <Text mt='2'>{color.name}</Text>
            </Box>
          )
      )}

      {cardsLength < colorGroupsLength && (
        <Flex align='center'>
          <H3>+ {colorGroupsLength - cardsLength}</H3>
        </Flex>
      )}
    </Wrapper>
  )
}

export default ColorsPreview
