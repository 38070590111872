import {
  mdiApplicationOutline,
  mdiArrowExpandHorizontal,
  mdiCardMultipleOutline,
  mdiDotsGrid,
  mdiDrawing,
  mdiFormatColorFill,
  mdiFormatColorText,
  mdiFormatLetterCase,
  mdiFormatTitle,
  mdiGestureTapButton,
  mdiPaletteOutline,
  mdiShapePlus
} from '@mdi/js'
import { Style } from 'models/style/index.js'

type CollectionIds = 'basics' | 'reusables' | 'elements' | 'blocks' | 'scene'

interface Collection {
  title: string
  id: CollectionIds
}

export const sidebarCollections: Collection[] = [
  { title: 'BASICS', id: 'basics' },
  { title: 'REUSABLE STYLES', id: 'reusables' },
  { title: 'ELEMENTS', id: 'elements' },
  { title: 'BLOCKS', id: 'blocks' }
  // {title: "SCENE", id: "scene"}
]

export interface StylePage {
  type: Style['type'] | string
  title: string
  description: string
  icon: any
  collectionId: CollectionIds
  disabled?: boolean
}

export const sidebarStyles: StylePage[] = [
  {
    type: 'font',
    title: 'Font selector',
    description: 'Typefaces and variants',
    icon: mdiFormatColorText,
    collectionId: 'basics'
  },
  {
    type: 'color',
    title: 'Colors',
    description: 'Groups and alternatives',
    icon: mdiPaletteOutline,
    collectionId: 'basics'
  },
  {
    type: 'icon',
    title: 'Graphics',
    description: 'Lorem Ipsum',
    icon: mdiDrawing,
    collectionId: 'basics',
    disabled: true
  },
  {
    type: 'typography',
    title: 'Typographies',
    description: 'Font variants with sizes',
    icon: mdiFormatTitle,
    collectionId: 'reusables'
  },
  {
    type: 'decoration',
    title: 'Decorations',
    description: 'Block-level visual accent',
    icon: mdiShapePlus,
    collectionId: 'reusables'
  },
  {
    type: 'fill',
    title: 'Fills',
    description: 'Block-level backgrounds',
    icon: mdiFormatColorFill,
    collectionId: 'reusables'
  },
  {
    type: 'spacing',
    title: 'Spacings',
    description: 'Lorem Ipsum',
    icon: mdiArrowExpandHorizontal,
    collectionId: 'reusables'
  },
  {
    type: 'text',
    title: 'Text blocks',
    description: 'Lorem Ipsum',
    icon: mdiFormatLetterCase,
    collectionId: 'elements'
  },
  {
    type: 'button',
    title: 'Buttons',
    description: 'Lorem Ipsum',
    icon: mdiGestureTapButton,
    collectionId: 'elements'
  },
  {
    type: 'card',
    title: 'Cards',
    description: 'Lorem Ipsum',
    icon: mdiCardMultipleOutline,
    collectionId: 'elements'
  },
  {
    type: 'section',
    title: 'Sections',
    description: 'Lorem Ipsum',
    icon: mdiApplicationOutline,
    collectionId: 'elements'
  },
  {
    type: 'accordion',
    title: 'Accordions',
    description: 'Lorem Ipsum',
    icon: mdiDotsGrid,
    collectionId: 'blocks',
    disabled: true
  },
  {
    type: 'tab',
    title: 'Tabs',
    description: 'Lorem Ipsum',
    icon: mdiDotsGrid,
    collectionId: 'blocks',
    disabled: true
  },
  {
    type: 'carousel',
    title: 'Carousels',
    description: 'Lorem Ipsum',
    icon: mdiDotsGrid,
    collectionId: 'blocks',
    disabled: true
  }
  // {
  //     type: "breakpoint",
  //     title: "Breakpoints",
  //     description: "Lorem Ipsum",
  //     icon: mdiResponsive,
  //     collectionId: "scene",
  //     disabled: true
  // },
  // {
  //     type: "grid",
  //     title: "Column Grids",
  //     description: "Lorem Ipsum",
  //     icon: mdiViewColumnOutline,
  //     collectionId: "scene",
  //     disabled: true
  // },
  // {
  //     type: "layout",
  //     title: "Layouts",
  //     description: "Lorem Ipsum",
  //     icon: mdiDotsGrid,
  //     collectionId: "scene",
  //     disabled: true
  // },
  // {
  //     type: "space",
  //     title: "Spaces",
  //     description: "Lorem Ipsum",
  //     icon: mdiArrowExpandHorizontal,
  //     collectionId: "scene",
  //     disabled: true
  // },
]
