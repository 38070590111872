import React, { FunctionComponent, ReactElement, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { StyledSelect } from '../../StyledSelect.js'
import { Option } from 'types/index.js'
import { Length, Unit } from 'models/style/types/length.js'
import {
  FormControl,
  FormLabel,
  Input,
  InputLeftElement,
  InputGroup,
  Icon,
  Flex,
  Box,
  NumberInput,
  NumberInputField
} from '@sitecore-ui/design-system'

interface Props {
  length: Length
  placeholder?: string
  units?: any[]
  label?: string
  icon?: string
  min?: number
  max?: number
  allowEmpty?: boolean
  onChange: (length: Length) => void
}

const defaultUnits = ['px', '%', 'em', 'rem']

// SizeField has to be able accept null for value
// For things like typography optional font-size
const SizeField: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { icon, units = defaultUnits, placeholder, length, onChange, label, min = 0, max, allowEmpty } = props

  const [unit, setUnit] = useState(length?.unit || 'px')
  const [current, setCurrent] = useState<any>(length?.value)

  useEffect(() => {
    if (length?.value != (allowEmpty ? null : 0) || current != '') setCurrent(length?.value)
  }, [length?.value])

  // using useEffect  here can create a situation where changes de-sync with upstream
  // if typing too rapidly. This slows things down by commiting changes synchronously
  useEffect(() => {
    var value = length?.value
    if (current == '' && allowEmpty) {
      value = null
    } else if (current == '') {
      value = 0
    } else if (current == '-') {
      value == 0
    } else {
      value = current
    }
    if (unit != null && value != null) {
      onChange(Length({ unit, value }))
    } else if (length?.value != null) {
      onChange(null)
    }
  }, [unit, current])

  const unitOptions = useMemo(
    () =>
      units.map((unit) => ({
        label: unit,
        value: unit
      })),
    units
  )
  return (
    <Box>
      <FormLabel>{label}</FormLabel>

      <FormControl>
        <Flex>
          <InputGroup flex={4}>
            {icon && <InputLeftElement children={<Icon w='5' h='5' path={icon} />} />}
            <NumberInput
              min={min}
              max={max}
              value={current}
              onChange={(valueAsString, valueAsNumber) => {
                setCurrent(Number.isNaN(valueAsNumber) ? valueAsString : valueAsNumber)
              }}
              onBlur={() => {
                if (Number.isNaN(current) || (current == '' && !allowEmpty)) setCurrent(0)
              }}
              inputMode='numeric'
            >
              <NumberInputField {...(icon ? { pl: '36px' } : {})} placeholder={placeholder} />
            </NumberInput>
          </InputGroup>

          <Box flex={2} ml={1}>
            <StyledSelect
              options={unitOptions}
              value={{ value: unit, label: unit }}
              onChange={(option: Option<Unit, Unit>) => setUnit(option.value)}
            />
          </Box>
        </Flex>
      </FormControl>
    </Box>
  )
}

export default SizeField
