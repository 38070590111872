import {
  mdiContentPaste,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiEyeOutline,
  mdiGhostOutline,
  mdiLink,
  mdiSync,
  mdiScriptOutline,
  mdiToyBrick
} from '@mdi/js'
import {
  Alert,
  AlertButton,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  Switch,
  Text,
  Textarea
} from '@sitecore-ui/design-system'
import { mdiArrowUpLeft } from '@mdi/js'

import { Editor, ModelElement } from 'hooks/useEditor.js'
import { useEditorBatch } from 'hooks/useEditorStyles.js'
import { ReactElement, useCallback, useReducer, useRef } from 'react'
import { EditorContextProps, ImplicitBorders } from './EditorChrome.js'
import { DialogMenuItem, EditorDialogMenu } from './EditorDialog.js'
import EditorDialogAttributes from './EditorDialogAttributes.js'
import EditorDialogAttributesFallback from './EditorDialogAttributesFallback.js'
import EditorDialogAttributesComponent from './EditorDialogAttributesComponent.js'

export type AttributeCallback = (context: ModelElement, attribute: string, value: string | boolean | null) => void

export interface ConfigureMenuItem extends DialogMenuItem {
  value: any
  type: 'url' | 'html' | 'image' | 'string' | 'boolean' | 'array' | 'object' | 'custom'
  jsonpath: string
  onConfigure: (value: any, attribute?: string) => void
  onChange: (value: any, attribute?: string) => void
  labels: {
    none?: string
    static?: string
    mapped?: string
  }
  [key: string]: any
  badge: (props: typeof this) => ReactElement
}
export function cleanCollectionBit(path) {
  return path.replace(/(\[[^\]\[]+\]|\.\*)$/g, '')
}

export function getLastPathBit(path) {
  return cleanCollectionBit(path).split('.').pop()
}
export function getHumanizedLabel(path, maxBits = 2) {
  var bits = cleanCollectionBit(path)
    .split(/]\.|\.\*\./g)
    .pop()
    .split(/\./g)
    .slice(-3)
  bits = bits.filter((b) => isNaN(parseInt(b)))
  return bits
    .map((bit) => bit.charAt(0).toUpperCase() + bit.slice(1))
    .slice(-maxBits)
    .join(' ')
}

export function EditorDialogAttributeBadge({ type, label, labels, value, jsonpath }: ConfigureMenuItem) {
  return (
    <>
      {jsonpath && (
        <>
          {label == 'Visibility' && (
            <Text variant='microcopy' mr={-3} ml={4} whiteSpace='nowrap'>
              based on
            </Text>
          )}
          <Badge
            colorScheme={'teal'}
            ml={4}
            mr='2'
            display={'block'}
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
          >
            {getLastPathBit(jsonpath)}
          </Badge>
        </>
      )}
      {!jsonpath && value == '' && (
        <Badge
          colorScheme={'red'}
          ml={4}
          mr='2'
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          Empty {type == 'url' ? 'URL' : type == 'string' ? 'Text' : null}
        </Badge>
      )}
      {!jsonpath && typeof value == 'boolean' && (
        <Badge
          colorScheme={'gray'}
          ml={4}
          mr='2'
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          {value == true ? labels.static : labels.none}
        </Badge>
      )}
      {!jsonpath && value != '' && typeof value !== 'boolean' && (
        <Text
          fontWeight='normal'
          fontSize='12px'
          ml={4}
          mr='2'
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          {value == '' ? <em></em> : value}
        </Text>
      )}
    </>
  )
}

export const getContextProperties = (
  editor: Editor,
  context: ModelElement,
  onChange: AttributeCallback,
  onConfigure: AttributeCallback
) => {
  // dive into image caption
  var generalContext = context
  if (context.name != 'var') {
    for (var c of context.getChildren()) {
      if (c.is('element') && c.name == 'caption') {
        generalContext = c
        break
      }
    }
  }

  // elements scan for the first variables and manage the text for them
  var textContext = generalContext
  if (context.name != 'var') {
    for (var c of generalContext.getChildren()) {
      if (c.is('element') && c.name == 'var') {
        textContext = c
        break
      }
    }
  }

  // find parent link context
  var linkContext = context
  //if (context.name == 'var') {
  for (var p of context.getAncestors()) {
    if (p.is('element') && (p.getAttribute('linkHref') != null || p.getAttribute('data-path-href'))) {
      linkContext = p
    }
  }
  //}

  return { linkContext, textContext, onConfigure, onChange }
}

export function getAttributeSet(
  editor: Editor,
  context: ModelElement,
  {
    linkContext = context,
    textContext = context,
    onConfigure,
    onChange
  }: {
    linkContext?: ModelElement
    textContext?: ModelElement
    onChange: AttributeCallback
    onConfigure: AttributeCallback
  }
): ConfigureMenuItem[] {
  const mappingContext = context.name.includes('image') ? context : textContext
  const mappedProperty = mappingContext.name.includes('image')
    ? 'data-path-src'
    : mappingContext.name == 'var' || mappingContext.name == 'embed' || mappingContext.name == 'component'
    ? 'data-path'
    : 'data-path-text'
  const elementContext = mappingContext.name == 'var' ? (mappingContext.parent as ModelElement) : mappingContext

  return (
    [
      editor.model.schema.checkAttribute(context, 'data-path-scope') && {
        id: 'repeating',
        label: 'Repeating',
        type: 'array',
        icon: mdiSync,
        value: null,
        jsonpath: context.getAttribute('data-path-scope') as string,
        onConfigure: (datapath) => {
          onConfigure(context, 'data-path-scope', datapath)
        },
        onChange: () => null,
        component: EditorDialogAttributes,
        labels: {
          none: 'No repeating',
          mapped: 'Mapped to collection'
        },
        badge: EditorDialogAttributeBadge
      },

      (context.name == 'embed' || context.name == 'component') && {
        id: 'component',
        label: context.getAttribute('data-embed-as') == 'feaas-component' ? 'Element settings' : 'Component settings',
        type: 'custom',
        icon: mdiToyBrick,
        value:
          context.getAttribute('component-id') || context.getAttribute('data-embed-as') == 'feaas-component'
            ? null
            : context.getAttribute('data-embed-as'),
        onChange: (value, attribute) => {
          onChange(context, attribute, value)
        },
        component: EditorDialogAttributesComponent,
        badge: (props) => {
          if (context.getAttribute('component-id')) {
            return (
              <Badge
                colorScheme={'teal'}
                ml={4}
                mr='2'
                display={'block'}
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
              >
                Component
              </Badge>
            )
          } else {
            return EditorDialogAttributeBadge(props)
          }
        }
      },

      (context.name == 'embed' || context.name == 'component') && {
        id: 'html',
        label: 'Component Content',
        type: 'html',
        icon: mdiContentPaste,
        value: context.getAttribute('data-embed-html'),
        jsonpath: context.getAttribute('data-path') || '',
        onConfigure: (datapath) => {
          if (context.getAttribute('data-embed-html') != null) onChange(context, 'data-embed-html', null)
          onConfigure(context, 'data-path', datapath || null)
        },
        onChange: (value) => {
          if (context.getAttribute('data-path')) onConfigure(context, 'data-path', null)
          onChange(context, 'data-embed-html', value || '')
        },
        component: EditorDialogAttributes,
        badge: EditorDialogAttributeBadge,
        labels: {
          none: 'Editor content',
          static: 'Static HTML',
          mapped: 'Mapped HTML'
        },
        below: ({ index }) =>
          index == 0 && (
            <>
              <Alert status='info' {...{ alignItems: 'flex-start', marginBottom: '12px' }}>
                <AlertIcon />
                <AlertDescription>
                  In this mode component acts like a card. Any child elements will be passed to the component as
                  children.
                </AlertDescription>
              </Alert>
            </>
          )
      },

      (context.name == 'embed' || context.name == 'component') && {
        id: context.getAttribute('data-embed-as') == 'feaas-component' ? 'HTML Content' : 'Component settings',
        label: 'JavaScript code',
        type: 'url',
        icon: mdiScriptOutline,
        value: context.getAttribute('data-embed-src') || null,
        jsonpath: context.getAttribute('data-path-embed-src') || '',
        onConfigure: (datapath) => {
          onConfigure(textContext, 'data-path-embed-src', datapath || null)
        },
        onChange: (value) => {
          onChange(elementContext, 'data-embed-src', value || '')
        },
        above: ({ index }) =>
          index > 0 && (
            <>
              <Alert status='warning' {...{ alignItems: 'flex-start', marginBottom: '12px' }}>
                <AlertIcon />
                <AlertDescription>
                  Loading untrusted code is a security risk. Script will be loaded once per page when component is first
                  displayed.
                </AlertDescription>
              </Alert>
            </>
          ),
        component: EditorDialogAttributes,
        badge: EditorDialogAttributeBadge,
        labels: {
          none: 'No script',
          static: 'From URL',
          mapped: 'Mapped source'
        }
      },

      editor.model.schema.checkAttribute(context, 'data-path-attributes') && {
        id: 'attributes',
        label: 'Attributes',
        type: 'object',
        icon: mdiDatabaseOutline,
        value: context.getAttribute('data-attributes'),
        jsonpath: context.getAttribute('data-path-attributes') as string,
        above: ({ menuItem: { onChange, value } }) => {
          return (
            <>
              <FormControl borderBottom={'1px'} borderColor='gray.200' mb={6} pb={6}>
                <FormLabel>Static attributes as JSON</FormLabel>
                <Textarea
                  placeholder='Paste JSON here'
                  onChange={(e) => onChange(e.target.value, 'data-attributes')}
                  value={value}
                ></Textarea>
              </FormControl>
            </>
          )
        },
        onConfigure: (datapath) => {
          onConfigure(context, 'data-path-attributes', datapath)
        },
        onChange: (value, attribute = 'data-attributes') => {
          onChange(context, attribute, value)
        },
        component: EditorDialogAttributes,
        labels: {
          mapped: 'Mapped attributes'
        },
        badge: EditorDialogAttributeBadge
      },
      context.name.includes('image') && {
        id: 'image',
        label: 'Image source',
        type: 'image',
        icon: 'M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z',
        value: editor.utils.getCustomAttribute(context, 'src') as string,
        jsonpath: context.getAttribute('data-path-src') as string,
        onConfigure: (datapath) => {
          onConfigure(context, 'data-path-src', datapath)
        },
        onChange: (value) => {
          onChange(context, 'src', value)
        },
        component: EditorDialogAttributes,
        labels: {
          static: 'Static image',
          mapped: 'Mapped image'
        },
        badge: EditorDialogAttributeBadge
      },

      ((context.name != 'caption' && editor.model.schema.checkChild(context, '$text')) ||
        (context.name == 'var' && !context.parent.is('element', 'caption'))) && {
        id: 'text',
        label: 'Text',
        type: 'string',
        icon: mdiDatabaseOutline,
        value:
          textContext.name == 'var' ? null : (editor.utils.getCustomAttribute(context, 'data-child-text') as string),
        jsonpath: textContext.getAttribute('data-path') as string,
        onConfigure: (datapath) => {
          if (textContext.name == 'var') {
            onConfigure(textContext, 'data-path', datapath || '')
          } else {
            onConfigure(elementContext, 'data-child-text', datapath)
          }
        },
        onChange: (value) => {
          onChange(elementContext, 'data-child-text', value)
        },
        component: EditorDialogAttributes,
        labels: {
          static: context.name == 'var' ? null : 'Static text',
          mapped: 'Mapped text'
        },
        badge: EditorDialogAttributeBadge
      },
      (context.name.includes('image') ||
        context.name == 'caption' ||
        (context.name == 'var' && context.parent.is('element', 'caption'))) && {
        id: 'caption',
        label: 'Caption',
        type: 'string',
        icon: mdiDatabaseOutline,
        value:
          textContext.name == 'var' ? null : (editor.utils.getCustomAttribute(context, 'data-child-caption') as string),
        jsonpath: textContext.getAttribute('data-path') as string,
        onConfigure: (datapath) => {
          onConfigure(context, 'data-child-caption', datapath)
        },
        onChange: (value) => {
          onChange(context, 'data-child-caption', value)
        },
        component: EditorDialogAttributes,
        labels: {
          none: 'No caption',
          static: 'Static caption',
          mapped: 'Mapped caption'
        },
        badge: EditorDialogAttributeBadge
      },
      (editor.model.schema.checkChild(context, '$text') ||
        editor.model.schema.checkChild(context, '$block') ||
        context.name.includes('image') ||
        context.name == 'var') && {
        id: 'link',
        label: 'Link',
        type: 'url',
        icon: mdiLink,
        value: editor.utils.getCustomAttribute(linkContext, 'linkHref') as string,
        isExternal: editor.utils.getCustomAttribute(linkContext, 'linkIsExternal') as boolean,
        isExplicit: editor.utils.getCustomAttribute(linkContext, 'linkStyle') == 'explicit',
        jsonpath: linkContext.getAttribute('data-path-href') as string,
        onConfigure: (datapath) => {
          onConfigure(linkContext, 'data-path-href', datapath)
        },
        onChange: (value, attribute = 'linkHref') => {
          if (attribute == 'linkHref' && value == null) {
            onChange(linkContext, 'linkStyle', null)
            onChange(linkContext, 'linkIsExternal', null)
          }
          onChange(linkContext, attribute, value)
          editor.execute('setContext', context)
        },
        component: EditorDialogAttributes,
        labels: {
          none: 'No link',
          static: 'Static link',
          mapped: 'Mapped link'
        },
        badge: EditorDialogAttributeBadge,
        above: () =>
          linkContext != context && (
            <Alert status='warning' {...{ display: 'flex', flexDir: 'row' }}>
              <AlertIcon />
              <AlertTitle>Provided by parent element </AlertTitle>
              <AlertButton
                onClick={() => editor.execute('setContext', linkContext, true)}
                size='sm'
                style={{ marginLeft: 'auto', marginRight: 0 }}
                leftIcon={<Icon path={mdiArrowUpLeft} fontSize='16px' />}
              >
                {linkContext.name}
              </AlertButton>
            </Alert>
          ),
        below: ({ menuItem: { onChange, isExternal, isExplicit }, index }) =>
          index != 0 && (
            <>
              <HStack as='label' alignItems={'center'}>
                <Switch
                  isChecked={isExternal === true}
                  onChange={(e) => onChange(e.target.checked ? true : null, 'linkIsExternal')}
                ></Switch>
                <Text as='p'>Open in a new tab</Text>
              </HStack>
              {(linkContext.name == 'card' || linkContext.name == 'button') && (
                <HStack as='label' alignItems={'center'}>
                  <Switch
                    isChecked={isExplicit}
                    onChange={(e) => onChange(e.target.checked ? 'explicit' : null, 'linkStyle')}
                  ></Switch>
                  <Text as='p'>Apply link style to contents</Text>
                </HStack>
              )}
            </>
          )
      },

      mappingContext.getAttribute(mappedProperty) && {
        id: 'placeholder',
        label: 'Fallback',
        type: context.name.includes('image') ? ('image' as const) : ('string' as const),
        icon: mdiGhostOutline,
        value: editor.utils.getCustomAttribute(mappingContext, 'data-path-placeholder') as string,
        jsonpath:
          elementContext.getAttribute('data-path-hidden') == mappingContext.getAttribute(mappedProperty)
            ? (mappingContext.getAttribute(mappedProperty) as string)
            : null,
        onConfigure: (datapath, attribute?: string) => {},
        onChange: (value, attribute?: string) => {
          if (value === false) {
            if (mappingContext.getAttribute(mappedProperty)) {
              onChange(mappingContext, 'data-path-placeholder', null)
              onChange(elementContext, 'data-path-hidden', mappingContext.getAttribute(mappedProperty) as string)
            }
          } else if (value == null) {
            if (elementContext.getAttribute('data-path-hidden') == mappingContext.getAttribute(mappedProperty)) {
              onChange(elementContext, 'data-path-hidden', null)
            }
            onChange(mappingContext, 'data-path-placeholder', null)
          } else {
            if (elementContext.getAttribute('data-path-hidden') == mappingContext.getAttribute(mappedProperty)) {
              onChange(elementContext, 'data-path-hidden', null)
            }
            if (value === true) {
              value = getHumanizedLabel(mappingContext.getAttribute(mappedProperty))
            }
            onChange(mappingContext, 'data-path-placeholder', value || null)
          }
        },
        above: ({ onNavigate }) =>
          elementContext.getAttribute('hidden') && (
            <Alert status='warning' {...{ display: 'flex', flexDir: 'row' }}>
              <AlertIcon />
              <AlertTitle>Element is set to be hidden </AlertTitle>
              <AlertButton
                onClick={() => onNavigate('visibility')}
                size='sm'
                style={{ marginLeft: 'auto', marginRight: 0 }}
              >
                Change
              </AlertButton>
            </Alert>
          ),
        component: EditorDialogAttributesFallback,
        badge: (props) => {
          if (props.jsonpath || props.value == null) {
            return (
              <Badge
                colorScheme={'gray'}
                ml={4}
                mr='2'
                display={'block'}
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
              >
                {props.jsonpath ? 'Hide element' : props.value == null ? 'No fallback' : null}
              </Badge>
            )
          } else {
            return EditorDialogAttributeBadge(props)
          }
        }
      },

      {
        id: 'visibility',
        label: 'Visibility',
        type: 'boolean' as const,
        icon: mdiEyeOutline,
        value: editor.utils.getCustomAttribute(context, 'hidden') ? true : null,
        jsonpath: context.getAttribute('data-path-hidden') as string,
        onConfigure: (datapath) => {
          onConfigure(context, 'data-path-hidden', datapath)
        },
        onChange: (value) => {
          onChange(context, 'hidden', value === '' ? 'hidden' : null)
        },
        component: EditorDialogAttributes,
        labels: {
          none: 'Display',
          static: 'Hide',
          mapped: 'Display based on data'
        },
        badge: EditorDialogAttributeBadge
      }
    ] as ConfigureMenuItem[]
  ).filter(Boolean)
}

export function EditorChromeDialogConfigure({ editor, context }: { editor: Editor; context: ModelElement }) {
  if (!context) return
  const [renders, forceRender] = useReducer((a) => a + 1, 0)
  const nextRender = useRef<ReturnType<typeof requestAnimationFrame>>()
  const scheduleRender = useCallback(() => {
    cancelAnimationFrame(nextRender.current)
    nextRender.current = requestAnimationFrame(forceRender)
  }, [])

  const [batch, startBatch] = useEditorBatch(editor, context)

  const onConfigure: AttributeCallback = (attributeContext, attribute, value) => {
    editor.model.enqueueChange(startBatch(), () => {
      editor.execute('configure', attribute, value, attributeContext)
      editor.execute('setContext', context, true)
    })
    scheduleRender()
  }
  const onChange: AttributeCallback = (attributeContext, attribute, value) => {
    if (context.is('element', 'caption') && value == null) {
      var nextContextParent = context.parent
    }

    editor.model.enqueueChange(startBatch(), (writer) => {
      editor.execute('override', attribute, value, attributeContext)
      editor.execute('setContext', nextContextParent || context)
    })
    scheduleRender()
  }

  return (
    <EditorDialogMenu
      context={context}
      editor={editor}
      items={(editor, context) =>
        getAttributeSet(editor, context, getContextProperties(editor, context, onChange, onConfigure))
      }
    />
  )
}
export default function EditorChromeContextConfigure({
  editor,
  context,
  onPopoverClose,
  onPopoverOpen,
  onPopoverRegister
}: EditorContextProps) {
  return (
    <Popover
      placement='bottom-start'
      isLazy={true}
      isOpen={context?.name == '$root' ? false : undefined}
      lazyBehavior='unmount'
      onOpen={() => onPopoverOpen('configure')}
      onClose={() => onPopoverClose('configure')}
    >
      {({ isOpen, onClose }) => (
        <>
          {onPopoverRegister('configure', onClose)}
          <PopoverTrigger>
            <IconButton
              isDisabled={context?.name == '$root'}
              isActive={isOpen}
              css={ImplicitBorders}
              aria-label='Configure element'
              icon={<Icon path={mdiCogOutline} fontSize={'18px'} />}
            ></IconButton>
          </PopoverTrigger>
          <PopoverArrow />
          <PopoverContent {...{ style: { width: '400px' } }}>
            <EditorChromeDialogConfigure context={context} editor={editor} />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
