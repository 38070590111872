export const compareArrays = (arr1, arr2) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export const checkable = (arr) => {
  return arr.map((item) => ({ ...item, isChecked: false }))
}

export const arrayToOptions = (arr) => {
  return arr.map((item) => ({ value: item, label: item }))
}
