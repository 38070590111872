import React from 'react'
import { default as styled } from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { sidebarCollections, sidebarStyles, StylePage } from './sidebar.js'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Text
} from '@sitecore-ui/design-system'
import { useApiData } from '../../hooks/useApiData.js'

const StyledAccordionItem = styled<any>(AccordionItem)``
const StyledAccordionButton = styled<any>(AccordionButton)``
const StyledAccordionPanel = styled<any>(AccordionPanel)``

const StylesSidebar = ({
  activeStyleType,
  setActiveStylePage
}: {
  activeStyleType: string
  setActiveStylePage: (stylePage: StylePage) => void
}) => {
  const library = useApiData('library')
  const navigate = useNavigate()

  const remembered = localStorage.getItem('styles:collapsed') && JSON.parse(localStorage.getItem('styles:collapsed'))

  const [collapsed, setCollapsed] = useState<any>(remembered || sidebarCollections.map((collection, index) => index))

  useEffect(() => {
    localStorage.setItem('styles:collapsed', JSON.stringify(collapsed))
  }, [collapsed])

  const openStyle = (style) => navigate(`${library.getPath()}/styles/${style.collectionId}/${style.type}`)

  const stylePage = sidebarStyles.find((item) => item.type === activeStyleType)

  useEffect(() => {
    setActiveStylePage(stylePage)
  }, [stylePage])

  return (
    <Box bg='gray.100' h='full' w='64' py='5' left='0' overflowY='auto' pos='sticky'>
      <Accordion
        allowMultiple
        index={collapsed}
        onChange={(activeIndexes) => setCollapsed(activeIndexes)}
        reduceMotion={true}
      >
        {sidebarCollections.map((collection) => (
          <StyledAccordionItem border='none' key={collection.id} _hover={{ background: 'none' }}>
            <StyledAccordionButton
              fontSize='x-small'
              color='gray.500'
              fontWeight='600'
              py='3'
              px='5'
              _focus={{ boxShadow: 'none' }}
              _hover={{ background: 'none' }}
            >
              {collection.title}

              <AccordionIcon />
            </StyledAccordionButton>

            <StyledAccordionPanel p='0'>
              {sidebarStyles
                .filter((style) => style.collectionId === collection.id)
                .map((style) => {
                  const wrapperBg = activeStyleType === style.type && 'primary.100'
                  const wrapperHover = activeStyleType !== style.type && { background: 'gray.100' }
                  const iconTextColor = style.disabled
                    ? 'gray.300'
                    : activeStyleType === style.type
                    ? 'primary.500'
                    : 'gray.500'

                  return (
                    <Flex
                      h='12'
                      w='full'
                      px='2'
                      align='center'
                      cursor='pointer'
                      key={style.type}
                      onClick={() => openStyle(style)}
                      pointerEvents={style.disabled ? 'none' : 'auto'}
                    >
                      <Flex
                        px='3'
                        borderRadius='md'
                        align='center'
                        w='full'
                        h='full'
                        bg={wrapperBg}
                        _hover={wrapperHover}
                      >
                        <Icon w='5' h='5' path={style.icon} color={iconTextColor} />

                        <Text ml='3' fontWeight='semibold' color={iconTextColor}>
                          {style.title}
                        </Text>
                      </Flex>
                    </Flex>
                  )
                })}
            </StyledAccordionPanel>
          </StyledAccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default StylesSidebar
