import React, { useEffect } from 'react'
import { Entry, Option } from 'types/index.js'
import cloneDeep from 'lodash-es/cloneDeep.js'
import { arrayToOptions } from 'utils/array.js'
import { stringToOption } from 'utils/string.js'
import {
  Text,
  Input,
  Grid,
  GridItem,
  Button,
  Flex,
  IconButton,
  CreatableSelect,
  CloseIcon,
  PlusSquareIcon,
  usePrevious
} from '@sitecore-ui/design-system'

interface Props {
  entries: Entry[]
  onChange: (entries: Entry[]) => void
  keysOptions?: Option[]
  valuesOptions?: Option[]
  enhancedIndexes?: { [key: number]: { required?: boolean; keys: string[]; values: string[] } }
}

const DatasourceKeyValue = ({ entries, onChange, keysOptions, valuesOptions, enhancedIndexes = {} }: Props) => {
  const prevEnhancedIndexes = usePrevious(enhancedIndexes)

  useEffect(() => {
    if (prevEnhancedIndexes) {
      Object.keys(prevEnhancedIndexes).map((index) => {
        onDelete(parseInt(index))
      })
    }

    if (Object.keys(enhancedIndexes).length) {
      Object.keys(enhancedIndexes).map((index) => {
        const item = enhancedIndexes[index]

        if (item.required) {
          const clonedItems = cloneDeep(entries)

          if (!clonedItems[index]) {
            clonedItems[index] = ['', '']
          }

          clonedItems[index][0] = item.keys[0]
          clonedItems[index][1] = item.values[0]

          onChange(clonedItems)
        }
      })
    }
  }, [enhancedIndexes])

  const change = (index: number, value: string, entryIndex: number) => {
    const clonedItems = cloneDeep(entries)

    if (!clonedItems[index]) {
      clonedItems[index] = ['', '']
    }

    clonedItems[index][entryIndex] = value

    onChange(clonedItems)
  }

  const onDelete = (index: number) => {
    const clonedItems = cloneDeep(entries)

    clonedItems.splice(index, 1)

    onChange(clonedItems)
  }

  const onAdd = () => onChange([...entries, ['', '']])

  return (
    <>
      <Grid templateColumns='repeat(13, 1fr)' gap={3}>
        <GridItem colSpan={6}>
          <Text fontWeight='semibold' fontSize='sm'>
            KEY
          </Text>
        </GridItem>

        <GridItem colSpan={6}>
          <Text fontWeight='semibold' fontSize='sm'>
            VALUE
          </Text>
        </GridItem>
      </Grid>

      {entries.map((entry: Entry, index: number) => {
        const isRequired = enhancedIndexes[index]?.required
        const keyOptionsComputed = enhancedIndexes[index]?.keys
          ? arrayToOptions(enhancedIndexes[index]?.keys)
          : keysOptions
        const valuesOptionsComputed = enhancedIndexes[index]?.keys
          ? arrayToOptions(enhancedIndexes[index]?.values)
          : valuesOptions

        return (
          <Grid templateColumns='repeat(13, 1fr)' gap={3} mt='3' key={index}>
            <GridItem colSpan={6}>
              {keyOptionsComputed ? (
                <CreatableSelect
                  options={keyOptionsComputed}
                  value={stringToOption(entry[0])}
                  onChange={({ value }) => change(index, value, 0)}
                />
              ) : (
                <Input value={entry[0]} onChange={(e) => change(index, e.target.value, 0)} />
              )}
            </GridItem>

            <GridItem colSpan={6}>
              {valuesOptionsComputed ? (
                <CreatableSelect
                  options={valuesOptionsComputed}
                  value={stringToOption(entry[1])}
                  onChange={({ value }) => change(index, value, 1)}
                />
              ) : (
                <Input value={entry[1]} onChange={(e) => change(index, e.target.value, 1)} />
              )}
            </GridItem>

            {!isRequired && (
              <GridItem colSpan={1}>
                <IconButton
                  ml='-2'
                  aria-label='delete'
                  icon={<CloseIcon fontSize='lg' />}
                  onClick={() => onDelete(index)}
                />
              </GridItem>
            )}
          </Grid>
        )
      })}

      <Flex justifyContent='start' mt='5'>
        <Button onClick={onAdd} variant='subtle'>
          <PlusSquareIcon mr='2' />
          {entries.length > 0 ? 'Add entry' : 'Add another'}
        </Button>
      </Flex>
    </>
  )
}

export default DatasourceKeyValue
