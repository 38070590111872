import { APIModel, Library, StylesheetModel } from '@sitecore-feaas/api'

import '../stylesheet'

import { nanoid } from '@sitecore-feaas/api'
import BasicColor from 'models/style/color.js'
import { ReusableDecoration } from 'models/style/decoration.js'
import { SceneDimensions } from 'models/style/dimensions.js'
import { ReusableFill } from 'models/style/fill.js'
import { BasicFont } from 'models/style/font.js'
import { SceneLayout } from 'models/style/layout.js'
import ReusablePalette from 'models/style/palette.js'
import { SceneSpacing } from 'models/style/spacing.js'
import ReusableTypography from 'models/style/typography.js'

export const StyleProps = (type: string, props?: any): Style['props'] => {
  switch (type) {
    case 'fill':
      return ReusableFill(props)
    case 'typography':
      return ReusableTypography(props)
    case 'decoration':
      return ReusableDecoration(props)
    case 'palette':
      return ReusablePalette(props)
    case 'spacing':
      return SceneSpacing(props)
    case 'layout':
      return SceneLayout(props)
    case 'dimensions':
      return SceneDimensions(props)
    case 'font':
      return BasicFont(props)
    case 'color':
      return BasicColor(props)
  }
  return props || {}
}

export type RuleId = string
export type RuleChoice = RuleId
export type BreakpointReference = RuleChoice
export type GraphicReference = RuleChoice
export type URL = string

export interface RuleDetails {
  id: string
  title?: string
  description?: string
  exampleContent?: any
  elementId?: string
  instanceId?: string
  isDefault?: boolean
  isHidden?: boolean
  isInternal?: boolean
}

export interface BasicIconProps {}

export type Basic = StyleRule<'font', BasicFont> | StyleRule<'icon', BasicIconProps> | StyleRule<'color', BasicColor>

// Reusables
export type Reusable =
  | StyleRule<'decoration', ReusableDecoration>
  | StyleRule<'fill', ReusableFill>
  | StyleRule<'palette', ReusablePalette>
  | StyleRule<'typography', ReusableTypography>

// Elements
export interface ElementTextProps {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'li'
  typographyIds?: RuleChoice[]
  paletteIds?: RuleChoice[]
}

export interface ElementCardProps {
  fillIds?: RuleChoice[]
  decorationIds?: RuleChoice[]
  layoutIds?: RuleChoice[]
  spacingIds?: RuleChoice[]
  paletteIds?: RuleChoice[]
}

export interface ElementSectionProps {
  fillIds?: RuleChoice[]
  decorationIds?: RuleChoice[]
  layoutIds?: RuleChoice[]
  gridIds?: RuleChoice[]
  spacingIds?: RuleChoice[]
  paletteIds?: RuleChoice[]
}

export interface ElementButtonProps {
  size?: string
  typographyIds?: RuleChoice[]
  decorationIds?: RuleChoice[]
  fillIds?: RuleChoice[]
  iconIds?: RuleChoice[]
  paletteIds?: RuleChoice[]
  spacingIds?: RuleChoice[]
}

export interface StyleRule<T, P> {
  type: T
  props: P
  details: RuleDetails
  stylesheetId?: StylesheetModel['id']
  stylesheet?: StylesheetModel
}

export type Element =
  | StyleRule<'button', ElementButtonProps>
  | StyleRule<'section', ElementSectionProps>
  | StyleRule<'card', ElementCardProps>
  | StyleRule<'text', ElementTextProps>

// Scene
export interface SceneBreakpointProps {
  query: string
}

export type Scene =
  | StyleRule<'breakpoint', SceneBreakpointProps>
  | StyleRule<'spacing', SceneSpacing>
  | StyleRule<'layout', SceneLayout>
  | StyleRule<'dimensions', SceneDimensions>

// Styles
export type Style = Element | Reusable | Basic | Scene

export interface StyleEmpty {
  type: Style['type']
}

type FindByType<Union, Type> = Union extends { type: Type } ? Union : never
export type StyleFor<Type extends Style['type']> = FindByType<Style, Type>

export interface PartialStyle {
  details?: Partial<Style['details']>
  props?: Partial<Style['props']>
  type: Style['type']
}

export const emptyStyle: PartialStyle = {
  type: null,
  details: {
    title: '',
    description: ''
  }
}
export const Style = (style: PartialStyle): Style => {
  // dont use deepmerge on props here
  return {
    type: style.type,
    details: {
      ...emptyStyle.details,
      exampleContent: style.type === 'text' || style.type === 'typography' ? 'Example' : null,
      ...style.details,
      id: style.details?.id || nanoid(10)
    },
    props: StyleProps(style.type, style.props) as any
  }
}

export const internalStyles: Style[] = [
  {
    type: 'layout' as const,
    details: {
      id: 'default-layout',
      title: 'default-layout',
      description: '',
      isInternal: true,
      isDefault: true,
      isHidden: true
    },
    props: SceneLayout({
      flexWrap: true,
      columnCount: 1,
      alignItems: 'stretch',
      justifyContent: 'center',
      weights: null
    })
  },
  {
    type: 'decoration' as const,
    details: {
      id: 'blank-decoration',
      title: 'No decoration',
      description: '',
      isInternal: true,
      isHidden: true
    },
    props: ReusableDecoration()
  },
  {
    type: 'fill' as const,
    details: {
      id: 'blank-fill',
      title: 'No fill',
      description: '',
      isInternal: true,
      isHidden: true
    },
    props: ReusableFill()
  },
  {
    type: 'typography' as const,
    details: {
      id: 'blank-typography',
      title: 'Unspecified typography',
      exampleContent: 'Example',
      description: '',
      isInternal: true,
      isHidden: true
    },
    props: ReusableTypography({})
  },
  {
    type: 'spacing' as const,
    details: {
      id: 'blank-spacing',
      title: 'No spacing',
      description: '',
      isInternal: true,
      isHidden: true
    },
    props: SceneSpacing()
  },
  {
    type: 'breakpoint' as const,
    details: {
      id: 'breakpoint-xs',
      title: 'Mobile (<576px)',
      isHidden: true
    },
    props: {
      query: '(max-width: 575px)'
    }
  },
  {
    type: 'breakpoint' as const,
    details: {
      id: 'breakpoint-sm',
      title: 'Small devices (576px)',
      isHidden: true
    },
    props: {
      query: '(min-width: 576px) and (max-width: 767px)'
    }
  },
  {
    type: 'breakpoint' as const,
    details: {
      id: 'breakpoint-md',
      title: 'Medium devices (768px)',
      isHidden: true
    },
    props: {
      query: '(min-width: 768px) and (max-width: 991px)'
    }
  },
  {
    type: 'breakpoint' as const,
    details: {
      id: 'breakpoint-lg',
      title: 'Large devices (992px)',
      isHidden: true
    },
    props: {
      query: '(min-width: 992px) and (max-width: 1199px)'
    }
  },
  {
    type: 'breakpoint' as const,
    details: {
      id: 'breakpoint-xl',
      title: 'Extra large devices (>1200px)',
      isHidden: true
    },
    props: {
      query: '(min-width: 1200px)'
    }
  }
]

export const isStyleDefault = (style: Style, styles: Style[]) => {
  return (
    style.details.elementId == null &&
    style ==
      styles.find(
        (s) =>
          s.type == style.type && ('tag' in style.props ? 'tag' in s.props && s.props.tag == style.props.tag : true)
      )
  )
}

// Simple interface of a style to be used for class
export interface IStyle {
  type: Style['type']
  details: RuleDetails
  props: any
  stylesheetId?: StylesheetModel['id']
  stylesheet?: StylesheetModel
}

// make fonts show up on top, otherwise @imports break
const desiredOrder = ['font'] as Style['type'][]

export interface StyleMinimal {
  type: Style['type']
  details?: Style['details']
  props?: any
}

export interface StyleImplicit {
  stylesheet: StylesheetModel
  stylesheetId: StylesheetModel['id']
  library: Library
  libraryId: Library['id']
}

export interface StyleModel extends IStyle {}

export class StyleModel extends APIModel<Style, StyleMinimal, StyleImplicit> implements IStyle {
  originId: Style['details']['id']

  getHiddenProps(): string[] {
    return ['library', 'libraryId', 'stylesheet']
  }

  declare idStruct: { details: { id: string } }

  getId() {
    return this.details.id
  }

  sortCompare(other: this): number {
    const ax = desiredOrder.indexOf(this.type)
    const bx = desiredOrder.indexOf(other.type)
    return bx - ax
  }

  // FIXME? can't get better type
  export: () => any

  static transformAttributes(object: any) {
    return Style(object)
  }

  static onCollectionChange(styles: StyleModel[]) {
    styles.forEach((style) => {
      style.details.isDefault = isStyleDefault(style, styles)
    })
    styles.sort((a, b) => a.sortCompare(b))
    return styles
  }

  duplicate() {
    return this.stylesheet.styles.add(
      this.change({
        originId: this.details.id,
        details: { ...this.details, id: nanoid(10) }
      })
    )
  }

  unlink() {
    this.originId = undefined
  }
}
