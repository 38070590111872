import React, { useEffect, useState } from 'react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import NumberField from '../fields/NumberField.js'
import { Button, Grid, GridItem } from '@sitecore-ui/design-system'
import { deepMerge } from 'utils/object.js'
import FlexGrid from '../../FlexGrid.js'
import { StyleFor } from 'models/style/index.js'
import { ReusableDecorationBorderRadius } from 'models/style/decoration.js'
import { Unformatted } from 'models/style/types/props.js'
import { StyleFormProps } from '../settings.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'

const borders: { label: string; prop: keyof ReusableDecorationBorderRadius }[] = [
  { label: 'Top left', prop: 'borderTopLeftRadius' },
  { label: 'Top right', prop: 'borderTopRightRadius' },
  { label: 'Bottom left', prop: 'borderBottomLeftRadius' },
  { label: 'Bottom right', prop: 'borderBottomRightRadius' }
]

const DecorationBorderRadiusFieldset = ({ onChange, currentStyle, isActive, style }: StyleFormProps<'decoration'>) => {
  const onUpdate = (changes: Unformatted<ReusableDecorationBorderRadius>) => {
    onChange(deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) }))
  }

  const allValuesOf = (value) => {
    return borders.reduce((changes, { prop }) => {
      return Object.assign(changes, { [prop]: value })
    }, {})
  }

  const radiuses = borders.map(({ prop }) => currentStyle.props[prop])
  const getIndex = () => {
    if (!ReusableDecorationBorderRadius.isValid(currentStyle.props)) {
      return 0
    }
    if (radiuses.every((radius) => radius.value === 9999)) {
      return 2
    }
    return 1
  }
  const [index, setIndex] = useState(getIndex())
  useEffect(() => setIndex(getIndex()), [style, isActive])

  useEffect(() => {
    if (index == 0 || (index == 1 && radiuses.every((radius) => radius.value === 9999))) {
      onUpdate(allValuesOf({ value: 0 }))
    }
    if (index == 2) {
      onUpdate(allValuesOf({ value: 9999 }))
    }
  }, [index])

  return (
    <>
      <SwitchGroupField value={index} onChange={setIndex} mb={6}>
        <Button>None</Button>
        <Button>Rounded</Button>
        <Button>Fully rounded</Button>
      </SwitchGroupField>

      {index == 1 && (
        <FlexGrid>
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            {borders.map((border: { label: string; prop: string }) => (
              <GridItem key={border.prop} colSpan={1}>
                <NumberField
                  value={currentStyle.props[border.prop].value}
                  onChange={(value) => onUpdate({ [border.prop]: { value } })}
                  label={border.label}
                  max={9999}
                />
              </GridItem>
            ))}
          </Grid>
        </FlexGrid>
      )}
    </>
  )
}

export default DecorationBorderRadiusFieldset
