import { useCallback, useContext, useEffect, useState } from 'react'
import { ConfirmationContext } from '../providers/ConfirmationProvider.js'

const useConfirmation = (callback, { title, button, body }, deps = []) => {
  const { setConfirm } = useContext(ConfirmationContext)

  return useCallback(() => {
    setConfirm({ title, button, body, action: callback })
  }, [setConfirm, ...deps])
}

export default useConfirmation
