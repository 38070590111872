import NumberField from '../fields/NumberField.js'
import { FunctionComponent, ReactElement } from 'react'
import { GridItem } from '@sitecore-ui/design-system'
import { Color } from 'models/style/types/color.js'
import { ColorDefinition } from 'models/style/color.js'

interface Props {
  color: Color
  index: number
  onChangeColors: (colors: ColorDefinition[]) => void
  colors: ColorDefinition[]
  selected: number
  opacity: number
  disabled: boolean
}

const mapping = [
  { label: 'Red', value: 'red' },
  { label: 'Blue', value: 'blue' },
  { label: 'Green', value: 'green' }
]

const ColorRGBFieldset: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { color, index, onChangeColors, colors, opacity, disabled } = props

  return (
    <>
      {mapping.map((i) => (
        <GridItem colSpan={1} key={i.label} opacity={opacity}>
          <NumberField
            disabled={disabled}
            value={color[i.value]}
            label={i.label}
            max={255}
            onChange={(value) => {
              colors[index].color = { ...colors[index].color, [i.value]: value }

              onChangeColors(colors)
            }}
          />
        </GridItem>
      ))}
    </>
  )
}

export default ColorRGBFieldset
