import { APICollection } from '../core/collection.js'
import { APIModel, APIModelSubclass } from '../core/model.js'

/**
 * Defines accessor over property, stores original value in _value
 * Both properties need to be typed
 * **/
export function defineAccessor<T = any>(
  target: T,
  name: keyof typeof target & string,
  settings?: TypedPropertyDescriptor<any>,
  hiddenProps?: string[]
) {
  for (var object = target; (object = Object.getPrototypeOf(object)); ) {
    var descriptor = Object.getOwnPropertyDescriptor(object, name)
    if (!descriptor) continue

    const prop = '_' + String(name)
    Object.defineProperty(target, name, {
      ...descriptor,
      enumerable: !hiddenProps?.includes(name),
      ...settings
    })
    Object.defineProperty(target, prop, {
      writable: true,
      enumerable: false
    })
    break
  }
}

/** Define setter swapping items in collection  */
export function defineCollectionAccessor<T>(
  target: T,
  name: keyof typeof target & string,
  collection: APICollection<any>,
  settings: TypedPropertyDescriptor<any> = {},
  hiddenProps?: string[]
) {
  Object.defineProperty(target, name, {
    enumerable: !hiddenProps?.includes(name),
    get() {
      return collection
    },
    set(values) {
      return collection.setItems(values || [])
    },
    ...settings
  })
  return collection
}

/** Implicit params are not overwritable, and are passed from collection dynamically instead**/
export function defineImplicitAccessors<M extends APIModel, T = M extends APIModel<infer T> ? T : never>(
  target: M,
  getParams: (model: M) => Partial<T>,
  hiddenProps?: string[]
) {
  const params = getParams(target)
  for (let property in params) {
    Object.defineProperty(target, property, {
      get: () => getParams(target)[property],
      set: () => {},
      enumerable: !hiddenProps?.includes(property)
    })
  }
}
