import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react'
import { Box, FormControl, FormLabel, Icon, Input, Text } from '@sitecore-ui/design-system'
import FormError from '../../FormError.js'
import { mdiAlertCircleOutline } from '@mdi/js'

interface Props {
  value: string
  onChange: (value: string) => void
  label?: string
  required?: boolean
  error?: string
  onBlur?: any
  disabled?: boolean
  focus?: boolean
  placeholder?: string
  caption?: string
  forceShowErrors?: boolean
}

const TextField: FunctionComponent<Props> = ({
  value,
  label,
  required,
  onChange,
  error,
  onBlur,
  disabled,
  focus,
  placeholder,
  caption,
  forceShowErrors
}: Props): ReactElement => {
  const [isDirty, setIsDirty] = useState(false)

  const onFieldChange = (e) => {
    setIsDirty(true)

    onChange(e.target.value)
  }

  const onFieldBlur = () => {
    if (onBlur) onBlur()
  }

  const showErrors = error && (isDirty || forceShowErrors)

  const ref = useRef(null)

  useEffect(() => {
    if (focus) ref.current.focus()
  }, [focus])

  return (
    <FormControl>
      <FormLabel>
        {label}

        {required && (
          <Box display='inline' color='red' ml='1'>
            *
          </Box>
        )}
      </FormLabel>

      <Input
        ref={ref}
        isInvalid={showErrors}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onFieldChange}
        onBlur={onFieldBlur}
        aria-label={label}
      />

      {!(showErrors || forceShowErrors) && caption && (
        <Text fontSize='xs' mt='2'>
          {caption}
        </Text>
      )}

      {(showErrors || forceShowErrors) && <FormError error={error} />}
    </FormControl>
  )
}

export default TextField
