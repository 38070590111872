import type { BreakpointReference } from 'models/style/index.js'
import { Length } from 'models/style/types/length.js'
import { Unformatted } from 'models/style/types/props.js'
import { FontVariant, FontVariantReference } from './font.js'

export const normalizeReusableTypographySize = (props: any = {}) => ({
  fontSize: props.fontSize == null ? null : Length(props.fontSize),
  lineHeight: props.lineHeight == null ? null : Length(props.lineHeight),
  letterSpacing: props.letterSpacing == null ? null : Length(props.letterSpacing),
  paragraphSpacing: props.paragraphSpacing == null ? null : Length(props.paragraphSpacing)
})
export type ReusableTypographySize = ReturnType<typeof normalizeReusableTypographySize>
export const ReusableTypographySize = (props?: Unformatted<ReusableTypographySize>) =>
  normalizeReusableTypographySize(props)

ReusableTypographySize.isValid = (props: ReusableTypographySize) => {
  return (
    props.fontSize?.value != null ||
    props.lineHeight?.value != null ||
    props.letterSpacing?.value != null ||
    props.paragraphSpacing?.value != null
  )
}

export interface UnformattedReusableTypography {
  fontVariant?: any
  allowBoldAndItalic?: any
  base?: Unformatted<ReusableTypographySize>
  overrides?: Record<BreakpointReference, Unformatted<ReusableTypographySize>>
}

export const normalizeReusableTypography = (props: any = {}) => {
  return {
    fontVariant: props.fontVariant as FontVariant | FontVariantReference,
    allowBoldAndItalic: !!props.allowBoldAndItalic,
    base: ReusableTypographySize(props.base),
    overrides: Object.keys(props.overrides || {}).reduce((object, breakpoint: BreakpointReference) => {
      const normalized = ReusableTypographySize(props.overrides[breakpoint])

      if (ReusableTypographySize.isValid(normalized) && breakpoint != 'new') {
        return Object.assign(object, {
          [breakpoint]: normalized
        })
      } else {
        return object
      }
    }, {} as Record<BreakpointReference, ReusableTypographySize>)
  }
}
export type ReusableTypography = ReturnType<typeof normalizeReusableTypography>
export const ReusableTypography = (props: Unformatted<ReusableTypography>) => normalizeReusableTypography(props)

export default ReusableTypography
