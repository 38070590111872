import React, { FunctionComponent, ReactElement } from 'react'
import { default as styled } from '@emotion/styled'
import { Style } from 'models/style/index.js'
import Preview from '../previews/index.js'
import { Checkbox, Flex, FormLabel, SimpleGrid, Text } from '@sitecore-ui/design-system'

const Label = styled<any>(FormLabel)``

interface Props {
  styles: Style[]
  setChecked: (style: Style) => void
  isChecked: (style: Style) => boolean
}

const StyleSelectGrid: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { styles, setChecked, isChecked } = props

  return (
    <SimpleGrid mt='5' spacing='4' templateColumns='repeat(auto-fit, 320px)'>
      {styles.map((style: Style) => {
        const { id, title, description } = style.details

        return (
          <Label
            key={id}
            bg='white'
            p='6'
            cursor='pointer'
            m='0'
            borderRadius='4px'
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            boxShadow='base'
          >
            <Preview style={style} />

            <Flex mt={6}>
              <Checkbox
                value={id}
                onChange={() => setChecked(style)}
                isChecked={isChecked(style) !== false}
                isDisabled={isChecked(style) === null}
              />

              <Text variant='microcopy' fontWeight='semibold' ml='3'>
                {title}
              </Text>
            </Flex>

            {description && (
              <Text variant='microcopy' ml='7'>
                {description}
              </Text>
            )}
          </Label>
        )
      })}
    </SimpleGrid>
  )
}

export default StyleSelectGrid
