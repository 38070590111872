import { mdiFormatBold, mdiFormatClear, mdiFormatItalic, mdiLinkVariant, mdiLinkVariantOff } from '@mdi/js'
import {
  ButtonGroup,
  Divider,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from '@sitecore-ui/design-system'
import { EditorContextProps, ImplicitBorders } from './EditorChrome.js'
import EditorDialogLink, { onEditorLink } from './EditorDialogLink.js'
import { css } from '@emotion/react'
import { useEffect, useReducer } from 'react'

export default function EditorChromeRangeToolbar(props: EditorContextProps & { children: any }) {
  const { onPopoverOpen, onPopoverClose, onPopoverRegister, popover, editor, children } = props
  const [, forceRender] = useReducer((r) => r + 1, 0)

  useEffect(() => {
    editor.model.document.selection.on('change:attribute', forceRender)
    //    editor.model.document.selection.on('change', forceRender)
    return () => {
      editor.model.document.selection.off('change:attribute', forceRender)
      //    editor.model.document.selection.off('change', forceRender)
    }
  }, [])

  const getCommandButtonProps = (commandName: string, attribute: string = commandName, preventExecution = false) => ({
    isActive: editor.model.document.selection.getAttribute(attribute) != null,
    isDisabled: !editor.commands.get(commandName).isEnabled,
    onClick: preventExecution
      ? undefined
      : () => {
          editor.execute(commandName)
          editor.focus()
        }
  })

  const formatProps = getCommandButtonProps('removeFormat')
  const linkProps = getCommandButtonProps('link', 'linkHref', true)
  const unlinkProps = getCommandButtonProps('unlink')
  return (
    <Popover
      placement='bottom-start'
      autoFocus={false}
      isOpen={popover == 'range'}
      onOpen={() => onPopoverOpen('range')}
      onClose={() => onPopoverClose('range')}
      isLazy={true}
    >
      <PopoverArrow />
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent {...{ style: { width: 'auto', border: 0, background: 'none', pointerEvents: 'all' } }}>
        <ButtonGroup
          spacing={'4px'}
          css={css`
            padding: 4px 3px 4px 4px;
            background: #fff;
            position: absolute;
            align-items: center;
            display: inline-flex;
            border: 1px solid #94949d;
            border-radius: 30px;
            height: 32px;
            z-index: 21;

            > hr {
              height: 24px;
              border-color: #e4e4e7;
            }
          `}
          color='rgba(0,0,0,0.68)'
        >
          <IconButton
            aria-label='Bold text'
            icon={<Icon path={mdiFormatBold} fontSize='18px' />}
            variant='minimal'
            css={ImplicitBorders}
            size='sm'
            {...getCommandButtonProps('bold')}
          />
          <IconButton
            aria-label='Italic text'
            icon={<Icon path={mdiFormatItalic} fontSize='18px' />}
            variant='minimal'
            css={ImplicitBorders}
            size='sm'
            {...getCommandButtonProps('italic')}
          />
          <Popover isLazy={true} lazyBehavior='unmount' placement='bottom-start' offset={[-58, -28]}>
            {({ onClose }) => (
              <>
                {onPopoverRegister('block', onClose)}
                <PopoverTrigger>
                  <IconButton
                    aria-label='Link text'
                    icon={<Icon path={mdiLinkVariant} fontSize='18px' />}
                    variant='minimal'
                    css={ImplicitBorders}
                    size='sm'
                    {...linkProps}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <EditorDialogLink editor={editor} context={null} onNavigate={onClose} />
                </PopoverContent>
              </>
            )}
          </Popover>
          {(!formatProps.isDisabled || !unlinkProps.isDisabled) && <Divider orientation='vertical' />}
          {!unlinkProps.isDisabled && (
            <IconButton
              aria-label='Remove format'
              icon={<Icon path={mdiLinkVariantOff} fontSize='18px' />}
              variant='minimal'
              css={ImplicitBorders}
              size='sm'
              {...unlinkProps}
            />
          )}
          {!formatProps.isDisabled && (
            <IconButton
              aria-label='Remove format'
              icon={<Icon path={mdiFormatClear} fontSize='18px' />}
              variant='minimal'
              css={ImplicitBorders}
              size='sm'
              {...formatProps}
            />
          )}
        </ButtonGroup>
      </PopoverContent>
    </Popover>
  )
}
