import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@sitecore-ui/design-system'
import { default as styled } from '@emotion/styled'

interface Props {
  value: number
  onChange: (value: number) => void
  label?: string
  min?: number
  max?: number
  step?: number
  disabled?: boolean
}

const Styled = styled(NumberInput)`
  div {
    display: none;
  }
`

const NumberField: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { value, onChange, min = 0, max = 100, step = 1, label, disabled } = props

  const [current, setCurrent] = useState<any>(value)

  useEffect(() => {
    if (current != '-') onChange(current == '' ? 0 : current)
  }, [current])

  useEffect(() => {
    if (value != 0 || current != '') setCurrent(value)
  }, [value])

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      <Styled
        min={min}
        step={step}
        max={max}
        value={current}
        onChange={(valueAsString, valueAsNumber) => {
          if (valueAsNumber > max || valueAsNumber < min) return

          setCurrent(Number.isNaN(valueAsNumber) ? valueAsString : valueAsNumber)
        }}
        onBlur={(e) => {
          if (Number.isNaN(current) || current == '') setCurrent(0)
        }}
        inputMode='numeric'
        isDisabled={disabled}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </Styled>
    </FormControl>
  )
}

export default NumberField
