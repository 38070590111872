import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

export type OnEdit = (id: string) => void
export type OnToggle = (id: string, force?: boolean) => void

const useEntities = (url, activeId) => {
  const [editedId, setEditedId] = useState(null)
  const navigate = useNavigate()

  const onEdit = (id) => {
    setEditedId(id)
  }

  const onToggle: OnToggle = (id: string, force: boolean = false) => {
    if (editedId && !force) return

    if (activeId === id) {
      navigate(`${url}`)

      setEditedId(null)

      return
    }

    navigate(`${url}/${id}`)
  }

  return { editedId, onEdit, onToggle, activeId }
}

export default useEntities
