import type { API } from '../api.js'
import APIAdapter from './adapter.js'
import { APICollection } from './collection.js'
import { isDeepEquals } from '../utils/object.js'
export type ClassExtending<T = {}> = new (...args: any[]) => T
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export type RequiredKeys<
  Interface,
  DefaultFN extends (...args: any) => any,
  extras extends keyof Interface = never
> = Optional<Interface, (keyof ReturnType<DefaultFN> & keyof Interface) | extras>

/** Make keys required**/
export type Atleast<Interface, Keys extends keyof Interface> = Optional<Interface, keyof Omit<Interface, Keys>>

export type APIModelSubclass = (new (...args: any[]) => APIModel<any>) & {
  [K in keyof typeof APIModel]: typeof APIModel[K]
}

export class APIModel<T = any, TMinimal extends Partial<T> = T, TImplicit extends Partial<T> = Partial<T>> {
  api: API

  get adapter(): APIAdapter<T> {
    throw new Error('Adapter not defined')
  }

  /** Create instance of a model. If no data is provided, it's just empty instance. Otherwise data needs to
   * contain all the required fields, and the model will be considered "new". Usually it is a better idea to
   * instantiate models via collections, as it provides necessary implicit attributes.
   */
  constructor(data?: TMinimal & TImplicit) {
    this.defineProperties()
    if (data) {
      this.new(data)
    }
  }

  new(data: TMinimal & Partial<TImplicit>) {
    if (data != null) {
      this.setDefaults().set(data)
      this.isNew = true
    }
    return this
  }

  /** To ensure that models look as plain objects all extra properties and method needs to be hidden
   * through the use of `enumerable: false` descriptors. */
  defineProperties() {
    Object.defineProperty(this, 'observers', {
      enumerable: false,
      value: []
    })
    Object.defineProperty(this, 'snapshotted', {
      enumerable: false,
      writable: true
    })
    this.api.utils.defineAccessor(this, 'isNew', { enumerable: false }, this.getHiddenProps())
    this.getHiddenProps().map((prop) => {
      Object.defineProperty(this, prop, {
        enumerable: false,
        writable: true,
        configurable: true
      })
    }, this)
    this.onConstruct()
  }
  /** constructor hook for subclasses**/
  onConstruct() {}

  getHiddenProps(): string[] {
    return []
  }
  getDefaults(api: API): any {
    return {}
  }

  setDefaults() {
    const values = this.getDefaults(this.api)
    for (var property in values) {
      if (this[property as keyof this] == null && values[property] !== undefined) {
        this[property as keyof this] = values[property]
      }
    }
    return this
  }

  /** Assign values from given object**/
  set(data: Partial<this | T>): this {
    var changed = false
    var property: keyof typeof data
    for (property in data) {
      /** @ts-ignore fix me**/
      if (this[property as keyof typeof this] !== data[property]) {
        changed = true
      }
    }
    Object.assign(this, data)
    if (changed) {
      this.onChange()
    }
    return this
  }
  copyHiddenProps(data: any, overwrite?: boolean) {
    this.getHiddenProps().map((prop) => {
      const current = this[prop as keyof this]
      if (overwrite || typeof current == 'undefined' || (current && current instanceof APICollection))
        this[prop as keyof this] = data[prop]
    })
    if (data.snapshotted) this.snapshotted = data.snapshotted
    this.isNew = data.isNew
    return this
  }

  /** Assign values and hidden properties from given object**/
  merge(data: Partial<this | T>) {
    this.set(data)
    if (data && data instanceof this.constructor) {
      this.copyHiddenProps(data, true)
    }
    return this
  }

  /** Set previously saved valeus to the model, returns clone**/
  populate(data: Partial<this | T>): this {
    const changed = this.set(data)
    changed.isNew = false
    return changed
  }
  /** return clone if any of given values are different**/
  change(data: Partial<this | T>) {
    var property: keyof typeof data
    for (property in data) {
      const left = this[property as keyof this] as any
      const right = data[property] as any
      if (!this.compareProp(property, left, right)) {
        return this.clone(data)
        break
      }
    }
    return this
  }

  put(data?: Partial<this | T>): Promise<this> {
    this.set(data)
    return this.adapter.put(this as this & T).then((data) => this.populate(data))
  }
  save(data?: Partial<this | T>): Promise<this> {
    this.set(data)
    this.setDefaults()

    if (this.isNew) {
      return this.post()
    } else {
      return this.put()
    }
  }
  update(data?: Partial<this | T>): Promise<this> {
    return this.put(data)
  }
  post(data?: Partial<this | T>): Promise<this> {
    this.set(data)
    return this.adapter.post(this as this & T).then((data) => this.populate(data))
  }
  insert(data?: Partial<this | T>): Promise<this> {
    return this.post(data)
  }
  delete(): Promise<this> {
    if (!this.isNew) {
      return this.adapter.delete(this as this & T).then((data) => this.populate(data))
    }
    return Promise.resolve(this)
  }
  get(): Promise<this> {
    return this.adapter.get(this as this & T).then((data) => this.populate(data))
  }
  fetch(options?: any): Promise<this[]> {
    return this.adapter.fetch(this as this & T).then((collection) => collection.map((data) => this.construct(data)))
  }
  select(options?: any): Promise<this[]> {
    return this.fetch(options)
  }
  search(query?: any) {
    return this.adapter.search(this, query).then((collection) => collection.map((data) => this.construct(data)))
  }

  /** Create new instance of this class**/
  construct(data?: Partial<this | T>) {
    const instance = Object.create(Object.getPrototypeOf(this)) as this
    instance.defineProperties()
    return instance.set(data)
  }

  /** Clone instance retaining data & hidden properties**/
  clone(data?: Partial<this | T>) {
    const clone = this.construct()
    Object.assign(clone, this)
    if (data) {
      clone.set(data)
    }
    clone.copyHiddenProps(this)
    return clone
  }

  /** Returns plain object without any descriptors or prototype**/
  export(): T {
    const object: any = {}
    for (var property in this) {
      if (this.hasOwnProperty(property)) object[property] = this[property]
    }
    return object as T
  }

  /** Clone mode, and assign its initial values as `snappshoted` property**/
  snapshotted?: T
  snapshot() {
    this.snapshotted = this.export()
    return this
  }

  /** default sorting logic**/
  sortCompare(other: this) {
    return 0
  }

  /** get object's id**/
  id: string
  idStruct:
    | {
        id: string
      }
    | {
        details: {
          id: string
        }
      }
  getId() {
    return this.id
  }

  /** returns object that has fresh identity but references same collection**/
  /** useful for shallow equality checked deps**/
  proxy(): this {
    return new Proxy(this, {
      get(target: any, prop: string | Symbol) {
        if (prop == Symbol.toStringTag) {
          return target[Symbol.toStringTag] + 'Proxy'
        }
        return target[prop as keyof typeof target]
      }
    }) as this
  }
  onChange() {
    this.observers?.forEach((observer) => observer.call(this, this), this)
  }
  observers: ((...args: any) => any)[]
  observe(observer: (model: this) => any) {
    ;(this.observers ||= []).push(observer)
  }
  unobserve(observer: (model: this) => any) {
    const index = this.observers.indexOf(observer)
    if (index > -1) this.observers.splice(index, 1)
  }

  /** hook to redefine new instance detection**/
  _isNew: boolean
  get isNew() {
    return this._isNew || false
  }
  set isNew(value: boolean) {
    this._isNew = value
  }

  /** compares changes**/
  isEqual(other: this, ignoreProps: string[] = ['modifiedAt', 'createdAt', 'sampledAt']) {
    return isDeepEquals(this, other, ignoreProps)
  }

  compareProp(property: keyof this, value1: any, value2: any) {
    return value1 && value1 instanceof Date && value2 && value2 instanceof Date
      ? Number(value1) == Number(value2)
      : value1 == value2
  }

  static onCollectionChange(collection: any[]) {
    return collection.sort((a, b) => a.sortCompare(b))
  }

  /** Apply normalization on style**/
  static transformAttributes(object: any) {
    return object
  }
}

APIModel.prototype.select = APIModel.prototype.fetch
