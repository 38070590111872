import { tinycolor } from '@ctrl/tinycolor'
import { FunctionComponent, ReactElement, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FormControl, FormLabel, GridItem, Input } from '@sitecore-ui/design-system'
import { Color } from 'models/style/types/color.js'
import { ColorDefinition } from 'models/style/color.js'

interface Props {
  color: Color
  index: number
  onChangeColors: (colors: ColorDefinition[]) => void
  colors: ColorDefinition[]
  selected: number
  opacity: number
  disabled: boolean
}

const ColorsFormHex: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { color, index, onChangeColors, colors, selected, opacity, disabled } = props

  const {
    register,
    setValue,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    update()
  }, [])

  useEffect(() => {
    if (selected === 0) {
      return
    }

    update()
  }, [color])

  const update = () => {
    const tiny = tinycolor({
      r: color.red,
      g: color.green,
      b: color?.blue
    })

    if (!tiny.isValid) {
      return
    }

    setValue('hex', tiny.toHexString())
  }

  const onChange = (e) => {
    const value = e.target.value

    if (!isHex(value)) {
      colors[index].color = Color({})

      onChangeColors(colors)

      return
    }

    const rgba = tinycolor(value).toRgb()

    colors[index].color = { red: rgba.r, green: rgba.g, blue: rgba.b, alpha: rgba.a }

    onChangeColors(colors)
  }

  const isHex = (string) => {
    const reg = /^#([0-9a-f]{3}){1,2}$/i

    return reg.test(string)
  }

  return (
    <GridItem colSpan={1} opacity={opacity}>
      <FormControl>
        <FormLabel>Color</FormLabel>

        <Input disabled={disabled} bg='white' type='text' {...register('hex')} onChange={onChange} />
      </FormControl>
    </GridItem>
  )
}

export default ColorsFormHex
