import {
  Box,
  Button,
  Container,
  DotsHorizontalIcon,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Placeholder,
  Spacer
} from '@sitecore-ui/design-system'
import Collection from 'components/library/Collection.js'
import CollectionDrawer from 'components/library/CollectionDrawer.js'
import ComponentDrawer from 'components/library/ComponentDrawer.js'
import FilterByDataSource from 'components/library/FilterByDataSource.js'
import Search from 'components/library/Search.js'
import Sort from 'components/library/Sort.js'
import { useApi, useApiData } from 'hooks/useApiData.js'
import { QueryStringContext } from 'providers/QueryStringProvider.js'
import { useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'

export default function CollectionsRoute() {
  const { status } = useApi()
  const library = useApiData('library')
  const collections = useApiData('library.collections')
  const components = useApiData('library.components')
  const [query, setQuery] = useContext(QueryStringContext)
  const navigate = useNavigate()

  // /libraries/:libraryId/collections/:id/components/:id
  // /libraries/:libraryId/collections/:id/components/new
  // /libraries/:libraryId/collections/new
  // /libraries/:libraryId/collections
  const { collectionId, componentId } = useParams()

  const queryDatasources = query.datasources?.split(',') || []
  useEffect(() => {
    if (!collectionId && status == 'ready') {
      library.collections.fetch()
    }
  }, [])

  const componentsByCollectionId = useMemo(
    () =>
      collections.reduce((acc, collection) => {
        const filteredComponents = components.filter(({ collectionId, datasourceIds, name }) => {
          if (query.collectionId && query.collectionId != collectionId) return false
          if (collectionId !== collection.id) return false
          if (query.search && !name.toLowerCase().includes(query.search.toLowerCase())) return false
          return !(
            queryDatasources.length &&
            datasourceIds.filter((id) => queryDatasources.find((dsId) => dsId === id)).length === 0
          )
        })

        const sortBy = query.sortBy || 'modifiedAt'

        filteredComponents.sort((a, b) => b[sortBy]?.getTime() - a[sortBy]?.getTime())

        return filteredComponents.length === 0 ? acc : { ...acc, [collection.id]: filteredComponents }
      }, {}),
    [query.search, query.datasources, query.sortBy, query.collectionId, collections, components]
  )

  return (
    <Container maxWidth='100%' p={10}>
      <Flex align='center' alignItems={'flex-start'} wrap={{ sm: 'wrap', lg: 'nowrap' }}>
        <Flex align='center' wrap='wrap'>
          <Box w='xs' mr='4' mb={6}>
            <Search />
          </Box>

          <Box mr='4' mb={6}>
            <Sort />
          </Box>

          <Box mb={6}>
            <FilterByDataSource queryDatasources={queryDatasources} />
          </Box>
        </Flex>

        <Spacer />

        <Flex align='center' mb={6}>
          <Box ml='3'>
            <Button
              size='md'
              variant='secondary'
              mr='3'
              onClick={(e) => navigate(`${library.getPath()}/collections/new`)}
            >
              Add collection
            </Button>
          </Box>

          <Box>
            <Button
              size='md'
              mr='3'
              variant='primary'
              onClick={(e) =>
                navigate(
                  `${library.getPath()}/collections/${
                    collections.find((collection) => collection.isDefault).id
                  }/components/new`
                )
              }
            >
              Add component
            </Button>
          </Box>

          <Menu>
            <IconButton
              as={MenuButton}
              size='sm'
              variant='minimal'
              aria-label='menu'
              icon={<DotsHorizontalIcon display='block' mx='auto' />}
            />

            <MenuList zIndex={5}>
              <MenuItem onClick={() => console.log('Publishing...')}>Publish</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Box mt={-6} flexGrow={1}>
        {collections.map((collection) => (
          <Collection
            key={collection.id}
            collection={collection}
            components={componentsByCollectionId[collection.id] || []}
          />
        ))}

        {!collections.length && (
          <Flex height='100%' align='center' justify='center'>
            <Placeholder text='Change your filter or search parameters and try again.' title='No collections found' />
          </Flex>
        )}
      </Box>

      <CollectionDrawer
        isOpen={collectionId && !componentId}
        collection={collections.find((collection) => collection.id === collectionId)}
        onClose={() => navigate(`${library.getPath()}/collections`)}
      />

      <ComponentDrawer
        isOpen={Boolean(componentId)}
        collection={collections.find((collection) => collection.id === collectionId)}
        component={components.find((component) => component.id === componentId)}
        onClose={() => navigate(`${library.getPath()}/collections`)}
        collections={collections}
      />
    </Container>
  )
}
