import { default as styled } from '@emotion/styled'
import { Box, HStack, Icon, IconButton, Text, VStack } from '@sitecore-ui/design-system'
import Preview from 'components/styles/previews/index.js'
import { Style } from 'models/style/index.js'

interface Props {
  onChange: (style: Style) => void
  onCustomize: (style: Style) => void
  styles: Style[]
  collection: Style[]
  currentStyle: Style
}

const StylerElementAspectChoice = styled(Box)`
  padding: 11px;
  border: 1px solid transparent;
  &:hover:not([aria-selected='true']) {
    border: 1px solid var(--chakra-colors-primary-200);
    border-radius: 4px;
  }
  &[aria-selected='true'] {
    border: 1px solid var(--chakra-colors-primary-500);
    border-radius: 4px;
  }
  &:not(&[aria-selected='true']) {
    button {
      opacity: 0;
    }
  }
`

const customizePath =
  'M9 12.75C7.605 12.75 6.4425 13.71 6.105 15H4.5V16.5H6.105C6.4425 17.79 7.605 18.75 9 18.75C10.395 18.75 11.5575 17.79 11.895 16.5H19.5V15H11.895C11.5575 13.71 10.395 12.75 9 12.75ZM9 17.25C8.175 17.25 7.5 16.575 7.5 15.75C7.5 14.925 8.175 14.25 9 14.25C9.825 14.25 10.5 14.925 10.5 15.75C10.5 16.575 9.825 17.25 9 17.25ZM17.895 7.5C17.5575 6.21 16.395 5.25 15 5.25C13.605 5.25 12.4425 6.21 12.105 7.5H4.5V9H12.105C12.4425 10.29 13.605 11.25 15 11.25C16.395 11.25 17.5575 10.29 17.895 9H19.5V7.5H17.895ZM15 9.75C14.175 9.75 13.5 9.075 13.5 8.25C13.5 7.425 14.175 6.75 15 6.75C15.825 6.75 16.5 7.425 16.5 8.25C16.5 9.075 15.825 9.75 15 9.75Z'

export default function StylerElementAspect({ collection, currentStyle, styles, onChange, onCustomize }: Props) {
  return (
    <VStack alignItems='stretch'>
      {collection.map((choice) => (
        <StylerElementAspectChoice
          key={choice.details.id}
          aria-selected={currentStyle == choice ? 'true' : 'false'}
          onClick={() => onChange(choice)}
        >
          <Preview style={choice}></Preview>
          <HStack justifyContent='space-between' alignItems='center' mt={3}>
            <Text fontSize={'12px'} fontWeight={600}>
              {choice.details.title}
            </Text>
            <IconButton
              variant='secondary'
              _hover={{ background: 'gray.200' }}
              icon={<Icon path={customizePath} />}
              aria-label='Customize style'
              onClick={() => onCustomize(choice)}
            />
          </HStack>
        </StylerElementAspectChoice>
      ))}
    </VStack>
  )
}
