import type { Editor } from 'hooks/useEditor.js'
import { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { mdiUndo, mdiRedo } from '@mdi/js'
import { ButtonGroup, IconButton, Icon, Box, Divider, AddIcon, Button, HStack } from '@sitecore-ui/design-system'
import { useEditorValue } from 'hooks/useEditorValue.js'

export default function EditorPanels({ editor, addNewVariant }: { editor: Editor; addNewVariant: (any) => void }) {
  const [isVariantVisible, setVariantVisible] = useState(false)
  useEffect(() => {
    const onScroll = () => {
      var fromBottom = page.scrollHeight - page.scrollTop - page.offsetHeight
      setVariantVisible(fromBottom > 130)
    }

    const page = document.querySelector('.scrollable-page') as HTMLElement
    onScroll()
    page.addEventListener('scroll', onScroll, { passive: true })
    return () => {
      // @ts-ignore
      page.removeEventListener('scroll', onScroll, { passive: true })
    }
  }, [])

  const [{ canUndo, canRedo }, setButtonState] = useState({
    canUndo: false,
    canRedo: false
  })

  useEffect(() => {
    if (editor) {
      const getButtonState = () => ({
        canUndo: editor?.commands.get('undo').isEnabled || false,
        canRedo: editor?.commands.get('redo').isEnabled || false
      })
      editor.commands.get('undo').on('change:isEnabled', () => {
        setButtonState(getButtonState)
      })
      editor.commands.get('redo').on('change:isEnabled', () => {
        setButtonState(getButtonState)
      })
      setButtonState(getButtonState)
    }
  }, [editor])

  return (
    <HStack position='fixed' bottom={4} right={4} zIndex={20}>
      <ButtonGroup isAttached={true} opacity={isVariantVisible ? 1 : 0} transition='opacity 0.3s'>
        <Button
          variant='secondary'
          bg='gray.200'
          leftIcon={<AddIcon fontSize='14px' />}
          onClick={addNewVariant}
          isDisabled={!editor}
        >
          Add variant
        </Button>
      </ButtonGroup>
      <ButtonGroup isAttached={true}>
        <IconButton
          isDisabled={!canUndo}
          onClick={() => editor.execute('undo')}
          icon={<Icon path={mdiUndo} fontSize='14px' />}
          aria-label='Undo'
          variant='primary'
        />
        <Divider orientation='vertical' />
        <IconButton
          isDisabled={!canRedo}
          onClick={() => editor.execute('redo')}
          icon={<Icon path={mdiRedo} fontSize='14px' />}
          aria-label='Redo'
          variant='primary'
        />
      </ButtonGroup>
    </HStack>
  )
}
