import React, { FunctionComponent, ReactElement, useMemo, useState } from 'react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import { StyleFor } from 'models/style/index.js'
import cloneDeep from 'lodash-es/cloneDeep.js'
import { rgbaToString } from 'models/style/types/color.js'
import OpacityField from '../fields/OpacityField.js'
import TextField from '../fields/TextField.js'
import ColorHexField from '../fields/ColorHexField.js'
import FlexGrid from 'components/FlexGrid.js'
import { Box, Button, Flex, Grid, GridItem, MinusIcon, PlusSquareIcon, Text } from '@sitecore-ui/design-system'
import ColorRGBFieldset from './ColorRGBFieldset.js'
import ColorHSLFieldset from './ColorHSLFieldset.js'
import { ColorDefinition } from 'models/style/color.js'

interface Props {
  currentStyle: StyleFor<'color'>
  onChange: (style: StyleFor<'color'>) => void
  style: StyleFor<'color'>
  errors: any
}

const ColorsFieldset: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { currentStyle, onChange, style, errors } = props

  const [selected, setSelected] = useState(0)

  const colors = useMemo(() => cloneDeep(currentStyle.props.colors || []), [currentStyle, style])

  const addColor = () => {
    let colors = []

    if (currentStyle.props && currentStyle.props.colors) {
      colors = [...currentStyle.props.colors]
    }

    colors.push({ color: { red: '', green: '', blue: '', alpha: '1' }, name: '' })

    onChangeColors(colors)
  }

  const onChangeColors = (colors: ColorDefinition[]) => {
    onChange({ ...currentStyle, props: { ...currentStyle.props, colors } })
  }

  const onRemove = (index: number) => {
    colors[index] = { ...colors[index], markedForDeletion: true }

    onChangeColors(colors)
  }

  const onRestore = (index) => {
    colors[index] = { name: colors[index].name, color: colors[index].color }

    onChangeColors(colors)
  }

  const onChangeName = (value, index) => {
    colors[index] = { ...colors[index], name: value }
    onChangeColors(colors)
  }

  const onChangeAlpha = (value, index) => {
    colors[index].color = { ...colors[index].color, alpha: value }
    onChangeColors(colors)
  }

  const rgbFormat = selected === 1
  const hslFormat = selected === 2
  const hexFormat = selected === 0

  return (
    <Box>
      <Text fontWeight='semibold' mb='2'>
        Color input
      </Text>

      <ButtonGroupSwitch index={selected} onChange={(index) => setSelected(index)} mb={4}>
        <Button>HEX</Button>
        <Button>RGB</Button>
        <Button>HSL</Button>
      </ButtonGroupSwitch>

      <FlexGrid>
        {colors.map((color: ColorDefinition, index: number) => {
          const opacity = color.markedForDeletion ? 0.2 : 1
          const disabled = opacity !== 1

          return (
            <Grid templateColumns='repeat(2, 1fr)' gap={6} key={index}>
              <GridItem
                colSpan={2}
                boxShadow='lg'
                h='16'
                borderRadius='base'
                bg={rgbaToString(color.color)}
                opacity={opacity}
              />

              <GridItem colSpan={1} opacity={opacity}>
                <TextField
                  disabled={disabled}
                  error={errors?.props?.colors[index]?.name}
                  label='Name'
                  value={color.name}
                  onChange={(value) => onChangeName(value, index)}
                />
              </GridItem>

              {hexFormat && (
                <ColorHexField
                  index={index}
                  colors={colors}
                  onChangeColors={onChangeColors}
                  color={color.color}
                  selected={selected}
                  opacity={opacity}
                  disabled={disabled}
                />
              )}

              {rgbFormat && (
                <ColorRGBFieldset
                  colors={colors}
                  onChangeColors={onChangeColors}
                  index={index}
                  color={color.color}
                  selected={selected}
                  opacity={opacity}
                  disabled={disabled}
                />
              )}

              {hslFormat && (
                <ColorHSLFieldset
                  color={color.color}
                  colors={colors}
                  onChangeColors={onChangeColors}
                  index={index}
                  selected={selected}
                  opacity={opacity}
                  disabled={disabled}
                />
              )}

              <GridItem colSpan={2} opacity={opacity}>
                <OpacityField
                  value={color.color.alpha}
                  onChange={(value) => onChangeAlpha(value, index)}
                  disabled={disabled}
                />
              </GridItem>

              <GridItem colSpan={2}>
                <Flex h='14' w='full' align='center'>
                  {!color.markedForDeletion ? (
                    <Button variant='minimal' onClick={(e) => onRemove(index)} marginLeft='auto' display='block'>
                      <MinusIcon mr='2' />
                      Remove color
                    </Button>
                  ) : (
                    <>
                      <Text color='red' variant='microcopy'>
                        Color marked for deletion
                      </Text>

                      <Button display='block' marginLeft='auto' variant='minimal' onClick={(e) => onRestore(index)}>
                        <PlusSquareIcon mr={2} />
                        Add back
                      </Button>
                    </>
                  )}
                </Flex>
              </GridItem>
            </Grid>
          )
        })}

        <Flex>
          <Button onClick={addColor} variant='subtle' mt='5'>
            <PlusSquareIcon mr='2' />
            Add another color
          </Button>
        </Flex>
      </FlexGrid>
    </Box>
  )
}

export default ColorsFieldset
