import { useEffect, useState } from 'react'

export function useEditorValue(editorObject, key) {
  const [value, setValue] = useState(editorObject ? editorObject[key] : null)

  useEffect(() => {
    if (!editorObject) return
    const onChange = (evt, name, newValue) => {
      return setValue(newValue)
    }
    editorObject.on('change:' + key, onChange)
    return () => {
      editorObject.off('change:' + key, onChange)
    }
  }, [editorObject, key])

  return value
}
