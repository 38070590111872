import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react'
import { Box, FormControl, FormLabel, Input, Text } from '@sitecore-ui/design-system'
import ButtonGroupSwitch from 'components/ButtonGroupSwitch.js'

interface Props {
  value: number
  onChange: (value: number) => void
  label?: string
  required?: boolean
  error?: string
  onBlur?: any
  disabled?: boolean
  children: any[]
  [prop: string]: any
}

const SwitchGroupField = (props: Props) => {
  const { value, label, required, onChange, error, children, mb, mt, my, ...more } = props

  const [showErrors, setShowErrors] = useState(false)

  return (
    <FormControl isInvalid={!!error} {...{ mb, mt, my }}>
      {label != null && (
        <FormLabel>
          {label}

          {required && (
            <Box display='inline' color='red' ml='1'>
              *
            </Box>
          )}
        </FormLabel>
      )}

      <ButtonGroupSwitch index={value} onChange={onChange} {...more}>
        {children}
      </ButtonGroupSwitch>

      {showErrors && (
        <Text pos='absolute' mt={1} right='0' variant='microcopy' color='red' fontSize='10px'>
          {error}
        </Text>
      )}
    </FormControl>
  )
}

export default SwitchGroupField
