import { APICollection } from './core/collection.js'
import { APIModelSubclass } from './core/model.js'
import Transport from './core/transport.js'
import { CollectionModel, Collections } from './models/collections.js'
import { ComponentModel, Components } from './models/components.js'
import { DatasourceModel, Datasources } from './models/datasources.js'
import { Libraries, LibraryModel } from './models/libraries.js'
import { StylesheetModel, Stylesheets } from './models/stylesheets.js'
import { VariantModel, Variants } from './models/variants.js'
import * as utils from './utils/index.js'
export * from './utils/index.js'

export interface APIUser {
  id: string
  name: string
  givenName: string
  familyName: string
  email: string
  picture: string
  nickname: string
}

// Create subclass that hasapi property defined]
function assignApi<T extends APIModelSubclass>(Base: T, api: API): T {
  const Subclass = class extends Base {
    constructor(...args: any[]) {
      super(...args)
    }
  }
  Object.defineProperty(Subclass.prototype, 'api', {
    enumerable: false,
    writable: true,
    value: api
  })
  Object.defineProperty(Subclass.prototype, Symbol.toStringTag, {
    value: Base.name
  })
  return Subclass
}

export class API extends Transport {
  Libraries = new Libraries(this)
  Collections = new Collections(this)
  Components = new Components(this)
  Variants = new Variants(this)
  Stylesheets = new Stylesheets(this)
  Datasources = new Datasources(this)
  Collection = assignApi(CollectionModel, this)
  Component = assignApi(ComponentModel, this)
  Variant = assignApi(VariantModel, this)
  Library = assignApi(LibraryModel, this)
  Stylesheet = assignApi(StylesheetModel, this)
  Datasource = assignApi(DatasourceModel, this)

  libraries = APICollection.construct(this.Library, () => ({
    //api: this
  }))

  library: LibraryModel
  static instance: API

  utils = utils
  static Library = LibraryModel
  static Collection = CollectionModel
  static Component = ComponentModel
  static Variant = VariantModel
  static Stylesheet = StylesheetModel
  static Datasource = DatasourceModel

  user: APIUser = {
    id: null,
    name: 'API',
    givenName: null,
    familyName: null,
    email: null,
    picture: null,
    nickname: null
  }
  setUserFromAuth0(user: any) {
    this.user = {
      id: user.sub?.replace('auth0|', ''),
      name: user.name,
      givenName: user.given_name,
      familyName: user.family_name,
      email: user.email,
      picture: user.picture,
      nickname: user.nickname
    }
  }

  normalizeHTML(html: string) {
    return (
      html
        // sort attributes
        .replace(/<([a-z0-9-]+) ([^>]+?)(\s?\/)>/g, (m, tag, attrs, closer = '') => {
          const matches = attrs.match(/(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g)
          matches.sort()
          return '<' + tag + ' ' + matches.join(' ') + closer + '>'
        })
        // remove extra whitespace
        .replace(/>\s+</g, '><')
        .trim()
    )
  }
}
