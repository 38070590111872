import Navbar from '../Navbar.js'
import { Box, Flex, VStack } from '@sitecore-ui/design-system'

const BasicLayout = ({ children, ...props }) => {
  return (
    <VStack h='full' alignItems={'stretch'} spacing={0} position='fixed' right={0} left={0}>
      <Navbar />

      <Flex
        flexGrow={1}
        overflowX='auto'
        direction={'column'}
        className='scrollable-page'
        css={`{
          overflow-x: auto
          overflow-y: scroll;
          overflow-y: overlay;
        }`}
        {...props}
      >
        {children}
      </Flex>
    </VStack>
  )
}

export default BasicLayout
