import { css } from '@emotion/react'
import { default as styled } from '@emotion/styled'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@sitecore-ui/design-system'
import { element as elementSettings } from 'components/styles/settings.js'
import { ModelElement } from 'hooks/useEditor.js'
import { getActiveAspectStyles, getElementStylesByAspect } from 'hooks/useEditorStyles.js'
import { Style } from 'models/style/index.js'
import { useEffect, useMemo, useState } from 'react'
import Preview from '../styles/previews/index.js'
import StylerElementAspect from './StylerElementAspect.js'

const StylerElementAccordionItem = styled(AccordionItem)`
  position: relative;
  border: 0;
  padding: 24px 12px;
  margin-bottom: 12px;
  align-items: stretch;
  border-radius: 4px;
  &:hover,
  &[aria-selected] {
    background: var(--chakra-colors-gray-100);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  }
  &[aria-disabled]:not(:hover) {
    opacity: 0.5;
    button {
      opacity: 0;
    }
  }
  border-radius: 4px;
`
const StylerElementAccordionPanel = styled(AccordionPanel)`
  padding: 12px 0 0 0;
`

const StylerElementAccordionButton = styled(AccordionButton)`
  position: absolute;
  right: 12px;
  left: auto;
  margin-top: 12px;
  height: 24px;
  width: 24px;
  padding: 0;
  border-radius: 100px;
  justify-content: center;
  &:active,
  &:hover,
  &[aria-expanded='true'] {
    background: var(--chakra-colors-blackAlpha-200);
  }
`

const ButtonLike = css`
  border: 0;
  font-size: 12px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 20px;
  margin-bottom: 6px;
  font-weight: 600;
  &[aria-selected='true'] {
    background: var(--chakra-colors-primary-100);
  }
`

interface Props {
  style: Style
  context: ModelElement
  styles: Style[]
  activeStyles: Style[]
  onChange: (style: Style) => void
  onPresetChange: (style: Style) => void
  onCustomizationIntent: (Style) => void
  isSelected: boolean
  isExpanded: boolean
  isDisabled: boolean
}

export function StylerElement({
  style,
  styles,
  activeStyles,
  context,
  onChange,
  onPresetChange,
  isSelected,
  isDisabled,
  onCustomizationIntent
}: Props) {
  const { aspects } = elementSettings[style.type]

  const aspectOptions = useMemo(() => {
    return getElementStylesByAspect(style, styles)
  }, [styles, context])

  const [activeAspectStyles, setActiveAspectStyles] = useState(() => {
    return getActiveAspectStyles(aspectOptions, !isSelected ? [] : activeStyles)
  })

  useEffect(() => {
    setActiveAspectStyles(getActiveAspectStyles(aspectOptions, !isSelected ? [] : activeStyles))
  }, [isSelected, aspectOptions, activeStyles])

  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setTabIndex(0)
  }, [context])

  return (
    <StylerElementAccordionItem
      aria-selected={isSelected ? true : null}
      aria-disabled={isDisabled ? true : null}
      onClick={() => onChange(style)}
    >
      <Preview style={style} customStyles={activeAspectStyles}></Preview>
      <StylerElementAccordionButton>
        <AccordionIcon />
      </StylerElementAccordionButton>
      <Text fontWeight={'bold'} fontSize='12px' lineHeight={'120%'} mt={4} mb={3}>
        {style.details.title}
      </Text>
      <StylerElementAccordionPanel>
        <Tabs onChange={setTabIndex} index={tabIndex}>
          <TabList borderBottom={0} flexWrap={'wrap'}>
            {aspects.map((aspect) => (
              <Tab css={ButtonLike} key={aspect.label}>
                {aspect.label}
                {aspectOptions[aspect.type].length ? ` (${aspectOptions[aspect.type].length})` : ''}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {aspects.map((aspect) => (
              <TabPanel p={0} pt={3} key={aspect.type}>
                <StylerElementAspect
                  styles={styles}
                  currentStyle={activeAspectStyles[aspect.type]}
                  collection={aspectOptions[aspect.type]}
                  onCustomize={(style) => {
                    onPresetChange(style)
                    onCustomizationIntent(style)
                  }}
                  onChange={(style) => onPresetChange(style)}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </StylerElementAccordionPanel>
    </StylerElementAccordionItem>
  )
}
