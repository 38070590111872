import { mdiSquareEditOutline } from '@mdi/js'
import {
  CopyIcon,
  DeleteIcon,
  DotsHorizontalIcon,
  EditIcon,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text
} from '@sitecore-ui/design-system'
import { ComponentModel } from '@sitecore-feaas/api'
import { FunctionComponent, ReactElement } from 'react'
import { useNavigate } from 'react-router'
import useConfirmation from '../../hooks/useConfirmation.js'
import { useApiData } from '../../hooks/useApiData.js'

interface Props {
  component: ComponentModel
  showInfoButton: boolean
}

const ComponentLowerActions: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { component, showInfoButton } = props
  const navigate = useNavigate()
  const library = useApiData('library')

  const handleDeleteComponent = useConfirmation(
    () => {
      component.delete()
    },
    {
      title: 'Delete Component',
      button: 'Delete',
      body: (
        <Text>
          Are you sure you want to delete <strong>{component.name}</strong>?
        </Text>
      )
    }
  )

  return (
    <Menu>
      <MenuButton as={IconButton} ml='2' icon={<DotsHorizontalIcon fontSize='24px' />} aria-label='Component actions' />

      <MenuList>
        <MenuItem
          onClick={() => navigate(`${library.getPath()}/components/${component.id}`)}
          icon={<Icon aria-label='See in Builder' path={mdiSquareEditOutline} fontSize='14px' />}
        >
          See in builder
        </MenuItem>

        <MenuDivider />

        <MenuItem onClick={() => navigate(component.getPath())} icon={<EditIcon fontSize='14px' />}>
          Edit details
        </MenuItem>

        <MenuItem icon={<CopyIcon fontSize='14px' />} isDisabled={true}>
          Duplicate
        </MenuItem>

        <MenuItem onClick={handleDeleteComponent} icon={<DeleteIcon fontSize='14px' />}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default ComponentLowerActions
