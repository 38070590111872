import { css } from '@emotion/react'
import { Box } from '@sitecore-ui/design-system'
import { useApiData } from 'hooks/useApiData.js'
import { useEffect } from 'react'

interface Props {
  children: any
}

export function EditorSidebar({ children }: Props) {
  const apiEditor = useApiData('editor')

  useEffect(() => {
    if (!apiEditor.isFocused)
      apiEditor.set({
        sidebarMode: null
      })
  }, [apiEditor.isFocused])

  return (
    <Box
      hidden={!apiEditor.sidebarMode}
      id='sidebar'
      css={css`
        &[hidden] {
          transform: translateX(100%);
          display: block !important;
        }
        transition: transform 0.2s;
        position: fixed;
        right: 0;
        height: 100%;
        width: 360px;
        height: 100%;
        background: #fff;
        z-index: 20;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
      `}
    >
      {children}
    </Box>
  )
}
