import { ColorReference } from 'models/style/types/color.js'
import { Length } from 'models/style/types/length.js'
import { Keyword } from 'models/style/types/keyword.js'
import { emptyIfInvalid, Unformatted } from 'models/style/types/props.js'

// Border
export enum BorderStyle {
  'solid' = 'solid',
  'dashed' = 'dashed',
  'dotted' = 'dotted',
  'double' = 'double'
}

export const ReusableDecorationBorder = (props: any = {}) => ({
  borderStyle: Keyword<BorderStyle>(props.borderStyle, BorderStyle),
  borderColor: ColorReference(props.borderColor),
  borderLeftWidth: Length(props.borderLeftWidth),
  borderRightWidth: Length(props.borderRightWidth),
  borderTopWidth: Length(props.borderTopWidth),
  borderBottomWidth: Length(props.borderBottomWidth)
})

ReusableDecorationBorder.isValid = (props: ReusableDecorationBorder) => {
  return (
    ColorReference.isValid(props.borderColor) &&
    (props.borderLeftWidth.value != 0 ||
      props.borderRightWidth.value != 0 ||
      props.borderTopWidth.value != 0 ||
      props.borderBottomWidth.value != 0)
  )
}

export type ReusableDecorationBorder = ReturnType<typeof ReusableDecorationBorder>

// Border radius
export const ReusableDecorationBorderRadius = (props: any = {}) => ({
  borderTopLeftRadius: Length(props.borderTopLeftRadius),
  borderTopRightRadius: Length(props.borderTopRightRadius),
  borderBottomLeftRadius: Length(props.borderBottomLeftRadius),
  borderBottomRightRadius: Length(props.borderBottomRightRadius)
})
ReusableDecorationBorderRadius.isValid = (props: ReusableDecorationBorderRadius) =>
  props.borderTopLeftRadius.value != 0 ||
  props.borderTopRightRadius.value != 0 ||
  props.borderBottomLeftRadius.value != 0 ||
  props.borderBottomRightRadius.value != 0
export type ReusableDecorationBorderRadius = ReturnType<typeof ReusableDecorationBorderRadius>

// Box shadow
export const ReusableDecorationBoxShadow = (props: any = {}) => ({
  boxShadowInset: !!props.boxShadowInset,
  boxShadowOffsetX: Length(props.boxShadowOffsetX),
  boxShadowOffsetY: Length(props.boxShadowOffsetY),
  boxShadowBlurRadius: Length(props.boxShadowBlurRadius),
  boxShadowSpreadRadius: Length(props.boxShadowSpreadRadius),
  boxShadowColor: ColorReference(props.boxShadowColor)
})

ReusableDecorationBoxShadow.isValid = (props: ReusableDecorationBoxShadow) => {
  return (
    ColorReference.isValid(props.boxShadowColor) &&
    (props.boxShadowOffsetX.value != 0 ||
      props.boxShadowOffsetY.value != 0 ||
      props.boxShadowBlurRadius.value != 0 ||
      props.boxShadowSpreadRadius.value != 0)
  )
}
export type ReusableDecorationBoxShadow = ReturnType<typeof ReusableDecorationBoxShadow>

// Combination of three above

export type ReusableDecoration = ReusableDecorationBorder & ReusableDecorationBorderRadius & ReusableDecorationBoxShadow
export const ReusableDecoration = (props: Unformatted<ReusableDecoration> = {}) => ({
  ...emptyIfInvalid(props, ReusableDecorationBorder),
  ...emptyIfInvalid(props, ReusableDecorationBorderRadius),
  ...emptyIfInvalid(props, ReusableDecorationBoxShadow)
})
export default ReusableDecoration
