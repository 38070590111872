import { css } from '@emotion/react'
import { Button, ButtonGroup, Divider, DragHandleIcon, Icon, IconButton, Text } from '@sitecore-ui/design-system'
import { useApi, useApiData } from 'hooks/useApiData.js'
import { ModelElement } from 'hooks/useEditor.js'
import inflection from 'inflection'
import { useEffect, useRef } from 'react'
import { EditorContextProps, getIconPathByElementType, ImplicitBorders } from './EditorChrome.js'
import EditorChromeContextActions from './EditorChromeContextActions.js'
import EditorChromeContextAdd from './EditorChromeContextAdd.js'
import EditorChromeContextConfigure from './EditorChromeContextConfigure.js'
import EditorChromeContextLayout from './EditorChromeContextLayout.js'
import EditorChromeContextParent from './EditorChromeContextParent.js'

export function getElementName(element: ModelElement) {
  return element && (element.is('rootElement') ? 'Variant' : inflection.titleize(inflection.underscore(element.name)))
}
export default function EditorChromeContextToolbar(props: EditorContextProps) {
  const { editor, dragged, context } = props
  const { api } = useApi()

  const dragRef = useRef<HTMLElement>(null)

  const apiEditor = useApiData('editor')
  const isSymbol = context?.getAttribute('data-symbol-ref') != null

  useEffect(() => {
    if (dragRef.current) editor.draggerPlugin.addExternalHandle(dragRef.current)
  }, [dragRef.current])

  return (
    <ButtonGroup
      spacing={'4px'}
      css={css`
        padding: 4px 3px 4px 4px;
        margin-top: -40px;
        background: #fff;
        position: absolute;
        align-items: center;
        display: inline-flex;
        border: 1px solid #94949d;
        border-radius: 30px;
        height: 32px;
        z-index: 21;

        > hr {
          height: 24px;
          border-color: #e4e4e7;
        }
      `}
      color='rgba(0,0,0,0.68)'
    >
      <IconButton
        {...{ ref: dragRef }}
        cursor='move'
        variant={dragged ? 'secondary' : 'minimal'}
        draggable={true}
        aria-label='Move element'
        isDisabled={context?.name == 'section' && !context.previousSibling && !context.nextSibling}
        icon={<DragHandleIcon fontSize='18px' />}
        css={ImplicitBorders}
      />
      {!dragged && <EditorChromeContextParent {...props} />}
      <Divider orientation='vertical' />
      <Button
        size='sm'
        borderRadius={'25px'}
        css={ImplicitBorders}
        borderWidth='1px'
        borderStyle={'solid'}
        px='4px'
        borderColor='blackAlpha.200'
        isActive={apiEditor.sidebarMode == 'style'}
        onClick={() => apiEditor.set({ sidebarMode: apiEditor.sidebarMode ? null : 'style' })}
        isDisabled={isSymbol}
        leftIcon={<Icon path={getIconPathByElementType(context?.name)} fontSize={'16'} />}
      >
        <Text as='div' style={{ display: 'block' }} noOfLines={0} maxWidth={300}>
          {isSymbol && 'Linked '}
          {getElementName(context)}
        </Text>
      </Button>

      {!isSymbol && (
        <>
          <EditorChromeContextLayout {...props} />
          <Divider orientation='vertical' />
          <EditorChromeContextConfigure {...props} />
        </>
      )}
      <Divider orientation='vertical' />
      <EditorChromeContextActions {...props} />
      <Divider orientation='vertical' />
      <EditorChromeContextAdd {...props} />
    </ButtonGroup>
  )
}
