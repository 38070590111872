import React, { FunctionComponent, ReactElement } from 'react'
import { QuestionOutlineIcon, NavigationHorizontal, Box, DotsGridIcon, Avatar } from '@sitecore-ui/design-system'
import { useAuth0 } from '@auth0/auth0-react'
import { AppSwitcher } from './AppSwitcher.js'
import { useLocation, useNavigate } from 'react-router'
import { useApiData } from '../hooks/useApiData.js'

const Navbar: FunctionComponent<{}> = (props: {}): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, logout } = useAuth0()
  const library = useApiData('library')

  const actions = [
    {
      active: false,
      ariaLabel: 'Toggle Drawer',
      icon: <QuestionOutlineIcon />,
      onClick: () => {}
    }
  ]

  const items = [
    {
      label: 'Component library',
      active: location.pathname.startsWith(`${library.getPath()}/collections`),
      onClick: () => navigate(`${library.getPath()}/collections`)
    },
    {
      label: 'Style guide',
      active: location.pathname.startsWith(`${library.getPath()}/styles`),
      onClick: () => navigate(`${library.getPath()}/styles`)
    },
    {
      label: 'Data sources',
      active: location.pathname.startsWith(`${library.getPath()}/datasources`),
      onClick: () => navigate(`${library.getPath()}/datasources`)
    }
  ]

  const menu = {
    name: user?.name,
    items: [
      {
        label: 'Logout',
        onClick: () => logout({ returnTo: window.location.origin })
      }
    ]
  }

  return (
    <Box height='14' boxShadow='base' flexShrink={0} zIndex={99} bg='white' pos='sticky' top='0' w='full'>
      <NavigationHorizontal
        actions={actions}
        icon={<AppSwitcher />}
        image={{
          src: 'https://mss-p-006-delivery.stylelabs.cloud/api/public/content/3997aaa0d8be4eb789f3b1541bd95c58?v=ecc6e45b',
          onClick: () => navigate(`${library.getPath()}/collections`),
          cursor: 'pointer'
        }}
        items={items}
        menu={menu}
      />
    </Box>
  )
}

export default Navbar
