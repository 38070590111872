import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input
} from '@sitecore-ui/design-system'
import type { CollectionModel } from '@sitecore-feaas/api'
import { useApiData } from 'hooks/useApiData.js'
import { FunctionComponent, ReactElement, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { collectionSchema } from 'utils/schemas/collectionSchema.js'
import FormError from '../FormError.js'

interface Props {
  collection: CollectionModel
  isOpen: boolean
  onClose: () => void
}

const CollectionDrawer: FunctionComponent<Props> = ({ isOpen, collection, onClose }: Props): ReactElement | null => {
  const title = collection ? 'Edit Collection' : 'Add collection'
  const action = collection ? 'Update Collection' : 'Add collection'
  const library = useApiData('library')

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({ mode: 'onChange', defaultValues: { name: collection?.name }, resolver: zodResolver(collectionSchema) })

  useEffect(() => {
    reset({ name: collection?.name })
  }, [collection, isOpen])

  const onSubmit = (data) => {
    if (collection) collection.put(data)
    else library.collections.post(data)
    onClose()
  }

  return (
    <Drawer preserveScrollBarGap isOpen={isOpen} placement='right' onClose={onClose} size='xs' blockScrollOnMount>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader>{title}</DrawerHeader>

        <DrawerBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <FormControl>
                <FormLabel>Name</FormLabel>

                <Input isInvalid={errors.name} placeholder='Choose unique name' {...register('name')} />
                {errors.name && <FormError error={errors.name.message} />}
              </FormControl>
            </Box>
          </form>
        </DrawerBody>

        <DrawerFooter mr='auto'>
          <Button mr='3' variant='primary' isDisabled={!isValid} onClick={handleSubmit(onSubmit)}>
            {action}
          </Button>

          <Button colorScheme='blue' onClick={onClose}>
            Dismiss
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default CollectionDrawer
