import {
  InputGroup,
  InputLeftElement,
  Input,
  SearchIcon,
  InputRightElement,
  CloseIcon
} from '@sitecore-ui/design-system'
import { useContext } from 'react'
import { QueryStringContext } from '../../providers/QueryStringProvider.js'

const Search = () => {
  const [query, setQuery] = useContext(QueryStringContext)

  return (
    <InputGroup>
      <InputLeftElement children={<SearchIcon w='5' h='5' color='gray.600' />} />
      <Input
        placeholder='Search by title'
        value={query.search || ''}
        onChange={(e) => setQuery({ search: e.target.value || null })}
      />
      {query.search?.length > 0 && (
        <InputRightElement>
          <CloseIcon onClick={() => setQuery({ search: null })} />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default Search
