import { z } from 'zod'
import { isValidJSON } from '../string.js'

export const datasourceSchema = z.object({
  name: z.string().min(1, { message: 'Name field is required' }),
  description: z.string(),
  jsonpath: z.string(),
  sample: z.string().refine(
    (v) => {
      return isValidJSON(v)
    },
    (val) => ({
      message: `Not valid JSON`
    })
  ),
  id: z.string().min(10),
  libraryId: z.string().min(10),
  createdAt: z.date(),
  modifiedAt: z.date(),
  sampledAt: z.date().nullable()
})
