import EditorWatchdog from '@ckeditor/ckeditor5-watchdog/src/editorwatchdog.js'
import { useCallback, useEffect, useRef, useState } from 'react'

import { Editor } from 'ckeditor5-custom-build'

import * as FEAAS from '@sitecore-feaas/sdk'

import type { ComponentModel, Datasource, DatasourceModel, Variant, VariantModel } from '@sitecore-feaas/api'
import { useApiData } from './useApiData.js'
export type { ModelBatch, ModelElement, ViewElement } from 'ckeditor5-custom-build'
export type { Editor }

export function useEditor(
  config: any,
  node: HTMLElement,
  component: ComponentModel,
  datasources: DatasourceModel[],
  onChange: () => any
) {
  const [editor, setEditor] = useState<Editor>()
  const apiEditor = useApiData('editor')

  const [EditorClass, setEditorClass] = useState(() => window.ComponentsCKEditor as any as typeof Editor)
  useEffect(() => {
    if (!EditorClass)
      import('ckeditor5-custom-build').then(() => {
        if (FEAAS?.HTMLFeaasComponent) {
          console.log('Sdk loaded')
        }
        console.log('Editor is loaded!', window.ComponentsCKEditor)
        setEditorClass(() => window.ComponentsCKEditor as any as typeof Editor)
        return () => {
          setEditorClass(null)
        }
      })
  }, [])

  useEffect(() => {
    if (!editor) return
    const observer = FEAAS.autoloadScripts(node)
    return () => {
      observer.disconnect()
    }
  }, [editor])

  // initialize editor watchdog that captures crashes
  useEffect(() => {
    if (EditorClass == null) return
    const watchdog = new EditorWatchdog()

    watchdog.setCreator((elementOrData, config) => {
      return EditorClass.createRoots(elementOrData, config).then((editor: Editor) => {
        editor['watchdog'] = watchdog
        setTimeout(() => {
          if (import.meta.env.MODE == 'development')
            if (window.location.search.includes('inspect')) {
              import('@ckeditor/ckeditor5-inspector').then(({ default: CKEditorInspector }) => {
                CKEditorInspector.attach(editor)
              })
            }
        }, 500)
        setEditor(editor)
        return editor
      })
    })

    console.log('Editor is initializing!', node)
    if (node) {
      watchdog.create(
        {},
        {
          FEAAS,
          container: node,
          ...config
        }
      )
    }
  }, [node, EditorClass])

  // initialize editor watchdog that captures crashes
  useEffect(() => {
    if (editor) {
      editor.ui.on('change:lastFocusedEditableElement', (evt, name, focusedElement) => {
        if (focusedElement) {
          apiEditor.set({
            focusedEditableName: focusedElement.id,
            isFocused: true
          })
        } else {
          apiEditor.set({
            isFocused: false
          })
        }
      })
      editor.model.document.on('change:data', (evt, batch) => {
        console.log('Data change', batch)
        if (!apiEditor.focusedEditableName) return
        if (!editor['suppressedChanges']) {
          onChange()
        }
      })
      editor.on('change:context', (evt, name, context) => {
        if (!context) return
        apiEditor.set({
          contextEditableName: context.root.rootName
        })
      })
      apiEditor.set({
        status: 'initialized'
      })
    }
  }, [editor])

  // hook into saving logic
  useEffect(() => {
    if (editor) {
      editor.autosavePlugin.on('change:state', (evt, prop, value) => {
        console.log('Autosave state:', value)
        apiEditor.set({
          savingStatus: value
        })
      })
    }
  }, [editor])

  // destroy editor when component is unloaded
  useEffect(() => {
    console.log('Editor init')
    if (!editor) return
    return () => {
      console.log('Editor destroy', editor)
      editor.destroy()
    }
  }, [editor])

  // pass options from UI to editor
  useEffect(() => {
    if (editor) {
      editor.plugins.get('Preview').set('isDataRepeated', apiEditor.isDataRepeated)
      editor.plugins.get('Preview').set('isDataDisplayed', apiEditor.isDataDisplayed)
    }
  }, [editor, apiEditor.isDataDisplayed, apiEditor.isDataRepeated])

  // pass datasource updates to editor
  useEffect(() => {
    if (editor) {
      editor.set('datasources', datasources)
    }
  }, [editor, datasources])

  // hand down variants to the editor, each variant creates editable root
  useEffect(() => {
    if (node && editor) {
      const body = editor.ui.view.body['_bodyCollectionContainer']
      if (!body.parentNode) return
      body.remove()
      node.parentNode.appendChild(body)
      if (apiEditor.status != 'ready') {
        apiEditor.set({
          status: 'ready'
        })
        editor.fire('ready')
      }
    }
  }, [node, editor, apiEditor.status])

  useEffect(() => {
    if (node && editor) {
      const onClick: (this: HTMLElement, e: MouseEvent) => any = (e) => {
        if (e.target instanceof HTMLElement && e.target.closest('[contenteditable] a, [contenteditable] button')) {
          e.preventDefault()
        }
      }
      node.addEventListener('click', onClick)
      return () => {
        node.removeEventListener('click', onClick)
      }
    }
  }, [node, editor])

  useEffect(() => {
    if (editor && node) {
      const wrapper = node.closest('[tabindex="-1"]') as HTMLElement
      editor.ui.focusTracker.add(wrapper)
      return () => {
        editor.ui.focusTracker.remove(wrapper)
      }
    }
  }, [node, editor])

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.metaKey) {
        node.classList.add('allow-interaction')
      }
      if (!event.ctrlKey && !event.metaKey && !event.altKey) return
      if (event.target.closest('[contenteditable], input, textarea')) return

      if (event.key.toLowerCase() === 'z') {
        editor.execute('undo')
      }
      if (event.key === 'y' || (event.shiftKey && event.key.toLowerCase() == 'z')) {
        editor.execute('redo')
      }
    }

    const onKeyUp = (event) => {
      if (!event.metaKey) {
        node.classList.remove('allow-interaction')
      }
    }

    document.addEventListener('keydown', onKeyDown, true)
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keydown', onKeyDown, true)
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [node, editor])

  // sync variant drag & drop back to the store
  useEffect(() => {
    const onVariantMoved = (evt, [rootName, beforeName]) => {
      component.findVariant(rootName).move(beforeName)
    }

    const onVariantRemoved = (evt, rootName) => {
      component.findVariant(rootName).archive()
    }

    if (editor) {
      editor.on('rootMoved', onVariantMoved)
      editor.on('rootRemoved', onVariantRemoved)
      return () => {
        editor.off('rootMoved', onVariantMoved)
        editor.off('rootRemoved', onVariantRemoved)
      }
    }
  }, [editor])

  const setFocus = useCallback(
    (rootName: string | boolean) => {
      return requestAnimationFrame(() => {
        if (typeof rootName !== 'string') rootName = editor.model.document.getRootNames().slice().pop()
        ;(document.querySelector(`[id="${rootName}"]`) as HTMLElement)?.focus()
        if (!editor) return
        const root = editor.model.document.getRoot(rootName)
        if (root && editor.current.context?.root != root) {
          editor.model.enqueueChange({ isUndoable: false }, () => {
            editor.execute('setContext', root)
            editor.execute('moveToApplicableContext')
          })
        }
      })
    },
    [editor]
  )

  const needsFocus = useRef<string | boolean>(null)
  useEffect(() => {
    if (needsFocus.current) {
      setFocus(needsFocus.current)
      needsFocus.current = null
    }
  }, [needsFocus.current])

  // deferToNextUpdate allows focus to be set on next react tick,
  // allowing new variant to be inserted into dom first
  // It is expected that the render will be triggered externally
  const focus = useCallback(
    (rootName?: string, deferToNextUpdate?: boolean) => {
      if (deferToNextUpdate) {
        needsFocus.current = rootName || true
      } else {
        setFocus(rootName)
      }
    },
    [needsFocus]
  )

  const blur = useCallback(() => {
    if (!editor.model.document.selection.isCollapsed)
      editor.model.enqueueChange({ isUndoable: false }, (writer) => {
        writer.setSelection(editor.model.document.selection.focus, 0)
      })
    // @ts-ignore
    document.activeElement.blur()
  }, [editor])

  return { editor, focus, blur }
}
