import { Box, Flex, H5, Radio, Text } from '@sitecore-ui/design-system'
import { useApiData } from 'hooks/useApiData.js'
import inflection from 'inflection'
import { StyleFor } from 'models/style/index.js'
import { FontVariant, FontVariantReference } from 'models/style/font.js'
import { deepMerge } from 'utils/object.js'
import FlexGrid from '../../FlexGrid.js'
import Preview from '../previews/index.js'
import { StyleFormProps } from '../settings.js'

const TypographyTypeFieldset = (props: StyleFormProps<'typography'>) => {
  const { onChange, currentStyle } = props

  const fonts = useApiData('library.stylesheet.styles').filter((style) => style.type === 'font') as StyleFor<'font'>[]

  const currentFontVariant = currentStyle.props?.fontVariant as FontVariantReference

  const onChangeVariant = (font: StyleFor<'font'>, variant: FontVariant): void => {
    let nextFontVariant = null

    if (currentFontVariant?.id !== font.details.id || currentFontVariant.name !== variant.name) {
      nextFontVariant = { id: font.details.id, name: variant.name }
    }

    onChange(deepMerge(currentStyle, { props: { fontVariant: nextFontVariant } }))
  }

  return (
    <>
      {fonts
        .filter((font: StyleFor<'font'>) => font.props.variants.length)
        .map((font: StyleFor<'font'>, index) => {
          const name = `${font.details.title} [${font.props?.familyName}]`

          return (
            <Box key={name} mt={index > 0 ? 5 : 0}>
              <H5 mb={3}>{name}</H5>

              <FlexGrid>
                {font.props.variants?.map((variant) => (
                  <Flex
                    as='label'
                    cursor='pointer'
                    p={5}
                    key={variant.name}
                    h='100px'
                    boxShadow='base'
                    bg='white'
                    borderRadius='base'
                    flexDirection='column'
                    justifyContent={'space-between'}
                  >
                    <Preview
                      style={{
                        ...font,
                        props: {
                          ...font.props,
                          variants: [variant]
                        }
                      }}
                    />

                    <Flex>
                      <Radio
                        name={`variant-${currentStyle.details.id}`}
                        onChange={() => onChangeVariant(font, variant)}
                        isChecked={
                          currentFontVariant?.id === font.details.id && currentFontVariant?.name === variant.name
                        }
                      />

                      <Text ml='2'>
                        {inflection.titleize(variant.style)} {variant.weight}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </FlexGrid>
            </Box>
          )
        })}
    </>
  )
}

export default TypographyTypeFieldset
