import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogButtonGroup,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure
} from '@sitecore-ui/design-system'
import { createContext, useEffect, useRef, useState } from 'react'

export const ConfirmationOverlayClassname = '.confirm-dialog'
export const eventOnConfirmationOverlay = (e) => (e.target as HTMLElement).closest(ConfirmationOverlayClassname)

interface Confirmation {
  type?: string
  body: any
  title: string | JSX.Element
  button: string | JSX.Element
  action: () => void
}

export const ConfirmationContext = createContext({
  setConfirm: null as (confirmation: Confirmation) => any
})

const ConfirmationProvider = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const [confirm, setConfirm] = useState<Confirmation>(null)

  useEffect(() => {
    if (confirm) {
      onOpen()
    } else {
      onClose()
    }
  }, [confirm])

  const close = () => {
    setConfirm(null)
    onClose()
  }

  const isModal = confirm?.type == 'modal'

  const Dialog = isModal ? Modal : AlertDialog
  const Overlay = isModal ? ModalOverlay : AlertDialogOverlay
  const Content = isModal ? ModalContent : AlertDialogContent
  const Header = isModal ? ModalHeader : AlertDialogHeader
  const Body = isModal ? ModalBody : AlertDialogBody
  const Footer = isModal ? ModalFooter : AlertDialogFooter
  const maxWidth = isModal ? '3xl' : 'md'

  return (
    <ConfirmationContext.Provider value={{ setConfirm }}>
      {children}

      <Dialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close} isCentered={false}>
        <Overlay className='confirm-dialog'>
          <Content maxWidth={maxWidth}>
            {isModal && <ModalCloseButton />}
            <Header fontSize='lg' fontWeight='bold'>
              {confirm?.title}
            </Header>

            <Body>{confirm?.body}</Body>

            <Footer>
              <Button ref={cancelRef} onClick={close}>
                {isModal ? 'Close' : 'Cancel'}
              </Button>

              {confirm?.button && (
                <Button
                  colorScheme={isModal ? 'primary' : 'red'}
                  onClick={() => {
                    confirm?.action()
                    close()
                  }}
                  ml={3}
                >
                  {confirm?.button}
                </Button>
              )}
            </Footer>
          </Content>
        </Overlay>
      </Dialog>
    </ConfirmationContext.Provider>
  )
}

export default ConfirmationProvider
