import { default as deepMerge } from 'ts-deepmerge'
import { any } from 'zod'

export { equal as isShallowEquals } from 'fast-shallow-equal'

deepMerge.options.mergeArrays = false

export { deepMerge }

export const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every((key) => obj1[key] === obj2[key])
