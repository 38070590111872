import React, { FunctionComponent, ReactElement } from 'react'
import StyleSelectGrid from './StyleSelectGrid.js'
import { ElementTextProps, Style, StyleFor, StyleRule } from 'models/style/index.js'
import { StyledSelect } from '../../StyledSelect.js'
import { deepMerge } from 'utils/object.js'
import { Option } from 'types/index.js'
import PaletteTextFieldset from '../fieldsets/PaletteTextFieldset.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import { Box, Button, FormControl, FormLabel, MinusIcon, PlusSquareIcon, Text } from '@sitecore-ui/design-system'

interface Props {
  onChange: (style) => void
  collection: Style[]
  selectedIds: string[]
  children?: ReactElement
  label: string
  customStyle: Style
  activeStyleType: string
  onToggleCustom: () => void
  currentStyle: Style
  onChangeCurrentStyle: (style: Style) => void
  onChangeCustom: (custom: Style) => void
}

const tags = [
  { label: 'h1', value: 'h1' },
  { label: 'h2', value: 'h2' },
  { label: 'h3', value: 'h3' },
  { label: 'h4', value: 'h4' },
  { label: 'h5', value: 'h5' },
  { value: 'h5', label: 'h6' },
  { value: 'p', label: 'p' },
  { value: 'li', label: 'li' }
]

const StyleSelect: FunctionComponent<Props> = (props: Props): ReactElement => {
  const {
    onChange,
    collection,
    selectedIds,
    children,
    label,
    customStyle,
    onToggleCustom,
    currentStyle,
    onChangeCurrentStyle,
    onChangeCustom,
    activeStyleType
  } = props

  const allStyles = (customStyle ? [customStyle] : []).concat(collection)

  const setChecked = (style) => {
    const nextIds = allStyles
      .filter((s) => {
        const alreadySelected = selectedIds == null || selectedIds?.includes(s.details.id)
        if (style.details.id == s.details.id) {
          return !alreadySelected
        }
        return alreadySelected
      })
      .map((s) => s.details.id)

    // todo removing custom shouldnt switch still be null
    onChange(nextIds.length == allStyles.length ? null : nextIds)
  }

  const onChangeSwitch = (index) => {
    if (index === 0) {
      onChange(null)

      return
    }

    if (index === 1) {
      const collectionWithCustom = customStyle ? collection.concat(customStyle) : collection
      onChange(collectionWithCustom.map((style) => style.details.id))

      return
    }

    if (index === 2) {
      onChange([])

      return
    }
  }

  const getSelectionIndex = () => {
    if (selectedIds === null) return 0

    if (Array.isArray(selectedIds)) {
      if (selectedIds.length === 0) return 2

      return 1
    }
  }

  const isChecked = (style: Style) => {
    if (selectedIds === null) return true
    if (selectedIds.length == 0 && style.details.isInternal) {
      return null
    }

    return selectedIds?.includes(style.details.id)
  }

  return (
    <>
      {activeStyleType === 'text' && label === 'Typography' && (
        <FormControl mb='6'>
          <FormLabel>Text element tag</FormLabel>

          <StyledSelect
            fixedWidth
            options={tags}
            onChange={(value: Option) => {
              onChangeCurrentStyle(deepMerge(currentStyle, { props: { tag: value.value } }))
            }}
            value={tags.find((tag) => tag.value === (currentStyle as StyleRule<'text', ElementTextProps>).props?.tag)}
          />
        </FormControl>
      )}
      {label === 'Color & link style' ? (
        <>
          {customStyle && (
            <PaletteTextFieldset
              currentStyle={customStyle as StyleFor<'palette'>}
              onChange={(style) => {
                onChange([style.details.id])
                onChangeCustom(style)
              }}
            />
          )}
        </>
      ) : (
        <>
          <SwitchGroupField value={getSelectionIndex()} onChange={onChangeSwitch} mb={6}>
            <Button>Allow all</Button>
            <Button>Only selected</Button>
            <Button>None</Button>
          </SwitchGroupField>

          <StyleSelectGrid styles={collection} setChecked={setChecked} isChecked={isChecked} />

          <Box mt='5'>
            {customStyle && (
              <>
                <Text onClick={onToggleCustom} fontWeight='600' color='blackAlpha.600'>
                  <MinusIcon mr='1' />
                  Remove custom {label.toLowerCase()}
                </Text>

                <StyleSelectGrid styles={[customStyle]} setChecked={setChecked} isChecked={isChecked} />

                <Box mt='5'>{children}</Box>
              </>
            )}

            {!customStyle && (
              <Text cursor='pointer' onClick={onToggleCustom} fontWeight='semibold' color='blackAlpha.600'>
                <PlusSquareIcon mr='1' />
                Add custom {label.toLowerCase()}
              </Text>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default StyleSelect
