import { API } from '../api.js'
import APIAdapter from '../core/adapter.js'
import { APICollection } from '../core/collection.js'
import { APIModel, RequiredKeys } from '../core/model.js'
import { nanoid } from '../core/transport.js'
import { generateApiKey } from 'generate-api-key'
import type { StylesheetModel } from './stylesheets.js'

export interface Library {
  id: string
  name: string
  xmProjectId: string
  apiKey?: string
}

export class Libraries extends APIAdapter<Library> {
  get({ id }: Library) {
    return this.api.fetch(`/libraries/${id}`)
  }
}

export function getLibraryDefaults(api: API) {
  return {
    id: nanoid(10),
    apiKey: generateApiKey(),
    createdAt: new Date(),
    modifiedAt: new Date()
  }
}

export type LibraryMinimal<T extends keyof Library = never> = RequiredKeys<Library, typeof getLibraryDefaults, T>
export interface LibraryParams extends Library {}
export interface LibraryModel extends LibraryParams {}

export class LibraryModel extends APIModel<Library, LibraryMinimal> implements LibraryParams {
  collections: ReturnType<this['constructCollections']>
  datasources: ReturnType<this['constructDatasources']>
  stylesheets: ReturnType<this['constructStylesheets']>
  components: ReturnType<this['constructComponents']>

  get adapter() {
    return this.api.Libraries
  }

  defineProperties() {
    super.defineProperties()
    this.api.utils.defineCollectionAccessor(this, 'collections', this.constructCollections(), {}, this.getHiddenProps())
    this.api.utils.defineCollectionAccessor(this, 'datasources', this.constructDatasources(), {}, this.getHiddenProps())
    this.api.utils.defineCollectionAccessor(this, 'stylesheets', this.constructStylesheets(), {}, this.getHiddenProps())
    this.api.utils.defineCollectionAccessor(this, 'components', this.constructComponents(), {}, this.getHiddenProps())
    this.api.utils.defineAccessor(this, 'stylesheet', {}, this.getHiddenProps())
  }

  getHiddenProps() {
    return ['stylesheet', 'collections', 'components', 'datasources', 'stylesheets']
  }

  constructCollections() {
    return APICollection.construct(this.api.Collection, () => ({
      library: this,
      libraryId: this.id
    }))
  }
  constructComponents() {
    return APICollection.construct(this.api.Component, () => ({
      library: this,
      libraryId: this.id
    }))
  }
  constructDatasources() {
    return APICollection.construct(this.api.Datasource, () => ({
      library: this,
      libraryId: this.id
    }))
  }
  constructStylesheets() {
    return APICollection.construct(this.api.Stylesheet, () => ({
      library: this,
      libraryId: this.id
    }))
  }

  _stylesheet: StylesheetModel
  get stylesheet() {
    return (this._stylesheet ||= this.stylesheets.construct())
  }
  set stylesheet(stylesheet: StylesheetModel) {
    this._stylesheet = stylesheet
  }

  getDefaults(api: API) {
    return getLibraryDefaults(api)
  }

  getPath() {
    return `/libraries/${this.id}`
  }

  static generateProjectIdFromTenant(tenant): string {
    const { annotations } = tenant
    if (annotations && annotations['XMCloud.ProjectId']) return annotations['XMCloud.ProjectId']
    else if (tenant.name.split('-')[1]) return `${tenant.organizationId}-${tenant.name.split('-')[1]}`
    else return null
  }
}
