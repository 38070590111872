export type Unit = 'px' | 'em' | '%' | 'rem'
export type Length = { unit: Unit; value: number }

const normalizeStringLength = (length: string): Length => {
  let parsedUnit = length.match(/px|em|%|rem/)
  let parsedValue = parseFloat(length)

  let unit: Unit = 'px'
  let value: number = 0

  if (parsedUnit) {
    unit = parsedUnit[0] as Unit
  }

  if (!Number.isNaN(parsedValue)) {
    value = parsedValue
  }

  return { value, unit }
}

export const Length = (length: any): Length => {
  if (typeof length === 'string') {
    return normalizeStringLength(length)
  }

  if (typeof length === 'number') {
    return { value: normalizeStringLength(`${length}px`).value, unit: 'px' }
  }

  if (length && (length.value || length.unit)) {
    return normalizeStringLength(`${length.value}${length.unit}`)
  }

  return { value: 0, unit: 'px' }
}
