import { Children, cloneElement, ReactElement } from 'react'
import { Flex } from '@sitecore-ui/design-system'
import { default as styled } from '@emotion/styled'

const Styled = styled<any>(Flex)`
  & > * {
    margin: ${(props: { spacing: number }) => props.spacing / 2}px;
    max-width: 100%;
    width: 320px;
  }

  align-items: flex-start;
`

const FlexGrid = (props) => {
  const { spacing = 24, children } = props

  return (
    <Styled spacing={spacing} wrap='wrap' m={`-${spacing / 2}px`} mt={`-${spacing / 2}px`}>
      {children}
    </Styled>
  )
}

export default FlexGrid
