export function emptyIfInvalid<T>(props: any, generator: { (any?): T; isValid: (T) => boolean }): T {
  const processed = generator(props)
  if (generator.isValid(processed)) {
    return processed
  } else {
    return generator()
  }
}

export type Unformatted<T> = { [key in keyof T]?: T[key] | string | number | object }
