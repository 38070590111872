import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { Style } from 'models/style/index.js'
import { StyleFormProps } from '../settings.js'
import { default as styled } from '@emotion/styled'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@sitecore-ui/design-system'

interface Props {
  mode: 'accordion' | 'tabs'
  forms?: { [key: string]: FunctionComponent<any> }
  currentStyle: Style
  isNew: boolean
  onChange: (style: Style) => void
  style: Style
  errors?: {}
  activeId: string
}

const StyledAccordionButton = styled<any>(AccordionButton)``

const StyleForm: FunctionComponent<Props> = ({
  mode,
  forms,
  currentStyle,
  onChange,
  style,
  activeId,
  errors,
  isNew
}: Props): ReactElement => {
  const formKeys = Object.keys(forms)

  const { id } = style.details

  const computedIndex = () => (isNew ? 0 : 1)

  const [index, setIndex] = useState(computedIndex())
  const [forceShowErrors, setForceShowErrors] = useState(false)

  useEffect(() => {
    setIndex(computedIndex())
  }, [id])

  const onClick = (enabled: boolean) => {
    if (!enabled) {
      setForceShowErrors(true)
    }
  }

  const onTabChange = (index: number) => {
    if (formKeys[index] !== 'Details' && !currentStyle.details.title) return

    setIndex(index)
  }

  const onFormChange = (value) => {
    setForceShowErrors(false)

    onChange(value)
  }

  return (
    <form>
      {mode === 'tabs' && (
        <Tabs w='full' index={index} onChange={onTabChange} isManual>
          <TabList>
            {formKeys.map((title: string) => {
              const enabled = title === 'Details' || !!currentStyle.details.title

              return (
                <Tab
                  fontWeight='semibold'
                  key={title}
                  opacity={enabled ? 1 : 0.5}
                  cursor={enabled ? 'pointer' : 'not-allowed'}
                  onClick={() => onClick(enabled)}
                  aria-label={`Tab ${title}`}
                >
                  {title}
                </Tab>
              )
            })}
          </TabList>

          <TabPanels>
            {Object.values(forms).map((Form: FunctionComponent<StyleFormProps> | ReactElement, i) => (
              <TabPanel key={i} py={6} px={0} aria-label={`Tab ${i} Content`}>
                {typeof Form === 'function' ? (
                  <Form
                    currentStyle={currentStyle}
                    onChange={onFormChange}
                    style={style}
                    errors={errors}
                    activeId={activeId}
                    forceShowErrors={forceShowErrors}
                    isActive={i == index}
                  />
                ) : (
                  Form
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}

      {mode === 'accordion' && (
        <Accordion allowToggle>
          {formKeys.map((title, index) => {
            const Form = forms[title]

            return (
              <AccordionItem key={index}>
                <StyledAccordionButton p={4}>
                  <Text fontWeight='semibold'>{title}</Text>

                  <AccordionIcon />
                </StyledAccordionButton>

                <AccordionPanel {...{ pt: 6, pb: 9 }}>
                  {typeof Form === 'function' ? (
                    <Form
                      currentStyle={currentStyle}
                      onChange={onChange}
                      style={style}
                      errors={errors}
                      activeId={activeId}
                    />
                  ) : (
                    Form
                  )}
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      )}
    </form>
  )
}

export default StyleForm
