import { Style, StyleFor } from 'models/style/index.js'
import ColorsForm from './fieldsets/ColorsFieldset.js'
import FontForm from './fieldsets/FontFieldset.js'
import FillColorFieldset from './fieldsets/FillColorFieldset.js'
import DetailsFieldset from './fieldsets/DetailsFieldset.js'
import FillEffectsFieldset from './fieldsets/FillEffectsFieldset.js'
import TypographyTypeFieldset from './fieldsets/TypographyTypeFieldset.js'
import TypographySizeFieldset from './fieldsets/TypographySizeFieldset.js'
import DecorationBorderRadiusFieldset from './fieldsets/DecorationBorderRadiusFieldset.js'
import DecorationBoxShadowFieldset from './fieldsets/DecorationBoxShadowFieldset.js'
import DecorationBorderFieldset from './fieldsets/DecorationBorderFieldset.js'
import { FunctionComponent } from 'react'
import SpacingFieldset from './fieldsets/SpacingFieldset.js'
import PaletteTextFieldset from './fieldsets/PaletteTextFieldset.js'

type StyleOrType<T> = T extends Style['type'] ? StyleFor<T> : T extends Style ? T : any

export interface StyleFormProps<T = Style> {
  currentStyle: StyleOrType<T>
  onChange: (style: StyleOrType<T>) => void
  style?: StyleOrType<T>
  errors?: Record<string, Record<string, string>>
  isActive?: boolean
  forceShowErrors?: boolean
  activeId?: string
}

export interface BasicSettings {
  type: Style['type']
  label: string
  property?: string
  forms: Record<string, FunctionComponent<StyleFormProps<any>>>
}

export interface ElementSettings {
  aspects: BasicSettings[]
}

export const basic: Record<string, BasicSettings> = {
  color: {
    type: 'color',
    label: 'Color',
    forms: {
      Details: DetailsFieldset,
      Colors: ColorsForm
    }
  },
  font: {
    type: 'font',
    label: 'Font',
    forms: {
      Details: DetailsFieldset,
      'Font selector': FontForm
    }
  }
}

export const reusable: Record<string, BasicSettings> = {
  fill: {
    type: 'fill',
    label: 'Fill',
    property: 'fillIds',
    forms: {
      Details: DetailsFieldset,
      Color: FillColorFieldset,
      Effects: FillEffectsFieldset
    }
  },
  typography: {
    type: 'typography',
    label: 'Typography',
    property: 'typographyIds',
    forms: {
      Details: DetailsFieldset,
      'Font type': TypographyTypeFieldset,
      Size: TypographySizeFieldset
    }
  },
  decoration: {
    type: 'decoration',
    label: 'Decoration',
    property: 'decorationIds',
    forms: {
      Details: DetailsFieldset,
      'Corner radius': DecorationBorderRadiusFieldset,
      'Drop shadow': DecorationBoxShadowFieldset,
      Border: DecorationBorderFieldset
    }
  },
  spacing: {
    type: 'spacing',
    label: 'Spacing',
    property: 'spacingIds',
    forms: {
      Details: DetailsFieldset,
      Spacing: SpacingFieldset
    }
  },
  layout: {
    type: 'layout',
    label: 'Layout',
    property: 'layoutIds',
    forms: {}
  },
  dimensions: {
    type: 'dimensions',
    label: 'Dimensions',
    property: 'dimensionIds',
    forms: {}
  },
  palette: {
    type: 'palette',
    label: 'Color & link style',
    property: 'paletteIds',
    forms: {
      Details: PaletteTextFieldset
    }
  }
}
export const element: Record<string, ElementSettings> = {
  section: {
    aspects: [reusable.fill, reusable.decoration, reusable.spacing]
  },

  text: {
    aspects: [reusable.typography, reusable.palette]
  },

  button: {
    aspects: [reusable.typography, reusable.spacing, reusable.decoration, reusable.fill, reusable.palette]
  },

  card: {
    aspects: [reusable.fill, reusable.decoration, reusable.spacing]
  }
}

export const settings = {
  ...element,
  ...reusable,
  ...basic
}
