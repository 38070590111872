import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import { StyleFor, StyleRule } from 'models/style/index.js'
import NumberField from '../fields/NumberField.js'
import ColorField from '../fields/ColorField.js'
import { Box, Button, FormControl, FormLabel, Grid, GridItem } from '@sitecore-ui/design-system'
import { deepMerge } from 'utils/object.js'
import FlexGrid from '../../FlexGrid.js'
import { BorderStyle, ReusableDecoration, ReusableDecorationBorder } from 'models/style/decoration.js'
import { Unformatted } from 'models/style/types/props.js'
import { StyleFormProps } from '../settings.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'

enum BorderStyleSettings {
  'solid',
  'dotted',
  'dashed'
}

const DecorationBorderFieldset = ({ onChange, currentStyle, style, isActive }: StyleFormProps<'decoration'>) => {
  const { borderRightWidth, borderLeftWidth, borderTopWidth, borderBottomWidth, borderStyle, borderColor } =
    currentStyle.props

  const onUpdate = (changes: Unformatted<ReusableDecorationBorder>) => {
    onChange(deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) }))
  }

  const borders = [borderRightWidth, borderLeftWidth, borderBottomWidth, borderTopWidth]

  const getIndex = () => {
    if (!ReusableDecorationBorder.isValid(currentStyle.props)) {
      return 0
    }
    if (borders.every((width) => width.value === borderTopWidth.value)) {
      return 1
    }
    return 2
  }
  const [index, setIndex] = useState(getIndex)
  useEffect(() => setIndex(getIndex), [style, isActive])

  useEffect(() => {
    if (index == 1 || index == 2) {
      onUpdate({
        borderBottomWidth: { value: borderTopWidth.value },
        borderRightWidth: { value: borderTopWidth.value },
        borderLeftWidth: { value: borderTopWidth.value },
        borderTopWidth: { value: borderTopWidth.value }
      })
    }
    if (index == 0) {
      onUpdate(ReusableDecorationBorder())
    }
  }, [index])

  return (
    <>
      <SwitchGroupField value={index} onChange={setIndex} mb={6}>
        <Button>None</Button>
        <Button>Same width</Button>
        <Button>Per side</Button>
      </SwitchGroupField>

      {index > 0 && (
        <FlexGrid>
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Style</FormLabel>

                <ButtonGroupSwitch
                  index={BorderStyleSettings[borderStyle]}
                  onChange={(index) => onUpdate({ borderStyle: BorderStyleSettings[index] })}
                >
                  <Button>Solid</Button>
                  <Button>Dotted</Button>
                  <Button>Dashed</Button>
                </ButtonGroupSwitch>
              </FormControl>
            </GridItem>

            {index == 2 && (
              <>
                <GridItem colSpan={1}>
                  <NumberField
                    value={borderTopWidth.value}
                    label='Top border'
                    onChange={(value) => onUpdate({ borderTopWidth: { value } })}
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <NumberField
                    value={borderRightWidth.value}
                    label='Right border'
                    onChange={(value) => onUpdate({ borderRightWidth: { value } })}
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <NumberField
                    value={borderBottomWidth.value}
                    label='Bottom border'
                    onChange={(value) => onUpdate({ borderBottomWidth: { value } })}
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <NumberField
                    value={borderLeftWidth.value}
                    label='Left border'
                    onChange={(value) => onUpdate({ borderLeftWidth: { value } })}
                  />
                </GridItem>
              </>
            )}

            {index == 1 && (
              <NumberField
                value={borderLeftWidth.value}
                label='Border'
                onChange={(value) => {
                  onUpdate({
                    borderBottomWidth: { value },
                    borderRightWidth: { value },
                    borderLeftWidth: { value },
                    borderTopWidth: { value }
                  })
                }}
              />
            )}
          </Grid>

          <Grid templateColumns='repeat(1, 1fr)' gap={6}>
            <GridItem colSpan={1}>
              <ColorField
                colorReference={borderColor}
                label='Color'
                onChange={(color) => onUpdate({ borderColor: color })}
              />
            </GridItem>
          </Grid>
        </FlexGrid>
      )}
    </>
  )
}
export default DecorationBorderFieldset
