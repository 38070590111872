import { default as styled } from '@emotion/styled'
import { Select } from '@sitecore-ui/design-system'

export const StyledSelect = styled(Select)`
  cursor: pointer;
  caret-color: transparent;

  > [id*='listbox'] {
    z-index: 3;
  }
`
