import { API } from '../api.js'
import APIAdapter from '../core/adapter.js'
import { APICollection } from '../core/collection.js'
import { APIModel, Atleast, RequiredKeys } from '../core/model.js'
import Transport, { nanoid } from '../core/transport.js'
import type { Component, ComponentModel } from './components.js'
import { Library, LibraryModel } from './libraries.js'

export interface Collection {
  id: string
  libraryId: string
  name: string
  isDefault: boolean
  orderIdx: string
  createdAt?: Date
}

export class Collections extends APIAdapter<Collection> {
  fetch(collection: Pick<CollectionParams, 'libraryId'>): Promise<Collection[]> {
    const { libraryId } = collection
    return this.api.fetch(`/libraries/${libraryId}/collections`)
  }
  get(collection: Pick<CollectionParams, 'libraryId' | 'id'>): Promise<Collection> {
    const { libraryId, id } = collection
    return this.api.fetch(`/libraries/${libraryId}/collections/${id}`)
  }
  post(collection: Atleast<CollectionParams, 'libraryId'>): Promise<Collection> {
    const { libraryId } = collection
    return this.api.fetch(`/libraries/${libraryId}/collections`, {
      method: 'POST',
      body: JSON.stringify({
        ...collection
      })
    })
  }
  put(collection: Atleast<CollectionParams, 'libraryId' | 'id'>) {
    return this.post(collection)
  }
  delete(collection: Pick<CollectionParams, 'libraryId' | 'id'>): Promise<Collection> {
    const { libraryId, id } = collection
    return this.api.fetch(`/libraries/${libraryId}/collections/${id}`, { method: 'DELETE' })
  }
}
export function getCollectionDefaults(api: API) {
  return {
    id: nanoid(10),
    isDefault: false,
    orderIdx: '',
    createdAt: new Date(),
    modifiedAt: new Date()
  }
}
export type CollectionMinimal<T extends keyof Collection = never> = RequiredKeys<
  Collection,
  typeof getCollectionDefaults,
  T
>
export interface CollectionImplicit {
  libraryId: Library['id']
  library?: LibraryModel
  components?: Partial<ComponentModel>[]
}
export interface CollectionParams extends Collection, CollectionImplicit {}
export interface CollectionModel extends CollectionParams {}

export class CollectionModel
  extends APIModel<Collection, CollectionMinimal, CollectionImplicit>
  implements CollectionParams
{
  get adapter() {
    return this.api.Collections
  }

  components: ReturnType<this['constructComponents']>

  defineProperties() {
    super.defineProperties()
    this.api.utils.defineCollectionAccessor(this, 'components', this.constructComponents(), {}, this.getHiddenProps())
  }

  constructComponents() {
    return APICollection.construct(this.api.Component, () => ({
      collection: this,
      collectionId: this.id,
      library: this.library,
      libraryId: this.libraryId
    }))
  }

  getHiddenProps() {
    return ['components', 'library']
  }

  getDefaults(api: API) {
    return getCollectionDefaults(api)
  }

  sortCompare(other: this): number {
    if ((this.orderIdx || '') < (other.orderIdx || '')) {
      return 1
    }
    if ((this.orderIdx || '') > (other.orderIdx || '')) {
      return -1
    }
    if (this.createdAt > other.createdAt) {
      return 1
    }
    return 0
  }

  getPath() {
    return `/libraries/${this.libraryId}/collections/${this.id}`
  }
}
