/** Collection is like a flat redux store slice. It's an array of models that can be */

import type { API } from '../api.js'
import { LibraryModel } from '../models/libraries.js'
import { APIModel, APIModelSubclass, ClassExtending } from './model.js'

/** subscribed to. On every change it returns a fresh array to observer */
export class APICollection<
  TClass extends APIModelSubclass = APIModelSubclass,
  TModel extends InstanceType<TClass> = InstanceType<TClass>,
  TInterface = TModel extends APIModel<infer TInterface> ? TInterface : never,
  TMinimal = TModel extends APIModel<any, infer TInterface> ? TInterface : never,
  TID = TModel['idStruct']
> extends Array<TModel> {
  observers: ((collection: TModel[]) => any)[] = []

  constructor(...args: any) {
    super(...args)
    this.defineProperties()
  }
  defineProperties() {
    Object.defineProperty(this, 'snapshotted', {
      enumerable: false,
      writable: true
    })
    Object.defineProperty(this, 'observers', {
      enumerable: false,
      value: []
    })
  }

  onChange() {
    this.model.onCollectionChange(this as unknown as APICollection<TClass>)
    this.observers.forEach((observer) => observer.call(this, this), this)
  }

  observe(observer: (collection: TModel[]) => any) {
    this.observers.push(observer)
  }
  unobserve(observer: (collection: TModel[]) => any) {
    const index = this.observers.indexOf(observer)
    if (index > -1) this.observers.splice(index, 1)
  }
  model: TClass

  /** Construct instance of a collection model */
  construct(object?: any, isNew = false): TModel {
    const clone = new this.model() as TModel
    /** define properties that model inherits from collection */
    clone.api.utils.defineImplicitAccessors(clone, this.getImplicitAttributes, clone.getHiddenProps())
    /** copy hidden properties that may need copying from given object */
    if (object) clone.copyHiddenProps(object, false)
    clone.set(object)
    /** set New flag */
    if (isNew) {
      clone.setDefaults()
      clone.isNew = true
    }
    /** run transformation hook */
    clone.set(this.transformAttributes(clone))
    return clone
  }

  transformAttributes(object?: any) {
    return this.model.transformAttributes(object)
  }

  getImplicitAttributes(): Partial<TModel> {
    return {}
  }

  /** Instantiate model with default values */
  new(object: TMinimal) {
    return this.construct(object, true)
  }

  /** Instantiate model and add it to collection */
  add(object: TMinimal) {
    return this.addItem(this.new(object))
  }

  /** Instantiate model and send create request without adding to collection
   * INSERT is alias */
  post(object: TMinimal) {
    return this.new(object).post()
  }
  insert(object: TMinimal) {
    return this.post(object)
  }

  /** PUT item (with all attributes), UPDATE as alias */
  put(data: TMinimal) {
    return this.construct(data).put()
  }
  update(data: TMinimal) {
    return this.put(data)
  }

  save(data: TMinimal) {
    return this.construct(data, true).save()
  }

  withId(target: string | number | TID): TModel {
    return this.construct(typeof target == 'object' ? target : { id: target })
  }

  /** DELETE item (or by id) */
  delete(target: string | number | TID) {
    return this.withId(target).delete()
  }

  /** GET item (or by id) */
  get(target: string | number | TID) {
    return this.withId(target).get()
  }

  /** GET ALL items, SELECT as alias */
  fetch(options?: any) {
    return this.construct()
      .fetch(options)
      .then((items) => this.setItems(items))
  }
  select(options?: any) {
    return this.fetch(options)
  }

  /** GET ALL items matching criteria */
  search(options?: any) {
    return this.construct().search(options)
  }

  /** Replace all items in collections with items in given array */
  setItems(objects: (TModel | TInterface | TMinimal)[]) {
    this.splice(0, this.length, ...objects.map((v) => this.construct(v), this))
    this.snapshot()
    this.onChange()
    return this
  }

  /** Adds new item into a collection */
  addItem(object: TModel | TInterface | TMinimal) {
    const model = this.construct(object)
    const index = this.findIndex((other) => other.getId() === model.getId())
    if (index == -1) {
      this.unshift(model)
    } else {
      this.splice(index, 1, model)
    }
    this.onChange()
    return model
  }

  /** Patches the item in collection with matching id */
  updateItem(changes: Partial<TModel | TInterface | TMinimal> & TID) {
    const index = this.indexOf(this.getItem(changes))
    if (index == -1) throw new Error('Trying to update object that is is not in collection')
    this.splice(index, 1, this[index].clone(changes))
    this.onChange()
    return this[index]
  }

  /** Swaps item for another within collection */
  replaceItem(changes: Partial<TModel | TInterface | TMinimal> & TID) {
    const index = this.indexOf(this.getItem(changes))
    if (index == -1) throw new Error('Trying to update object that is is not in collection')
    this.splice(index, 1, this.construct(changes))
    this.onChange()
    return this[index]
  }

  /** Removes item from collection */
  removeItem(target: string | number | TID) {
    const index = this.indexOf(this.getItem(target))
    if (index === -1) throw new Error('Trying to delete object that is not in collection')
    var removed = this[index]
    this.splice(index, 1)
    this.onChange()
    return removed
  }

  /** Gets item in collection by id or by id in object */
  getItem(target: string | number | TID) {
    var id = typeof target == 'object' ? this.construct(target).getId() : target
    return this.find((object) => object.getId() === id)
  }

  /** Return plain array with models exported as plain objects */
  export(): TInterface[] {
    return this.map((model) => model.export() as TInterface)
  }

  /** Return instance of collection with new identity, but fully refering to current one */
  proxy() {
    return new Proxy(this, {}) as this
  }

  /** Define a collection of given models. During construction of models, the attributes
   * returned by second callback will be used as implicitly provided values */
  static construct<
    M extends APIModelSubclass,
    C extends (model?: InstanceType<M>) => any,
    TInterface = InstanceType<M> extends APIModel<infer TInterface> ? TInterface : never,
    TMinimal = InstanceType<M> extends APIModel<any, infer TMinimal> ? TMinimal : never
  >(model: M, getImplicitAttributes: C) {
    const collection = new this<M, InstanceType<M>, TInterface, Omit<TMinimal, keyof ReturnType<C>>>()
    Object.defineProperty(collection, 'model', {
      value: model,
      enumerable: false
    })
    Object.defineProperty(collection, 'getImplicitAttributes', {
      value: getImplicitAttributes,
      enumerable: false
    })
    return collection
  }

  /** Save collection as raw values for future reference */
  snapshotted?: TInterface[]
  snapshot() {
    this.snapshotted = this.export()
    return this
  }
}
