import { default as styled } from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox,
  ChevronDownIcon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@sitecore-ui/design-system'
import { useApiData } from 'hooks/useApiData.js'
import { FunctionComponent, ReactElement, useContext } from 'react'
import { QueryStringContext } from '../../providers/QueryStringProvider.js'

export const PopoverContentStyled = styled(PopoverContent)`
  width: 250px;
`

const FilterByDataSource: FunctionComponent<{ queryDatasources: string[] }> = (props: {
  queryDatasources: string[]
}): ReactElement => {
  const { queryDatasources } = props

  const [query, setQuery] = useContext(QueryStringContext)

  const datasources = useApiData('library.datasources')

  const onChange = (e) => {
    if (queryDatasources.includes(e.target.value)) {
      const result = queryDatasources.filter((id) => id !== e.target.value)

      if (!result.length) {
        setQuery({ datasources: null })

        return
      }

      setQuery({ datasources: result.join(',') })

      return
    }

    setQuery({ datasources: [...queryDatasources, e.target.value].filter(Boolean).join(',') })
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant='secondary' w='32'>
          <Text>Data source</Text>

          <ChevronDownIcon ml='2' />
        </Button>
      </PopoverTrigger>

      <PopoverContentStyled>
        <PopoverArrow />

        <PopoverBody>
          <Box p={2}>
            {datasources.map((datasource, index) => (
              <Box key={datasource.id} mt={index > 0 && 3} w='3xs'>
                <Checkbox
                  value={datasource.id}
                  onChange={onChange}
                  isChecked={queryDatasources.includes(datasource.id)}
                >
                  {datasource.name}
                </Checkbox>
              </Box>
            ))}
          </Box>
        </PopoverBody>
      </PopoverContentStyled>
    </Popover>
  )
}

export default FilterByDataSource
