import {
  Accordion,
  Box,
  ChevronLeftIcon,
  CloseIcon,
  HStack,
  IconButton,
  Text,
  VStack
} from '@sitecore-ui/design-system'
import { Editor } from 'hooks/useEditor.js'

interface Props {
  editor: Editor
}

import * as settings from 'components/styles/settings.js'
import { useApi, useApiData } from 'hooks/useApiData.js'
import useEditorStyles, {
  getElementActiveStyles,
  getElementAvailableStyles,
  getElementEffectiveStyles,
  getElementInstanceId,
  getElementStyle
} from 'hooks/useEditorStyles.js'
import { useEditorValue } from 'hooks/useEditorValue.js'
import inflection from 'inflection'
import { RuleId, Style } from 'models/style/index.js'
import { useEffect, useState } from 'react'
import { StylerElement } from './StylerElement.js'
import { StylerRule } from './StylerRule.js'

export default function Styler({ editor }: Props) {
  const { context, block }: typeof editor.current = useEditorValue(editor, 'current')
  const { api } = useApi()

  const styles = useApiData('library.stylesheet.styles')

  const elements = getElementAvailableStyles(context, styles)

  const [selectedId, setSelectedId] = useState<RuleId>()
  const [expandedId, setExpandedId] = useState<RuleId>()
  const [customizedStyle, setCustomizedStyle] = useState<Style>()
  const instanceId = context ? getElementInstanceId(context) : null

  const [customStyles, setElementStyle] = useEditorStyles(editor, context)

  const effectiveStyles = getElementEffectiveStyles(context, customStyles, styles)
  const activeStyles = getElementActiveStyles(context, effectiveStyles)

  useEffect(() => {
    setExpandedId(null)
    setSelectedId(getElementStyle(context, styles)?.details.id)
    setCustomizedStyle(null)
  }, [context])

  const expandedIndex = elements.findIndex((e) => e.details.id == expandedId)
  const onElementSelect = (style: Style) => {
    if (activeStyles.find((s) => s.details.id == style.details.id)) {
      return null
    }
    setSelectedId(style.details.id)
    editor.model.change((writer) => {
      activeStyles.forEach((activeStyle) => {
        // retain layout settings
        if (activeStyle.type != 'layout' && activeStyle.type != 'dimensions')
          setElementStyle(context, { type: activeStyle.type })
      })
      setElementStyle(context, style)
      editor.focus()
    })
    if (expandedId && expandedId != style.details.id) setExpandedId(null)
    editor.contextPlugin.refresh()
  }

  const onPresetSelect = (style: Style) => {
    setElementStyle(context, style)
    editor.contextPlugin.refresh()
  }

  const onPresetModified = (style: Style) => {
    setElementStyle(context, style)
    setCustomizedStyle(style)
    editor.contextPlugin.refresh()
  }

  const onAccordionChange = (index) => {
    setExpandedId(index > -1 ? elements[index].details.id : null)
  }

  const onCustomizationIntent = (style: Style) => {
    setCustomizedStyle(style)
  }
  return (
    <>
      <VStack height='100%' overflowY={'scroll'} alignItems={'stretch'} color='blackAlpha.800' py={6} px={3}>
        <HStack alignItems={'center'}>
          <IconButton
            onClick={() => {
              api.editor.set({ sidebarMode: null })
              editor.focus()
            }}
            aria-label='Close'
            icon={<CloseIcon height='24px' width='24px' />}
          />
          <Text as='h2' fontSize='md' fontWeight='bold'>
            {inflection.titleize(context?.name || '')} styles
          </Text>
        </HStack>
        <Accordion
          {...{ alignItems: 'stretch', py: 3, px: 0 }}
          allowToggle={true}
          index={expandedIndex}
          onChange={onAccordionChange}
        >
          {elements.map((style, index) => (
            <StylerElement
              key={index}
              style={style}
              context={context}
              styles={effectiveStyles}
              activeStyles={activeStyles}
              onChange={onElementSelect}
              onPresetChange={onPresetSelect}
              onCustomizationIntent={onCustomizationIntent}
              isExpanded={style.details.id == expandedId}
              isDisabled={expandedId && style.details.id != expandedId}
              isSelected={style.details.id == selectedId}
            />
          ))}
        </Accordion>
      </VStack>
      {customizedStyle && (
        <VStack
          height='100%'
          position={'absolute'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          overflowY={'auto'}
          overflowX={'hidden'}
          alignItems={'stretch'}
          background='#fff'
          zIndex='1'
          color='blackAlpha.800'
          py={6}
          px={3}
          pb={12}
        >
          <HStack alignItems={'center'}>
            <IconButton
              onClick={() => setCustomizedStyle(null)}
              aria-label='Close'
              icon={<ChevronLeftIcon height='24px' width='24px' />}
            />
            <Text as='h3' fontSize='md' fontWeight='600'>
              <Text as='span' color='blackAlpha.500'>
                {settings.reusable[customizedStyle.type].label}:{' '}
              </Text>
              {customizedStyle.details.title || 'Custom style'}
            </Text>
          </HStack>
          <Box p={3} mt={3}>
            <StylerRule
              currentStyle={customizedStyle}
              style={styles.find((s) => s.details.id == customizedStyle.details.id)}
              onChange={onPresetModified}
            />
          </Box>
        </VStack>
      )}
    </>
  )
}
