import { mdiSquareEditOutline } from '@mdi/js'
import useOnCaptureClickOutside from 'hooks/useOnCaptureClickOutside.js'
import { eventOnConfirmationOverlay } from '../providers/ConfirmationProvider.js'
import { useEffect, useRef } from 'react'
import {
  Menu,
  IconButton,
  MenuButton,
  DotsHorizontalIcon,
  MenuList,
  MenuItem,
  Button,
  Icon,
  DeleteIcon,
  CopyIcon,
  Flex,
  Box,
  useToast
} from '@sitecore-ui/design-system'

interface Props {
  isActive?: boolean
  hasChanges: boolean
  onToggle?: (e) => void
  onSave?: () => void
  onDiscard?: () => void
  hasErrors?: boolean
  onDelete?: () => void
  onCopy?: () => void
  isNew?: boolean
  children?: any
  otherEditedExists?: boolean
  entity?: string
  id?: string
  Top?: any
  Content?: any
  dark?: boolean
}

const Edit = ({
  isActive,
  hasChanges,
  onToggle,
  onDiscard,
  onSave,
  hasErrors,
  onDelete,
  onCopy,
  isNew,
  otherEditedExists,
  entity,
  id,
  Top,
  Content,
  dark
}: Props) => {
  const frozenRef = useRef(null)
  const frozenRefObject = isNew || hasChanges ? { ref: frozenRef } : {}
  const toast = useToast()

  useEffect(() => {
    frozenRef?.current?.scrollIntoView({ behavior: 'smooth' })

    setTimeout(() => {
      const content = document.getElementById(`entity-content-${id}`)
      if (!content) return
      const firstInput = content.querySelector('input')
      if (!firstInput) return
      firstInput.focus()
    }, 1)
  }, [])

  useOnCaptureClickOutside(frozenRef, (e) => {
    if (eventOnConfirmationOverlay(e)) return

    e.stopPropagation()

    if (!isNew) {
      toast({
        isClosable: false,
        duration: 4000,
        status: 'warning',
        title: `${entity} was changed`,
        description: 'Please save or discard the changes to continue'
      })

      return
    }

    toast({
      isClosable: false,
      duration: 4000,
      status: 'warning',
      title: `${entity} was not saved`,
      description: `Please save or delete ${entity.toLowerCase()}`
    })
  })

  const bg = (isActive || dark) && 'gray.100'
  const _hover = !dark && { background: 'gray.100' }

  return (
    <Box
      mb='6'
      borderRadius='base'
      transition='opacity .5s ease'
      opacity={otherEditedExists ? 0.5 : 1}
      id={`entity-content-${id}`}
      aria-label={entity}
      mt='5'
      position='relative'
      border='none'
      key={id}
      bg={bg}
      _hover={_hover}
      {...frozenRefObject}
    >
      <Box px='6' pt='6' pb={6} pos='relative'>
        <Box top='0' pos='absolute' cursor='pointer' left='0' width='full' height='full' onClick={onToggle}></Box>

        <Flex position='absolute' ml='auto' h='10' right='0' top='0' m='6'>
          {!isActive && (
            <IconButton
              mr='2'
              size='sm'
              variant='minimal'
              aria-label='toggle'
              onClick={onToggle}
              icon={<Icon path={mdiSquareEditOutline} />}
            />
          )}

          {isActive && (
            <>
              {isNew && (
                <Button mr='2' variant='minimal' size='sm' onClick={onDelete}>
                  Delete
                </Button>
              )}

              <Button
                mr='2'
                variant='subtle'
                size='sm'
                aria-label='discard'
                onClick={onDiscard}
                isDisabled={!hasChanges}
              >
                Discard
              </Button>

              {hasChanges || isNew ? (
                <Button
                  key='save'
                  w='68px'
                  mr='2'
                  variant='primary'
                  size='sm'
                  aria-label='save'
                  onClick={onSave}
                  isDisabled={hasErrors}
                >
                  Save
                </Button>
              ) : (
                <Button key='close' w='68px' mr='2' size='sm' variant='secondary' aria-label='close' onClick={onToggle}>
                  Close
                </Button>
              )}
            </>
          )}

          <Menu isLazy={true}>
            <IconButton
              as={MenuButton}
              size='sm'
              variant='minimal'
              aria-label='menu'
              icon={<DotsHorizontalIcon display='block' mx='auto' />}
            />

            <MenuList>
              {onCopy && (
                <MenuItem isDisabled={isNew || hasChanges} onClick={onCopy} icon={<CopyIcon fontSize='14px' />}>
                  Copy
                </MenuItem>
              )}

              <MenuItem onClick={onDelete} icon={<DeleteIcon fontSize='14px' />}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        {Top}
      </Box>

      {isActive && Content}
    </Box>
  )
}

export default Edit
