import { useApiData } from 'hooks/useApiData.js'
import { Style } from 'models/style/index.js'
import { useMemo } from 'react'
import * as CSS from 'models/stylesheet/css.js'
import { BasicFont } from 'models/style/font.js'
import { deepMerge } from 'utils/object.js'

interface Props {
  style: Style
  customStyles?: Record<string, Style>
  children: any
}

export function GeneratedPreview({ style, children, customStyles }: Props) {
  const styles = useApiData('library.stylesheet.styles')

  const id = useMemo(() => 'preview-' + String(Math.floor(Math.random() * 1000000000)), [])

  var ruleProp: any[]

  if (style.type == 'font') {
    // TODO: remove cast after retype
    const variants = (style.props as BasicFont).variants
    if ((style.props as BasicFont).variants) {
      ruleProp = CSS.produceFontVariantProps({
        id: style.details.id,
        ...variants[0]
      })
    }
  } else if (style.type == 'section') {
    customStyles = {
      layout: Style({
        type: 'layout',
        details: {
          id: 'preview-layout'
        },
        props: {
          columnCount: 2
        }
      })
    }
    deepMerge(style, {
      props: {
        layoutIds: ['preview-layout']
      }
    })
    ruleProp = CSS.produceRuleProps(style.type, style.props)
  } else {
    ruleProp = CSS.produceRuleProps(style.type, style.props)
  }
  if (!ruleProp) return children

  const cssText = CSS.stringify(
    [
      [
        'rule',
        [`:wrapper(#${id}) +`, `:wrapper(#${id}) + *`], // `+ .target` OR `+ * .target`
        ['rule', '.target', ...ruleProp] // reoslve `&` to `.target`
      ]
    ],
    {
      styles: styles,
      customStyles
    }
  )

  return (
    <>
      <style id={id}>{cssText}</style>
      {children}
    </>
  )
}
