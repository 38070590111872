import { API, APIUser } from '../api.js'
import APIAdapter from '../core/adapter.js'
import { APICollection } from '../core/collection.js'
import { APIModel, Optional, Atleast, RequiredKeys } from '../core/model.js'
import Transport, { nanoid } from '../core/transport.js'
import type { Collection, CollectionModel } from './collections.js'
import { Datasource } from './datasources.js'
import type { Library, LibraryModel } from './libraries.js'
import { Variant, VariantModel } from './variants.js'

/**
 * Test
 *
 * @category Component2
 * @module Component1
 */
export interface Component {
  id: string
  collectionId: string
  name: string
  description: string
  status: 'published' | 'deleted' | 'staged' | 'changed' | 'draft'
  variantCount?: number
  datasourceIds?: string[]
  createdAt?: Date
  stagedAt?: Date
  publishedAt?: Date
  modifiedAt?: Date
  modifiedBy?: APIUser
}
/**
 * Test2
 *
 * @module Component2
 */
export class Components extends APIAdapter<Component> {
  fetch(component: Pick<ComponentParams, 'libraryId' | 'collectionId'>): Promise<Component[]> {
    const { libraryId, collectionId } = component
    return this.api.fetch(`/libraries/${libraryId}/collections/${collectionId}/components`, {})
  }
  get(component: Pick<ComponentParams, 'libraryId' | 'collectionId' | 'id'>): Promise<Component> {
    const { libraryId, collectionId, id } = component
    return this.api.fetch(`/libraries/${libraryId}/collections/${collectionId}/components/${id}`, {})
  }
  post(component: Atleast<ComponentParams, 'libraryId' | 'collectionId'>): Promise<Component> {
    const { libraryId, collectionId } = component
    return this.api.fetch(`/libraries/${libraryId}/collections/${collectionId}/components`, {
      method: 'POST',
      body: JSON.stringify(component)
    })
  }
  put(component: Atleast<ComponentParams, 'libraryId' | 'collectionId' | 'id'>): Promise<Component> {
    return this.post(component)
  }
  delete(component: Pick<ComponentParams, 'libraryId' | 'collectionId' | 'id'>): Promise<Component> {
    const { libraryId, collectionId, id } = component
    return this.api.fetch(`/libraries/${libraryId}/collections/${collectionId}/components/${id}`, {
      method: 'DELETE'
    })
  }
}
/**
 * Test3
 *
 * @module Component3
 */
export function getComponentDefaults(api: API) {
  return {
    id: nanoid(10),
    status: 'draft',
    createdAt: new Date(),
    modifiedAt: new Date(),
    variantCount: 0,
    datasourceIds: [] as Datasource['id'][],
    modifiedBy: api.user
  }
}

export type ComponentMinimal<T extends keyof Component = never> = RequiredKeys<
  Component,
  typeof getComponentDefaults,
  T
>
export interface ComponentImplicit {
  libraryId: Library['id']
  library?: LibraryModel
  collectionId: Collection['id']
  collection?: CollectionModel
}

export interface ComponentParams extends Component, ComponentImplicit {}
export interface ComponentModel extends ComponentParams {}
export class ComponentModel
  extends APIModel<Component, ComponentMinimal, ComponentImplicit>
  implements ComponentParams
{
  get adapter() {
    return this.api.Components
  }

  builder: APIModel
  variants: ReturnType<this['constructVariantCollection']>

  constructVariantCollection() {
    return APICollection.construct(this.api.Variant, () => ({
      collection: this.collection,
      collectionId: this.collectionId,
      library: this.library,
      libraryId: this.libraryId,
      component: this,
      componentId: this.id
    }))
  }

  getThumbnailURL() {
    return `${this.api.cdnHostname}/thumbnails/${this.id}.jpg`
  }

  defineProperties() {
    super.defineProperties()
    this.api.utils.defineCollectionAccessor(
      this,
      'variants',
      this.constructVariantCollection(),
      {},
      this.getHiddenProps()
    )
  }
  getDefaults(api: API) {
    return getComponentDefaults(api)
  }

  getHiddenProps() {
    return ['variants', 'library', 'libraryId', 'collection']
  }

  addVariant(values?: Partial<Variant>) {
    const neighbours = this.getNeighbourVariants(null)
    return this.variants.add({
      name: 'Name of a new variant',
      orderIdx: VariantModel.getVariantOrderIdx(neighbours[0], null),
      ...values
    })
  }

  /** Ensures that variant with given `refId` has given data. Best way to work with versioning,
   * as it takes care about drafts, changes and versions behind the scenes.
   */
  establishVariant(values?: Partial<Variant>) {
    const current = this.findVariant(values.refId)
    if (current) {
      return current.mutate(values)
    } else {
      return this.addVariant(values)
    }
  }

  async changeVariants(callback: (variants: VariantModel[]) => any) {
    await this.variants.fetch()
    callback(this.variants)
    await this.saveVariants()
  }

  findVariant(refId: Variant['refId'], statuses?: Variant['status'][]) {
    return this.variants.find(
      (variant) =>
        variant.refId == refId && (statuses == null || statuses.length == 0 || statuses.includes(variant.status))
    )
  }

  getNeighbourVariants(refId: string) {
    const heads = VariantModel.getOrderedVariants(this.variants)
    const targetIndex = heads.findIndex((variant) => variant.refId == refId)
    if (targetIndex == -1) {
      return [heads[heads.length - 1], null]
    } else {
      return [heads[targetIndex - 1], heads[targetIndex + 1]]
    }
  }

  getVariants() {
    return VariantModel.getOrderedVariants(this.variants)
  }

  reduceVariantVersions() {
    return VariantModel.reduceVariantVersions(this.variants)
  }

  saveVariants() {
    if (!this.variants.snapshotted) {
      throw new Error('component.variants.snapshot() needs to be called when variants are loaded initially ')
    }

    const versions = this.variants
    let toBeAdded: VariantModel[] = [],
      toBeUpdated: VariantModel[] = []

    // find new and edited variants
    const nonDraftVersions = versions.filter((variant) => variant.status !== 'draft')
    nonDraftVersions.forEach((currentVersion) => {
      const previousVersion = this.variants.snapshotted.find((prev) => prev.id === currentVersion.id)
      if (previousVersion) {
        const isChanged = currentVersion.isDifferentFrom(previousVersion)
        if (isChanged) {
          console.log('  > Changed version', currentVersion.refId, currentVersion.status, currentVersion.version)
          toBeUpdated.push(currentVersion)
        }
      } else {
        console.log('  > New version', currentVersion.refId, currentVersion.status, currentVersion.version)
        toBeAdded.push(currentVersion)
      }
    })

    this.variants.snapshot()
    return Promise.all([...toBeAdded.map((variant) => variant.post()), ...toBeUpdated.map((variant) => variant.put())])
  }

  aggregateVariantData() {
    const variants = this.api.Variant.getOrderedVariants(this.variants)
    const uniqueDatasourceIds = new Set<string>()

    const update: Partial<Component> = {}
    for (const variant of variants) {
      // Ignore deleted variants
      if (variant.status == 'saved' && variant.deletedAt != null) {
        continue
      }
      const { datasourceIds: datasourceIds, modifiedAt: modifiedAt, modifiedBy: modifiedBy } = variant
      datasourceIds.map((id) => uniqueDatasourceIds.add(id))
      if (update.modifiedAt == null || modifiedAt > update.modifiedAt) {
        update.modifiedAt = modifiedAt
        update.modifiedBy = modifiedBy
      }
    }

    update.variantCount = variants.length
    update.datasourceIds = Array.from(uniqueDatasourceIds).sort()

    if (variants.every((v) => v.status == 'published')) {
      update.status = 'published'
    } else if (variants.every((v) => v.status == 'published' || v.status == 'staged')) {
      update.status = 'staged'
    } else if (variants.length) {
      update.status = 'changed'
    } else {
      update.status = 'draft'
    }
    return this.set(update)
  }
  // default sorting logic
  sortCompare(other: this) {
    return Number(other.modifiedAt) - Number(this.modifiedAt)
  }

  getPath() {
    return `/libraries/${this.libraryId}/collections/${this.collectionId}/components/${this.id}`
  }
}
