import React, { useState } from 'react'
import { Flex } from '@sitecore-ui/design-system'
import Navbar from '../components/Navbar.js'
import { useParams } from 'react-router'
import StylesList from 'components/styles/StylesList.js'
import StylesSidebar from '../components/styles/StylesSidebar.js'

export default function Styles() {
  const { type: activeStyleType = 'font' } = useParams()

  const [activeStylePage, setActiveStylePage] = useState(null)

  return (
    <>
      <Flex h='full' w='full' overflow='hidden'>
        <StylesSidebar activeStyleType={activeStyleType} setActiveStylePage={setActiveStylePage} />

        {activeStylePage && <StylesList activeStylePage={activeStylePage} />}
      </Flex>
    </>
  )
}
