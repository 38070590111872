import { mdiContentCopy } from '@mdi/js'
import {
  Button,
  ChevronDownIcon,
  CopyIcon,
  DeleteIcon,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList
} from '@sitecore-ui/design-system'
import { EditorContextProps, ImplicitBorders } from './EditorChrome.js'

export default function EditorChromeContextActions({
  editor,
  context,
  onCommand,
  onPopoverClose,
  onPopoverOpen
}: EditorContextProps) {
  return (
    <>
      {context?.getAttribute('data-symbol-ref') == null && (
        <Menu
          flip={false}
          autoSelect={true}
          isLazy={true}
          onOpen={() => onPopoverOpen('duplicate')}
          onClose={() => onPopoverClose('duplicate')}
        >
          <MenuButton
            px={'3px'}
            as={Button}
            aria-label='Actions menu'
            isDisabled={context?.name == '$root'}
            variant='minimal'
            css={ImplicitBorders}
            rightIcon={<ChevronDownIcon ml='-5px' />}
          >
            <Icon path={mdiContentCopy} fontSize={'18px'} />
          </MenuButton>
          <MenuList zIndex={10}>
            <MenuGroup>
              <MenuItem icon={<CopyIcon />} onClick={() => onCommand('duplicate')}>
                Duplicate
              </MenuItem>
              <MenuItem icon={<CopyIcon />} onClick={() => onCommand('symbol')}>
                Duplicate linked
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      )}
      <IconButton
        css={ImplicitBorders}
        isDisabled={!editor.commands.get('deleteElement').isEnabled}
        onClick={() => onCommand('deleteElement')}
        icon={<DeleteIcon fontSize={'18px'} />}
        aria-label='Delete element'
      />
    </>
  )
}
