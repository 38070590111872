export {}
import { API, APIModel } from '@sitecore-feaas/api'

const initialState = {
  status: 'unloaded' as 'unloaded' | 'loading' | 'initialized' | 'ready',
  savingStatus: 'synchronized' as 'saving' | 'synchronized' | 'saved' | 'waiting',
  focusedEditableName: null as string,
  contextEditableName: null as string,
  isFocused: false as boolean,
  isDirty: false,
  styles: `


  .ck-content button {
    min-width: 16px;
    min-height: 16px;
  }

  .ck-content section {
    min-height: 100px;
  }


  .ck-content section.wide {
    min-width: 900px;
  }

  .ck-content .card {
    min-height: 50px;
    min-width: 300px;
  }

  .allow-interaction .ck-content.ck-content.ck-content.ck-content.ck-content * {
    pointer-events: all !important;
  }

    `,
  isHiddenDisplayed: true,
  isDataDisplayed: true,
  isDataRepeated: true,
  hasData: true,
  hasRepeating: true,
  sidebarMode: null as string
}

type IEditor = typeof initialState

export interface Editor extends IEditor {}
export class Editor extends APIModel<Editor> implements IEditor {
  getDefaults(api: API) {
    return initialState
  }
}

// extend component with builder with local types
declare module '@sitecore-feaas/api' {
  export interface API {
    editor: Editor
  }
}
