import {
  mdiArrowBottomLeft,
  mdiArrowBottomRight,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowTopLeft,
  mdiArrowTopRight,
  mdiArrowUp
} from '@mdi/js'
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Switch,
  Text
} from '@sitecore-ui/design-system'
import cloneDeep from 'lodash-es/cloneDeep.js'
import { useEffect, useState } from 'react'
import { Gradient } from 'models/style/types/gradient.js'
import { Unformatted } from 'models/style/types/props.js'
import { ReusableFillColor } from 'models/style/fill.js'
import { Option } from 'types/index.js'
import { backgroundColorToGradient, ColorReference, gradientToBackgroundColor } from 'models/style/types/color.js'
import { deepMerge } from 'utils/object.js'
import FlexGrid from '../../FlexGrid.js'
import { StyledSelect } from '../../StyledSelect.js'
import ColorField from '../fields/ColorField.js'
import NumberField from '../fields/NumberField.js'
import OpacityField from '../fields/OpacityField.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import { StyleFormProps } from '../settings.js'

const Label = ({ icon, text }) => (
  <Flex align='center'>
    <Icon mr={2} w='4' h='4' path={icon} />

    <Text>{text}</Text>
  </Flex>
)

const angleOptions = [
  { label: <Label icon={mdiArrowUp} text='Top' />, value: 0 },
  { label: <Label icon={mdiArrowTopRight} text='Top right' />, value: 45 },
  { label: <Label icon={mdiArrowRight} text='Right' />, value: 90 },
  { label: <Label icon={mdiArrowBottomRight} text='Bottom right' />, value: 135 },
  { label: <Label icon={mdiArrowDown} text='Bottom' />, value: 180 },
  { label: <Label icon={mdiArrowBottomLeft} text='Bottom left' />, value: 225 },
  { label: <Label icon={mdiArrowLeft} text='Left' />, value: 270 },
  { label: <Label icon={mdiArrowTopLeft} text='Top left' />, value: 315 }
]

const FillColorFieldset = ({ currentStyle, onChange, style, isActive }: StyleFormProps<'fill'>) => {
  const { backgroundColor, backgroundGradient } = currentStyle.props

  const angleInOptions = angleOptions.find((angle) => angle.value === backgroundGradient.angle)

  const onUpdate = (changes: Unformatted<ReusableFillColor>) => {
    onChange(deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) }))
  }

  const getIndex = () => {
    if (!ReusableFillColor.isValid(currentStyle.props)) return 0

    if (ColorReference.isValid(backgroundColor)) {
      return 1
    }

    if (Gradient.isValid(backgroundGradient)) {
      return 2
    }
    return 0
  }
  const [index, setIndex] = useState(getIndex)
  useEffect(() => setIndex(getIndex), [style, isActive])

  const [customAngle, setCustomAngle] = useState<boolean>(!angleInOptions)

  const stops = cloneDeep(backgroundGradient.stops)

  const onGroupChange = (index) => {
    setIndex(index)

    if (index === 0) {
      onUpdate(ReusableFillColor())
      return
    }

    if (index === 1) {
      onUpdate({
        backgroundColor: gradientToBackgroundColor(backgroundGradient),
        backgroundGradient: Gradient({
          angle: 0
        })
      })
    }

    if (index === 2) {
      onUpdate({
        backgroundColor: ColorReference(),
        backgroundGradient: backgroundColorToGradient(backgroundColor)
      })
      return
    }
  }

  return (
    <>
      <SwitchGroupField value={index} onChange={onGroupChange} mb={6}>
        <Button>None</Button>

        <Button>Color</Button>

        <Button>Gradient</Button>
      </SwitchGroupField>

      <Flex flexWrap='wrap' alignItems={'flex-start'}>
        <FlexGrid>
          {index === 1 && (
            <Grid templateColumns='repeat(1, 1fr)' gap={6} mr={4}>
              <GridItem colSpan={1}>
                <ColorField
                  label='Color'
                  colorReference={backgroundColor}
                  onChange={(backgroundColor) => onUpdate({ backgroundColor })}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <OpacityField
                  label='Opacity'
                  value={backgroundColor.alpha}
                  onChange={(alpha) => onUpdate({ backgroundColor: { alpha } })}
                />
              </GridItem>
            </Grid>
          )}

          {index === 2 && (
            <>
              <Grid templateColumns='repeat(1, 1fr)' gap={6}>
                <GridItem colSpan={1}>
                  {customAngle && (
                    <NumberField
                      label='Angle'
                      value={backgroundGradient.angle}
                      onChange={(value) => onUpdate({ backgroundGradient: { angle: value } })}
                      min={0}
                      max={360}
                    />
                  )}

                  {!customAngle && (
                    <>
                      <FormControl>
                        <FormLabel>Angle</FormLabel>
                      </FormControl>

                      <StyledSelect
                        options={angleOptions}
                        value={angleInOptions}
                        onChange={({ value: angle }: Option) => onUpdate({ backgroundGradient: { angle } })}
                      />
                    </>
                  )}
                </GridItem>

                <GridItem colSpan={1} mb={6}>
                  <Flex align='center'>
                    <Switch isChecked={customAngle} onChange={() => setCustomAngle(!customAngle)} />

                    <Text ml='2'>Custom direction angle</Text>
                  </Flex>
                </GridItem>
              </Grid>
            </>
          )}
        </FlexGrid>
        <Divider width='4'></Divider>
        {index == 2 && (
          <FlexGrid>
            {stops.map((stop, index) => (
              <Grid templateColumns='repeat(1, 1fr)' gap={6} key={index}>
                <GridItem colSpan={1}>
                  <ColorField
                    label={`Color ${index + 1}`}
                    colorReference={stop.color}
                    onChange={(value) => {
                      stops[index].color = value
                      onUpdate({ backgroundGradient: { stops } })
                    }}
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <OpacityField
                    label={`Opacity ${index + 1}`}
                    value={stop.color.alpha}
                    onChange={(value) => {
                      stops[index].color.alpha = value
                      onUpdate({ backgroundGradient: { stops } })
                    }}
                  />
                </GridItem>
              </Grid>
            ))}
          </FlexGrid>
        )}
      </Flex>
    </>
  )
}

export default FillColorFieldset
