import { css } from '@emotion/react'
import {
  Box,
  ChevronLeftIcon,
  ChevronRightIcon,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack
} from '@sitecore-ui/design-system'
import useCapturedFocus from 'hooks/useCapturedFocus.js'
import type { Editor, ModelElement } from 'hooks/useEditor.js'
import { FunctionComponent, useEffect, useRef, useState } from 'react'

export interface DialogMenuItem {
  icon: string
  label: string
  id: string
  component: DialogComponent<this>
  badge?: DialogBadgeComponent
  below?: DialogComponent<this, { [key: string]: any }>
  above?: DialogComponent<this, { [key: string]: any }>
}

export type DialogComponentProps<T> = {
  menuItem: T extends void ? DialogMenuItem : T
  onNavigate: (string) => void
  tabIndex?: number
  setTabIndex?: (index: number) => any
  context: ModelElement
  editor: Editor
}

export type DialogComponent<T = void, E = {}> = FunctionComponent<DialogComponentProps<T> & E>
export type DialogBadgeComponent = FunctionComponent<DialogMenuItem>

const MenuItemStyle = css`
  font-weight: 600;
  height: 56px;
  align-items: center;
  padding: 0 20px;
`

type DialogProps = {
  menuItem: DialogMenuItem
  onNavigate: (string) => void
  children: any
}

export default function EditorDialog({ menuItem: { icon, label }, onNavigate, children }: DialogProps) {
  const [ref, setFocus] = useCapturedFocus()
  return (
    <VStack alignItems={'stretch'} p={4} spacing={6} {...{ ref }}>
      <HStack alignItems={'center'}>
        {onNavigate != null && (
          <IconButton
            p={3}
            m={-3}
            aria-label='Back'
            icon={<ChevronLeftIcon fontSize='16px' />}
            onClick={() => onNavigate(null)}
          />
        )}
        <Icon zIndex={1} path={icon} fontSize='24px' />
        <Text fontWeight={600} mr='auto'>
          {label}
        </Text>
      </HStack>
      {children}
    </VStack>
  )
}

interface MenuProps {
  items: DialogMenuItem[] | ((editor: Editor, context: ModelElement) => DialogMenuItem[])
  context: ModelElement
  editor: Editor
}

export function EditorDialogMenu({ items, editor, context }: MenuProps) {
  const [dialog, setDialog] = useState(null)
  const itemsArray = typeof items == 'function' ? items(editor, context) : items
  const item = itemsArray.find((item) => item.id == dialog)
  const Dialog = itemsArray.find((item) => item.id == dialog)?.component
  const [ref, setFocus] = useCapturedFocus()
  useEffect(setFocus, [dialog, ref])

  return (
    <Box>
      {Dialog == null && (
        <VStack
          {...{ ref }}
          color='blackAlpha.600'
          width='full'
          borderRadius={'4px'}
          alignItems='stretch'
          spacing={0}
          divider={<Divider m='0' />}
        >
          {itemsArray.map(({ icon, label, id, badge }, index) => (
            <Flex as='button' key={id} css={MenuItemStyle} onClick={() => setDialog(id)}>
              <Icon path={icon} height='24px' width='24px' mr='8px' />
              <Text mr='auto' whiteSpace={'nowrap'}>
                {label}
              </Text>
              {badge?.(itemsArray[index])}
              <ChevronRightIcon fontSize='16px' />
            </Flex>
          ))}
        </VStack>
      )}
      {Dialog != null && (
        <EditorDialog menuItem={item} onNavigate={setDialog}>
          <Dialog menuItem={item} context={context} editor={editor} onNavigate={setDialog} />
        </EditorDialog>
      )}
    </Box>
  )
}
