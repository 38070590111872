import Transport, { nanoid } from '../core/transport.js'
import qs from 'query-string'
import { Optional, RequiredKeys, APIModel, Atleast, APIModelSubclass } from '../core/model.js'
import type { Component, ComponentModel } from './components.js'
import type { Collection, CollectionModel } from './collections.js'
import type { Library, LibraryModel } from './libraries.js'
import mudder from 'mudder'
import APIAdapter from '../core/adapter.js'
import { APICollection } from '../core/collection.js'
import { API, APIUser } from '../api.js'

export interface Variant {
  id: string
  componentId: string
  refId: string
  version: number

  name: string
  status: 'published' | 'draft' | 'staged' | 'saved'

  view: string
  model?: string
  diff?: object

  orderIdx?: string
  datasourceIds?: string[]
  createdAt?: Date
  modifiedAt?: Date
  modifiedBy?: APIUser
  deletedAt?: Date
}

export interface VariantSnapshot {
  refId: VariantModel['refId']
  head?: VariantModel
  previous?: VariantModel
  draft?: VariantModel
  saved?: VariantModel
  staged?: VariantModel
  published?: VariantModel
  versions: VariantModel[]
}

export class Variants extends APIAdapter<Variant> {
  search(variant: Pick<VariantParams, 'libraryId'>, query?: any): Promise<Variant[]> {
    const { libraryId } = variant
    return this.api.fetch(`/libraries/${libraryId}/variants?${qs.stringify(query)}`)
  }
  get(variant: Pick<VariantParams, 'libraryId' | 'collectionId' | 'componentId' | 'id'>): Promise<Variant> {
    const { libraryId, collectionId, componentId, id } = variant
    return this.api.fetch(
      `/libraries/${libraryId}/collections/${collectionId || 'unspecified'}/components/${componentId}/variants/${id}`
    )
  }
  fetch(variant: Pick<VariantParams, 'libraryId' | 'collectionId' | 'componentId'>): Promise<Variant[]> {
    const { libraryId, collectionId, componentId } = variant
    return this.api.fetch(
      `/libraries/${libraryId}/collections/${collectionId || 'unspecified'}/components/${componentId}/variants`
    )
  }
  post(
    variant: Optional<Variant, 'componentId' | 'id' | 'version'> &
      Atleast<VariantParams, 'libraryId' | 'collectionId' | 'componentId'>
  ) {
    const { libraryId, collectionId, componentId } = variant
    return this.api.fetch(
      `/libraries/${libraryId}/collections/${collectionId || 'unspecified'}/components/${componentId}/variants`,
      {
        method: 'POST',
        body: JSON.stringify(variant)
      }
    )
  }
  put(
    variant: Optional<Variant, 'componentId' | 'version'> &
      Atleast<VariantParams, 'libraryId' | 'collectionId' | 'componentId'>
  ) {
    return this.post(variant)
  }
  delete(variant: Atleast<VariantParams, 'libraryId' | 'collectionId' | 'componentId'>) {
    const { libraryId, collectionId, componentId, id } = variant
    return this.api.fetch(
      `/libraries/${libraryId}/collections/${collectionId || 'unspecified'}/components/${componentId}/variants/${id}`,
      {
        method: 'DELETE'
      }
    )
  }
}

function getVariantDefaults(api: API) {
  return {
    id: nanoid(10),
    refId: nanoid(10),
    orderIdx: '',
    version: 0,
    createdAt: new Date(),
    modifiedAt: new Date(),
    deletedAt: null as Date,
    modifiedBy: api.user,
    datasourceIds: [] as string[],
    view: '<section class="-section"></section>',
    model: '',
    diff: {},
    status: 'draft'
  }
}
export type VariantMinimal<T extends keyof Variant = never> = RequiredKeys<Variant, typeof getVariantDefaults, T>
export interface VariantImplicit {
  libraryId: Library['id']
  componentId: Component['id']
  collectionId?: Collection['id']
  library?: LibraryModel
  component?: ComponentModel
  collection?: CollectionModel
}
export interface VariantParams extends Variant, VariantImplicit {}
export interface VariantModel extends VariantParams {}
export class VariantModel extends APIModel<Variant, VariantMinimal, VariantImplicit> implements VariantParams {
  get adapter() {
    return this.api.Variants
  }

  getHiddenProps(): string[] {
    return ['libraryId', 'library', 'collectionId', 'collection', 'component']
  }
  getDefaults(api: API) {
    return getVariantDefaults(api)
  }

  defineProperties() {
    super.defineProperties()
    this.api.utils.defineAccessor(this, 'view', {}, this.getHiddenProps())
  }

  /** View needs to be wrapped into <section> */
  _view: string
  get view() {
    return this._view
  }

  /** Normalizes html, extracts used datasources */
  set view(value: string) {
    if (typeof value == 'string') {
      value = this.normalizeView(value)
      this.datasourceIds = this.getDatasourcesFromView(value)
    }
    this._view = value
  }

  /** Extract all datasource ids from view used in data-path-* attributes */
  getDatasourcesFromView(view: string) {
    const set = new Set<string>()
    view.replace(/(data-path[a-z-]*)="([a-z0-9-_]+)[^"]*"/gi, (m, attribute, datasourceId) => {
      if (attribute != 'data-path-placeholder') set.add(datasourceId)
      return m
    })
    return Array.from(set)
      .filter((v) => Boolean(v))
      .sort()
  }

  /** Transform js minimally to help reduce amount of versions */
  normalizeView(view: string) {
    // ensure there's wrapping section element
    if (!view.match(/^[\s\n]*(<section|<style)/i)) {
      view = `<section class="-section">${view}</section>`
    }
    return this.api.normalizeHTML(view)
  }

  /** Get latest versions of a variant matching given statuses (or first) */
  getCurrent(statuses?: Variant['status'][] | Variant['status']) {
    return this.component.findVariant(this.refId, [statuses || []].flat())
  }

  /** Get current draft or create a new one */
  getDraft() {
    const current = this.getCurrent()
    if (current.status == 'draft') {
      return current
    } else {
      return current.fork({
        ...current,
        modifiedBy: this.api.user,
        modifiedAt: new Date(),
        version: current.version + 1,
        deletedAt: null,
        status: 'draft'
      })
    }
  }

  /** Create new variant based on the current with new ID */
  fork(data?: Partial<Variant>) {
    return this.component.variants.add({
      ...this,
      ...data,
      id: nanoid(10)
    })
  }

  /**
   * Creates new instance of a variant version if any data has changed.
   *
   * ! Replaces it in the collection to notify observers */
  mutate(data: Partial<Variant>) {
    var start = data.view == null || this.compareProp('view', data.view, this.view) ? this : this.getDraft()
    const changed = start.change(data)
    if (changed != start) {
      this.component.variants.replaceItem(changed)
    }
    return changed
  }

  /** Move variant after another matching given refId (or to the top if null) */
  move(before: Variant['id']) {
    const previous = this.component.findVariant(before)
    const neighbours = this.component.getNeighbourVariants(before)
    const orderIdx = VariantModel.getVariantOrderIdx(neighbours[0], previous)
    return this.getCurrent().mutate({ orderIdx })
  }

  /** Soft-delete saved variant, discards draft */
  archive() {
    const draft = this.getCurrent('draft')
    const saved = this.getCurrent('saved')
    if (draft && saved && draft.version > saved.version) {
      draft.discard()
    }
    const target = saved || draft
    return target.mutate({ deletedAt: new Date() })
  }

  compareProp(property: keyof this, left: any, right: any) {
    if (property == 'view' && left != null && right != null) {
      return this.normalizeView(left) == this.normalizeView(right)
    }
    return super.compareProp(property, left, right)
  }

  /** Soft-undelete variant */
  restore() {
    const saved = this.getCurrent('saved')
    return saved.mutate({ deletedAt: null })
  }

  /** Set current view/model data to variant. Ensures that there is a draft,
   *  in case given data has changes */
  commitData({ view, model }: { view: Variant['view']; model: Variant['model'] }) {
    const last = this.getCurrent()
    if (last.view != view || last.model != model) {
      return this.getDraft().mutate({
        modifiedBy: this.api.user,
        modifiedAt: new Date(),
        view,
        model
      })
    }
    return this
  }

  /** Commits given data as new saved version if there were an changes */
  saveData(data?: Atleast<Variant, 'view' | 'model'>) {
    const last = this.getCurrent()
    if (last && last.status == 'draft' && last.deletedAt != null) {
      return
    }
    if (!data) data = last
    const { view, model } = data
    if (last && (last.status == 'draft' || last.model != model || !this.compareProp('view', last.view, view))) {
      last.fork({
        view,
        model,
        version: last.status == 'draft' ? last.version : last.version + 1,
        modifiedBy: this.api.user,
        modifiedAt: new Date(),
        status: 'saved'
      })
    }
    return this
  }

  /** Create a separate variant starting from first version, but based on given one */
  duplicate() {
    const variant = this.getCurrent()
    const neighbours = this.component.getNeighbourVariants(variant.refId)
    return variant.fork({
      version: 0,
      id: nanoid(10),
      refId: nanoid(10),
      modifiedBy: this.api.user,
      modifiedAt: new Date(),
      createdAt: new Date(),
      status: 'draft',
      orderIdx: VariantModel.getVariantOrderIdx(variant, neighbours[1])
    })
  }

  /** Change name of variant (does not create new version) */
  rename(name: Variant['name']) {
    this.getCurrent().mutate({ name })
  }

  /** Create a staged version based on current one.
   *  Ensures that it's not soft-deleted */
  stage() {
    var variant = this.getCurrent()
    var lastStaged = this.getCurrent('staged')
    if (lastStaged?.version == variant.version) {
      return lastStaged.mutate({
        deletedAt: null,
        name: variant.name,
        orderIdx: variant.orderIdx
      })
    } else {
      return variant.fork({
        status: 'staged',
        deletedAt: null
      })
    }
  }

  /** Soft-deletes staged version */
  unstage() {
    return this.getCurrent('staged').mutate({
      deletedAt: new Date()
    })
  }

  /** Create a published version based on current one */
  /** Ensures that it's not soft-deleted */
  publish() {
    var variant = this.getCurrent()
    var lastPublished = this.getCurrent('published')
    if (lastPublished?.version == variant.version) {
      return lastPublished.mutate({
        deletedAt: null,
        name: variant.name,
        orderIdx: variant.orderIdx
      })
    } else {
      return variant.fork({
        status: 'published',
        deletedAt: null
      })
    }
  }

  /** Soft-deletes published version */
  unpublish() {
    return this.getCurrent('published').mutate({
      deletedAt: new Date()
    })
  }

  /** Discards the draft, removes it from version list */
  discard() {
    if (this.status != 'draft') {
      throw new Error('Only drafts can be discarded')
    }
    this.component.variants.removeItem(this)
    return this
  }

  /** Revert current changes to last published/staged one */
  revertTo(version: VariantModel) {
    const draft = this.getCurrent('draft')
    if (draft) {
      draft.discard()
    }
    const saved = this.getCurrent('saved')
    if (!version.deletedAt && saved.version > version.version) {
      return saved.getDraft().mutate({
        view: version.view,
        model: version.model
      })
    }
  }
  revert() {
    const draft = this.getCurrent('draft')
    const saved = this.getCurrent('saved')
    if (!draft && !saved) return
    const published = this.getCurrent('published')
    const staged = this.getCurrent('staged')

    if (published?.version >= staged?.version) {
      this.revertTo(published)
    } else if (staged) {
      this.revertTo(staged)
    } else {
      return draft.mutate({
        view: '',
        model: ''
      })
    }
  }

  isDifferentFrom(other: Variant) {
    return (
      this.name !== other.name ||
      this.status !== other.status ||
      this.orderIdx !== other.orderIdx ||
      this.datasourceIds?.slice().sort().join(',') !== other.datasourceIds?.slice().sort().join(',') ||
      String(this.deletedAt) !== String(other.deletedAt)
    )
  }

  static getOrderedVariants(variants: VariantModel[]) {
    return this.reduceVariantVersions(variants)
      .map((snapshot) => snapshot.head)
      .sort((a, b) => a.orderIdx.localeCompare(b.orderIdx))
  }

  static getVariantOrderIdx(left: Variant, right: Variant) {
    const leftIdx = left?.orderIdx || ''
    const rightIdx = right?.orderIdx || ''
    return mudder.base62.mudder(leftIdx, rightIdx, 1, undefined, 1000, 4)[0] || ''
  }

  sortCompare(other: this): number {
    return this.orderIdx.localeCompare(other.orderIdx)
  }

  static statuses = ['published', 'staged', 'saved', 'draft']
  static sortVariantVersions(versions: VariantModel[]) {
    return versions.sort((a, b) => {
      return (
        a.refId.localeCompare(b.refId) ||
        Number(b.version) - Number(a.version) /** higher version first */ ||
        VariantModel.statuses.indexOf(a.status) -
          VariantModel.statuses.indexOf(b.status) /**  published first, draft last */ ||
        Number(a.createdAt) - Number(b.createdAt)
      )
    })
  }
  static reduceVariantVersions(versions: VariantModel[]) {
    return this.sortVariantVersions(versions)
      .reduce((snapshots, version) => {
        var snapshot = snapshots.find((snapshot) => snapshot.refId == version.refId)
        if (!snapshot) {
          snapshot = { refId: version.refId, versions: [] }
          snapshots.push(snapshot)
        }

        snapshot.versions.push(version)
        if (!snapshot[version.status]) snapshot[version.status] = version
        if (!snapshot.head) snapshot.head = version
        if (version.status != 'draft' && !snapshot.previous) snapshot.previous = version
        return snapshots
      }, [] as VariantSnapshot[])
      .sort((a, b) => a.head.orderIdx.localeCompare(b.head.orderIdx))
  }
  static onCollectionChange(variants: APICollection<typeof VariantModel>) {
    return this.sortVariantVersions(variants)
  }

  getPath() {
    return `/libraries/${this.libraryId}/collections/${this.collectionId}/components/${this.componentId}/variants/${this.id}`
  }
}
