import { Box, Skeleton, Text } from '@sitecore-ui/design-system'
import { fetchAndRevalidate, ComponentModel, Variant, VariantModel } from '@sitecore-feaas/api'
import { renderComponent } from '@sitecore-feaas/sdk'
import { useApi, useApiData } from 'hooks/useApiData.js'
import { useEffect, useRef, useState } from 'react'
import * as CSS from 'models/stylesheet/css.js'
import { convertBase64toBlob } from 'utils/blob.js'

interface Props {
  component: ComponentModel
  onLoad?: ({}: { width: number; height: number; src: string }) => any
  [key: string]: any
}

export default function ComponentThumbnail({ component, onLoad, ...props }: Props) {
  const url = component.getThumbnailURL()
  const styles = useApiData('library.stylesheet.styles')
  const datasources = useApiData('library.datasources')
  const [status, setStatus] = useState<'fetching' | 'generating' | 'showing' | 'instant' | 'empty'>('fetching')
  const imgRef = useRef(null)

  const setImage = (src, state: typeof status = 'showing', delay = 0) => {
    if (!imgRef.current) return
    imgRef.current.src = src
    imgRef.current.style.opacity = 1

    setTimeout(() => {
      if (!imgRef.current) return
      if (imgRef.current.offsetHeight >= 200) imgRef.current.style.alignSelf = 'flex-start'
      onLoad({
        src: imgRef.current.src,
        height: imgRef.current.offsetHeight,
        width: imgRef.current.offsetWidth
      })
      setStatus(state)
    }, delay)
  }

  useEffect(() => {
    if (!component) return
    fetchAndRevalidate(
      url,
      {},
      (response) => {
        // allow 2-seconds window
        return (
          Math.abs(
            Number(new Date(response.headers.get('x-ms-meta-componentModifiedAt'))) -
              Number(new Date(component.modifiedAt))
          ) < 2000
        )
      },
      (response, state) => {
        console.log(`Thumbnail ${component.id} status: ${state}`)
        response.blob().then((blob) => {
          setImage(URL.createObjectURL(blob), state == 'cached' ? 'instant' : 'showing', 0)
        })
      }
    ).catch((e) => {
      console.log(`Thumbnail ${component.id} status: generating: ${e}`)
      setStatus('generating')
    })
  }, [url, Number(component?.modifiedAt)])

  useEffect(() => {
    if (status != 'generating' || !component) return

    var generated = false
    var variant

    component.variants
      .fetch()

      // generate thumbnail
      .then((variants: VariantModel[]) => {
        variants = component.variants.model.getOrderedVariants(variants.slice())
        if (variants[0]) {
          variant = variants[0]
          return renderVariant(variant.view, datasources, CSS.toText(styles)).then(({ src }) => {
            setImage(src, 'showing', 50)
            generated = true
            return src
          })
        } else {
          throw new Error('Can not find variant')
        }
      })

      // upload blob
      .then((src) => {
        return convertBase64toBlob(src).then((blob) => {
          return component.api.uploadBlob('thumbnails', `${component.id}.jpg`, blob, {
            headers: {
              'x-ms-blob-content-type': 'image/jpg',
              'x-ms-blob-cache-control': 'public,max-age=31536000,immutable',
              'x-ms-meta-variantRefId': variant.refId,
              'x-ms-meta-variantVersion': variant.version.toString(),
              'x-ms-meta-componentId': component.id,
              'x-ms-meta-componentModifiedAt': component.modifiedAt.toUTCString(),
              'x-ms-meta-componentCreatedAt': component.createdAt.toUTCString(),
              'x-ms-meta-modifiedAt': component.modifiedAt.toUTCString()
            }
          })
        })
      })

      // bust thumbnail cache for the future
      .then((res) => {
        fetch(url, {
          cache: 'no-cache'
        }).catch((e) => {})
        console.log('Successfully uploaded thumbnail', res)
      })
      .catch((e) => {
        if (generated) {
          console.log('Could not upload thumbnail', e)
        } else {
          setStatus('empty')
        }
      })
  }, [status, component])

  return (
    <Box {...props} justifyContent='center' alignItems='center' height='full' width='full' display='flex'>
      {status == 'empty' && (
        <Box
          justifyContent='center'
          alignItems='center'
          height='full'
          width='full'
          display='flex'
          background='gray.100'
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          <Text fontWeight={500} color='blackAlpha.300' textTransform={'uppercase'}>
            Click to start editing
          </Text>
        </Box>
      )}
      {status != 'empty' && (
        <Skeleton
          {...{
            height: '100%',
            opacity: status == 'showing' || status == 'instant' ? 0 : 0.3,
            transition: status == 'instant' ? 'none' : 'opacity 0.3s',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          &nbsp;
        </Skeleton>
      )}
      <img
        ref={imgRef}
        style={{
          pointerEvents: 'none',
          opacity: 0,
          transition: status == 'instant' ? 'none' : 'opacity 0.3s 0.1s'
        }}
      />
    </Box>
  )
}

function renderVariant(template: string, data = {}, cssText: string) {
  const iframe = document.createElement('iframe')
  iframe.style.position = 'absolute'
  iframe.style.top = '-2000px'
  iframe.style.left = '-2000px'
  iframe.style.width = '1000px'
  iframe.style.height = '600px'
  iframe.style.zIndex = '100011'
  document.body.appendChild(iframe)
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js'
    script.onload = () => {
      ;(iframe.contentWindow.document.fonts?.ready || Promise.resolve()).then(function () {
        return (
          iframe.contentWindow
            //@ts-ignore
            .html2canvas(root, {
              scale: 2,
              width: Math.min(1000, Math.max(320, root.offsetWidth)),
              height: Math.max(1, Math.min(1000, root.offsetHeight)),
              windowWidth: 1000,
              windowHeight: 1000,
              proxy: import.meta.env.VITE_BACKEND_ENDPOINT_URL + '/proxy/media',
              backgroundColor: '#ffffff'
            })
            .then((canvas) => {
              const src = canvas.toDataURL('image/jpeg', 0.6)
              const results = { src, width: root.offsetWidth, height: root.offsetHeight }
              iframe.parentElement.removeChild(iframe)
              return results
            })
            .then(resolve)
        )
      })
    }
    iframe.contentWindow.document.head.appendChild(script)
    const stylesheet = iframe.contentWindow.document.createElement('style')
    stylesheet.textContent = cssText + 'body, html { padding: 0; margin: 0; overflow: hidden } '
    iframe.contentWindow.document.head.appendChild(stylesheet)
    const root = renderComponent({ template, data })
    iframe.contentWindow.document.body.appendChild(root)
    iframe.contentWindow.document.body.style.display = 'inline-flex'
    root.style.minWidth = '320px'
  })
}
