import { Box, Divider, Flex, Text } from '@sitecore-ui/design-system'
import { StyleModel } from 'models/style/index.js'
import { useApi, useApiData } from 'hooks/useApiData.js'
import cloneDeep from 'lodash-es/cloneDeep.js'
import omit from 'lodash-es/omit.js'
import { Style, StyleFor } from 'models/style/index.js'
import { createElement, FunctionComponent, ReactElement, useEffect, useState } from 'react'
import * as CSS from 'models/stylesheet/css.js'
import useConfirmation from '../../../hooks/useConfirmation.js'
import { OnEdit, OnToggle } from '../../../hooks/useEntities.js'
import useEntity from '../../../hooks/useEntity.js'
import Edit from '../../Edit.js'
import DetailsForm from '../fieldsets/DetailsFieldset.js'
import Preview from '../previews/index.js'
import { BasicSettings, ElementSettings, reusable, settings as formsSettings } from '../settings.js'
import StyleForm from './StyleForm.js'
import StyleSelect from './StyleSelect.js'
import { ColorDefinition } from 'models/style/color.js'
import { toIdentifier } from 'models/stylesheet/css.js'

interface Props {
  style: StyleModel
  activeId: string
  activeStyleType: string
  editedId: string
  activeCollection: string
  onToggle: OnToggle
  onEdit: OnEdit
}

const StyleComponent: FunctionComponent<Props> = ({
  style,
  onEdit,
  activeId,
  activeStyleType,
  onToggle,
  editedId,
  activeCollection
}: Props): ReactElement => {
  const { api } = useApi()
  const stylesheet = useApiData('library.stylesheet')
  const styles = useApiData('library.stylesheet.styles')

  const [errors, setErrors] = useState<any>(null)
  const [currentStyle, setCurrentStyle] = useState(style)
  const [customStyles, setCustomStyles] = useState(getSavedCustomStyles)

  const {
    details: { id, title }
  } = style

  const {
    details: { title: currentTitle, description: currentDescription }
  } = currentStyle

  // reinitialize styles. Happens on save/discard/start
  const reset = () => {
    setCurrentStyle(styles.find((s) => s.details.id == style.details.id))
    setCustomStyles(getSavedCustomStyles)
    if (activeStyleType === 'button' || activeStyleType === 'text') {
      addCustom(reusable.palette)
    }
  }

  const { hasChanges, isNew, isActive, editedExists } = useEntity({
    id: style.details.id,
    hasChanges: !style.isEqual(currentStyle) || !api.utils.isDeepEquals(getSavedCustomStyles(), customStyles),
    isNew: !Boolean(title) || style.originId,
    onEdit,
    activeId,
    editedId
  })

  useEffect(() => {
    setErrors(getErrors())
  }, [currentStyle])

  const onChange = (style) => setCurrentStyle(style)

  const onCopy = () => onToggle(style.duplicate().details.id)

  const onDiscard = () => {
    reset()
  }

  useEffect(reset, [])

  const onSave = () => {
    let style = cloneDeep(currentStyle)

    style.originId = undefined

    if (activeStyleType === 'color' && (currentStyle as StyleFor<'color'>).props.colors) {
      ;(style as StyleFor<'color'>).props.colors = (currentStyle as StyleFor<'color'>).props.colors.filter(
        (colorDefinition: ColorDefinition) => !colorDefinition.markedForDeletion
      )
    }

    styles.updateItem(style)

    const savedCustomStyles = getSavedCustomStyles()

    Object.keys(customStyles).forEach((key) => {
      let custom = customStyles[key]

      if (custom === null) {
        if (savedCustomStyles[key]) {
          styles.removeItem(savedCustomStyles[key])
        }

        return
      }

      if (styles.find((style) => style.details.id === custom.details.id)) {
        styles.replaceItem(custom)
      } else {
        styles.addItem(custom)
      }
    })

    // Styles may be transformed and modified on save (like in case of normalization),
    // causing the internal form state to de-sync, going into "Something has changed" state.
    // Thus, every time styles are saved, the form state needs to be reinitialized
    reset()
    saveStyles()
  }

  const onDelete = useConfirmation(
    () => {
      if (isActive) {
        onToggle(currentStyle.details.id, true)
      }

      onEdit(null)

      styles.removeItem(currentStyle)

      saveStyles()
    },
    {
      title: 'Delete Variant',
      button: 'Delete',
      body: (
        <Text>
          Are you sure you want to delete <strong>{currentStyle.details.title || 'new style'}</strong>?
        </Text>
      )
    }
  )

  const saveStyles = () => {
    const userStyles = styles.filter(
      (s) => !s.details.isInternal && styles.find((o) => o.details.id == s.details.id) == s
    )
    return stylesheet.put({
      source: userStyles,
      css: CSS.toText(styles)
    })
  }

  function getSavedCustomStyles() {
    if (activeCollection !== 'elements') return {}

    return (formsSettings[style.type] as ElementSettings).aspects.reduce((savedCustomStyles, { type }) => {
      return Object.assign(savedCustomStyles, {
        [type]:
          styles.find((style) => style.details.elementId === currentStyle.details.id && type === style.type) || null
      })
    }, {})
  }

  const getErrors = () => {
    let errors = null

    if (
      isNew &&
      styles.filter(
        (style) =>
          toIdentifier(style.details.title) === toIdentifier(currentStyle.details.title) &&
          style.type === currentStyle.type
      ).length > 1
    ) {
      errors = { ...errors, details: { title: 'Title needs to be unique' } }
    }

    if (!currentStyle.details.title) {
      errors = { ...errors, details: { title: 'Title is required before accessing other tabs' } }
    }

    if (currentStyle.type == 'color') {
      const colors = {}
      const arr = []
      currentStyle.props.colors?.map((c, i) => {
        if (c.markedForDeletion) return

        if (!c.name) {
          colors[i] = { name: 'Name is required' }
        } else if (arr.includes(c.name)) {
          colors[i] = { name: 'Name already exists' }
        }
        arr.push(c.name)
      })

      if (Object.keys(colors).length !== 0) {
        errors = { ...errors, props: { ...errors?.props, colors } }
      }
    }

    return errors
  }

  const updateSelection = (prop, value) => {
    onChange((oldItem) => ({
      ...oldItem,
      props: { ...oldItem.props, [prop]: value }
    }))
  }

  const onCustomChange = (customStyle, aspect) => {
    setCustomStyles((oldStyles) => ({
      ...oldStyles,
      [aspect.type]: customStyle
    }))
  }

  const addCustom = (aspect) => {
    if (customStyles[aspect.type]) return

    setCustomStyles({
      ...customStyles,
      [aspect.type]: Style({
        type: aspect.type,
        details: {
          title: 'Custom',
          elementId: currentStyle.details.id
        }
      })
    })
  }

  const onToggleCustom = (aspect) => {
    if (customStyles[aspect.type]) {
      setCustomStyles({ ...customStyles, [aspect.type]: null })
      return
    }

    addCustom(aspect)
  }

  const forms = (function () {
    if (activeCollection !== 'elements') {
      return (formsSettings[style.type] as BasicSettings).forms
    }

    const aspectForms = {}
    // Semicolons!
    ;(formsSettings[style.type] as ElementSettings).aspects.forEach((aspect) => {
      const form = createElement(StyleForm, {
        style,
        isNew,
        mode: 'accordion',
        currentStyle: customStyles[aspect.type],
        forms: omit(aspect.forms, 'Details'),
        activeId,
        onChange: (customStyle) => onCustomChange(customStyle, aspect),
        errors
      })

      aspectForms[aspect.label] = createElement(
        StyleSelect,
        {
          key: aspect.label,
          activeStyleType,
          label: aspect.label,
          customStyle: customStyles[aspect.type],
          collection: styles.filter((style) => style.type === aspect.type && !style.details.elementId),
          selectedIds: currentStyle?.props[aspect.property] || null,
          onChange: (newIds) => updateSelection(aspect.property, newIds),
          onToggleCustom: () => onToggleCustom(aspect),
          onChangeCustom: (customStyle) => onCustomChange(customStyle, aspect),
          currentStyle,
          onChangeCurrentStyle: onChange
        },
        form
      )
    })

    return { Details: DetailsForm, ...aspectForms }
  })()

  return (
    <Edit
      isActive={isActive}
      hasChanges={hasChanges}
      onToggle={() => onToggle(id)}
      onDiscard={onDiscard}
      onSave={onSave}
      hasErrors={!!errors}
      id={id}
      onDelete={onDelete}
      onCopy={onCopy}
      isNew={isNew}
      entity='Style'
      otherEditedExists={editedExists}
      Top={
        <Box pb={6}>
          <Preview style={currentStyle} customStyles={customStyles} />

          <Flex mt='5' h='20px'>
            <Text fontWeight='semibold' color='gray.500' aria-label='Style Title'>
              {currentTitle}
            </Text>

            {currentTitle && currentDescription && (
              <Box>
                <Divider orientation='vertical' mx='2' borderColor='blackAlpha' />
              </Box>
            )}

            <Text fontWeight='medium' color='gray.500'>
              {currentDescription}
            </Text>
          </Flex>
        </Box>
      }
      Content={
        <Box p='6' pt='0'>
          <StyleForm
            mode='tabs'
            style={style}
            currentStyle={currentStyle}
            forms={forms}
            errors={errors}
            activeId={activeId}
            isNew={isNew}
            onChange={onChange}
          />
        </Box>
      }
    />
  )
}

export default StyleComponent
