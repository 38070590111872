// Glues together api & frontend app by asisgning Style collection to stylesheet

import API, { APICollection, APIModel, StylesheetModel } from '@sitecore-feaas/api'
import { isStyleDefault, IStyle, Style, StyleModel } from '../style/index.js'

// extend stylesheet with local types
declare module '@sitecore-feaas/api' {
  export interface Stylesheet {
    // @ts-ignore meh cant properly redefine it without subclassing everything
    source: Style[]
  }
  export interface StylesheetModel {
    styles: APICollection<typeof StyleModel>
  }
}

// Define collection of styles on stylesheet
API.Stylesheet.prototype.onConstruct = function (this: StylesheetModel) {
  this.api.utils.defineCollectionAccessor(
    this,
    'styles',
    APICollection.construct(StyleModel, () => ({
      stylesheet: this,
      libraryId: this.libraryId,
      library: this.library
    })),
    {},
    this.getHiddenProps()
  )
}
