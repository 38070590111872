import { Box, Flex, HStack, SimpleGrid, Text, VStack } from '@sitecore-ui/design-system'
import { ElementTextProps, Style, StyleFor, StyleRule } from 'models/style/index.js'
import { default as color } from './ColorsPreview.js'
import { GeneratedPreview } from './GeneratedPreview.js'
import { css } from '@emotion/react'
import { stringifyLength } from 'models/stylesheet/css.js'
import { useMemo } from 'react'
import { useMemoWithComparator } from 'react-use-memo-with-comparator'
import * as CSS from 'models/stylesheet/css.js'
import { isDeepEquals } from '@sitecore-feaas/api'

interface Props<T = Style> {
  style: T
  customStyles?: Record<string, Style>
}

const checkerboard = css`
  background-image: linear-gradient(45deg, var(--chakra-colors-blackAlpha-50) 25%, transparent 25%),
    linear-gradient(-45deg, var(--chakra-colors-blackAlpha-50) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--chakra-colors-blackAlpha-50) 75%),
    linear-gradient(-45deg, transparent 75%, var(--chakra-colors-blackAlpha-50) 75%);
  background-size: 16px 16px;
  background-position: 0 0, 0 8px, 8px -8px, -8px 0px;
`

const SpacingStyle = css`
  grid-template-areas:
    '. . t . . '
    '. c c c . '
    'l c c c r '
    '. c c c . '
    '. . b . . ';
  grid-template-columns: auto 0px 1fr 0px auto;
  grid-template-rows: auto 0px 1fr 0px auto;

  .value {
    > span {
      background: var(--chakra-colors-red-700);
      color: #fff;
      padding: 0 4px;
      font-size: 10px;
      font-weight: 700;
      line-height: 16px;
      display: inline-block;
      text-align: center;
      margin: auto;
      z-index: 1;
      border-radius: 3px;
    }
    display: flex;
    position: relative;
    &:before {
      position: absolute;
      background: var(--chakra-colors-red-600);
      content: '';
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &.left,
    &.right,
    &.gap {
      min-width: 60px;
      &:before {
        width: 100%;
        height: 1px;
      }
    }
    &.gap {
      min-width: 54px;
    }
    &.top,
    &.bottom {
      min-height: 50px;
      &:before {
        width: 1px;
        height: 100%;
      }
    }
    &.left {
      grid-area: l;
    }
    &.right {
      grid-area: r;
    }
    &.bottom {
      grid-area: b;
    }
    &.top {
      grid-area: t;
    }
    &.gap {
      border-left: 1px solid var(--chakra-colors-red-600);
      border-right: 1px solid var(--chakra-colors-red-600);
      height: 28px;
    }
  }
  .column {
    background: var(--chakra-colors-blackAlpha-200);
    align-self: stretch;
    flex-grow: 1;
    border-radius: 4px;
    margin: 2px;
  }
  .center {
    grid-area: c;
    border-radius: 4px;
    outline: 1px solid var(--chakra-colors-red-600);
  }
`

function Thumbnail({ maxWidth = 512, hasCheckerboard = false, children, ...props }) {
  return (
    <Flex
      maxWidth={maxWidth}
      display='inline-flex'
      direction='row'
      css={hasCheckerboard && checkerboard}
      maxHeight={160}
      align='stretch'
      width='full'
      flexDirection={'column'}
      overflow='hidden'
      {...props}
    >
      {children}
    </Flex>
  )
}

const previewTypes = {
  color,
  card: () => (
    <Thumbnail hasCheckerboard={true} p={6} height='160'>
      <Box flexGrow={1} className='target' />
    </Thumbnail>
  ),
  typography: ({
    style: {
      details: { title, exampleContent }
    }
  }: Props) => (
    <Thumbnail className='target' color='blackAlpha.800'>
      {exampleContent || title || 'Click me'}
    </Thumbnail>
  ),
  button: ({
    style: {
      details: { title, exampleContent }
    }
  }: Props) => (
    <Thumbnail hasCheckerboard={true} p={6} width='min-width'>
      <Box display='inline-flex' className='target'>
        {exampleContent || title || 'Click me'}
      </Box>
    </Thumbnail>
  ),
  text: ({
    style: {
      details: { title, exampleContent },
      props: { tag }
    }
  }: Props<StyleRule<'text', ElementTextProps>>) => (
    <Thumbnail>
      <Text as={tag || 'p'} className='target' color='blackAlpha.800'>
        {exampleContent || title || 'Text example'}&nbsp;
        <a href='#'>with link</a>
      </Text>
    </Thumbnail>
  ),
  section: () => (
    <Thumbnail hasCheckerboard={true} align='stretch'>
      <Flex height={160} overflow='hidden' flexDirection='row' className='target'>
        <Box background='gray.200' opacity={0.8} flexGrow={1} borderRadius={4} />
        <VStack spacing={6} flexGrow={1} align='stretch' flexBasis='auto'>
          <Box background='gray.200' opacity={0.6} flexGrow={1} borderRadius={4} />
          <Box background='gray.200' opacity={0.3} flexGrow={1} borderRadius={4} />
        </VStack>
      </Flex>
    </Thumbnail>
  ),
  decoration: () => (
    <Thumbnail hasCheckerboard={true}>
      <Box m={6} className='target' h='20' bg='gray.300' />
    </Thumbnail>
  ),
  fill: () => (
    <Thumbnail hasCheckerboard={true}>
      <Box
        className='target'
        h='20'
        border='1px solid'
        borderColor='blackAlpha.400'
        borderRadius='4px'
        boxShadow='lg'
        bg='gray.100'
      />
    </Thumbnail>
  ),
  font: ({
    style: {
      details: { title, exampleContent }
    }
  }: Props) => (
    <Thumbnail fontSize='3xl' className='target'>
      {exampleContent || title || 'Example text'}
    </Thumbnail>
  ),

  fontVariant: ({
    style: {
      details: { title, exampleContent }
    }
  }: Props) => (
    <Thumbnail fontSize='2xl' className='target'>
      {exampleContent || 'Example text'}
    </Thumbnail>
  ),

  spacing: ({ style: { props } }: Props<StyleFor<'spacing'>>) => {
    return (
      <Box background='blackAlpha.50' maxWidth='512'>
        <SimpleGrid css={SpacingStyle} height={160} spacing={0}>
          <Box className='value top'>
            <span>{stringifyLength(props.paddingTop).replace('px', '')}</span>
          </Box>
          <Box className='value bottom'>
            <span>{stringifyLength(props.paddingBottom).replace('px', '')}</span>
          </Box>
          <Box className='value left'>
            <span>{stringifyLength(props.paddingLeft).replace('px', '')}</span>
          </Box>
          <Box className='value right'>
            <span>{stringifyLength(props.paddingRight).replace('px', '')}</span>
          </Box>
          <HStack className='center' spacing={0} justifyContent='space-between'>
            <Box className='column'></Box>
            <Box className='value gap'>
              <span>{stringifyLength(props.columnGap).replace('px', '')}</span>
            </Box>
            <Box className='column'></Box>
          </HStack>
        </SimpleGrid>
      </Box>
    )
  }
}

export default function Preview({ style, customStyles, ...props }: Props) {
  const PreviewType = previewTypes[style.type]
  if (!PreviewType) return <>No preview for {style.type}</>
  return (
    <GeneratedPreview style={style} customStyles={customStyles}>
      <PreviewType style={style} customStyles={customStyles} {...props} />
    </GeneratedPreview>
  )
}

export function PreviewFontsStylesheet({ styles }: { styles: Style[] }) {
  const usedFonts = styles.filter((style) => style.type == 'font')
  const cssText = useMemoWithComparator(
    () => {
      return CSS.stringify(usedFonts.map((font) => CSS.produceStyle(font), { styles: styles }))
    },
    [usedFonts],
    isDeepEquals
  )
  return <style>{cssText}</style>
}
