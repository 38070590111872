import { Spinner } from '@chakra-ui/spinner'

import {
  Button,
  CheckIcon,
  ChevronRightIcon,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Link,
  Skeleton,
  Switch,
  Text,
  Tooltip
} from '@sitecore-ui/design-system'
import ButtonGroupSwitch from 'components/ButtonGroupSwitch.js'
import type { Collection, Component } from '@sitecore-feaas/api'
import { useApiData } from 'hooks/useApiData.js'
import { Editor, useEditor } from 'hooks/useEditor.js'

export default function EditorHeader({
  editor,
  collection,
  component,
  onSave
}: {
  editor: Editor
  collection: Collection
  component: Component
  onSave: () => void
}) {
  const library = useApiData('library')
  const apiEditor = useApiData('editor')
  const { isDataDisplayed, isDataRepeated, savingStatus, isHiddenDisplayed, hasData } = apiEditor

  const previewIndex = isDataRepeated ? 0 : isDataDisplayed ? 1 : 2
  const onPreviewIndexChange = (index) => {
    apiEditor.set({
      isDataRepeated: index == 0,
      isDataDisplayed: index <= 1
    })
  }
  return (
    <HStack background='gray.100' px='8' justifyContent='space-between'>
      <HStack
        whiteSpace='nowrap'
        minWidth='0'
        fontSize='14'
        spacing='3'
        mr='4'
        divider={<ChevronRightIcon color='gray.500' border='0' height='5' width='5' />}
      >
        <Link href={`${library.getPath()}/collections`}>Library</Link>

        <Skeleton
          isLoaded={!!collection}
          style={{
            height: '20px',
            flexShrink: 2,
            verticalAlign: 'baseline',
            minWidth: collection ? '0px' : '50px',
            display: 'inline-block'
          }}
        >
          <Text noOfLines={0} display='block'>
            Collection:&nbsp;
            {collection && (
              <Link
                color='text-color-subtle'
                href={collection && `${library.getPath()}/collections/?collectionId=${collection.id}`}
              >
                {collection.name}
              </Link>
            )}
          </Text>
        </Skeleton>
        <Skeleton
          isLoaded={!!component}
          style={{
            height: '20px',
            flexShrink: 1,
            verticalAlign: 'baseline',
            minWidth: component ? '0' : '50px',
            display: 'inline-block'
          }}
        >
          {(component && (
            <Text
              noOfLines={0}
              color='gray.800'
              style={{ height: '20px', verticalAlign: 'baseline', minWidth: '50px', display: 'block' }}
            >
              {component.name}
            </Text>
          )) ||
            '&nbsp;'}
        </Skeleton>
      </HStack>
      <HStack
        whiteSpace={'nowrap'}
        spacing='4'
        divider={<Divider orientation='vertical' colorScheme='red' height='8' />}
      >
        <HStack justifyContent='center' spacing='8' py='8'>
          <ButtonGroupSwitch index={previewIndex} onChange={onPreviewIndexChange}>
            <Button size='sm' isDisabled={!editor}>
              <Tooltip
                label={
                  <ul style={{ paddingLeft: 10 }}>
                    <li>Substitute variables with data</li>
                    <li>Display repeated elements</li>
                    <li>
                      Do <strong>NOT</strong> show hidden elements
                    </li>
                  </ul>
                }
              >
                Preview
              </Tooltip>
            </Button>
            <Button size='sm' isDisabled={!editor}>
              <Tooltip
                label={
                  <ul style={{ paddingLeft: 10 }}>
                    <li>Substitute variables with data</li>
                    <li>Show original elements without repeating</li>
                    <li>Show hidden elements</li>
                  </ul>
                }
              >
                No repeating
              </Tooltip>
            </Button>
            <Button size='sm' isDisabled={!editor}>
              <Tooltip
                label={
                  <ul style={{ paddingLeft: 10 }}>
                    <li>Show variables with placeholder</li>
                    <li>Show original elements without repeating</li>
                    <li>Show hidden elements</li>
                  </ul>
                }
              >
                Template
              </Tooltip>
            </Button>
          </ButtonGroupSwitch>
          <Tooltip label='Not implemented'>
            <FormControl display='flex' alignItems='center' width='auto' isDisabled={true} hidden>
              {/* @ts-ignore */}
              <FormLabel mb='0' htmlFor='switch-repeating'>
                Show outlines
              </FormLabel>
              <Switch id='switch-repeating' />
            </FormControl>
          </Tooltip>
          <Tooltip label={hasData ? null : 'Component does not use data'}>
            <FormControl hidden display='flex' alignItems='center' width='auto'>
              {/* @ts-ignore */}
              <FormLabel variant='subtle' mb='0' htmlFor='switch-data'>
                Show outline
              </FormLabel>
              <Switch
                id='switch-data'
                isChecked={isHiddenDisplayed}
                onChange={(e) => apiEditor.set({ isHiddenDisplayed: e.target.checked })}
              />
            </FormControl>
          </Tooltip>
        </HStack>
        <HStack>
          <Button
            width={95}
            spinner={<Spinner width='1em' height='1em' speed='1.5s' />}
            variant='secondary'
            size='sm'
            leftIcon={savingStatus == 'waiting' ? null : <CheckIcon width='1em' height='1em' marginRight='auto' />}
            onClick={onSave}
            disabled={savingStatus != 'waiting'}
            isLoading={savingStatus == 'saving'}
            loadingText='Saving...'
          >
            {savingStatus == 'synchronized' ? 'Saved' : 'Save'}
          </Button>
        </HStack>
      </HStack>
    </HStack>
  )
}
