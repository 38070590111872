import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select
} from '@sitecore-ui/design-system'
import FormError from 'components/FormError.js'
import SwitchGroupField from 'components/styles/fields/SwitchGroupField.js'
import type { Variant } from '@sitecore-feaas/api'
import { useApiData } from 'hooks/useApiData.js'
import { useEffect, useState } from 'react'
import { ConfigureMenuItem } from './EditorChromeContextConfigure.js'
import { DialogComponent } from './EditorDialog.js'
const EditorDialogAttributesComponent: DialogComponent<ConfigureMenuItem> = ({ context, menuItem: { onChange } }) => {
  const components = useApiData('library.components')
  const component = components.find((c) => c.id == context.getAttribute('component-id'))
  const collections = useApiData('library.collections')
  const [variants, setVariants] = useState<Variant[]>([])
  const [index, setIndex] = useState(() => {
    if (context.getAttribute('component-id') || context.getAttribute('data-embed-as') == 'feaas-component') {
      return 1
    }
    return 0
  })

  const onIndexChange = (newIndex: number) => {
    if (newIndex == index) return
    if (newIndex == 0) {
      onChange(null, 'data-embed-as')
      onChange(null, 'component-id')
      onChange(null, 'variant-id')
      onChange(null, 'version')
    }
    if (newIndex == 1) {
      onChange('feaas-component', 'data-embed-as')
    }
    setIndex(newIndex)
  }

  const componentId = context.getAttribute('component-id') ? String(context.getAttribute('component-id')) : null
  useEffect(() => {
    if (componentId) {
      component.variants.fetch().then((variants) => {
        const staged = variants.filter((v) => v.status == 'staged')
        setVariants(staged)
        if (!context.getAttribute('variant-id')) onChange(staged[0]?.refId, 'variant-id')
      })
    }
  }, [componentId])

  const variant = variants.find((v) => v.refId == context.getAttribute('variant-id'))

  const value = String(context.getAttribute('data-embed-as') || '')
  const error = !value || value.match(/^[a-z]+[a-z0-9-]*$/i) ? null : 'Tag is invalid'
  return (
    <>
      <SwitchGroupField value={index} onChange={onIndexChange} alignSelf={'flex-start'}>
        <Button>HTML component</Button>
        <Button>From library</Button>
      </SwitchGroupField>

      {index == 0 && (
        <FormControl>
          <Alert status='info' {...{ alignItems: 'flex-start', marginBottom: '12px' }}>
            <AlertIcon />
            <AlertDescription>
              You can later configure this component to lazily load JavaScript file that will register the component
            </AlertDescription>
          </Alert>
          <FormLabel>Use web component by name </FormLabel>
          <Input
            value={value}
            onChange={(e) => onChange(e.target.value, 'data-embed-as')}
            placeholder='E.g. feaas-component'
          />
          {error && <FormError error={error} />}
        </FormControl>
      )}
      {index == 1 && (
        <FormControl>
          <FormLabel>Choose component from library</FormLabel>
          <Select
            isClearable={true}
            value={components.find((c) => c.id == context.getAttribute('component-id'))}
            options={collections.map((collection) => ({
              label: collection.name,
              options: components.filter((c) => c.collectionId == collection.id)
            }))}
            getOptionLabel={(c: any) => c.name}
            getOptionValue={(c: any) => c.id}
            /*@ts-ignore*/
            onChange={(component: Component) => {
              onChange(component?.id, 'component-id')
              onChange(component?.id ? 'feaas-component' : null, 'data-embed-as')
              onChange(null, 'variant-id')
            }}
            placeholder='Select component'
          ></Select>

          <FormLabel>Choose variant</FormLabel>
          <Select
            value={variant}
            options={variants}
            getOptionLabel={(c) => c.name}
            getOptionValue={(c) => c.refId}
            onChange={(variant) => {
              onChange(variant?.refId, 'variant-id')
              onChange(variant?.refId, 'staged')
            }}
            placeholder='Select variant to embed'
          ></Select>
        </FormControl>
      )}
    </>
  )
}

export default EditorDialogAttributesComponent
