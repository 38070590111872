import { FormControl, FormLabel, Grid, GridItem, Switch } from '@sitecore-ui/design-system'
import { Unformatted } from 'models/style/types/props.js'
import ReusablePalette from 'models/style/palette.js'
import { deepMerge } from 'utils/object.js'
import FlexGrid from '../../FlexGrid.js'
import ColorField from '../fields/ColorField.js'
import { StyleFormProps } from '../settings.js'

const PaletteTextFieldset = (props: StyleFormProps<'palette'>) => {
  const { currentStyle, onChange } = props
  const { textColor, textHighlightColor, linkColor, linkHoverColor, linkDecoration, linkHoverDecoration } =
    currentStyle.props

  const onUpdate = (changes: Unformatted<ReusablePalette>) => {
    onChange(deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) }))
  }

  return (
    <FlexGrid>
      <Grid templateColumns='repeat(1, 1fr)' gap={6}>
        <GridItem colSpan={1}>
          <ColorField colorReference={textColor} label='Text color' onChange={(textColor) => onUpdate({ textColor })} />
        </GridItem>
      </Grid>

      <Grid templateColumns='repeat(1, 1fr)' gap={6}>
        <GridItem colSpan={1}>
          <ColorField
            colorReference={textHighlightColor}
            label='Text highlight color'
            onChange={(textHighlightColor) => onUpdate({ textHighlightColor })}
          />
        </GridItem>
      </Grid>

      <Grid templateColumns='repeat(1, 1fr)' gap={6}>
        <GridItem colSpan={1}>
          <ColorField
            colorReference={linkColor}
            label='Link text color'
            onChange={(linkColor) => onUpdate({ linkColor })}
          />
          <FormControl display='flex' flexDirection='row' mt={5}>
            <Switch
              isChecked={linkDecoration == 'underline'}
              {...{ mr: 3 }}
              onChange={(e) =>
                onUpdate({
                  linkDecoration: e.target.checked ? 'underline' : 'none'
                })
              }
            />
            <FormLabel>Underline link text</FormLabel>
          </FormControl>
        </GridItem>
      </Grid>

      <Grid templateColumns='repeat(1, 1fr)' gap={6}>
        <GridItem colSpan={1}>
          <ColorField
            colorReference={linkHoverColor}
            label='Link color on hover'
            onChange={(linkHoverColor) => onUpdate({ linkHoverColor })}
          />
          <FormControl display='flex' flexDirection='row' mt={5}>
            <Switch
              isChecked={linkHoverDecoration == 'underline'}
              {...{ mr: 3 }}
              onChange={(e) =>
                onUpdate({
                  linkHoverDecoration: e.target.checked ? 'underline' : 'none'
                })
              }
            />
            <FormLabel>Underline link text</FormLabel>
          </FormControl>
        </GridItem>
      </Grid>
    </FlexGrid>
  )
}

export default PaletteTextFieldset
