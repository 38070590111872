import { Box, Button, Flex, H2, PlusSquareIcon, Text } from '@sitecore-ui/design-system'
import { useApiData } from 'hooks/useApiData.js'
import { Style } from 'models/style/index.js'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import useEntities from '../../hooks/useEntities.js'
import { PreviewFontsStylesheet } from './previews/index.js'
import { StylePage } from './sidebar.js'
import StyleComponent from './style/Style.js'

const StyleList = ({ activeStylePage }: { activeStylePage: StylePage }) => {
  const styles = useApiData('library.stylesheet.styles')
  const library = useApiData('library')

  const { collection: activeCollection = 'basics', type: activeStyleType = 'font', id: activeStyleId } = useParams()

  const { editedId, onEdit, activeId, onToggle } = useEntities(
    `${library.getPath()}/styles/${activeCollection}/${activeStylePage.type}`,
    activeStyleId
  )

  const collection = useMemo(
    () =>
      styles.filter((style) => style.type === activeStyleType && !style.details.isHidden && !style.details.isInternal && !style.details.elementId),
    [styles, activeStyleType]
  )

  const onCreate = () => {
    const style = styles.add({ type: activeStyleType as Style['type'] })
    onToggle(style.details.id)
  }

  return (
    <Box h='full' p='10' overflowY='scroll' overflowX='hidden' flex='1'>
      <Flex>
        <Box>
          <H2 fontWeight='semibold'>{activeStylePage.title}</H2>
          <Text mt='3'>{activeStylePage.description}</Text>
        </Box>

        <Box ml='auto'>
          <Button variant='secondary' size='md' mr='3'>
            Reorder
          </Button>

          <Button variant='primary' size='md' onClick={onCreate}>
            Add style
          </Button>
        </Box>
      </Flex>

      <PreviewFontsStylesheet styles={styles} />

      <Box mt='8'>
        {collection.map((style) => (
          <StyleComponent
            key={style.details.id}
            style={style}
            onEdit={onEdit}
            activeId={activeId}
            activeStyleType={activeStyleType}
            onToggle={onToggle}
            editedId={editedId}
            activeCollection={activeCollection}
          />
        ))}
      </Box>

      {!editedId && (
        <Flex
          h='20'
          mt='10'
          bg='gray.100'
          borderColor='gray.200'
          align='center'
          justifyContent='center'
          cursor='pointer'
          border='2px dotted lightgrey'
          onClick={onCreate}
        >
          <Text fontWeight='semibold'>
            <PlusSquareIcon mr='2' />
            Add new variant
          </Text>
        </Flex>
      )}
    </Box>
  )
}

export default StyleList
