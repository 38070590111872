import { Box, Button, Container, Flex, H2 } from '@sitecore-ui/design-system'
import { useApiData } from 'hooks/useApiData.js'
import { useParams } from 'react-router'
import DatasourceCard from '../components/datasources/DatasourceCard.js'
import useEntities from '../hooks/useEntities.js'

export default function Datasources() {
  const datasources = useApiData('library.datasources')
  const library = useApiData('library')
  const { datasourceId } = useParams()

  const { editedId, onEdit, activeId, onToggle } = useEntities(`${library.getPath()}/datasources`, datasourceId || null)

  const onCreateDatasource = () => {
    const { id } = datasources.add({
      name: '',
      description: '',
      sampledAt: null,
      sample: null
    })
    onToggle(id)
  }

  return (
    <Container maxW='container.xl' p={10}>
      <Flex align='center' wrap='wrap'>
        <H2 fontSize='4xl' fontWeight='semibold'>
          Data sources
        </H2>

        <Button size='md' ml='auto' variant='primary' onClick={onCreateDatasource}>
          Add Datasource
        </Button>
      </Flex>

      <Box mt='10'>
        {datasources.map((datasource) => (
          <DatasourceCard
            key={datasource.id}
            datasource={datasource}
            activeId={activeId}
            editedId={editedId}
            onEdit={onEdit}
            onToggle={onToggle}
          />
        ))}
      </Box>
    </Container>
  )
}
