import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import { Button, Grid, GridItem } from '@sitecore-ui/design-system'
import { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import SizeField from '../fields/SizeField.js'
import { deepMerge } from 'utils/object.js'
import FlexGrid from '../../FlexGrid.js'
import SceneSpacing from 'models/style/spacing.js'
import { StyleFor } from 'models/style/index.js'
import { Unformatted } from 'models/style/types/props.js'
import { Length } from 'models/style/types/length.js'
import { StyleFormProps } from '../settings.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import { isDeepEquals } from '@sitecore-feaas/api'

const SpacingFieldset = ({ currentStyle, onChange, isActive, style }: StyleFormProps<'spacing'>) => {
  const { paddingTop, paddingBottom, paddingLeft, paddingRight, rowGap, columnGap } = currentStyle.props

  const allEqualTo = (lengths: Length[], target: Length) => {
    return lengths.every((item) => {
      return isDeepEquals(item, paddingTop)
    })
  }

  const getIndex = () => {
    if (allEqualTo([paddingBottom, paddingLeft, paddingRight, columnGap, rowGap], paddingTop)) {
      return 0
    }

    if (allEqualTo([paddingBottom, rowGap], paddingTop) && allEqualTo([paddingRight, columnGap], paddingLeft)) {
      return 1
    }

    return 2
  }
  const [index, setIndex] = useState(getIndex)
  useEffect(() => setIndex(getIndex), [style, isActive])

  const onUpdate = (changes: Unformatted<SceneSpacing>) => {
    onChange(deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) }))
  }

  useEffect(() => {
    if (index === 0) {
      onUpdate({
        paddingTop: paddingTop,
        paddingBottom: paddingTop,
        paddingLeft: paddingTop,
        paddingRight: paddingTop,
        columnGap: columnGap,
        rowGap: rowGap
      })
    } else if (index === 1) {
      onUpdate({
        paddingLeft: paddingRight,
        columnGap: paddingRight,
        paddingBottom: paddingTop,
        rowGap: paddingTop
      })
    }
  }, [index])

  return (
    <>
      <SwitchGroupField value={index} onChange={setIndex} mb={6}>
        <Button>Sync</Button>
        <Button>Vertical/horizontal</Button>
        <Button>All custom</Button>
      </SwitchGroupField>

      <FlexGrid>
        {index === 0 && (
          <Grid templateColumns='repeat(1, 1fr)' gap={6}>
            <GridItem colSpan={1}>
              <SizeField
                label='Space'
                length={paddingTop}
                onChange={(value) => {
                  onUpdate({
                    paddingTop: value,
                    paddingLeft: value,
                    paddingRight: value,
                    paddingBottom: value,
                    columnGap: value,
                    rowGap: value
                  })
                }}
              />
            </GridItem>
          </Grid>
        )}

        {index === 1 && (
          <Grid templateColumns='repeat(1, 1fr)' gap={6}>
            <GridItem colSpan={1}>
              <SizeField
                label='Vertical spacing'
                length={paddingTop}
                onChange={(value) => {
                  onUpdate({
                    paddingTop: value,
                    paddingBottom: value,
                    rowGap: value
                  })
                }}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <SizeField
                label='Horizontal spacing'
                length={paddingLeft}
                onChange={(value) => {
                  onUpdate({
                    paddingLeft: value,
                    paddingRight: value,
                    columnGap: value
                  })
                }}
              />
            </GridItem>
          </Grid>
        )}

        {index === 2 && (
          <>
            <Grid templateColumns='repeat(1, 1fr)' gap={6}>
              <GridItem colSpan={1}>
                <SizeField label='Top' length={paddingTop} onChange={(value) => onUpdate({ paddingTop: value })} />
              </GridItem>

              <GridItem colSpan={1}>
                <SizeField
                  label='Bottom'
                  length={paddingBottom}
                  onChange={(value) => onUpdate({ paddingBottom: value })}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <SizeField
                  label='Vertical gap'
                  length={rowGap}
                  onChange={(value) =>
                    onUpdate({
                      rowGap: value
                    })
                  }
                />
              </GridItem>
            </Grid>
            <Grid templateColumns='repeat(1, 1fr)' gap={6}>
              <GridItem colSpan={1}>
                <SizeField
                  label='Right'
                  length={paddingRight}
                  onChange={(value) => onUpdate({ paddingRight: value })}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <SizeField label='Left' length={paddingLeft} onChange={(value) => onUpdate({ paddingLeft: value })} />
              </GridItem>

              <GridItem colSpan={1}>
                <SizeField
                  label='Horizontal gap'
                  length={columnGap}
                  onChange={(value) =>
                    onUpdate({
                      columnGap: value
                    })
                  }
                />
              </GridItem>
            </Grid>
          </>
        )}
      </FlexGrid>
    </>
  )
}

export default SpacingFieldset
