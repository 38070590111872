import { css } from '@emotion/react'
import {
  AddIcon,
  Badge,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@sitecore-ui/design-system'
import { EditorContextProps, getIconPathByElementType, ImplicitBorders } from './EditorChrome.js'
import { mdiContentPaste } from '@mdi/js'
export default function EditorChromeContextAdd({
  context,
  onCommand,
  onPopoverClose,
  onPopoverOpen
}: EditorContextProps) {
  const onElementCommand = (name: string, ...values: any[]) => {
    onCommand('moveToApplicableContext', name)
    onCommand(name, ...values)
  }

  return (
    <Menu
      autoSelect={true}
      flip={false}
      onOpen={() => onPopoverOpen('add')}
      isLazy={true}
      onClose={() => onPopoverClose('add')}
    >
      <MenuButton
        as={Button}
        aria-label='Element menu'
        variant='primary'
        className='primary'
        css={css`
          ${ImplicitBorders}
          padding: 4px 8px;
          margin-left: 3px;
          &[class][class][class] {
            :not(.chakra-button__icon) {
              font-size: 11px;
            }
            border-radius: 32px;
          }
        `}
        leftIcon={<AddIcon h={'12px'} w={'12px'} />}
      >
        Add element
      </MenuButton>
      <MenuList zIndex={10}>
        <MenuItem
          icon={<Icon fontSize={'16px'} path={getIconPathByElementType('paragraph')} />}
          onClick={() => onElementCommand('appendElement', 'paragraph', null, 'Text element')}
        >
          Text
        </MenuItem>
        <MenuItem
          icon={<Icon fontSize={'16px'} path={getIconPathByElementType('card')} />}
          onClick={() => onElementCommand('appendElement', 'card')}
        >
          Card
        </MenuItem>
        <MenuItem
          icon={<Icon fontSize={'16px'} path={getIconPathByElementType('section')} />}
          onClick={() => onElementCommand('appendElement', 'section')}
        >
          Section
        </MenuItem>
        <MenuItem
          icon={<Icon fontSize={'16px'} path={getIconPathByElementType('button')} />}
          onClick={() => onElementCommand('appendElement', 'button', {}, 'Click here')}
        >
          Button
        </MenuItem>
        <MenuDivider />
        <MenuItem
          icon={<Icon path={getIconPathByElementType('var')} fontSize={'16px'} />}
          onClick={() => onElementCommand('insertVariable')}
        >
          Variable
        </MenuItem>
        <MenuItem
          icon={<Icon path={getIconPathByElementType('link')} fontSize={'16px'} />}
          onClick={() => onElementCommand('link', 'Select to edit')}
        >
          Link
        </MenuItem>
        <MenuDivider />
        <MenuItem
          as='label'
          {...{ htmlFor: 'upload-images' }}
          icon={<Icon path={getIconPathByElementType('imageBlock')} fontSize={'16px'} />}
        >
          Image
        </MenuItem>
        <MenuItem
          icon={<Icon path={getIconPathByElementType('video')} fontSize={'16px'} />}
          isDisabled={true}
          onClick={() => onElementCommand('mediaEmbed')}
        >
          Video
        </MenuItem>
        <MenuItem
          icon={<Icon path={getIconPathByElementType('table')} fontSize={'16px'} />}
          onClick={() => onElementCommand('insertTable')}
        >
          Table
        </MenuItem>
        <MenuItem
          icon={<Icon path={getIconPathByElementType('embed')} fontSize={'16px'} />}
          onClick={() => onElementCommand('appendElement', 'component')}
        >
          Component
        </MenuItem>
        <MenuItem
          icon={<Icon path={mdiContentPaste} fontSize={'16px'} />}
          onClick={() => onElementCommand('appendElement', 'embed')}
        >
          HTML
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
