import { mdiFormatLetterCase, mdiFormatLetterSpacing, mdiFormatLineSpacing, mdiFormatParagraph } from '@mdi/js'
import {
  AddIcon,
  Box,
  Button,
  CloseIcon,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Text,
  VStack
} from '@sitecore-ui/design-system'
import { StyledSelect } from 'components/StyledSelect.js'
import { useApiData } from 'hooks/useApiData.js'
import { useMemo } from 'react'
import { StyleFor } from 'models/style/index.js'
import { Unformatted } from 'models/style/types/props.js'
import ReusableTypography, { ReusableTypographySize } from 'models/style/typography.js'
import { deepMerge } from 'utils/object.js'
import FlexGrid from '../../FlexGrid.js'
import SizeField from '../fields/SizeField.js'
import { StyleFormProps } from '../settings.js'
const TypographySizeFieldset = (props: StyleFormProps<'typography'>) => {
  const { currentStyle, onChange } = props
  const { base, overrides } = currentStyle.props

  const onUpdate = (changes: Unformatted<ReusableTypography>) => {
    const newStyle = deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) })
    for (var property in changes.overrides as any) {
      if (newStyle.props.overrides[property] == null) {
        delete newStyle.props.overrides[property]
      }
    }
    onChange(newStyle)
  }

  const onAddOverride = () => {
    onUpdate({
      overrides: {
        new: {}
      }
    })
  }
  const breakpoints = useApiData('library.stylesheet.styles').filter(
    (s) => s.type == 'breakpoint'
  ) as StyleFor<'breakpoint'>[]

  const breakpointOptions = useMemo(() => {
    return breakpoints.map((breakpoint) => {
      var [match, title, meta] = breakpoint.details.title.match(/^([^)]+)\(([^(]+)\)/) || []
      return {
        label:
          meta != null ? (
            <Flex flexDirection='row' justifyContent='space-between' width='full'>
              <Text>{title}</Text>
              <Text color='gray.400'>{meta}</Text>
            </Flex>
          ) : (
            title
          ),
        value: breakpoint.details.id
      }
    })
  }, [breakpoints])

  return (
    <VStack alignItems={'flex-start'} spacing='12'>
      <Box>
        <TypographySizeSettingFieldset
          values={base}
          base={ReusableTypographySize()}
          onUpdate={(changes) => onUpdate({ base: changes })}
        />
      </Box>
      {breakpoints
        .map((b) => b.details.id)
        .concat('new')
        .map((breakpointId) => {
          if (overrides[breakpointId] == null) return
          return (
            <Box key={breakpointId}>
              <FormControl mb={6} maxWidth={320}>
                <FormLabel>
                  <IconButton
                    onClick={() =>
                      onUpdate({
                        overrides: {
                          [breakpointId]: null
                        }
                      })
                    }
                    icon={<CloseIcon />}
                    aria-label='Delete'
                    position={'absolute'}
                    right={1.5}
                    top={-2}
                    fontSize={16}
                  />

                  <span>When screen size is:</span>
                </FormLabel>

                <StyledSelect
                  options={breakpointOptions.filter((o) => o.value == breakpointId || !(o.value in overrides))}
                  value={breakpointOptions.find((o) => o.value == breakpointId)}
                  placeholder='Select size'
                  onChange={({ value }) => {
                    onUpdate({
                      overrides: {
                        [breakpointId]: null,
                        [value]: overrides[breakpointId]
                      }
                    })
                  }}
                />
              </FormControl>
              <TypographySizeSettingFieldset
                values={overrides[breakpointId]}
                base={base}
                onUpdate={(changes) => onUpdate({ overrides: { [breakpointId]: changes } })}
              />
            </Box>
          )
        })}
      <Box>
        <Button leftIcon={<AddIcon />} onClick={onAddOverride}>
          Add font size group for a breakpoint
        </Button>
      </Box>
    </VStack>
  )
}

const TypographySizeSettingFieldset = ({
  values,
  base,
  onUpdate
}: {
  values: ReusableTypographySize
  base: ReusableTypographySize
  onUpdate: (change: Unformatted<ReusableTypographySize>) => void
}) => {
  const { fontSize, lineHeight, letterSpacing, paragraphSpacing } = values

  return (
    <FlexGrid>
      <Grid templateColumns='repeat(1, 1fr)' gap={6}>
        <GridItem colSpan={1}>
          <SizeField
            allowEmpty={true}
            onChange={(value) => onUpdate({ fontSize: value })}
            icon={mdiFormatLetterCase}
            placeholder={String(base.fontSize?.value ?? 'Inhert parent size')}
            label='Font size'
            length={fontSize}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <SizeField
            allowEmpty={true}
            onChange={(value) => onUpdate({ lineHeight: value })}
            placeholder={String(base.fontSize?.value ?? 'Inherit parent height')}
            icon={mdiFormatLineSpacing}
            label='Line height'
            length={lineHeight}
          />
        </GridItem>
      </Grid>

      <Grid templateColumns='repeat(1, 1fr)' gap={6}>
        <GridItem colSpan={1}>
          <SizeField
            allowEmpty={true}
            onChange={(value) => onUpdate({ letterSpacing: value })}
            units={['px', 'em']}
            placeholder={String(base.fontSize?.value ?? 'Inherit parent setting')}
            icon={mdiFormatLetterSpacing}
            label='Letter spacing'
            length={letterSpacing}
          />
        </GridItem>

        <GridItem colSpan={1}>
          <SizeField
            allowEmpty={true}
            onChange={(value) => onUpdate({ paragraphSpacing: value })}
            icon={mdiFormatParagraph}
            placeholder={String(base.fontSize?.value ?? 'Unspecified')}
            label='Paragraph spacing'
            length={paragraphSpacing}
          />
        </GridItem>
      </Grid>
    </FlexGrid>
  )
}
export default TypographySizeFieldset
