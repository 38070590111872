import { mdiPaperclip as mdiUpload } from '@mdi/js'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Switch,
  Textarea
} from '@sitecore-ui/design-system'
import ButtonGroupSwitch from 'components/ButtonGroupSwitch.js'
import DatasourceTree from 'components/datasources/DatasourceTree.js'
import { StyledSelect } from 'components/StyledSelect.js'
import { DatasourceModel } from '@sitecore-feaas/api'
import { useApiData } from 'hooks/useApiData.js'
import type { ModelElement } from 'hooks/useEditor.js'
import { useEffect, useState } from 'react'
import { ConfigureMenuItem } from './EditorChromeContextConfigure.js'
import { DialogComponent } from './EditorDialog.js'

const EditorDialogAttributes: DialogComponent<ConfigureMenuItem> = (props) => {
  const {
    context,
    editor,
    menuItem: { above, below, type, value, jsonpath, labels, label, onChange, onConfigure, ExtraComponent }
  } = props

  const getIndex = () => {
    if (jsonpath) return 2
    if (value != null) return 1
    if (!labels.none) {
      if (labels.static) return 1
      if (labels.mapped) return 2
    }
    return 0
  }
  const [index, setIndex] = useState(getIndex)

  const onIndexChange = (newIndex: number) => {
    if (newIndex == index) return
    if (newIndex != 1) {
      if (value != null && label != 'Text') {
        onChange(null)
      }
    }
    if (newIndex != 2) {
      if (jsonpath != null) {
        onConfigure(null)
      }
    }
    if (newIndex == 1 && value == null) {
      onChange('')
    }
    setIndex(newIndex)
  }

  return (
    <>
      {above?.({ ...props, index, setIndex: onIndexChange })}
      {Object.values(labels).filter(Boolean).length > 1 && (
        <ButtonGroupSwitch index={index} onChange={onIndexChange} alignSelf={'flex-start'}>
          {labels.none && <Button>{labels.none}</Button>}
          {labels.static && <Button>{labels.static}</Button>}
          {labels.mapped && <Button>{labels.mapped}</Button>}
        </ButtonGroupSwitch>
      )}

      {index == 1 && <EditorDialogAttributesStatic {...props} />}
      {index == 2 && <EditorDialogAttributesMapping {...props} />}
      {index != 0 && ExtraComponent && <ExtraComponent {...props} />}
      {below?.({ ...props, index, setIndex: onIndexChange })}
    </>
  )
}

export const EditorDialogAttributesStatic: DialogComponent<ConfigureMenuItem> = ({
  menuItem: { type, value, label, onChange }
}) => {
  return (
    type != 'boolean' &&
    type != 'custom' && (
      <Box>
        {type == 'string' && (
          <FormControl>
            <FormLabel>Text</FormLabel>
            <Input value={value || ''} onChange={(e) => onChange(e.target.value)} />
          </FormControl>
        )}
        {type == 'url' && (
          <FormControl>
            <FormLabel>URL</FormLabel>
            <Input value={value || ''} placeholder={'http://'} onChange={(e) => onChange(e.target.value)} />
          </FormControl>
        )}
        {type == 'html' && (
          <FormControl>
            <Textarea
              value={value || ''}
              placeholder={'Raw HTML, data mapping syntax and web components are allowed'}
              onChange={(e) => onChange(e.target.value)}
            />
          </FormControl>
        )}
        {type == 'image' && (
          <>
            <FormControl>
              <FormLabel>URL</FormLabel>
              <InputGroup>
                <Input value={value || ''} placeholder={'http://'} onChange={(e) => onChange(e.target.value)} />
                <InputRightElement style={{ position: 'relative', height: '100%' }}>
                  <IconButton
                    as='label'
                    {...{ htmlFor: 'upload-image' }}
                    icon={<Icon path={mdiUpload} fontSize={'24px'} />}
                    aria-label='Upload'
                    isDisabled={true}
                  />
                  <input
                    id='upload-image'
                    type='file'
                    disabled={true}
                    onChange={(e) => onChange(e.target.files[0])}
                    style={{
                      position: 'absolute',
                      opacity: 0,
                      margin: 0,
                      padding: 0,
                      border: 0,
                      left: -2000,
                      appearance: 'none',
                      width: '100%',
                      height: '100%',
                      display: 'block'
                    }}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </>
        )}
      </Box>
    )
  )
}

function getDefaultDatasource(model: ModelElement, datasources: DatasourceModel[]) {
  for (var m = model; m; m = m.parent as ModelElement) {
    for (const [name, value] of m.getAttributes()) {
      if (name.startsWith('data-path')) {
        const id = String(value).replace(/^\$.?/, '').split('.')[0]
        const datasource = datasources.find((d) => d.id == id)
        return datasource
      }
    }
  }
  return datasources[0]
}

const EditorDialogAttributesMapping: DialogComponent<ConfigureMenuItem> = ({
  context,
  menuItem: { jsonpath, type, onConfigure, onChange }
}) => {
  const datasources = useApiData('library.datasources')
  const [showDetails, setShowDetails] = useState(false)
  const scopes = context.getAncestors({ includeSelf: true }).reduce((scopes, el: ModelElement) => {
    if (el.getAttribute('data-path-scope') == null) return scopes
    return [el.getAttribute('data-path-scope')].concat(scopes)
  }, [])

  const [datasource, setDatasource] = useState(() => getDefaultDatasource(context, datasources))

  const [textpath, setTextpath] = useState(jsonpath)
  useEffect(() => {
    setTextpath(jsonpath)
  }, [jsonpath])

  const validate = (value) => {
    if (type == 'url' || type == 'image') {
      if (typeof value != 'string' || (!value.startsWith('/') && !value.includes('//'))) {
        return 'Value does not look like an URL'
      }
    }
    if (type == 'boolean') {
      if (typeof value != 'boolean' && typeof value != 'number') {
        return 'Value does not look like an boolean'
      }
    }
  }

  return (
    <>
      <FormControl zIndex={2} mb={6}>
        <FormLabel>Data source</FormLabel>
        <StyledSelect
          options={datasources}
          value={datasource}
          onChange={setDatasource}
          placeholder='Select datasource'
          getOptionValue={(data: DatasourceModel) => data as any}
          getOptionLabel={(data: DatasourceModel) => data.name}
        />
      </FormControl>

      <Box pt={2}>
        <HStack justifyContent={'space-between'} alignItems='baseline'>
          <FormLabel>
            {showDetails ? (
              <Link target='_blank' href='https://www.npmjs.com/package/jsonpath-plus'>
                JSONPath
              </Link>
            ) : (
              'Path'
            )}{' '}
            to data {type == 'array' ? 'collection' : 'value'}
          </FormLabel>
          <FormControl display={'flex'} alignItems='center' float='right' width='auto' zIndex={'1'}>
            <FormLabel mb='0' htmlFor='is-expert'>
              Show details
            </FormLabel>
            <Switch id='is-expert' onChange={() => setShowDetails((v) => !v)} isChecked={showDetails}></Switch>
          </FormControl>
        </HStack>

        {showDetails && (
          <Input
            type='text'
            mb={4}
            fontFamily='mono'
            value={textpath}
            onChange={(e) => setTextpath(e.target.value)}
            onBlur={(e) => onConfigure(e.target.value)}
            placeholder='JSON Path'
          />
        )}
        {datasource && (
          <FormControl>
            <DatasourceTree
              validator={validate}
              isVerbose={showDetails}
              onConfigure={onConfigure}
              intent={type == 'array' ? 'repeating' : type == 'object' ? 'scoping' : 'mapping'}
              data={datasource.sample}
              prefix={datasource?.id}
              path={jsonpath}
              scopes={scopes}
            />
          </FormControl>
        )}
      </Box>
    </>
  )
}

export default EditorDialogAttributes
