import { default as styled } from '@emotion/styled'
import { Box, Flex, IconButton, InfoOutlineIcon, Text } from '@sitecore-ui/design-system'
import type { ComponentModel } from '@sitecore-feaas/api'
import { FunctionComponent, ReactElement } from 'react'
import ComponentLowerActions from './ComponentLowerActions.js'

const TruncatedTitle = styled(Text)`
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TruncatedDescription = styled(Text)`
  display: -webkit-box;
  max-width: 80%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

interface Props {
  component: ComponentModel
  showInfo: boolean
  showInfoButton: boolean
  onToggleShowInfo: () => void
}

const ComponentLower: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { component, showInfo, showInfoButton, onToggleShowInfo } = props

  return showInfo ? (
    <Box pos='absolute' boxShadow='base' bg='gray.50' zIndex='999' w='full' pb='2' borderEndRadius={'4px'}>
      <Flex px='5' align='center'>
        <TruncatedTitle fontSize='lg' fontWeight='semibold'>
          {component.name}
        </TruncatedTitle>

        <Flex ml='auto'>
          <IconButton
            opacity={showInfoButton ? 1 : 0}
            aria-label='Users'
            icon={<InfoOutlineIcon />}
            onClick={() => onToggleShowInfo()}
          />

          <ComponentLowerActions showInfoButton={showInfoButton} component={component} />
        </Flex>
      </Flex>

      {component.description && (
        <TruncatedDescription variant='microcopy' px='5' mt='-3'>
          {component.description}
        </TruncatedDescription>
      )}
    </Box>
  ) : (
    <Flex px='5' align='center'>
      <TruncatedTitle fontSize='lg' fontWeight='semibold'>
        {component.name}
      </TruncatedTitle>

      <Flex ml='auto'>
        <IconButton
          opacity={showInfoButton ? 1 : 0}
          aria-label='Users'
          icon={<InfoOutlineIcon />}
          onClick={() => onToggleShowInfo()}
        />

        <ComponentLowerActions showInfoButton={showInfoButton} component={component} />
      </Flex>
    </Flex>
  )
}

export default ComponentLower
