import { Color } from 'models/style/types/color.js'
import { Unformatted } from 'models/style/types/props.js'

export interface BasicColorProps {
  colors?: ColorDefinition[]
}

export interface ColorDefinition {
  color?: Color
  name: string
  markedForDeletion?: boolean
}

export const ColorDefinition = (props: any = {}) => ({
  color: Color(props.color),
  name: props.name,
  markedForDeletion: !!props.markedForDeletion
})

export const BasicColor = (props: { colors: any[] } = { colors: [] }) => {
  return {
    colors: (props.colors || []).map(ColorDefinition) as ColorDefinition[]
  }
}
export type BasicColor = ReturnType<typeof BasicColor>
export default BasicColor
