import { fetchAndRevalidate } from './fetch.js'

export interface CloudTemplate {
  hostname?: string
  componentId: string
  variantId: string
  version?: string
}
export interface CloudStylesheet {
  hostname?: string
  libraryId: string
  version?: string
}

export function getComponentURL({
  hostname = 'https://feaascomponentsapidev.blob.core.windows.net',
  componentId,
  variantId,
  version = 'published'
}: CloudTemplate) {
  return `${hostname}/components/${componentId}/variants/${variantId}/${version}.html`
}

export function getStylesheetURL({
  hostname = `https://feaascomponentsapidev.blob.core.windows.net`,
  libraryId,
  version = 'published'
}: CloudStylesheet) {
  return `${hostname}/styles/${libraryId}/${version}.css`
}

export async function fetchComponent(props: CloudTemplate, callback: (html: string) => void) {
  return fetchAndRevalidate(
    getComponentURL(props),
    {},
    (response) => {
      return response.headers.get('x-ms-meta-variantDeletedAt') == null
    },
    (response) => {
      response.text().then(callback)
    }
  )
}

export async function fetchStylesheet(props: CloudStylesheet, callback: (css: string) => void) {
  return fetchAndRevalidate(
    getStylesheetURL(props),
    {},
    (response) => {
      return response.headers.get('x-ms-meta-deletedAt') == null
    },
    (response) => {
      response.text().then(callback)
    }
  )
}
