import React, { ReactElement } from 'react'
import { deepMerge } from 'utils/object.js'
import TextField from '../fields/TextField.js'
import FlexGrid from '../../FlexGrid.js'
import { Grid, GridItem } from '@sitecore-ui/design-system'
import { StyleFormProps } from '../settings.js'
import { StyleModel } from 'models/style/index.js'

const DetailsFieldset = ({
  currentStyle,
  onChange,
  errors,
  forceShowErrors
}: StyleFormProps<StyleModel>): ReactElement => (
  <FlexGrid>
    <Grid templateColumns='repeat(1, 1fr)' gap={6}>
      <GridItem colSpan={1}>
        <TextField
          error={errors?.details?.title}
          required
          forceShowErrors={forceShowErrors}
          value={currentStyle.details.title}
          label='Title'
          onChange={(value: string) => onChange(deepMerge(currentStyle, { details: { title: value } }))}
          caption={
            currentStyle.originId
              ? '*Titles need to be unique before accessing other tabs'
              : '*Title is required before accessing other tabs'
          }
        />
      </GridItem>

      <GridItem colSpan={1}>
        <TextField
          value={currentStyle.details.description}
          label='Description'
          onChange={(value: string) => onChange(deepMerge(currentStyle, { details: { description: value } }))}
        />
      </GridItem>

      {(currentStyle.type === 'typography' || currentStyle.type === 'button') && (
        <GridItem colSpan={1}>
          <TextField
            value={currentStyle.details.exampleContent}
            label='Example Text'
            onChange={(value: string) => onChange(deepMerge(currentStyle, { details: { exampleContent: value } }))}
          />
        </GridItem>
      )}
    </Grid>
  </FlexGrid>
)

export default DetailsFieldset
