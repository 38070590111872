import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import { useEffect, useMemo, useState } from 'react'
import { StyledSelect } from '../../StyledSelect.js'
import Preview, { PreviewFontsStylesheet } from '../previews/index.js'
import FlexGrid from 'components/FlexGrid.js'
import { deepMerge } from 'utils/object.js'
import { parseGoogleFont } from 'utils/font.js'
import inflection from 'inflection'
import { BasicFont, FontVariant } from 'models/style/font.js'
import { Unformatted } from 'models/style/types/props.js'
import { StyleFormProps } from '../settings.js'
import { Button, Checkbox, Flex, FormControl, FormLabel, GridItem, Text } from '@sitecore-ui/design-system'

export type GoogleFontVariant = 'regular' | 'italic'
export type GoogleFontSubset = 'latin' | 'latin-ext'

export interface GoogleFontFiles {
  regular?: string
  italic?: string
}

export interface GoogleFont {
  family: string
  variants: GoogleFontVariant[]
  subsets: GoogleFontSubset[]
  version: string
  lastModified: string
  files: GoogleFontFiles
  category: string
  kind: string
}

const getFontVariantLabel = (variant) => {
  const mapping = {
    100: 'Thin',
    200: 'ExtraLight',
    300: 'Light',
    400: 'Regular',
    500: 'Medium',
    600: 'SemiBold',
    700: 'Bold',
    800: 'ExtraBold',
    900: 'Italic'
  }

  return mapping[variant] ?? ''
}

const platforms = ['google', 'adobe', 'custom']

const FontFieldset = ({ currentStyle, onChange }: StyleFormProps<'font'>) => {
  const { familyName, platform, variants } = currentStyle.props

  const [googleFonts, setGoogleFonts] = useState<FontVariant[]>([])
  const [adobeFonts, setAdobeFonts] = useState([])

  const onUpdate = (changes: Unformatted<BasicFont>) => {
    onChange(deepMerge(currentStyle, { props: deepMerge(currentStyle.props, changes) }))
  }

  // Based on the platform, need fetch the available fonts
  useEffect(() => {
    if (platform === 'google') {
      fetchGoogleFonts()
    } else if (platform === 'adobe') {
      fetchAdobeFonts()
    }
  }, [platform])

  // Fetch Google Fonts list from the Google Platform API
  const fetchGoogleFonts = async () => {
    const response = await fetch(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC2Q34wSNXyM_dKY8gaydCvVAvd2-SX8Ok'
    )

    const data = await response.json()

    setGoogleFonts(data.items.map(parseGoogleFont))
  }

  const fetchAdobeFonts = async () => {}

  const onSelectVariant = (variant: FontVariant): void => {
    const variantExists = variants.find((v) => v.name === variant.name)

    if (variantExists) {
      onUpdate({ variants: variants.filter((v) => v.name !== variant.name) })

      return
    }

    onUpdate({
      variants: font.variants.filter((_variant) => [...variants, variant].map((v) => v.name).includes(_variant.name))
    })
  }

  const fonts = platform == 'google' ? googleFonts : adobeFonts
  const font = fonts.find((font) => font.familyName == familyName) || { variants: [] }

  const stylesheet = useMemo(() => {
    return (
      <PreviewFontsStylesheet
        styles={[
          {
            type: 'font',
            details: { id: 'preview-' + familyName },
            props: { platform, variants: font.variants, familyName }
          }
        ]}
      />
    )
  }, [font])
  return (
    <>
      {stylesheet}

      <ButtonGroupSwitch
        index={platforms.indexOf(platform)}
        onChange={(index) => {
          onUpdate({ platform: platforms[index] })
        }}
        mb={3}
      >
        <Button>Google Fonts</Button>
        <Button isDisabled={true}>Adobe</Button>
        <Button isDisabled={true}>Custom</Button>
      </ButtonGroupSwitch>

      <FormControl mt='3' width={320} mb={6}>
        <FormLabel>Font family</FormLabel>

        <StyledSelect
          aria-label='Select font'
          fixedWidth
          options={fonts.map((font) => ({ label: font.familyName, value: font.familyName }))}
          value={{ label: familyName, value: familyName }}
          onChange={({ value }) =>
            onUpdate({
              familyName: value,
              variants: []
            })
          }
        />
      </FormControl>

      <FlexGrid spacing='16' w='full' flex='3' p='0'>
        {font.variants.map((variant: FontVariant) => (
          <GridItem
            key={variant.name}
            as='label'
            cursor='pointer'
            colSpan={1}
            bg='white'
            shadow='base'
            h='36'
            p='4'
            borderRadius='base'
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
          >
            <Text color='gray.500' fontWeight='semibold'>
              {getFontVariantLabel(variant.weight)} {variant.weight}{' '}
              {variant.style == 'italic' ? inflection.titleize(variant.style) : ''}
            </Text>

            <Preview
              style={{
                type: 'font',
                details: { id: 'a' },
                props: { platform, variants: [variant], familyName }
              }}
            />

            <Flex align='center'>
              <Checkbox
                isChecked={!!variants.find((v) => v.name === variant.name)}
                onChange={() => onSelectVariant(variant)}
              />

              <Text ml='2'>Activate Font Style</Text>
            </Flex>
          </GridItem>
        ))}
      </FlexGrid>
    </>
  )
}

export default FontFieldset
