import { MenuList, MenuItem, Icon, MenuButton, IconButton, Menu, useDisclosure } from '@sitecore-ui/design-system'
import { EditorContextProps, getIconPathByElementType, ImplicitBorders } from './EditorChrome.js'
import { mdiArrowUpLeft } from '@mdi/js'
import { css } from '@emotion/react'
import { getElementName } from './EditorChromeContextToolbar.js'
import { useCallback, useEffect, useRef } from 'react'
import type { ModelElement } from 'hooks/useEditor.js'
export default function EditorChromeContextParent(props: EditorContextProps) {
  const { onCommand, context } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  const closeRef = useRef<ReturnType<typeof setTimeout>>()
  const scheduleClose = useCallback(() => {
    clearTimeout(closeRef.current)
    closeRef.current = setTimeout(onClose, 200)
  }, [onClose])

  const open = useCallback(() => {
    clearTimeout(closeRef.current)
    onOpen()
  }, [onOpen])

  const isRoot = context?.is('rootElement')
  useEffect(() => {
    clearTimeout(closeRef.current)
    onClose()
  }, [context])

  return (
    <Menu placement='bottom-start' offset={[-10, 10]} isOpen={isOpen}>
      <MenuButton
        onMouseEnter={open}
        onMouseLeave={scheduleClose}
        as={IconButton}
        onClick={() => onCommand('selectParent')}
        isDisabled={context?.is('rootElement')}
        css={ImplicitBorders}
        aria-label='Select parent'
        icon={<Icon path={mdiArrowUpLeft} fontSize='18px' className='return' />}
      />
      <MenuList
        style={{ width: 'auto', minWidth: 'auto' }}
        onMouseEnter={(isOpen && open) || null}
        onMouseLeave={(isOpen && scheduleClose) || null}
        css={css`
          pointer-events: ${isOpen ? 'all' : 'none'};
          &:before {
            position: absolute;
            margin-top: -20px;
            height: 10px;
            width: 100%;
            z-index: 1;
            content: '';
            display: block;
          }
        `}
      >
        {false && (
          <MenuItem
            {...{ color: 'blackAlpha.400', textTransform: 'uppercase', fontSize: '12px', fontWeight: '600' }}
            onClick={() => {
              onCommand('selectParent')
              onClose()
            }}
          >
            Select parent
          </MenuItem>
        )}
        {((context?.getAncestors().reverse() || []) as ModelElement[]).map((a, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onCommand('setContext', a)
                onClose()
              }}
              icon={<Icon path={getIconPathByElementType(a.name)} fontSize='18px' />}
            >
              {getElementName(a)}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
