import { GoogleFont, GoogleFontVariant } from '../components/styles/fieldsets/FontFieldset.js'
import { BasicFont, BasicFontPlatform, BasicFontVariantStyle, FontVariant } from 'models/style/font.js'

export const extractFontWeight = (variant): number => {
  let variantWeight = variant.match(/\d+/g)
  return (variantWeight?.length && parseInt(variantWeight[0])) ?? 400
}

export const extractFontStyle = (variant): BasicFontVariantStyle => {
  let variantStyle = variant.match(/[a-zA-Z]+/g)
  let variantStyleParsed = (variantStyle?.length && variantStyle[0]) ?? 'normal'
  if (variantStyleParsed === 'regular') variantStyleParsed = 'normal'

  return variantStyleParsed
}

export const parseGoogleFont = (font: GoogleFont): BasicFont => {
  return {
    familyName: font.family,
    variants: parseGoogleFontVariants(font),
    platform: BasicFontPlatform.google
  }
}

export const parseGoogleFontVariants = (font: GoogleFont): FontVariant[] => {
  if (!font) return []

  return font.variants.map((variant: GoogleFontVariant) => {
    return {
      name: variant,
      style: extractFontStyle(variant),
      weight: extractFontWeight(variant),
      familyName: font.family
    }
  })
}
