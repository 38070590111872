import { default as styled } from '@emotion/styled'
import { css } from '@emotion/react'
import {
  CloseButton,
  FormLabel,
  Badge,
  Code,
  Container,
  FormControl,
  HStack,
  RepeatIcon,
  Switch,
  Tooltip,
  WarningIcon,
  CloseIcon
} from '@sitecore-ui/design-system'

const List = styled.ul`
  padding-left: 19px;
  list-style: none;
  border-left: 1px solid var(--chakra-colors-blackAlpha-200);
`
const ListRoot = styled.ul`
  list-style: none;
  user-select: none;
`
const Item = styled.li`
  &[data-action='rooting'][data-action='rooting'] > ul {
    border-left-color: var(--chakra-colors-green-300);
  }
`

export default function DatasourceTree({
  validator = null,
  data,
  prefix = '$',
  path = undefined,
  intent,
  isVerbose,
  scopes = [],
  onConfigure = undefined
}) {
  return (
    <ListRoot>
      {ConfigureDataBranch({
        validator,
        prefix,
        label: prefix,
        data,
        bits: [prefix],
        path,
        intent,
        action: null,
        isVerbose,
        scopes,
        onConfigure
      })}
    </ListRoot>
  )
}

type ConfigureAction =
  | 'mapping'
  | 'trimming'
  | 'scoping'
  | 'rooting'
  | 'repeating'
  | 'nesting'
  | 'repeating-mapping'
  | 'indicating'

interface BranchParams {
  prefix: string
  data: any
  bits: string[]
  path: string | null
  label?: string
  intent: ConfigureAction
  action: ConfigureAction | 'reporting' | null
  isVerbose: boolean
  scopes: string[]
  validator?: (value: any) => string
  onConfigure: (value: string | null) => void
}

function walk(callback, data, key) {
  if (data != null && Array.isArray(data)) {
    for (var prop of data) {
      callback(data[prop], prop)
      walk(callback, data[prop], prop)
    }
  } else if (data != null && typeof data == 'object') {
    for (var prop1 in data) {
      callback(data[prop1], prop1)
      walk(callback, data[prop1], prop1)
    }
  } else {
    callback(data, key)
  }
}

function ConfigureDataLeaf({ warning, action, label, isVerbose, error, onClick, intent, onClear }) {
  if (error) {
    if (!isVerbose) return
    return (
      <Tooltip label={error}>
        <Badge variant='datapath' colorScheme='red'>
          {label}
        </Badge>
      </Tooltip>
    )
  }
  var badge = null

  if (action == 'trimming') {
    return (
      <Badge variant='datapath' colorScheme='purple' cursor='pointer' onClick={onClick}>
        {label}
      </Badge>
    )
  } else if (action == 'repeating') {
    return (
      <Badge variant='datapath' colorScheme='purple' cursor='pointer' onClick={onClick}>
        {label}{' '}
      </Badge>
    )
  } else if (action == 'mapping' || action == 'scoping') {
    if (warning) {
      return (
        <Tooltip label={warning}>
          <Badge variant='datapath' colorScheme='purple' opacity={0.5} cursor='pointer' onClick={onClick}>
            {label}
          </Badge>
        </Tooltip>
      )
    } else {
      return (
        <Badge variant='datapath' colorScheme='purple' cursor='pointer' onClick={onClick}>
          {label}
        </Badge>
      )
    }
  } else if (action == 'repeating-mapping') {
    return (
      <Badge variant='datapath' colorScheme='purple' cursor='pointer' onClick={onClick}>
        {label}
        <RepeatIcon />
      </Badge>
    )
  } else if (action == 'nesting') {
    return (
      <Badge variant='datapath' colorScheme='white'>
        {label} <RepeatIcon />{' '}
      </Badge>
    )
  } else if (action == 'indicating') {
    return (
      <>
        <HStack spacing={0} onClick={onClear} display='inline-flex' verticalAlign={'middle'}>
          <Badge variant='datapath' colorScheme='green' cursor='pointer'>
            {label}
          </Badge>
          <CloseIcon />
        </HStack>
      </>
    )
  } else if (action == 'rooting') {
    return (
      <Badge variant='datapath' colorScheme='green' cursor='pointer'>
        {label}
      </Badge>
    )
  } else {
    return (
      <Badge variant='datapath' colorScheme='grey' px={0} opacity={0.5}>
        {label}
      </Badge>
    )
  }
}

function isPrimitive(value) {
  return !(value != null && (Array.isArray(value) || typeof value == 'object'))
}

// check if paths equal, or if left path is qualifying the right with array expression ([], .*)
function matchPath(left, right) {
  return left.startsWith(right) && left.substring(right.length).match(/^(|\.\*|\[[^]+\])$/)
}

// this following function is not great, but it handles all the cases
// as long as this mess is contained in here, it works alright.
function ConfigureDataBranch({
  validator,
  prefix,
  data,
  bits,
  path,
  label = prefix,
  intent,
  action,
  isVerbose,
  scopes = [],
  onConfigure
}: BranchParams) {
  var currentPath = bits.join('.')
  var descendIntent = intent

  if (!action && scopes.find((scope) => matchPath(scope, currentPath))) {
    action = 'nesting'
  }
  if (path && matchPath(path, bits.join('.'))) {
    action = intent == 'trimming' ? 'rooting' : 'indicating'
    //    descendIntent = false
  }
  if (typeof data == 'object' && data != null) {
    if (!action && intent == 'trimming' && !isPrimitive(data)) {
      action = 'trimming'
    }
    if (!action && intent == 'scoping' && !isPrimitive(data)) {
      action = 'scoping'
    }
    var entries = Object.entries(data)
    if (entries.length > 0 && Array.isArray(data)) {
      bits = bits.concat('*')
      if (intent == 'repeating' && !action) {
        action = 'repeating'
      }
      if (!isPrimitive(entries[0][1])) {
        entries = Object.entries(entries[0][1])
      } else {
        descendIntent = null
      }
      // go deeper quicker by skipping objects with a single key and going into arrays direcly
    } else if (entries.length == 1 && intent != 'trimming' && intent != 'scoping') {
      return ConfigureDataBranch({
        prefix: (bits.length == 1 ? '' : prefix + '.') + entries[0][0],
        validator,
        path,
        data: entries[0][1],
        bits: bits.concat(entries[0][0]),
        intent,
        action,
        isVerbose,
        onConfigure,
        scopes
      })
    }
  }

  // dont show nodes that dont contain arrays when repeating
  if (intent == 'repeating') {
    var hasArrays = action == 'repeating' || action == 'nesting'
    walk(
      (value: any) => {
        if (Array.isArray(value)) hasArrays = true
      },
      data,
      ''
    )

    if (!hasArrays && action != 'repeating' && action != 'nesting' && action != 'indicating') {
      if (isPrimitive(data)) {
        var error = 'Only collections can be used for repeating'
      } else {
        var error = 'Does not contain collections for repeating'
      }
    }

    if (!hasArrays || action == 'repeating') descendIntent = null
  }
  if (intent == 'scoping') {
    if (Array.isArray(data)) {
      descendIntent = null
      if (scopes.length) {
        error = 'This collection is not in the current scope'
      } else {
        error = 'Use repeating on this collection to map its values'
      }
    }
  }
  if (intent == 'mapping' && (!action || action == 'nesting')) {
    if (action == 'nesting' && isPrimitive(Object.entries(data)[0][1])) {
      action = 'repeating-mapping'
      descendIntent = null
      currentPath += '.*' // ugh lol
    } else if (isPrimitive(data)) {
      var warning = validator ? validator(data) : null
      action = 'mapping'
    } else {
      if (Array.isArray(data) && action != 'nesting') {
        descendIntent = null
        if (scopes.length) {
          error = 'This collection is not in the current scope'
        } else {
          error = 'Use repeating on this collection to map its values'
        }
      }
      var hasPrimitives = true
      walk(
        (value) => {
          if (isPrimitive(value)) hasPrimitives = true
        },
        data,
        ''
      )

      if (!hasPrimitives) {
        var error = 'Does not contain primitive value'
      }
    }
  }

  return (
    <Item key={prefix} data-action={action} data-intent={intent}>
      <ConfigureDataLeaf
        onClick={() => onConfigure(currentPath)}
        warning={warning}
        onClear={() => onConfigure(null)}
        label={label == '$' ? 'Root' : label}
        action={action}
        intent={intent}
        error={error}
        isVerbose={isVerbose}
      />
      {entries && descendIntent && entries.length > 0 && (
        <List>
          {entries.map(([key, value]) => {
            return ConfigureDataBranch({
              prefix: key,
              validator,
              path,
              data: value,
              bits: bits.concat(key),
              intent,
              action: null,
              isVerbose,
              scopes,
              onConfigure
            })
          })}
        </List>
      )}
    </Item>
  )
}
