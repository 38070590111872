import { BasicSettings, element } from 'components/styles/settings.js'
import { Style, StyleEmpty } from 'models/style/index.js'
import { deepMerge } from 'utils/object.js'
import * as CSS from 'models/stylesheet/css.js'
import type { Editor, ModelBatch, ModelElement } from './useEditor.js'

import { element as elementSettings } from 'components/styles/settings.js'
import { useApiData } from 'hooks/useApiData.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isDeepEquals, nanoid } from '@sitecore-feaas/api'

declare type SerializedStyle = [string, Style]
type StylesByAspect = Record<string, Style[]>

export function setElementStyleClassName(
  editor: Editor,
  context: ModelElement,
  style: Style | StyleEmpty,
  batch?: ModelBatch
) {
  editor.model.enqueueChange(batch, (writer) => {
    const oldClasses = String(context.getAttribute('class') || '')
      .split(/\s+/g)
      .filter(Boolean)
      .sort()
    const newClasses = oldClasses
      .filter(
        (c) =>
          (!c.startsWith(`-${style.type}--`) && !(element[style.type] && c.startsWith('-'))) ||
          (style.type != 'layout' && c.startsWith('-layout--')) ||
          (style.type != 'dimensions' && c.startsWith('-dimensions--'))
      )
      .concat('props' in style ? CSS.getStyleClassName(style) : [])
      .sort()

    if (!isDeepEquals(oldClasses, newClasses)) {
      writer.setAttribute('class', newClasses.join(' '), context)
    }
  })
}

export function getElementStylesheet(editor: Editor, context: ModelElement): ModelElement {
  const rootElement = context.root as ModelElement
  const children = Array.from(rootElement.getChildren()).filter((c) => c.is('element')) as ModelElement[]
  return children.find((child) => child.name == 'style')
}

export function getStylesheetRules(editor: Editor, stylesheet: ModelElement): SerializedStyle[] {
  const text = String(stylesheet.getAttribute('cssText') || '')
  const styles: SerializedStyle[] = []
  text.split(/\n*\/\* --- \*\/\n*/g).map((cssText) => {
    cssText.replace(/\/\*\s*Data:\s*(.*?)\s*\*\//g, (m, json) => {
      styles.push([cssText, JSON.parse(json)])
      return m
    })
  })

  return styles
}

export function getElementInstanceId(context: ModelElement): string {
  return context.getAttribute('data-instance-id') ? String(context.getAttribute('data-instance-id')) : null
}

export function acquireElementInstanceId(editor: Editor, context: ModelElement, batch?: ModelBatch) {
  var instanceId = getElementInstanceId(context)
  if (!instanceId) {
    instanceId = nanoid(10)
    editor.model.enqueueChange(batch, (writer) => {
      writer.setAttribute('data-instance-id', instanceId, context)
    })
  }
  return instanceId
}

export function setElementStyleSource(
  editor: Editor,
  context: ModelElement,
  styles: SerializedStyle[],
  type: Style['type'],
  value: string,
  batch?: ModelBatch
) {
  editor.model.enqueueChange(batch, (writer) => {
    var instanceId = acquireElementInstanceId(editor, context, batch)
    var stylesheet = getElementStylesheet(editor, context)
    if (!stylesheet) {
      stylesheet = writer.createElement('style')
      writer.setAttribute('data-role', 'customizations', stylesheet)
      writer.insert(stylesheet, writer.createPositionAt(context.root, 0))
    }
    const index = styles.findIndex(([, s]) => s.details.instanceId == instanceId && s.type == type)
    const texts = styles.map(([t]) => t)
    if (index > -1) {
      var newTexts = texts.slice(0, index).concat(value || [], texts.slice(index + 1))
    } else {
      var newTexts = texts.concat(value || [])
    }
    const cssText = newTexts.join('\n/* --- */\n')
    if (String(stylesheet.getAttribute('cssText')) != cssText) writer.setAttribute('cssText', cssText, stylesheet)
  })
}

export function getElementEffectiveStyles(context: ModelElement, customStyles: Style[], styles: Style[]) {
  const instanceId = context ? getElementInstanceId(context) : null
  if (instanceId == null) return styles

  const contextCustomStyles = customStyles.filter((r) => r.details.instanceId == instanceId)

  // List of all styles for context, with customizations applied
  return styles
    .map((style) => contextCustomStyles.find((r) => r.details.id == style.details.id) || style)
    .concat(contextCustomStyles.filter((r) => !styles.find((s) => s.details.id == r.details.id)))
}

export function getElementActiveStyles(context: ModelElement, effectiveStyles: Style[]) {
  if (!context) return []
  const classes =
    context && context.getAttribute('class') ? String(context.getAttribute('class')).trim().split(/\s+/g) : []
  return effectiveStyles.filter((style) => {
    return classes.includes(CSS.getStyleClassName(style))
  })
}

const textTags = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  heading5: 'h5',
  heading6: 'h6',
  listItem: 'li',
  paragraph: 'p'
}

export function getAspectStyles(style: Style, styles: Style[], aspect: BasicSettings) {
  if (!style?.props[aspect.property]) return styles.filter((s) => s.type == aspect.type)
  return style.props[aspect.property]
    .map((id) => {
      return styles.find((s) => s.details.id == id)
    })
    .filter(Boolean)
}

export function getCurrentStyle(allowedStyles: Style[], activeStyles: Style[]) {
  // selected specific
  return (
    allowedStyles.find((r) => activeStyles.find((a) => a.details.id == r.details.id)) ||
    // preference to custom
    allowedStyles.find((s) => s.details.elementId) ||
    // first in the list, but if it's not blank one
    (allowedStyles[0]?.details.isInternal && allowedStyles[1]) ||
    allowedStyles[0]
  )
}

export function getActiveAspectStyles(aspectOptions: StylesByAspect, activeStyles: Style[]) {
  return Object.keys(aspectOptions).reduce((result, type) => {
    return Object.assign(result, {
      [type]: getCurrentStyle(aspectOptions[type], activeStyles)
    })
  }, {})
}

export function getElementStylesByAspect(style: Style, styles: Style[]): StylesByAspect {
  return elementSettings[style.type].aspects.reduce((result, aspect) => {
    return Object.assign(result, {
      [aspect.type]: getAspectStyles(style, styles, aspect)
    })
  }, {})
}

export function getElementAvailableStyles(context: ModelElement, styles: Style[]): Style[] {
  return styles.filter((style) => {
    if (!context) return
    if (textTags[context.name]) {
      return style.type == 'text' && style.props.tag == textTags[context.name]
    } else {
      return style.type == (context?.name == 'embed' || context?.name == 'component' ? 'card' : context?.name)
    }
  })
}

export function getElementStyle(context: ModelElement, styles: Style[]): Style {
  const available = getElementAvailableStyles(context, styles)
  return getElementActiveStyles(context, available)[0] || available[0]
}

export function getElementAspectStyle(
  context: ModelElement,
  aspect: BasicSettings,
  styles: Style[],
  customStyles: Style[]
) {
  const effectiveStyles = getElementEffectiveStyles(context, customStyles, styles)
  const activeStyles = getElementActiveStyles(context, effectiveStyles)
  const elementStyle = getElementStyle(context, effectiveStyles)
  if (!elementStyle)
    return activeStyles.find((s) => s.type == aspect.type) || effectiveStyles.find((s) => s.type == aspect.type)
  const allowedStyles = getAspectStyles(elementStyle, effectiveStyles, aspect)
  return getCurrentStyle(allowedStyles, activeStyles)
}

type StyleSetter = (element: ModelElement, style: Style | StyleEmpty, batch?: ModelBatch) => boolean
type StylesAndSetter = [styles: Style[], setStyle: StyleSetter]

export function setElementStyle(
  editor: Editor,
  context: ModelElement,
  style: Style | StyleEmpty,
  styles: Style[],
  serializedRules: SerializedStyle[],
  batch: ModelBatch
) {
  const id =
    'props' in style ? style.details.id || acquireElementInstanceId(editor, context, batch) + '-' + style.type : null

  if ('props' in style && !isDeepEquals(style.props, styles.find((s) => s.details.id == id)?.props)) {
    const newStyle: Style = deepMerge(style, {
      details: {
        id: id,
        instanceId: acquireElementInstanceId(editor, context, batch)
      }
    })

    setElementStyleSource(
      editor,
      context,
      serializedRules,
      style.type,
      CSS.stringify(CSS.produceStyle(newStyle), { styles }) + '\n/* Data: ' + JSON.stringify(newStyle) + ' */',
      batch
    )
    var custom = true
  } else if (serializedRules.length) {
    setElementStyleSource(editor, context, serializedRules, style.type, null)
  }
  setElementStyleClassName(editor, context, style, batch)
  return custom
}

export function getRootCustomStyles(editor: Editor, root: ModelElement, styles: Style[]): StylesAndSetter {
  const stylesheet = root ? getElementStylesheet(editor, root) : null
  const serializedRules = stylesheet ? getStylesheetRules(editor, stylesheet) : []
  const customStyles = serializedRules.map((s) => s[1])

  const setStyle: StyleSetter = (context, style, batch) => {
    return setElementStyle(editor, context, style, styles, serializedRules, batch)
  }
  return [customStyles, setStyle]
}

export function useEditorBatch(
  editor: Editor,
  context: ModelElement
): [batch: ModelBatch, startBatch: () => ModelBatch, finishBatch: () => ModelBatch] {
  const batchRef = useRef<ModelBatch>()
  // any changes done by some other thing should reset current batch
  useEffect(() => {
    const onChange = (evt, batch) => {
      if (batch.isLocal && batch.isUndoable && batch !== batchRef.current) {
        batchRef.current = null
      }
    }

    editor.model.document.on('change', onChange)
    return () => {
      editor.model.document.off('change', onChange)
    }
  }, [])

  // changing context will reset batch
  useEffect(() => {
    batchRef.current = null
  }, [context])

  const finishBatch = useCallback(() => {
    const batch = batchRef.current
    batchRef.current = null
    return batch
  }, [])
  const startBatch = useCallback(() => {
    return (batchRef.current ||= editor.model.createBatch())
  }, [])
  return [batchRef.current, startBatch, finishBatch]
}

// maintains single undo item for subsequent changes
export default function useEditorStyles(editor: Editor, root: ModelElement): StylesAndSetter {
  const styles = useApiData('library.stylesheet.styles')
  const [renders, setRenders] = useState(() => 0)

  const stylesheet = root ? getElementStylesheet(editor, root) : null
  const serializedRules = stylesheet ? getStylesheetRules(editor, stylesheet) : []
  const customStyles = serializedRules.map((s) => s[1])
  const [batch, startBatch] = useEditorBatch(editor, root)

  useEffect(() => {
    const onCSSUpdate = () => {
      setRenders((r) => r + 1)
    }
    editor.observerPlugin.onAttributeChange('cssText', onCSSUpdate)
    return () => {
      editor.observerPlugin.offAttributeChange('cssText', onCSSUpdate)
    }
  }, [])

  const setStyle: StyleSetter = (context, style, batch = startBatch()) => {
    setRenders((r) => r + 1)
    return setElementStyle(editor, context, style, styles, serializedRules, batch)
  }
  return [customStyles, setStyle]
}
