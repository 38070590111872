import { ColorProps } from '@chakra-ui/styled-system'
import inflection from 'inflection'
import {
  RuleDetails,
  Style,
  URL,
  GraphicReference,
  BreakpointReference,
  RuleChoice,
  SceneBreakpointProps,
  StyleRule,
  StyleFor
} from 'models/style/index.js'
import { Color, ColorReference } from 'models/style/types/color.js'
import { Gradient } from 'models/style/types/gradient.js'
import { Length } from 'models/style/types/length.js'
import { StyleProps } from '../style/index.js'
import BasicColor from 'models/style/color.js'
import { ReusableDecoration } from 'models/style/decoration.js'
import { SceneDimensions } from 'models/style/dimensions.js'
import { ReusableFill } from 'models/style/fill.js'
import { BasicFont, FontVariant, FontVariantReference } from 'models/style/font.js'
import { SceneLayout, SceneLayoutJustifyContent } from 'models/style/layout.js'
import ReusablePalette from 'models/style/palette.js'
import SceneSpacing from 'models/style/spacing.js'
import ReusableTypography, { ReusableTypographySize } from 'models/style/typography.js'

export type UnformattedLength = Length | number | string

export function toIdentifier(name: string) {
  return inflection.dasherize(
    inflection.underscore(
      name
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .replace(/[^a-z0-9_-]/gi, '-')
    )
  )
}

declare type ASTBlock = [type: string, value: string, ...children: any[]]

// format markdown-metadata compatible text object with line wrapping, like:
// title:   Description
// id:      #ccc
function produceMeta(object: Record<string, any>, { keyLength = 18, valueLength = 80 } = {}) {
  var array = []
  for (var property in object) {
    var string = ''
    if (object[property] == null) {
      continue
    }
    const rawValue = object[property]
    var value = String(rawValue && Array.isArray(rawValue) ? rawValue.join(', ') : rawValue).trim()

    if (!value) {
      continue
    }
    var valueActualLength = value.length
    const title = inflection.titleize(inflection.underscore(property))
    string += title
    string += ':'
    string += Array(keyLength - title.length).join(' ')

    for (var i = 0; i < valueActualLength; i += valueLength) {
      if (i != 0) {
        string += Array(keyLength + 1).join(' ')
      }
      var rest = value.substring(i, i + valueLength)
      var restLength = rest.length
      // check if word can be broken, and scroll back to word boundary
      if (restLength == valueLength) {
        for (var j = 0; j < restLength; j++) {
          if (rest.charAt(restLength - j - 1).match(/\W/)) {
            break
          }
        }
        if (j != restLength) {
          rest = rest.slice(0, restLength - j)
          i -= j
        }
      }
      string += rest
      array.push(string)
      string = ''
    }
    if (string) {
      array.push(string)
    }
  }
  return array
}

export function produceStyle(style: Style) {
  const propsStyle = produceRuleProps(style.type, style.props)
  if (!propsStyle) {
    throw new Error('Cant serialize ' + style.type)
  }
  return [
    ['comment', ...produceRuleDetails(style.type, style.details)],
    ['rule', getStyleSelector(style), ...propsStyle]
  ]
}

export function getStyleSelector(style: Style) {
  switch (style.type) {
    case 'color':
      return ':root'
    case 'font':
      return null
    // elements need 0.3.* specificity
    case 'section':
    case 'card':
    case 'button':
      return [
        style.details.isDefault && `:wrapper(.-feaas[class]) .-${style.type}`,
        `.${getStyleClassName(style)}[class][class]`
      ].filter(Boolean)
    case 'text':
      if (style.props.tag && style.details.isDefault) {
        return [`:wrapper(.-feaas[class]) ${style.props.tag}`, `.${getStyleClassName(style)}[class][class]`].filter(
          Boolean
        )
      } else {
        return `.${getStyleClassName(style)}[class][class]`
      }
    // reusables need 0.4.* specificity
    default:
      if (style.details.instanceId != null) {
        return `.${getStyleClassName(style)}[class][class][class][data-instance-id="${style.details.instanceId}"]`
      } else {
        return `.${getStyleClassName(style)}[class][class][class]`
      }
  }
}

export function getStyleClassName(style: Style) {
  return `-${style.type}--${toIdentifier(style.details.title)}`
}

function combineSelectors(left: string, right: string) {
  return []
    .concat(left)
    .map((l) => {
      return [].concat(right).map((r) => {
        if (r.includes('&')) {
          return r.replace('&', l)
        } else {
          return l + ' ' + r
        }
      })
    })
    .flat()
}

function produceReusablePropsConditionally(type: Style['type'], ids: RuleChoice[]) {
  if (ids?.length == 0) return []

  return (context: Context) => {
    const props = resolve('props', type, ids)(context)
    return props ? produceRuleProps(type, props) : []
  }
}

export function produceRuleProps<T extends Style['type']>(type: T, props: any) {
  switch (type) {
    case 'color':
      return produceBasicColorProps(props)
    case 'decoration':
      return produceReusableDecorationProps(props)
    case 'typography':
      return produceReusableTypographyProps(props)
    case 'fill':
      return produceReusableFillProps(props)
    case 'font':
      return produceBasicFontProps(props)
    case 'spacing':
      return produceSceneSpacingProps(props)
    case 'palette':
      return produceReusablePaletteProps(props)
    case 'layout':
      return produceSceneLayoutProps(props)
    case 'dimensions':
      return produceSceneDimensionsProps(props)
    case 'breakpoint':
      return produceSceneBreakpointProps(props)
    case 'section':
    case 'card':
      return [
        ['comment', ...produceMeta(props)],
        produceReusablePropsConditionally('decoration', props.decorationIds),
        produceReusablePropsConditionally('fill', props.fillIds),
        produceReusablePropsConditionally('spacing', props.spacingIds),
        produceReusablePropsConditionally('layout', props.layoutIds)
      ]
    case 'button':
      return [
        ['comment', ...produceMeta(props)],
        produceReusablePropsConditionally('decoration', props.decorationIds),
        produceReusablePropsConditionally('typography', props.typographyIds),
        produceReusablePropsConditionally('palette', props.paletteIds),
        produceReusablePropsConditionally('fill', props.fillIds),
        produceReusablePropsConditionally('spacing', props.spacingIds)
      ]
    case 'text':
      return [
        ['comment', ...produceMeta(props)],
        produceReusablePropsConditionally('typography', props.typographyIds),
        produceReusablePropsConditionally('palette', props.paletteIds)
        //produceReusablePropsConditionally('spacing', props.spacingIds),
      ]
  }
}

interface Context {
  styles?: Style[]
  customStyles?: Record<Style['type'], Style>
}

const resolvers = {
  color(context: Context, ref: ColorReference | Color) {
    if (ref && 'red' in ref) {
      return ref
    }
    if (!ref) return
    const colorRef = <ColorReference>ref
    const styles: Style[] = context?.styles
    const props = <BasicColor>styles?.find((style) => style.type == 'color' && style.details.id == colorRef.id)?.props
    const color = props?.colors.find((colorDef) => colorDef.name == colorRef.name)?.color
    return ref.alpha == null ? color : { ...color, alpha: ref.alpha }
  },

  fontVariant(context: Context, ref: FontVariantReference | FontVariant): FontVariant {
    if (ref && 'familyName' in ref) {
      return ref
    }
    if (!ref) return
    const fontId = (<FontVariantReference>ref).id
    const font = context?.styles?.find(
      (style) => style.type == 'font' && style.details.id == fontId
    ) as StyleFor<'font'>
    if (font) {
      var variant = font?.props.variants?.find((variant) => variant.name == ref.name)
    }

    return {
      ...variant,
      familyName: font?.props.familyName
    }
  },

  graphic(context: Context, ref: GraphicReference | URL) {
    if (typeof ref == 'string') {
      return ref
    }
    return context && context['...']
  },

  breakpoint(context: Context, ref: BreakpointReference) {
    if (!ref) return
    const styles: Style[] = context?.styles
    const props = <SceneBreakpointProps>(
      styles?.find((style) => style.type == 'breakpoint' && style.details.id == ref)?.props
    )
    return props?.query || ref
  },

  props(context: Context, type: string, ids?: RuleChoice[]) {
    const custom = context?.customStyles && context.customStyles[type]
    //if (custom?.props) {
    //  return custom.props
    //}
    const styles = [].concat(custom || [], context?.styles || [])
    var filtered = !ids ? styles.filter((s) => s.type == type) : ids.map((id) => styles.find((s) => s.details.id == id))

    return ((filtered[0]?.details.isInternal && filtered[1]) || filtered[0])?.props || StyleProps(type)
  }
}

function resolve(type, ...args) {
  return (context: Context) => resolvers[type](context, ...args)
}

const fallbackColor = 'rgba(0, 0, 0, 0)'

// typography spacing takes precedence over row gap
var marginTop = 'var(---typography--paragraph-spacing, var(---self--row-gap, 0px))'

export const produceReusableDecorationProps = (props: ReusableDecoration) => [
  [
    'property',
    'box-shadow',
    props.boxShadowInset ? 'inset' : null,
    ['length', props.boxShadowOffsetX],
    ['length', props.boxShadowOffsetY],
    ['length', props.boxShadowBlurRadius],
    ['length', props.boxShadowSpreadRadius],
    ['or', ['color', resolve('color', props.boxShadowColor)], fallbackColor]
  ],
  [
    'property',
    'border-radius',
    ['length', props.borderTopLeftRadius],
    ['length', props.borderTopRightRadius],
    ['length', props.borderBottomRightRadius],
    ['length', props.borderBottomLeftRadius]
  ],
  [
    'property',
    'border-width',
    ['length', props.borderTopWidth],
    ['length', props.borderRightWidth],
    ['length', props.borderBottomWidth],
    ['length', props.borderLeftWidth]
  ],
  ['property', 'border-style', ['keyword', props.borderStyle, 'none']],
  ['property', 'border-color', ['or', ['color', resolve('color', props.borderColor)], fallbackColor]]
]

export const produceReusableTypographyProps = (props: ReusableTypography) => [
  ['property', 'margin-top', marginTop],
  ...produceFontVariantProps(props.fontVariant),
  ...produceFontSetting(props.base),
  ...Object.keys(props.overrides).map((key) => {
    return ['media', resolve('breakpoint', key), ...produceFontSetting(props.overrides[key], true)]
  })
]

export const produceFontVariantProps = (props: FontVariant | FontVariantReference) => [
  ['fontVariant', resolve('fontVariant', props)]
]

export const produceSceneSpacingProps = (props: SceneSpacing) => [
  ['property', 'padding-top', ['length', props.paddingTop]],
  ['property', 'padding-right', ['length', props.paddingRight]],
  ['property', 'padding-bottom', ['length', props.paddingBottom]],
  ['property', 'padding-left', ['length', props.paddingLeft]],
  [
    'rule',
    '> *',
    ['property', '---spacing--row-gap', ['length', props.rowGap]],
    ['property', '---spacing--column-gap', ['length', props.columnGap]]
  ]
]

export const produceReusablePaletteProps = (props: ReusablePalette) => [
  ['property', 'color', ['or', ['color', resolve('color', props.textColor)], 'inherit']],
  [
    'rule',
    ['&::selection', '*::selection'],
    [
      'if',
      resolve('color', props.textHighlightColor),
      ['property', 'background', ['or', ['color', resolve('color', props.textHighlightColor)], 'inherit']]
    ]
  ],
  [
    'rule',
    ['a.-link', '&.-link', 'a', '.-link &'],
    ['property', 'color', ['or', ['color', resolve('color', props.linkColor)], 'inherit']],
    ['property', 'text-decoration', props.linkDecoration || 'underline'],
    [
      'rule',
      '&:hover',
      [
        'property',
        'color',
        ['or', ['color', resolve('color', props.linkHoverColor)], resolve('color', props.linkColor), 'inherit']
      ],
      ['property', 'text-decoration', props.linkHoverDecoration || 'underline']
    ]
  ]
]

export const produceFontSetting = (props: ReusableTypographySize, dontOverwrite?: boolean) => [
  ['property', 'font-size', ['length', props.fontSize, dontOverwrite ? null : 'inherit']],
  ['property', 'line-height', ['length', props.lineHeight, dontOverwrite ? null : 'inherit']],
  ['property', 'letter-spacing', ['length', props.letterSpacing, dontOverwrite ? null : 'inherit']],
  [
    'rule',
    ':not(div):not(a) + &',
    ['property', '---typography--paragraph-spacing', ['length', props.paragraphSpacing, null]]
  ]
]

export const produceBasicFontProps = (props: BasicFont) => {
  if (props.platform == 'google' || !props.platform) {
    return [
      [
        'import',
        'https://fonts.googleapis.com/css?family=' +
          encodeURIComponent(props.familyName) +
          (props.variants?.length ? ':' + props.variants.map((v) => v.name).join(',') : '')
      ]
    ]
  }
  return []
}

export const produceSceneDimensionsProps = (props: SceneDimensions) => [
  ['property', 'min-width', ['length', props.minWidth, null]],
  ['property', 'max-width', ['length', props.maxWidth, null]],
  ['property', 'min-height', ['length', props.minHeight, null]],
  ['property', 'max-height', ['length', props.maxHeight, null]]
]
export const produceSceneBreakpointProps = (props: SceneBreakpointProps) => [['media', props.query, [['comment', ' ']]]]

export const produceSceneLayoutProps = (props: SceneLayout) => [
  ['property', 'display', 'flex'],
  ['property', 'flex-wrap', props.columnCount > 1 && props.flexWrap ? 'wrap' : 'nowrap'],
  ['property', 'flex-direction', props.columnCount > 1 ? 'row' : 'column'],
  // using stretch + flex-wrap + gaps wraps columns prematurely.
  // use space-between value between to emulate
  [
    'property',
    'justify-content',
    props.columnCount > 1 && (!props.justifyContent || props.justifyContent == SceneLayoutJustifyContent.stretch)
      ? 'space-between'
      : props.justifyContent || 'stretch'
  ],
  ['property', 'align-items', props.alignItems || 'flex-start'],
  [
    'rule',
    '> *',
    props.columnCount > 1 && [
      'property',
      '---layout--available-width',
      `calc(100% - var(---spacing--column-gap, 0px) * ${props.columnCount - 1})`
    ],
    props.columnCount > 1 && ['property', '---typography--paragraph-spacing', 'initial !important'],
    ['property', 'margin-top', marginTop],
    props.columnCount > 1 && ['property', 'margin-left', 'var(---self--column-gap, 0px)'],
    props.columnCount > 1 &&
      props.justifyContent != SceneLayoutJustifyContent.stretch && [
        'property',
        'max-width',
        'var(---self--column-width)'
      ],
    props.columnCount > 1 && ['property', 'flex-basis', 'var(---self--column-width)'],
    props.columnCount > 1 && props.justifyContent == SceneLayoutJustifyContent.stretch && ['property', 'flex-grow', 1]
  ],
  // reset row gap for cases when element redefines columns count
  ['rule', `> :nth-child(-n+${props.columnCount || 1})`, ['property', '---self--row-gap', 'initial']],
  [
    'rule',
    `> :not(:nth-child(-n+${props.columnCount || 1}))`,
    ['property', '---self--row-gap', 'var(---spacing--row-gap, 0px)']
  ],
  // reset column gap for cases when element redefines columns count
  ['rule', `> :nth-child(${props.columnCount || 1}n + 1))`, ['property', '---self--column-gap', 'initial']],
  [
    'rule',
    `> :not(:nth-child(${props.columnCount || 1}n + 1))`,
    ['property', '---self--column-gap', 'var(---spacing--column-gap, 0px)']
  ],
  ...(props.weights || []).map((weight, index) => {
    const sum = props.weights.reduce((s, v) => s + v, 0)
    return [
      'rule',
      `> :nth-child(${props.columnCount}n + ${index + 1})`,
      ['property', '---self--column-width', `calc(var(---layout--available-width, 100%) / ${sum} * ${weight})`]
    ]
  })
]

// first background is pattern
// second is gradient
// third is color
export const produceReusableFillProps = (props: ReusableFill) => [
  [
    'property',
    'background',
    [
      'or',
      [
        'comma-separated',
        [
          [
            'if',
            resolve('graphic', props.backgroundImage),
            ['image', resolve('graphic', props.backgroundImage)],
            props.backgroundRepeat,
            ['length', props.backgroundPositionX],
            ['length', props.backgroundPositionY],
            ['if', props.backgroundSize, '/', ['keyword', props.backgroundSize, 'auto']]
          ]
        ],
        [
          [
            'if',
            resolve('color', props.backgroundGradient?.stops[0]?.color),
            [
              'gradient',
              props.backgroundGradient?.angle,
              ...(props.backgroundGradient?.stops || []).map((stop) => [
                ['color', resolve('color', stop.color)],
                ['length', stop.start, null],
                ['length', stop.end, null]
              ])
            ]
          ]
        ],
        [['if', resolve('color', props.backgroundColor), ['color', resolve('color', props.backgroundColor)]]]
      ],
      'none'
    ]
  ],
  [
    'property',
    'backdrop-filter',
    ['non-empty', ['space-separated', props.blur.value > 0 ? `blur(${stringifyLength(props.blur)})` : null], 'none']
  ],
  ['property', 'opacity', props.opacity]
]

export function produceRuleDetails(type: string, { id, title, description, exampleContent, instanceId }: RuleDetails) {
  return produceMeta({ type, id, title, description, exampleContent, instanceId })
}

export function produceBasicColorProps({ colors }: BasicColor) {
  return (colors || []).map((color) => {
    return ['property', `---color--${toIdentifier(color.name)}`, ['color', resolve('color', color.color)]]
  })
}

const joinTokens = (children) => {
  if (!Array.isArray(children)) return String(children)
  const filtered = children.flat().filter((v) => v != null)
  if (!filtered.length) return null
  return filtered.join(' ')
}

export function stringifyLength(length: UnformattedLength, defaultValue = '0px') {
  if (length && typeof length == 'object') {
    // Skip NaNs
    if (parseInt(String(length.value)) !== parseInt(String(length.value))) {
      return defaultValue
    }
    if (length.unit?.match(/%|em|px|em|rem/)) return length.value + length.unit
    return length.value + 'px'
  }
  if (typeof length == 'string' && length.match(/%|em|px|em|rem/)) {
    return length
  }
  if (length == null) {
    return defaultValue
  }
  return length + 'px'
}

const commands = {
  import: (src: string) => {
    return [`@import url("${src}");`]
  },
  length: (length: UnformattedLength, defaultValue = '0px') => {
    return [stringifyLength(length, defaultValue)]
  },
  or: (...args) => {
    return args.find((arg) => arg != null)
  },
  'non-empty': (...args) => {
    return args.find((arg) => arg != null && arg != '')
  },
  keyword: (value, defaultValue) => {
    if (value == null) {
      return defaultValue
    }
    return [value]
  },
  comment: (...children) => {
    if (!children.length) return []
    return ['/*', children, '*/']
  },
  breakpoint: (name) => {
    return name
  },
  property: (name, ...values) => {
    const value = joinTokens(values)
    if (!value) return []
    return [`${name}: ${value};`]
  },
  fontVariant: (variant: FontVariant) => {
    return [
      `font-family: ${variant?.familyName ? JSON.stringify(variant.familyName) : 'inherit'};`,
      `font-weight: ${variant?.weight || 'inherit'};`,
      `font-style: ${variant?.style || 'inherit'};`
    ]
  },
  color: (color: Color) => {
    if (color == null) return []
    const { red = 0, green = 0, blue = 0, alpha = 0 } = color
    return [`rgba(${red}, ${green}, ${blue}, ${alpha})`]
  },
  rule: (selector, ...children) => {
    if (!selector) return children
    if (!children.length) return []
    const selectors = [].concat(selector).flat().filter(Boolean)
    if (!selectors.length) return
    // `div + :wrapper(#a) h1` =>  `#a div + h1`
    const selectorString = selectors
      .map((s) => {
        var wrapper
        var cleaned = s.replace(/:wrapper\(([^\)]+)\)\s*/g, (m, w) => {
          wrapper = w
          return ''
        })
        if (wrapper) {
          return wrapper + ' ' + cleaned
        } else {
          return s
        }
      })
      .join(', ')
    return [`${selectorString} {`, children, `}`]
  },
  media: (query, ...children) => {
    if (!children.length) return []
    const conditions = [].concat(query).filter(Boolean)
    if (!conditions.length) return children
    if (!children.length) return []
    return [`@media ${conditions.map((c) => `(${c})`).join(' and ')} {`, children, `}`]
  },
  if: (condition, ...children) => {
    if (condition) {
      return children
    }
    return []
  },
  unless: (condition, ...children) => {
    if (!condition) {
      return children
    }
    return []
  },
  'comma-separated': (...children) => {
    const values = children.filter((child) =>
      child && Array.isArray(child) && child.length == 0 ? false : child != null
    )
    if (values.length > 0) {
      return values.map(joinTokens).join(', ')
    }
  },
  'space-separated': (...children) => {
    const values = children.filter((child) =>
      child && Array.isArray(child) && child.length == 0 ? false : child != null
    )
    if (values.length > 0) {
      return values.map(joinTokens).join(' ')
    }
  },
  gradient: (angle: Gradient['angle'], ...stops) => {
    if (angle != null) {
      return [`linear-gradient(${angle}deg, ${stops.map((stop) => joinTokens(stop)).join(', ')})`]
    }
  },
  image: (url) => {
    if (url) {
      return [`url("${encodeURI(url.replace(/"/g, '"'))}")`]
    }
  }
}

export function isBlock(ast) {
  return ast && (ast[0] == 'rule' || ast[0] == 'media') && ast.length > 2
}

export function unnest(ast: any, isInside?: boolean) {
  if (ast && Array.isArray(ast)) {
    if (isBlock(ast)) return unnestBlock(ast)
    if (isInside) {
      return [ast]
    }
    return ast.map((c) => unnest(c, true)).flat()
  } else {
    return [ast]
  }
}

export function unnestBlock(ast) {
  const [type, value, ...children] = ast
  const regularChildren = children.filter((child) => !isBlock(child))
  const blockChildren = children
    .filter((child) => isBlock(child))
    .map(unnest)
    .flat()
  if (blockChildren.length) {
    // skip single rules inside media query
    if (
      !(
        type == 'media' &&
        children.length == 1 &&
        blockChildren.length == 1 &&
        blockChildren[0][0] == 'rule' &&
        !blockChildren[0].slice(2).some((gc) => isBlock(gc))
      )
    ) {
      var extras = []

      blockChildren.map((child) => {
        // combine media queries
        if (child && child[0] == 'media' && type == 'media') {
          extras.push(...unnestBlock(['media', [value, child[1]].flat(), ...child.slice(2)]))
        }
        // combine selectors of parent & nested rule
        if (child && child[0] == 'rule' && type == 'rule') {
          extras.push(...unnestBlock(['rule', combineSelectors(value, child[1]), ...child.slice(2)]))
        }
        // invert query to be on top
        // may cause unnesting parent rule with children rule of a media query
        if (child && child[0] == 'media' && type == 'rule') {
          extras.push(...unnestBlock(['media', child[1], ...unnestBlock(['rule', value, ...child.slice(2)])]))
        }
        // retain rule inside media query
        if (child && child[0] == 'rule' && type == 'media') {
          extras.push(...unnestBlock(['media', value, child]))
        }
      })

      if (extras.length) {
        if (regularChildren.length == 0) {
          return extras
        }
        return [[type, value, ...regularChildren], ...extras]
      }
    }
  }
  return [ast]
}

// invoke all functions with context as argument
// values returned from functions would be injected back into AST
// functions returning arrays splice in the values
export function reify(ast: any, context?: any) {
  if (ast && Array.isArray(ast)) {
    return ast
      .map((child) => {
        if (typeof child == 'function') {
          return reify([].concat(child(context)), context)
        } else {
          return [reify(child, context)]
        }
      })
      .flat()
  } else {
    return ast
  }
}

// walk the AST and process all commands into their results
// the result is arrays with string lines, with sub-arrays for indentation
export function process(ast: any, isInside?: boolean) {
  if (!ast || !Array.isArray(ast)) {
    return [ast]
  }
  const command = commands[ast[0]]
  const args = (command ? ast.slice(1) : ast).map((c) => process(c, true)).flat()
  const result = command ? command(...args) : [args]
  return isInside ? result : result.flat()
}

// merge adjacent media queries with identical
export function simplify(ast: any) {
  return ast.reduce((next, child) => {
    const prev = next[next.length - 1]
    if (child[0] == 'media' && prev && prev[0] == 'media' && prev[1].toString() == child[1].toString()) {
      next[next.length - 1] = [child[0], child[1], ...prev.slice(2), ...child.slice(2)]
    } else {
      next.push(child)
    }
    return next
  }, [])
}

// resolve variables, process the ast, and serialize it
export function stringify(ast: any, context?: Context) {
  return concat(process(simplify(unnest(reify(ast, context)))), '')
}

// stringify the lines, processing sub-arrays to add indentation
export function concat(lines, identation = '') {
  return lines
    .filter(Boolean)
    .map((line) => {
      if (Array.isArray(line)) {
        return concat(line, identation + '  ')
      } else {
        return identation + line
      }
    })
    .join('\n')
}

const prelude = `.-feaas * {
    /* Important: Disable variables from cascading */
    ---layout--available-width: initial;
    ---layout--columns: initial;
    ---typography--paragraph-spacing: initial;
    ---spacing--column-gap: initial;
    ---spacing--row-gap: initial;
    ---self--column-width: initial;
    ---self--column-gap: initial;
    ---self--row-gap: initial;
    box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.-feaas button,
.-feaas .-button {
    display: table;
    width: max-content;
}

.-feaas .-embed,
.-feaas .-component {
  width: 100%;
}
.-feaas figure {
  max-width: 100%;
  display: table;
}
.-feaas figure img {
  max-width: 100%;
  min-width: 100%;
}
.-feaas a {
  text-decoration: none;
}
`
export function toText(styles: Style[]) {
  return (
    styles
      .map((style) =>
        stringify(produceStyle(style), {
          styles
        })
      )
      .join('\n\n') +
    '\n\n' +
    prelude
  )
}
