export function normalizeNumber(input: any, defaultValue = 1) {
  const number = parseInt(input)
  if (Number.isNaN(number)) {
    return defaultValue
  } else {
    return number
  }
}
export function normalizeFloat(input: any, defaultValue = 1) {
  const number = parseFloat(input)
  if (Number.isNaN(number)) {
    return defaultValue
  } else {
    return number
  }
}
