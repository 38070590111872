import { css } from '@emotion/react'
import { Button, EditIcon, Menu, MenuButton, MenuDivider, MenuItemOption, MenuList } from '@sitecore-ui/design-system'
import { getElementActiveStyles, getElementEffectiveStyles, getRootCustomStyles } from 'hooks/useEditorStyles.js'
import { useEditorValue } from 'hooks/useEditorValue.js'
import { useApiData } from 'hooks/useApiData.js'
import { EditorContextProps } from './EditorChrome.js'

const MenuButtonStyle = css`
  background: #fff;
  padding: 0;
  border: 1px solid #94949d;
  color: rgba(0, 0, 0, 0.68);
  height: 30px;
  width: 30px;

  svg {
    height: 18px;
    width: 18px;
    margin-top: -1px;
  }
`

export default function EditorChromeBlockToolbar({
  editor,
  block,
  onCommand,
  onPopoverClose,
  onPopoverOpen
}: EditorContextProps) {
  const blockQuote = useEditorValue(editor.commands.get('blockQuote'), 'value')
  const styles = useApiData('library.stylesheet.styles')

  const blockType = editor.commands.get('numberedList').value
    ? 'numberedList'
    : editor.commands.get('bulletedList').value
    ? 'bulletedList'
    : block?.name

  const onBlockCommand = (name, ...args) => {
    editor.model.change((writer) => {
      onCommand(name, ...args)
      const [customStyles, setElementStyle] = getRootCustomStyles(editor, block, styles)
      const effectiveStyles = getElementEffectiveStyles(block, customStyles, styles)
      const activeStyles = getElementActiveStyles(block, effectiveStyles)
      activeStyles.forEach((activeStyle) => {
        setElementStyle(block, { type: activeStyle.type }, writer.batch)
      })
    })
  }
  return (
    <Menu autoSelect={true} flip={false} onOpen={() => onPopoverOpen('block')} onClose={() => onPopoverClose('block')}>
      <MenuButton as={Button} css={MenuButtonStyle} aria-label='Block type menu' variant={'minimal'} minWidth={0}>
        <EditIcon />
      </MenuButton>
      <MenuList zIndex={10}>
        <MenuItemOption isChecked={blockType == 'paragraph'} onClick={() => onBlockCommand('paragraph')}>
          Paragraph
        </MenuItemOption>
        {[1, 2, 3, 4, 5, 6].map(
          (level) =>
            styles.find((s) => s.type == 'text' && s.props.tag == 'h' + level) && (
              <MenuItemOption
                key={level}
                isChecked={blockType == 'heading' + level}
                onClick={() => onBlockCommand('heading', { value: 'heading' + level })}
              >
                Heading {level}
              </MenuItemOption>
            )
        )}
        <MenuItemOption isChecked={blockType == 'numberedList'} onClick={() => onBlockCommand('numberedList')}>
          Numbered list
        </MenuItemOption>
        <MenuItemOption isChecked={blockType == 'bulletedList'} onClick={() => onBlockCommand('bulletedList')}>
          Bulleted list
        </MenuItemOption>
        <MenuDivider borderColor='' />
        <MenuItemOption value='blockquote' isChecked={blockQuote} onClick={() => onBlockCommand('blockQuote')}>
          Blockquote
        </MenuItemOption>
      </MenuList>
    </Menu>
  )
}
