import { API } from '../api.js'
import APIAdapter from '../core/adapter.js'
import { APIModel, Atleast, RequiredKeys } from '../core/model.js'
import { nanoid } from '../core/transport.js'
import type { Library, LibraryModel } from './libraries.js'

export interface Stylesheet {
  id: string
  libraryId: Library['id']
  source: any
  //source: any[]
  css: string
  modifiedAt: Date
  createdAt: Date
}

export function getStylesheetDefaults(api: API) {
  return {
    id: nanoid(10),
    modifiedAt: new Date(),
    createdAt: new Date()
  }
}

export class Stylesheets extends APIAdapter<Stylesheet> {
  get(stylesheet: Pick<StylesheetParams, 'libraryId'>): Promise<Stylesheet> {
    const { libraryId } = stylesheet
    return this.api.fetch(`/libraries/${libraryId}/stylesheets`)
  }
  post(stylesheet: Atleast<StylesheetParams, 'libraryId'>) {
    return this.put(stylesheet)
  }
  put(stylesheet: Atleast<StylesheetParams, 'libraryId'>): Promise<Stylesheet> {
    const { libraryId } = stylesheet
    return this.api.fetch(`/libraries/${libraryId}/stylesheets`, {
      method: 'PUT',
      body: JSON.stringify({
        ...stylesheet
      })
    })
  }
}

export interface StylesheetImplicit {
  libraryId: Library['id']
  library?: LibraryModel
}
export type StylesheetMinimal<T extends keyof Stylesheet = never> = RequiredKeys<
  Stylesheet,
  typeof getStylesheetDefaults,
  T
>
export interface StylesheetParams extends Stylesheet, StylesheetImplicit {}
export interface StylesheetModel extends StylesheetParams {}
export class StylesheetModel
  extends APIModel<Stylesheet, StylesheetMinimal, StylesheetImplicit>
  implements StylesheetParams
{
  get adapter() {
    return this.api.Stylesheets
  }
  getHiddenProps(): string[] {
    return ['library']
  }
  getDefaults(api: API) {
    return getStylesheetDefaults(api)
  }
}
