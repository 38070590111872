import { css } from '@chakra-ui/styled-system'
import copy from 'copy-to-clipboard'

import {
  Badge,
  Box,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  HStack,
  Icon,
  IconButton,
  InfoOutlineIcon,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack
} from '@sitecore-ui/design-system'
import EditLink from 'components/EditLink.js'
import type { VariantModel, VariantSnapshot } from '@sitecore-feaas/api'
import { useContext, useEffect, useRef, useState } from 'react'

import { mdiCodeBraces, mdiDownload, mdiSync, mdiUndoVariant, mdiUpload, mdiClipboardOutline } from '@mdi/js'
import { default as useResizeObserver } from '@react-hook/resize-observer'
import { Button } from '@sitecore-ui/design-system'
import { useApiData } from 'hooks/useApiData.js'
import type { Editor } from 'hooks/useEditor.js'
import { ConfirmationContext } from 'providers/ConfirmationProvider.js'
import EditorCanvasVariantEmbed from './EditorCanvasVariantEmbed.js'

const VariantStyle = css`
  z-index: inherit;

  &.focused .content {
    outline: none;
    transition: 0.4s box-shadow;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.08);
  }
  &.narrow .top {
    max-width: 768px;
    width: 768px;
  }
  &.collapsed .top {
    position: relative;
    padding-bottom: 14px;
    flex-wrap: wrap;
  }
  &.collapsed .name {
    flex-basis: 100%;
    min-width: 50%;
  }
  &.collapsed .menu {
    flex-basis: 150px;
  }
  &.collapsed .status {
    margin: 0 auto;
    justify-content: center;
    margin-top: 12px;
    order: 2;
  }
`

const MenuButtonStyle = css`
  z-index: inherit;
  svg {
    transition: transform 0.2s !important;
  }
  &[data-active] svg {
    transform: rotateZ(-180deg);
  }
`

function StatusBadge({
  snapshot,
  status,
  label
}: {
  snapshot: VariantSnapshot
  status: 'draft' | 'saved' | 'published' | 'staged'
  label: string
}) {
  const current = snapshot[status]
  const head = snapshot.head
  if (current == null) {
    return (
      <Badge variant='solid' bg='blackAlpha.100' color='blackAlpha.800'>
        Not {label}
      </Badge>
    )
  } else {
    if (current.deletedAt) {
      return (
        <Badge variant='solid' bg='red.100' color='red.800'>
          {status == 'draft' || status == 'saved' ? 'Deleted' : 'Un' + label.toLowerCase()}
        </Badge>
      )
    } else {
      const isBehind = head && head.version > current.version
      if (isBehind && status != 'draft' && status != 'saved') {
        return (
          <Badge variant='solid' bg='orange.100' color='orange.800'>
            old: {label}
          </Badge>
        )
      } else {
        return (
          <Badge variant='solid' bg='teal.100' color='teal.800'>
            {label}
          </Badge>
        )
      }
    }
  }
}

declare type Props = {
  variant: VariantModel
  editor: Editor
  snapshot: VariantSnapshot
  focusVariant: (refId?: string, deferToNextUpdate?: boolean) => void
}

export default function Variant({ variant, editor, snapshot, focusVariant }: Props) {
  const { id, refId, name, view, status, deletedAt, createdAt, componentId, version } = variant
  const refContent = useRef<HTMLElement>(null)
  const refName = useRef<HTMLElement>(null)
  const refStatuses = useRef<HTMLElement>(null)
  const refBoxes = useRef({
    name: null,
    content: null,
    statuses: null
  })
  const { setConfirm } = useContext(ConfirmationContext)
  const apiEditor = useApiData('editor')

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isNarrow, setIsNarrow] = useState(false)

  function recomputeState() {
    setIsNarrow(refBoxes.current.content?.width <= 768)
    const space = (Math.max(768, refBoxes.current.content?.width) - refBoxes.current.statuses?.width - 20) / 2
    setIsCollapsed(refBoxes.current.name?.width >= space)
  }
  useResizeObserver(refContent, (entry) => {
    refBoxes.current.content = entry.contentRect
    recomputeState()
  })
  useResizeObserver(refName, (entry) => {
    refBoxes.current.name = entry.contentRect
    recomputeState()
  })
  useResizeObserver(refStatuses, (entry) => {
    refBoxes.current.statuses = entry.contentRect
    recomputeState()
  })

  function saveVariantData() {
    return variant.saveData({
      view: editor.getData({ rootName: refId }),
      model: ''
    })
  }
  function commitVariantData() {
    return variant.commitData({
      view: editor.getData({ rootName: refId }),
      model: ''
    })
  }

  const needsWrite = useRef(true)
  useEffect(() => {
    needsWrite.current = true
  }, [editor])
  useEffect(() => {
    if (editor && needsWrite.current) {
      needsWrite.current = false
      editor.model.enqueueChange({ isUndoable: false }, () => {
        editor['suppressedChanges'] = true
        if (!editor.model.document.getRoot(refId)) editor.addRoot(refId, refContent.current)
        editor.setRootContent(refId, view)
        editor['suppressedChanges'] = false
      })
    }
  }, [editor, view, needsWrite.current])

  const onRename = (name: string) => {
    variant.rename(name)
  }

  const lastPushed = snapshot.published?.version > snapshot.staged?.version ? snapshot.published : snapshot.staged
  const needsStaging = !snapshot.staged || version > snapshot.staged.version || !!snapshot.staged.deletedAt
  const needsPublishing = !snapshot.published || version > snapshot.published.version || !!snapshot.published.deletedAt
  const isDeleted = !!(snapshot.saved || snapshot.draft)?.deletedAt
  return (
    <VStack
      key={refId}
      direction='column'
      align={'center'}
      css={VariantStyle}
      width='100%'
      data-variant-id={refId}
      className={`variant ${isCollapsed && 'collapsed'} ${isNarrow && 'narrow'} ${
        apiEditor.isFocused && apiEditor.focusedEditableName == refId ? 'focused' : ''
      }`}
    >
      <HStack
        width={'100%'}
        className='top'
        maxWidth={`${Math.max(768, refBoxes.current.content?.width)}px`}
        minWidth='768px'
      >
        <HStack
          className='ui name'
          minWidth={0}
          flexBasis='50%'
          flexGrow={1}
          maxWidth={`calc(${Math.max(768, refBoxes.current.content?.width)}px - 200px)`}
        >
          <Popover placement='bottom-start' isLazy={true}>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  {/*@ts-ignore*/}
                  <Box display='inline-flex' {...{ ref: refName }} maxWidth='100%'>
                    <Text as='span' color='blackAlpha.500'>
                      Variant:&nbsp;
                    </Text>
                    <EditLink href='#'>
                      <Text as='span' color='blackAlpha.800' fontWeight={600}>
                        {name}
                      </Text>
                    </EditLink>
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <EditorCanvasVariantName name={name} onClose={onClose} onChange={onRename} />
                </PopoverContent>
              </>
            )}
          </Popover>
        </HStack>
        <HStack
          {...{ ref: refStatuses }}
          className='status'
          alignContent='center'
          divider={<ChevronRightIcon color='blackAlpha.200' border='0' />}
        >
          {snapshot.draft && <StatusBadge snapshot={snapshot} status='draft' label='Draft' />}
          {!snapshot.draft && <StatusBadge snapshot={snapshot} status='saved' label='Draft' />}
          <StatusBadge snapshot={snapshot} status='staged' label='Staged' />
          <StatusBadge snapshot={snapshot} status='published' label='Published' />
        </HStack>
        <HStack justifyContent='flex-end' flexBasis='50%' spacing={0} className='menu' height='32px' flexGrow={1}>
          {!isDeleted && (
            <IconButton
              color={'gray.500'}
              aria-label='Duplicate variant'
              icon={<CopyIcon w={6} h={6} />}
              onClick={() => {
                focusVariant(undefined, true)
                commitVariantData().duplicate()
              }}
            />
          )}
          {!isDeleted && needsStaging && (
            <Button
              onClick={() => saveVariantData().stage()}
              variant='primary'
              size='sm'
              leftIcon={<Icon path={snapshot.staged ? mdiSync : mdiUpload} height={3} width={3} />}
            >
              {snapshot.staged ? 'Restage' : 'Stage'}
            </Button>
          )}
          {!isDeleted && !needsStaging && needsPublishing && (
            <Button
              onClick={() => {
                setConfirm({
                  action: () => {
                    saveVariantData().stage().publish()
                  },
                  title: 'Publishing new version of variant',
                  button: snapshot.published ? 'Republish' : 'Publish',
                  body: 'Users of the website will now see this version of the variant. Are you sure?'
                })
              }}
              variant='primary'
              size='sm'
              leftIcon={<Icon path={snapshot.published ? mdiSync : mdiUpload} height={3} width={3} />}
            >
              {snapshot.published ? 'Republish' : 'Publish'}
            </Button>
          )}
          {!isDeleted && !needsStaging && !needsPublishing && !isDeleted && (
            <Button size='sm' leftIcon={<CheckIcon />} isDisabled={true} variant='primary'>
              Up to date
            </Button>
          )}
          {isDeleted && (
            <Button
              size='sm'
              onClick={() => variant.restore()}
              leftIcon={<Icon path={mdiUndoVariant} />}
              variant='primary'
            >
              {lastPushed ? 'Unarchive' : 'Undelete'}
            </Button>
          )}

          <Menu placement='bottom-end' isLazy={true} lazyBehavior='unmount'>
            {({ isOpen }) => (
              <>
                <MenuButton
                  css={MenuButtonStyle}
                  color='gray.500'
                  size='sm'
                  height={8}
                  width={8}
                  isActive={isOpen}
                  as={IconButton}
                  icon={<ChevronDownIcon h={6} w={6} />}
                />
                <MenuList color='gray.600' zIndex='30'>
                  {isDeleted && (
                    <MenuItem
                      onClick={() => variant.restore()}
                      icon={<Icon path={mdiUndoVariant} height='14px' width='14px' mt='-2px' />}
                    >
                      {lastPushed ? 'Unarchive' : 'Undelete'}
                    </MenuItem>
                  )}
                  {!isDeleted && (
                    <MenuItem
                      onClick={() => {
                        needsWrite.current = true
                        focusVariant(refId, true)
                        variant.revert()
                      }}
                      isDisabled={lastPushed?.version == version}
                      icon={<CloseIcon height='14px' width='14px' mt='-2px' />}
                    >
                      {!lastPushed && 'Clear design'}
                      {lastPushed?.status == 'staged' && 'Revert to staged version'}
                      {lastPushed?.status == 'published' && 'Revert to published version'}
                    </MenuItem>
                  )}
                  <MenuDivider />
                  <MenuItem
                    onClick={() => saveVariantData().stage()}
                    isDisabled={!needsStaging}
                    icon={<Icon path={snapshot.staged ? mdiSync : mdiUpload} height='14px' width='14px' mt='-2px' />}
                  >
                    {snapshot.staged ? 'Restage' : 'Stage'}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      const publish = () => {
                        saveVariantData().stage().publish()
                      }
                      if (snapshot.published) {
                        setConfirm({
                          action: publish,
                          title: 'Publishing new version of variant',
                          button: snapshot.published ? 'Republish' : 'Publish',
                          body: 'Users of the website will now see this version of the variant. Are you sure?'
                        })
                      } else {
                        publish()
                      }
                    }}
                    isDisabled={!needsPublishing}
                    icon={<Icon path={snapshot.published ? mdiSync : mdiUpload} height='14px' width='14px' mt='-2px' />}
                  >
                    {snapshot.published ? 'Republish' : 'Publish'}
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    onClick={() =>
                      setConfirm({
                        action: () => {
                          variant.unstage()
                        },
                        title: 'Unstaging variant',
                        button: 'Unstage',
                        body: 'This makes it impossible to embed this variant in new places in Page Builder. Are you sure?'
                      })
                    }
                    isDisabled={!snapshot.staged || !!snapshot.staged?.deletedAt}
                    icon={<Icon path={mdiDownload} height='14px' width='14px' mt='-2px' />}
                  >
                    Unstage
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      setConfirm({
                        action: () => variant.unpublish(),
                        title: 'DANGER: Unpublishing variant',
                        button: 'Unpublish',
                        body: 'This variant will disappear from public-facing pages of the website. Are you sure?'
                      })
                    }
                    isDisabled={!snapshot.published || !!snapshot.published?.deletedAt}
                    icon={<Icon path={mdiDownload} height='14px' width='14px' mt='-2px' />}
                  >
                    Unpublish
                  </MenuItem>
                  {!isDeleted && (
                    <MenuItem
                      onClick={() => variant.archive()}
                      isDisabled={!!isDeleted}
                      icon={<DeleteIcon height='14px' width='14px' mt='-2px' />}
                    >
                      {lastPushed ? 'Archive' : 'Delete'}{' '}
                    </MenuItem>
                  )}
                  <MenuDivider />
                  <MenuItem
                    onClick={() => {
                      setConfirm({
                        type: 'modal',
                        title: 'Embedding the component',
                        body: <EditorCanvasVariantEmbed componentId={componentId} refId={refId} />,
                        button: (
                          <>
                            <Icon path={mdiClipboardOutline} fontSize={'24px'} mr='2' />
                            Copy code
                          </>
                        ),
                        action: () => {
                          copy(document.querySelector('#generated-code').textContent)
                        }
                      })
                    }}
                    icon={<Icon path={mdiCodeBraces} height='14px' width='14px' mt='-2px' />}
                  >
                    Get embedding code
                  </MenuItem>

                  <MenuDivider />
                  <MenuItem icon={<InfoOutlineIcon height='14px' width='14px' mt='-2px' />}>
                    <Text as='span'>Learn more about publishing</Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </HStack>
      </HStack>
      {false && (
        <Box whiteSpace={'nowrap'}>
          {snapshot.versions.length}
          {(Object.entries(snapshot) as [key: string, value: VariantModel][])
            .filter(([key]) => key != 'refId' && key != 'versions' && key != 'previous' && key != 'head')
            .sort((a, b) => a[1].sortCompare(b[1]))
            .map(([key, value]) => {
              if (key != 'refId' && key != 'versions')
                return (
                  <li key={value.status}>
                    {key}: {value.status}: {value?.deletedAt ? 'Deleted' : null} / {value?.version}
                  </li>
                )
            })}
        </Box>
      )}
      {
        <VStack
          background='white'
          boxShadow={'base'}
          borderWidth='1px'
          borderColor='gray.200'
          borderRadius={3}
          width='min-content'
          minW={320}
          p={0}
          hidden={!!isDeleted}
        >
          {/*@ts-ignore*/}
          <Box
            {...{ ref: refContent }}
            maxWidth='100%'
            width='100%'
            minH={100}
            placeholder={'Click and start typing'}
            id={refId}
            className={`content -feaas`}
          />
        </VStack>
      }
    </VStack>
  )
}

const EditorCanvasVariantName = ({
  name,
  onChange,
  onClose
}: {
  name: VariantModel['name']
  onChange: (name: string) => void
  onClose: () => void
}) => {
  const [currentName, setCurrentName] = useState(name)

  return (
    <VStack alignItems={'stretch'} p={4} spacing={4}>
      <HStack alignItems={'center'}>
        <EditIcon zIndex={1} fontSize='18px' />
        <Text fontWeight={600}>Rename variant</Text>
      </HStack>

      <InputGroup>
        <Input
          placeholder='Variant name'
          w='320px'
          value={currentName}
          onChange={(e) => setCurrentName(e.target.value)}
        />
        {name && (
          <InputRightElement>
            <CloseIcon cursor={'pointer'} onClick={() => setCurrentName('')} />
          </InputRightElement>
        )}
      </InputGroup>

      <HStack spacing={2} justifyContent='flex-end'>
        <Button variant='subtle' colorScheme={'purple'} onClick={onClose}>
          Discard
        </Button>
        <Button
          variant='primary'
          isDisabled={!currentName.trim()}
          onClick={() => {
            onChange(currentName)
            onClose()
          }}
        >
          Save
        </Button>
      </HStack>
    </VStack>
  )
}
