import { Keyword } from 'models/style/types/keyword.js'
import { normalizeNumber } from 'models/style/types/number.js'
import { Unformatted } from 'models/style/types/props.js'

export enum SceneLayoutJustifyContent {
  'stretch' = 'stretch',
  'flex-start' = 'flex-start',
  'center' = 'center',
  'space-between' = 'space-between',
  'space-evenly' = 'space-evenly',
  'flex-end' = 'flex-end'
}
export enum SceneLayoutAlignItems {
  'stretch' = 'stretch',
  'flex-start' = 'flex-start',
  'center' = 'center',
  'flex-end' = 'flex-end'
}

export const SceneLayoutColumns = (props: any = {}) => {
  const columnCount = normalizeNumber(props.columnCount)
  if (!props.weights || !Array.isArray(props.weights) || props.weights.length != props.columnCount) {
    var weights: number[] = new Array(columnCount).fill(1)
  } else {
    var weights: number[] = props.weights.map((weight) => {
      return normalizeNumber(weight, 1)
    })
  }
  return {
    flexWrap: !!props.flexWrap,
    columnCount,
    weights,
    justifyContent: Keyword<SceneLayoutJustifyContent>(props.justifyContent, SceneLayoutJustifyContent),
    alignItems: Keyword<SceneLayoutAlignItems>(props.alignItems, SceneLayoutAlignItems)
  }
}
export type SceneLayout = ReturnType<typeof SceneLayoutColumns>
export const SceneLayout = (props: Unformatted<SceneLayout> = {}) => SceneLayoutColumns(props)
export default SceneLayout
