/* Utility to cache-bust static files.

It handles multiple situations:

1. Initial request, valid response
2. Initial request, invalid response
2. Initial request, missing response
3. Cached request, valid response (1 extra head request)
4. Cached request, invalid response, valid refetch (2 fetches + 1 head)
5. Cached request, invalid response, invalid refetch (2 fetches + 1 head)

It works as promise, but also accepts callback to use cached blobs mid-flight and have access to headers.
Any network errors will not caught.
Function throws when unable to fetch valid response.

*/
export async function fetchAndRevalidate(
  url: Parameters<typeof fetch>[0],
  options: Parameters<typeof fetch>[1],
  validator: (resposne: Response) => boolean,
  callback: (response: Response, status: 'cached' | 'valid') => void
) {
  // make request as usual, see if there's cached version of an asset
  const date = new Date()
  const initialResponse = await fetch(url, options)
  if (!initialResponse.ok) throw new Error('Fetch failed')
  const responseDate = new Date(initialResponse.headers.get('date'))
  var response = initialResponse
  callback(initialResponse, 'cached')

  // see if response was pulled from cache
  if (responseDate < date) {
    // check if response was stale by sending quick HEAD reque st busting cache
    const freshHeadResponse = await fetch(url + '?' + Math.random(), {
      ...options,
      method: 'HEAD',
      cache: 'no-cache'
    })

    // there's a newer version of the file that we may fetch
    if (freshHeadResponse.headers.get('last-modified') != initialResponse.headers.get('last-modified')) {
      // check if re-fetching is even necessary
      if (validator(freshHeadResponse) === false) {
        //callback('stale', freshHeadResponse)
        throw new Error('Response is stale')
      }

      // otherwise the new resource has to be re-fetched
      response = await fetch(url, {
        ...options,
        cache: 'reload'
      })
    }
  }

  if (validator(response) === false) {
    throw new Error('Response is not valid')
  }
  if (initialResponse !== response) {
    callback(response, 'valid')
  }

  return response
}
