import { css } from '@emotion/react'
import { mdiArrowLeftRight } from '@mdi/js'
import { AddIcon, Box, Button, HStack, Icon, IconButton, MinusIcon, Text, VStack } from '@sitecore-ui/design-system'
import ButtonGroupSwitch from 'components/ButtonGroupSwitch.js'
import { reusable } from 'components/styles/settings.js'
import type { Editor, ModelElement } from 'hooks/useEditor.js'
import useEditorStyles, { getElementAspectStyle } from 'hooks/useEditorStyles.js'
import { useApiData } from 'hooks/useApiData.js'
import { StyleFor } from 'models/style/index.js'
import { stringifyLength } from 'models/stylesheet/css.js'
import { SceneLayoutAlignItems, SceneLayoutJustifyContent } from 'models/style/layout.js'
import { deepMerge } from 'utils/object.js'

interface Props {
  editor: Editor
  context: ModelElement
}

interface Alignment {
  property: string
  value: `${SceneLayoutAlignItems | SceneLayoutJustifyContent}`
  label: string
}

const horizontalAlignmentRow: Alignment[] = [
  { property: 'justifyContent', value: 'flex-start', label: 'Left' },
  { property: 'justifyContent', value: 'center', label: 'Center' },
  { property: 'justifyContent', value: 'space-between', label: 'Justify' },
  { property: 'justifyContent', value: 'space-evenly', label: 'Distribute' },
  { property: 'justifyContent', value: 'stretch', label: 'Stretch' },
  { property: 'justifyContent', value: 'flex-end', label: 'Right' }
]

const verticalAlignmentRow: Alignment[] = [
  { property: 'alignItems', value: 'flex-start', label: 'Top' },
  { property: 'alignItems', value: 'center', label: 'Center' },
  { property: 'alignItems', value: 'stretch', label: 'Stretch' },
  { property: 'alignItems', value: 'flex-end', label: 'Bottom' }
]

const horizontalAlignmentColumn: Alignment[] = [
  { property: 'alignItems', value: 'flex-start', label: 'Left' },
  { property: 'alignItems', value: 'center', label: 'Center' },
  { property: 'alignItems', value: 'stretch', label: 'Stretch' },
  { property: 'alignItems', value: 'flex-end', label: 'Right' }
]

const verticalAlignmentColumn: Alignment[] = [
  { property: 'justifyContent', value: 'flex-start', label: 'Top' },
  { property: 'justifyContent', value: 'center', label: 'Center' },
  { property: 'justifyContent', value: 'space-between', label: 'Justify' },
  { property: 'justifyContent', value: 'stretch', label: 'Stretch' },
  //{property: 'justifyContent', value: 'space-around', label: 'Distribute'},
  { property: 'justifyContent', value: 'flex-end', label: 'Bottom' }
]

function getDefaultOption(options: Alignment[]) {
  return (
    options.find((v) => v.value == 'stretch') ||
    options.find((v) => v.value == 'center') ||
    options.find((v) => v.value == 'space-between')
  )
}

const boxesCss = css`
  .top {
    position: absolute;
    top: 4px;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .bottom {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .box {
    position: relative;
    border-radius: 4px;
    flex-grow: 1;
    border: 1px dashed var(--chakra-colors-blackAlpha-800);
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .top {
      opacity: 1;
    }
  }
`

export default function EditorDialogLayout({ editor, context }: Props) {
  const styles = useApiData('library.stylesheet.styles')
  const [customStyles, setElementStyle] = useEditorStyles(editor, context)
  const style = getElementAspectStyle(context, reusable.layout, styles, customStyles) as StyleFor<'layout'>

  const verticalOptions = style.props.columnCount == 1 ? verticalAlignmentColumn : verticalAlignmentRow
  const horizontalOptions = style.props.columnCount == 1 ? horizontalAlignmentColumn : horizontalAlignmentRow

  const vertical = verticalOptions.find((v) => style.props[v.property] == v.value)
  const horizontal = horizontalOptions.find((h) => style.props[h.property] == h.value)

  function setLayoutProperty(property, value) {
    var props: Partial<typeof style.props> = {
      [property]: value
    }
    if (property == 'columnCount') {
      props.weights = new Array(value).fill(1)

      // swap horizontal/vertical
      if (value == 1 || style.props.columnCount == 1) {
        const nextVerticalOptions = value == 1 ? verticalAlignmentColumn : verticalAlignmentRow
        const nextHorizontalOptions = value == 1 ? horizontalAlignmentColumn : horizontalAlignmentRow

        const nextV =
          nextVerticalOptions.find((v) => v.value == vertical.value) || getDefaultOption(nextVerticalOptions)
        const nextH =
          nextHorizontalOptions.find((h) => h.value == horizontal.value) || getDefaultOption(nextHorizontalOptions)

        props[nextV.property] = nextV.value
        props[nextH.property] = nextH.value
      }
    }

    setElementStyle(
      context,
      deepMerge(style, {
        props: props
      })
    )
  }

  const weights = style.props.weights || [1]
  const sum = weights.reduce((s, v) => s + v, 0)
  const columns = weights.map((v, index) => {
    const child = context.getChild(index) as ModelElement
    return child
      ? (getElementAspectStyle(child, reusable.dimensions, styles, customStyles) as StyleFor<'dimensions'>)?.props
      : null
  })
  const hasLimits = columns.some((c) => c?.maxWidth != null)
  const boxes = weights.map((v, index) => {
    const plus = hasLimits && style.props.columnCount != 1 && horizontal.value == 'stretch' ? '+' : ''
    const width = `${Math.floor((v / sum) * 100)}${plus}%`
    const minWidth = stringifyLength(columns[index]?.minWidth, null)
    const maxWidth = stringifyLength(columns[index]?.maxWidth, null)
    const isFixedWidth = minWidth == maxWidth && minWidth

    return (
      <Box className='box' bg='white' key={'box' + index}>
        {style.props.columnCount != 1 && (
          <HStack className='top'>
            <IconButton
              icon={<MinusIcon fontSize={'16px'} />}
              onClick={() =>
                setLayoutProperty('weights', Object.assign(weights.slice(), { [index]: Math.max(1, v - 1) }))
              }
              bg='white'
              variant='secondary'
              size='xs'
              aria-label='Decrease weight'
            />
            <Text fontSize='12px' whiteSpace={'nowrap'}>
              {style.props.columnCount > 3 ? '' : 'Weight:'} {v}
            </Text>
            <IconButton
              icon={<AddIcon fontSize={'16px'} />}
              variant='secondary'
              onClick={() => setLayoutProperty('weights', Object.assign(weights.slice(), { [index]: v + 1 }))}
              bg='white'
              size='xs'
              aria-label='Increase weight'
            />
          </HStack>
        )}
        <Text fontSize='18px' fontWeight={600}>
          {isFixedWidth && minWidth ? minWidth : width}
        </Text>
        {!isFixedWidth && (minWidth || maxWidth) && (
          <VStack className='bottom' whiteSpace={'nowrap'} spacing={0}>
            {minWidth && <Text fontSize={'10px'}>min: {minWidth}</Text>}
            {maxWidth && <Text fontSize={'10px'}>max: {maxWidth}</Text>}
          </VStack>
        )}
      </Box>
    )
  })

  const divider = (
    <Box width='16px' height={0} transform='scaleY(66%)' borderTop='2px solid var(--chakra-colors-blackAlpha-600)' />
  )
  const spacer = <Icon path={mdiArrowLeftRight} width='16px' height='16px' color='blackAlpha.600' />

  return (
    <VStack spacing={4} alignItems='stretch'>
      <HStack justifyContent={'space-between'}>
        <Box>
          <Text color='blackAlpha.800' fontWeight={500} mb={2}>
            Number of columns
          </Text>
          <ButtonGroupSwitch
            onChange={(c) => {
              setLayoutProperty('columnCount', c + 1)
            }}
            index={style.props.columnCount - 1}
          >
            <Button>1</Button>
            <Button>2</Button>
            <Button>3</Button>
            <Button>4</Button>
          </ButtonGroupSwitch>
        </Box>
        <Box hidden={style.props.columnCount == 1}>
          <Text color='blackAlpha.800' fontWeight={500} mb={2}>
            Allow wrapping
          </Text>
          <ButtonGroupSwitch
            onChange={(c) => {
              setLayoutProperty('flexWrap', c == 0)
            }}
            index={style.props.flexWrap ? 0 : 1}
          >
            <Button>Yes</Button>
            <Button>No</Button>
          </ButtonGroupSwitch>
        </Box>
      </HStack>

      <HStack p={0} spacing={null} py='4' css={boxesCss} bg='gray.100' borderRadius={'4px'}>
        {boxes
          .map((box, index) => {
            return [
              // first
              index == 0 &&
                (horizontal.value == 'flex-start' ||
                horizontal.value == 'stretch' ||
                horizontal.value == 'space-between'
                  ? divider
                  : spacer),

              // between
              index != 0 &&
                (horizontal.value == 'space-between' || horizontal.value == 'space-evenly' ? spacer : divider),

              box,

              // last
              index == boxes.length - 1 &&
                (horizontal.value == 'flex-end' || horizontal.value == 'stretch' || horizontal.value == 'space-between'
                  ? divider
                  : spacer)
            ]
          })
          .filter(Boolean)
          .flat()}
      </HStack>

      <Box>
        <Text color='blackAlpha.800' fontWeight={500} mb={2}>
          Horizontal alignment
        </Text>
        <ButtonGroupSwitch
          onChange={(index) => {
            setLayoutProperty(horizontalOptions[index].property, horizontalOptions[index].value)
          }}
          index={horizontalOptions.indexOf(horizontal)}
        >
          {horizontalOptions.map((option) => {
            return (
              <Button size='sm' key={option.value}>
                {option.label}
              </Button>
            )
          })}
        </ButtonGroupSwitch>
      </Box>

      <Box>
        <Text color='blackAlpha.800' fontWeight={500} mb={2}>
          Vertical alignment
        </Text>
        <ButtonGroupSwitch
          onChange={(index) => {
            setLayoutProperty(verticalOptions[index].property, verticalOptions[index].value)
          }}
          index={verticalOptions.indexOf(vertical)}
        >
          {verticalOptions.map((option) => {
            return (
              <Button size='sm' key={option.value}>
                {option.label}
              </Button>
            )
          })}
        </ButtonGroupSwitch>
      </Box>
    </VStack>
  )
}
