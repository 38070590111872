import type { API } from '../api.js'

export default class APIAdapter<T, A = API> {
  api: A
  constructor(api: A) {
    this.api = api
  }
  fetch(object?: Partial<T>): Promise<T[]> {
    throw new Error('fetch not implemented')
  }
  search(object?: Partial<T> | any, query?: any): Promise<T[]> {
    throw new Error('search not implemented')
  }
  get(object: Partial<T>): Promise<T> {
    throw new Error('get not implemented')
  }
  post(object: Partial<T>): Promise<T> {
    throw new Error('post not implemented')
  }
  put(object: Partial<T>): Promise<T> {
    throw new Error('put not implemented')
  }
  save(object: Partial<T>): Promise<T> {
    return this.post(object)
  }
  delete(object: Partial<T>): Promise<T> {
    throw new Error('delete not implemented')
  }
}
