import { ButtonGroup } from '@sitecore-ui/design-system'
import { Children, cloneElement, ReactElement, ReactNode } from 'react'

interface Props {
  index?: number
  onChange: (index: number) => void
  activeProps?: any
  children?: ReactNode
  inactiveProps?: any
  [prop: string]: any
}

const ButtonGroupSwitch = (props: Props) => {
  const {
    index,
    onChange,
    activeProps = { bg: 'primary.100', variant: 'subtle' },
    inactiveProps = { variant: 'minimal' },
    ...more
  } = props

  return (
    <ButtonGroup
      p={1}
      bg='white'
      border='1px solid transparent'
      borderColor='blackAlpha.200'
      borderRadius='3xl'
      spacing={0}
      {...more}
    >
      {Children.map(
        props.children,
        (child, i) =>
          child &&
          cloneElement(
            child as ReactElement,
            i === index
              ? { onClick: (e) => onChange(i), ...activeProps }
              : { onClick: (e) => onChange(i), ...inactiveProps }
          )
      )}
    </ButtonGroup>
  )
}

export default ButtonGroupSwitch
