import { Color, ColorReference } from './color.js'
import { Length } from './length.js'

export const GradientColorStop = ({ color, start, end }) => {
  return {
    color: ColorReference(color),
    start: start != null ? Length(start) : null,
    end: end != null ? Length(end) : null
  }
}

export const Gradient = (gradient?) => {
  let angle = 0
  let stops: GradientColorStop[] = []

  if (!gradient) {
    return { angle, stops }
  }

  if (gradient.angle) {
    angle = gradient.angle
  }

  if (Array.isArray(gradient.stops)) {
    stops = gradient.stops.map(GradientColorStop)
  }

  return { angle, stops }
}

Gradient.isValid = (gradient: Gradient) => {
  return gradient.stops.length > 0 && gradient.stops.every((stop) => ColorReference.isValid(stop.color))
}

export type GradientColorStop = ReturnType<typeof GradientColorStop>
export type Gradient = ReturnType<typeof Gradient>
