import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { tinycolor } from '@ctrl/tinycolor'
import NumberField from '../fields/NumberField.js'
import { FormControl, FormLabel, GridItem } from '@sitecore-ui/design-system'
import { Color } from 'models/style/types/color.js'
import { ColorDefinition } from 'models/style/color.js'

interface Props {
  color: Color
  index: number
  onChangeColors: (colors: ColorDefinition[]) => void
  colors: ColorDefinition[]
  selected: number
  opacity: number
  disabled: boolean
}

const ColorHSLFieldset: FunctionComponent<Props> = ({
  color: { red, green, blue, alpha },
  index,
  onChangeColors,
  colors,
  opacity,
  disabled
}: Props): ReactElement => {
  const hslString = tinycolor({ r: red, g: green, b: blue }).toHslString()
  const [hue, setHue] = useState(parseInt(hslString.split(',')[0].split('(')[1]))
  const [saturation, setSaturation] = useState(parseInt(hslString.split(', ')[1].split(',')[0].replace('%', '')))
  const [lightness, setLightness] = useState(parseInt(hslString.split(', ')[2].split(')')[0].replace('%', '')))

  const onChangeHue = (h: number) => {
    setHue(h)

    const { r, g, b } = tinycolor(`hsl(${h}, ${saturation}%, ${lightness}%)`).toRgb()

    colors[index].color = { red: r, green: g, blue: b, alpha }

    onChangeColors(colors)
  }

  const onChangeSaturation = (s: number) => {
    setSaturation(s)

    const { r, g, b } = tinycolor(`hsl(${hue}, ${s}%, ${lightness}%)`).toRgb()

    colors[index].color = { red: r, green: g, blue: b, alpha }

    onChangeColors(colors)
  }

  const onChangeLightness = (l: number) => {
    setLightness(l)

    const { r, g, b } = tinycolor(`hsl(${hue}, ${saturation}%, ${l}%)`).toRgb()

    colors[index].color = { red: r, green: g, blue: b, alpha }

    onChangeColors(colors)
  }

  return (
    <>
      <GridItem colSpan={1} opacity={opacity}>
        <FormControl>
          <FormLabel>Hue</FormLabel>

          <NumberField disabled={disabled} max={360} value={hue} onChange={onChangeHue} />
        </FormControl>
      </GridItem>

      <GridItem colSpan={1} opacity={opacity}>
        <FormControl>
          <FormLabel>Saturation</FormLabel>

          <NumberField disabled={disabled} max={100} value={saturation} onChange={onChangeSaturation} />
        </FormControl>
      </GridItem>

      <GridItem colSpan={1} opacity={opacity}>
        <FormControl>
          <FormLabel>Lightness</FormLabel>

          <NumberField disabled={disabled} max={100} value={lightness} onChange={onChangeLightness} />
        </FormControl>
      </GridItem>
    </>
  )
}

export default ColorHSLFieldset
