import { Auth0Provider } from '@auth0/auth0-react'
import { extendTheme, sitecoreTheme, SitecoreUIProvider } from '@sitecore-ui/design-system'
import { defineCustomElements } from '@sitecore-ui/web-components/dist/esm/loader.js'
import { useEffect } from 'react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import APIProvider from './providers/APIProvider.js'
import ConfirmationProvider from './providers/ConfirmationProvider.js'
import QueryStringProvider from './providers/QueryStringProvider.js'

import { Route, Routes } from 'react-router'
import Collections from './routes/Collections.js'
import Datasources from './routes/Datasources.js'
import Editor from './routes/Editor.js'
import Logout from './routes/Logout.js'
import Styles from './routes/Styles.js'
import BasicLayout from 'components/layout/BasicLayout.js'

defineCustomElements()

const globalStyles = {
  styles: {
    global: {
      // Styles that allow page components
      // to take all vertical space with flex grow
      'html, body, #__next': {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        margin: '0'
      }
    }
  },
  components: {
    Input: {
      variants: {
        outline: {
          field: { background: 'white' }
        }
      }
    },
    Textarea: {
      variants: {
        outline: {
          background: 'white'
        }
      }
    },
    NumberInput: {
      variants: {
        outline: {
          field: { background: 'white' }
        }
      }
    },
    Button: {
      variants: {
        selected: (props) => ({
          ...sitecoreTheme.components.Button.variants.subtle(props),
          bg: 'purple.100'
        }),
        unselected: (props) => ({
          ...sitecoreTheme.components.Button.variants.minimal(props)
        })
      }
    },
    Badge: {
      variants: {
        datapath: (props) => ({
          ...sitecoreTheme.components.Badge.variants.subtle({
            colorScheme: 'green',
            ...props
          }),
          lineHeight: '1.6'
        })
      }
    },
    Popover: {
      defaultProps: {
        variant: 'default'
      },
      variants: {
        default: {
          content: {
            transition: 'box-shadow 0.2s',
            boxShadow: '0',
            _focusWithin: {
              transition: 'box-shadow 0.7s',
              boxShadow: '0 0 0 3px var(--chakra-colors-blackAlpha-200)',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            },
            _focus: {
              transition: 'box-shadow 0.2s !important',
              boxShadow: 'var(--chakra-shadows-outline) !important',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            }
          }
        }
      }
    },
    Menu: {
      defaultProps: {
        variant: 'default'
      },
      variants: {
        default: {
          list: {
            boxShadow: 'outline',
            transition: 'box-shadow 0.2s !important',
            _focusWithin: {
              boxShadow: 'outline',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            }
          },
          divider: {
            borderBottomColor: 'blackAlpha.300'
          }
        }
      }
    },
    FormLabel: {
      variants: {
        subtle: {
          fontSize: 12,
          color: 'gray.700'
        }
      }
    },
    FormControl: {
      variants: {
        horizontal: {
          display: 'flex',
          alignItems: 'center',
          width: 'auto'
        }
      }
    },
    Link: {
      baseStyle: {
        color: 'gray.800',
        ':hover': {
          textDecoration: 'underline',
          color: 'primary.500'
        }
      }
    }
  }
}

const theme = extendTheme(globalStyles, sitecoreTheme)

function Body({ children }) {
  const navigate = useNavigate()
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const link: HTMLAnchorElement = (e.target as HTMLElement).closest('a[href]')
      if (link && link.origin == location.origin && !link.closest('[contenteditable]')) {
        navigate(link.pathname + link.search)
        e.preventDefault()
      }
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  })

  return children
}

export default function App() {
  return (
    <BrowserRouter>
      <Body>
        <Auth0Provider
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          redirectUri={`${window.location.origin}${window.location.search}${
            window.location.search ? '&' : '?'
          }redirectPath=${location.pathname}`}
          audience={import.meta.env.VITE_AUTH0_AUDIENCE}
          useRefreshTokens={true}
          cacheLocation={'localstorage'}
        >
          <QueryStringProvider>
            <SitecoreUIProvider theme={theme}>
              <APIProvider>
                <ConfirmationProvider>
                  <BasicLayout>
                    <Routes>
                      <Route path='/logout' element={<Logout />} />
                      <Route path='/' element={<Collections key='collections' />} />
                      <Route path='/libraries/:libraryId' element={<Collections key='collections' />}>
                        <Route path='collections' element={<Collections key='collections' />}>
                          <Route path=':collectionId' element={<Collections key='collections' />}>
                            <Route path='components/:componentId' element={<Collections key='collections' />} />
                          </Route>
                        </Route>
                      </Route>
                      <Route path='/libraries/:libraryId/datasources' element={<Datasources key='datasource' />}>
                        <Route path=':datasourceId' element={<Datasources key='datasource' />} />
                      </Route>
                      <Route path='/libraries/:libraryId/styles' element={<Styles key='styles' />}>
                        <Route path=':collection' element={<Styles key='styles' />}>
                          <Route path=':type' element={<Styles key='styles' />}>
                            <Route path=':id' element={<Styles key='styles' />} />
                          </Route>
                        </Route>
                      </Route>

                      <Route path='/libraries/:libraryId/components/:componentId' element={<Editor />} />
                    </Routes>
                  </BasicLayout>
                </ConfirmationProvider>
              </APIProvider>
            </SitecoreUIProvider>
          </QueryStringProvider>
        </Auth0Provider>
      </Body>
    </BrowserRouter>
  )
}
