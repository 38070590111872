import { JSONPath } from 'jsonpath-plus'
import { CloudStylesheet, CloudTemplate, fetchComponent, fetchStylesheet } from './cloud.js'

export interface FEAASCustomizations {
  processValue?: (element: HTMLElement, property: string, value: any) => any
  shouldRepeat?: (element: HTMLElement, value: any) => boolean
}

export interface ExplicitTemplate {
  template: string
}

export type Datascopes = Record<string, any>

export type FEAASComponentProps = {
  data?: Datascopes
} & (ExplicitTemplate | CloudTemplate)

export function renderComponentElement(content: string, datasources?: Datascopes) {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = content
  const fragment = document.createDocumentFragment()
  while (wrapper.firstChild) {
    fragment.appendChild(wrapper.firstChild)
  }
  renderDOMElement(fragment, datasources)
  return wrapper
}

export function cleanCollectionBit(path: string) {
  return path.replace(/(\[[^\]\[]+\]|\.\*)$/g, '')
}

export function normalizeCollectionScope(path: string) {
  return cleanCollectionBit(path) + '.*'
}

function getDOMCollectionIndex(element: HTMLElement) {
  const datascope = element.getAttribute('data-path-scope')
  var index = 0
  for (var p = element; (p = <HTMLElement>p.previousElementSibling); ) {
    if (p.getAttribute('data-path-scope') == datascope) {
      index++
    }
  }
  return index
}

function updateDOMClones(element: HTMLElement, targetCount: number, config?: FEAASCustomizations) {
  const datascope = element.getAttribute('data-path-scope')
  if (datascope == null) {
    return
  }
  var index = getDOMCollectionIndex(element)
  if (index != 0) {
    return
  }

  const parentElement = element.parentElement
  if (parentElement == null) {
    return
  }

  /*dont remove first element, just hide it*/
  if (targetCount == 0) {
    element.style.display = 'none'
  } else if (element.style.display) {
    element.style.display = ''
    if (element.getAttribute('style') == '') element.removeAttribute('style')
  }

  var count = index + 1
  var last = element
  for (var n = element; (n = <HTMLElement>n.nextElementSibling); ) {
    if (n.getAttribute('data-path-scope') == datascope) {
      count++
      last = n
    }
  }
  /* add missing clones */
  for (var i = count; i < targetCount; i++) {
    parentElement.insertBefore(last.cloneNode(true), last.nextElementSibling)
  }
  /* remove excessive clones fron previous renders */
  for (var i = Math.max(1, targetCount); i < count; i++) {
    last = <HTMLElement>last.previousElementSibling
    parentElement.removeChild(<HTMLElement>last.nextElementSibling)
  }
}

export function queryScopes(scopes: any, datapath: string, singleValue = false, json: any = scopes) {
  var path = datapath
  /* find closest parent scope */
  const keys = Object.keys(scopes).sort((b, a) => a.length - b.length)
  for (const scope of keys) {
    if (datapath.startsWith(scope) && 'ends with it') {
      if (scope == datapath) {
        if (singleValue) {
          return scopes[scope]
        } else {
          continue
        }
      }
      path = datapath.substring(scope.length + 1)
      json = scopes[scope]
      break
    }
  }

  const collection = JSONPath({
    path: path,
    json: json
  })
  if (singleValue && collection) {
    return collection[0]
  } else {
    return collection
  }
}

export function setDOMAttribute(element: HTMLElement, attribute: string, value: any, config?: FEAASCustomizations) {
  if (!element) return
  var target = element
  if (config?.processValue) {
    value = config?.processValue(element, attribute, value)
  }
  var prop = attribute.replace('data-path-', '')
  if (attribute == 'data-path-src') {
    target = element.tagName == 'IMG' ? element : <HTMLElement>element.querySelector('img, video')
  } else if (attribute == 'data-path-href') {
    target = element.querySelector('a') || element.closest('a')
  } else if (attribute == 'data-path-hidden') {
    value = !value ? true : null
  } else if (attribute == 'data-path' || attribute == 'data-embed-html' || attribute == 'html') {
    prop = 'innerHTML'
  } else if (attribute == 'data-embed-src') {
    loadScript(element, value)
  } else if (attribute == 'data-path-attributes') {
    const oldKeys = element.getAttribute('data-attributes-keys')?.split(',').filter(Boolean) || []
    for (var property in value) {
      var subvalue = value[property]
      setDOMAttribute(
        element,
        property,
        typeof subvalue == 'object' && subvalue ? JSON.stringify(subvalue) : subvalue,
        config
      )
    }
    for (var i = 0; i < oldKeys.length; i++) {
      if (value == null || !(oldKeys[i] in value)) {
        setDOMAttribute(element, oldKeys[i], null, config)
      }
    }
    setDOMAttribute(element, 'data-attributes-keys', Object.keys(value || {}).join(','))
    return
  }
  if (typeof value == 'boolean') {
    value = value ? '' : null
  }
  if (!target) return

  var placeholder = String(element.getAttribute('data-path-placeholder') || target[prop as keyof HTMLElement])

  if (value != null) {
    if (prop == 'innerHTML') {
      if (target.innerHTML != value) {
        target.innerHTML = value
      }
    } else {
      if (target.getAttribute(prop) != value) {
        target.setAttribute(prop, value)
      }
    }
  } else {
    if (prop == 'innerHTML') {
      target.innerHTML = placeholder
    } else {
      target.removeAttribute(prop)
    }
  }
}

export function observeDOMElement(element: HTMLElement) {
  const observer = new element.ownerDocument.defaultView.MutationObserver((changes) => {
    changes.forEach((change) => {
      const target = change.target as HTMLElement
      const src = target.getAttribute('data-embed-src')
      if (src) {
        loadScript(target, src)
      }

      if (change.addedNodes) {
        change.addedNodes.forEach((node) => {
          if (node.nodeType == 1) autoloadScripts(node as HTMLElement, false)
        })
      }
    })
  })
  observer.observe(element, { attributes: true, subtree: true, childList: true })
  return observer
}

export function autoloadScripts(element: HTMLElement, observe = true) {
  const embeds = element.querySelectorAll('[data-embed-src]')
  for (var i = 0; i < embeds.length; i++) {
    const embed = embeds[i]
    loadScript(embed as HTMLElement, embed.getAttribute('data-embed-src'))
  }
  if (observe) return observeDOMElement(element)
}

export function loadScript(element: HTMLElement, src: string) {
  for (var p = element; p; p = p.parentElement) {
    if (!p.parentElement) {
      const scripts = p.querySelectorAll('script')
      for (var i = 0; i < scripts.length; i++) {
        if (scripts[i].getAttribute('src') == src) {
          return true
        }
      }
      if (p.tagName == 'HTML') p = p.querySelector('head') || p
      const script = p.ownerDocument.createElement('script')
      script.type = 'module'
      script.src = src
      p.appendChild(script)
      break
    }
  }
}

// order in which attributes are proccesed. Rightmost are more important
var sortOrder = ['data-attributes-keys', 'data-path-attributes', 'data-path-scope']
export function renderDOMElement(
  input: HTMLElement | DocumentFragment,
  datascopes?: any,
  config?: FEAASCustomizations
) {
  const element = <HTMLElement>input
  if (element.nodeType == 1) {
    const datascope = element.getAttribute('data-path-scope')
    if (datascope) {
      const index = getDOMCollectionIndex(element)
      const collection = queryScopes(datascopes, datascope, false) || []
      if (index == 0) {
        updateDOMClones(element, config?.shouldRepeat(element, collection) === false ? 1 : collection.length, config)
      }

      datascopes = {
        ...datascopes,
        [normalizeCollectionScope(datascope)]: collection[index]
      }
    }
    const attrs = Array.prototype.slice.call(element.attributes).sort((a, b) => {
      return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)
    })
    for (var i = 0; i < attrs.length; i++) {
      const { name, value: datapath } = attrs[i]
      if (name.startsWith('data-path') && name != 'data-path-scope') {
        setDOMAttribute(element, name, queryScopes(datascopes, datapath, true), config)
      }
      if (name == 'data-embed-src') {
        setDOMAttribute(element, name, element.getAttribute(name), config)
      }
    }
  }
  for (var i = 0; i < element.children.length; i++) {
    renderDOMElement(<HTMLElement>element.children[i], datascopes, config)
  }
  return element
}

export function renderDOMContent(root: HTMLElement, content: string, data?: Datascopes) {
  root.innerHTML = content
  root.classList.add('-feaas')
  return renderDOMElement(root, data)
}

export function renderStylesheet(props: CloudStylesheet, style?: HTMLStyleElement) {
  renderStylesheetPromise(props, (style ||= document.createElement('style')))
  return style
}

export function renderStylesheetPromise(props: CloudStylesheet, style?: HTMLStyleElement) {
  style ||= document.createElement('style')
  return fetchStylesheet(props, (cssText) => {
    style.textContent = cssText
  }).then(() => style)
}

export function renderComponent(props: FEAASComponentProps, root?: HTMLElement) {
  renderComponentPromise(props, (root ||= document.createElement('div')))
  return root
}

export async function renderComponentPromise(props: FEAASComponentProps, root?: HTMLElement) {
  root ||= document.createElement('div')
  if (!root.childNodes.length) {
    if ('template' in props) {
      return renderDOMContent(root, props.template, props.data)
    } else {
      return fetchComponent(props, (template) => {
        return renderDOMContent(root, template, props.data)
      }).then(() => root)
    }
    /* update element with new data */
  } else {
    return renderDOMElement(root, props.data)
  }
}
