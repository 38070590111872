import React, { FunctionComponent, ReactElement } from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack
} from '@sitecore-ui/design-system'

interface Props {
  onChange: (value: any) => void
  value: number
  label?: string
  disabled?: boolean
}

const OpacityField: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { onChange, value, label = 'Opacity', disabled } = props

  return (
    <Flex align='center'>
      <FormControl>
        <FormLabel>{label}</FormLabel>

        <Flex>
          <NumberInput
            flex={2}
            min={0}
            step={0.01}
            precision={2}
            max={1}
            value={value ?? 1}
            onChange={(valueAsString) => onChange(valueAsString)}
            keepWithinRange={true}
            isDisabled={disabled}
          >
            <NumberInputField bg='white' />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

          <RangeSlider
            flex={4}
            ml={3}
            max={1}
            min={0}
            value={[value ?? 1]}
            step={0.01}
            onChange={([newValue]) => newValue !== value && onChange(newValue)}
            isDisabled={disabled}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>

            <RangeSliderThumb index={0} />
          </RangeSlider>
        </Flex>
      </FormControl>
    </Flex>
  )
}

export default OpacityField
