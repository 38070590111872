import { default as styled } from '@emotion/styled'
import { mdiDatabaseOutline } from '@mdi/js'
import { Box, Button, EditIcon, Flex, Icon, Link, Tag, Text } from '@sitecore-ui/design-system'
import type { ComponentModel } from '@sitecore-feaas/api'
import { useApiData } from 'hooks/useApiData.js'
import { FunctionComponent, ReactElement, useState } from 'react'
import { useNavigate } from 'react-router'
import StatusBadge from '../StatusBadge.js'
import ComponentInfo from './ComponentInfo.js'
import ComponentLower from './ComponentLower.js'
import ComponentThumbnail from './ComponentThumbnail.js'

const DataSourceTag = styled<any>(Tag)`
  border-radius: 12px;
  cursor: pointer;
`

const StyledLink = styled(Link)`
  cursor: pointer;
`

interface Props {
  component: ComponentModel
}

const Component: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { component } = props

  const navigate = useNavigate()
  const library = useApiData('library')
  const datasources = useApiData('library.datasources')

  const [showInfoButton, setShowInfoButton] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [imageHeight, setImageHeight] = useState(0)

  return (
    <Box
      h='264px'
      flexBasis='320px'
      flexShrink={1}
      borderRadius='base'
      boxShadow='base'
      bg='white'
      pos='relative'
      _hover={{ boxShadow: 'md' }}
      className={'component'}
      onMouseEnter={() => setShowInfoButton(true)}
      onMouseLeave={() => setShowInfoButton(false)}
    >
      <Flex
        mx='5'
        mt='5'
        h='200'
        pos='relative'
        overflowY='hidden'
        align={imageHeight < 200 && !showInfo ? 'center' : ''}
        cursor='pointer'
        className='wrapper'
        onClick={(e) =>
          !(e.target as HTMLElement).closest('a, button') && navigate(`${library.getPath()}/components/${component.id}`)
        }
      >
        <Flex pos='absolute' top='0' zIndex={3}>
          <StatusBadge status={component.status} />
        </Flex>

        {showInfo && (
          <>
            <Flex
              position='absolute'
              flexDirection='column'
              zIndex={2}
              left={0}
              right={0}
              p={0}
              pt={8}
              background='white'
              minHeight='100%'
            >
              <Button
                position={'absolute'}
                top={0}
                right={0}
                variant='primary'
                ml='auto'
                onClick={() => navigate(`${library.getPath()}/components/${component.id}`)}
                float='right'
              >
                <EditIcon mr='2' />
                Edit
              </Button>
              {component.modifiedAt && (
                <ComponentInfo
                  passedProps={{ mt: 0 }}
                  title='Modified at'
                  value={component.modifiedAt.toDateString()}
                />
              )}

              {component.publishedAt && (
                <ComponentInfo
                  passedProps={{ mt: 1 }}
                  title='Published at'
                  value={component.publishedAt.toDateString()}
                />
              )}

              <ComponentInfo passedProps={{ mt: 1 }} title='Modified by' value={component.modifiedBy.name} />

              <ComponentInfo passedProps={{ mt: 1 }} title='Variants' value={String(component.variantCount)} />
            </Flex>

            {component.datasourceIds.length > 0 && (
              <Flex align='center' pos='absolute' bottom='4' zIndex={2}>
                <Icon path={mdiDatabaseOutline} />
                {component.datasourceIds.map((id) => (
                  <StyledLink href={`${library.getPath()}/datasources/${id}`} key={id}>
                    <DataSourceTag colorScheme='cyan' variant='solid' ml='1'>
                      <Text color='white' variant='microcopy'>
                        {datasources.find((ds) => ds.id === id).name}
                      </Text>
                    </DataSourceTag>
                  </StyledLink>
                ))}
              </Flex>
            )}
          </>
        )}

        <ComponentThumbnail component={component} onLoad={({ height }) => setImageHeight(height)} />
      </Flex>

      {imageHeight >= 200 && (
        <Box
          boxShadow='0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06)'
          height='1px'
          left={0}
          right={0}
          pos={'absolute'}
        ></Box>
      )}
      <ComponentLower
        component={component}
        showInfo={showInfo}
        showInfoButton={showInfo || showInfoButton}
        onToggleShowInfo={() => setShowInfo(!showInfo)}
      />
    </Box>
  )
}

export default Component
