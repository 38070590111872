import Preview from 'components/styles/previews/index.js'
import { Style } from 'models/style/index.js'

import { reusable as reusableSettings } from 'components/styles/settings.js'
import {
  Alert,
  AlertButton,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  HStack,
  Icon,
  IconButton,
  InfoIcon,
  Text
} from '@sitecore-ui/design-system'
import { mdiAlertOutline } from '@mdi/js'

interface Props {
  style: Style
  currentStyle: Style
  onChange: (Style) => void
}

import { css } from '@emotion/react'

const StylerRuleStyle = css`
  .chakra-button__group[role='group'] button {
    height: 32px;
  }
`

export function StylerRule({ style, currentStyle, onChange }: Props) {
  return (
    <Box css={StylerRuleStyle}>
      {/* @ts-ignore */}
      <Alert status='warning' alignItems='flex-start' mb={6}>
        {/* @ts-ignore */}
        <AlertIcon path={mdiAlertOutline} as={Icon} fontSize='24px' mt={0} />
        <Box>
          <AlertTitle>Advanced style customization</AlertTitle>
          {/* @ts-ignore */}
          <AlertDescription lineHeight={'19px'} fontSize='14px' whiteSpace='nowrap'>
            Only chosen element is affected
          </AlertDescription>
        </Box>
      </Alert>
      <Preview style={currentStyle} />

      {Object.keys(reusableSettings[style.type].forms).map((title) => {
        if (title == 'Details') return
        const Form = reusableSettings[style.type].forms[title]
        return (
          <Box mt={12} key={title}>
            <h4>
              <Text fontSize='md' fontWeight='600' mb={2}>
                {title}
              </Text>
            </h4>
            <Form currentStyle={currentStyle} onChange={onChange} style={style} />
          </Box>
        )
      })}
    </Box>
  )
}
