import { QueryStringContext } from '../../providers/QueryStringProvider.js'
import React, { useContext, useMemo } from 'react'
import {
  ChevronDownIcon,
  Radio,
  Text,
  Menu,
  MenuList,
  MenuItem,
  Button,
  MenuButton,
  Flex
} from '@sitecore-ui/design-system'

interface SortFilter {
  id: string
  label: string
}

const Sort = () => {
  const [query, setQuery] = useContext(QueryStringContext)

  const { sortBy } = query

  const sortFilters: SortFilter[] = [
    { id: 'publishedAt', label: 'Published at' },
    { id: 'modifiedAt', label: 'Last modified' }
  ]

  const label = useMemo(
    () => sortFilters.find((sortFilter: SortFilter) => query.sortBy === sortFilter.id)?.label || sortFilters[1].label,
    [sortBy]
  )

  const onChange = (id) => {
    setQuery({ sortBy: id })
  }

  const isChecked = (sortFilter) => (query.sortBy ? query.sortBy === sortFilter.id : sortFilter.id === 'modifiedAt')

  return (
    <Menu>
      <Button minW='48' as={MenuButton} variant='secondary' aria-label='menu'>
        <Flex>
          <Text>
            <strong>Sort by:</strong> {label}
          </Text>
          <ChevronDownIcon ml='2' />
        </Flex>
      </Button>

      <MenuList>
        {sortFilters.map(({ id, label }: SortFilter) => (
          <MenuItem key={id} onClick={() => onChange(id)}>
            <Radio value={id} isChecked={isChecked({ id, label })}>
              {label}
            </Radio>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default Sort
