import { Button } from '@sitecore-ui/design-system'
import SwitchGroupField from 'components/styles/fields/SwitchGroupField.js'
import { useState } from 'react'
import { ConfigureMenuItem } from './EditorChromeContextConfigure.js'
import { DialogComponent } from './EditorDialog.js'
import { EditorDialogAttributesStatic } from './EditorDialogAttributes.js'

const EditorDialogAttributesFallback: DialogComponent<ConfigureMenuItem> = (props) => {
  const { context, editor, menuItem } = props
  const { above, below, type, value, jsonpath, labels, label, onChange, onConfigure } = menuItem

  const getIndex = () => {
    if (value === false) return 1
    if (value != null) return 2
    return 0
  }
  const [index, setIndex] = useState(getIndex)

  const onIndexChange = (newIndex: number) => {
    if (newIndex == index) return
    if (newIndex == 0) {
      onChange(null)
    }
    if (newIndex == 1) {
      onChange(false)
    }
    if (newIndex == 2) {
      onChange(true)
    }
    setIndex(newIndex)
  }

  return (
    <>
      {above?.({ ...props, index, setIndex: onIndexChange })}
      <SwitchGroupField
        value={index}
        label='What to do if data is missing?'
        onChange={onIndexChange}
        alignSelf={'flex-start'}
      >
        {type != 'image' && <Button>Hide text</Button>}
        <Button>Hide element</Button>
        <Button>Show fallback</Button>
      </SwitchGroupField>
      {index == 2 && <EditorDialogAttributesStatic {...props} />}
      {below?.({ ...props, index, setIndex: onIndexChange })}
    </>
  )
}
export default EditorDialogAttributesFallback
