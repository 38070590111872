import { ColorReference } from 'models/style/types/color.js'
import { Keyword } from 'models/style/types/keyword.js'
import { Unformatted } from 'models/style/types/props.js'

export enum LinkDecorationStyle {
  'underline' = 'underline',
  'dotted' = 'dotted',
  'double' = 'double',
  'none' = 'none'
}

export const normalizeReusablePalette = (props: any = {}) => ({
  textColor: ColorReference(props.textColor),
  textHighlightColor: ColorReference(props.textHighlightColor),
  linkColor: ColorReference(props.linkColor),
  linkHoverColor: ColorReference(props.linkHoverColor),
  linkDecoration: Keyword<LinkDecorationStyle>(props.linkDecoration, LinkDecorationStyle),
  linkHoverDecoration: Keyword<LinkDecorationStyle>(props.linkHoverDecoration, LinkDecorationStyle)
})
export type ReusablePalette = ReturnType<typeof normalizeReusablePalette>
export const ReusablePalette = (props: Unformatted<ReusablePalette>) => normalizeReusablePalette(props)
export default ReusablePalette
