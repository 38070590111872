import React from 'react'
import useWindowQueryUpdate from '../hooks/useWindowQueryUpdate.js'

export const QueryStringContext = React.createContext(null)

const QueryStringProvider = (props) => {
  const [query, setQuery] = useWindowQueryUpdate()

  return <QueryStringContext.Provider value={[query, setQuery]}>{props.children}</QueryStringContext.Provider>
}

export default QueryStringProvider
