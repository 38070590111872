import { Box, Flex, FormControl, FormLabel } from '@sitecore-ui/design-system'
import { StyledSelect } from 'components/StyledSelect.js'
import { useApiData } from 'hooks/useApiData.js'
import { FunctionComponent, ReactElement } from 'react'
import { ColorReference } from 'models/style/types/color.js'
import { ColorDefinition } from 'models/style/color.js'
import { StyleFor } from 'models/style/index.js'
import { Option, OptionGroup } from 'types/index.js'
import { findColor, rgbaToString } from 'models/style/types/color.js'

const ColorLabel = ({ bg, name }: { bg: string; name: string }) => (
  <Flex align='center'>
    <Box bg={bg} w='4' h='4' mr='2' borderColor={'blackAlpha.400'} borderWidth={1} borderRadius={'4px'} />
    {name}
  </Flex>
)

interface Props {
  onChange: (colorReference: ColorReference) => void
  colorReference: ColorReference | null
  label?: string
}

const ColorField: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { onChange, colorReference, label } = props

  const colors = useApiData('library.stylesheet.styles').filter((s) => s.type == 'color') as StyleFor<'color'>[]

  const { colorGroup, colorDefinition } = findColor(colorReference, colors)

  const toColorOption = (
    colorGroup: StyleFor<'color'>,
    colorDefinition: ColorDefinition
  ): Option<ReactElement, ColorReference> => ({
    label: <ColorLabel bg={rgbaToString(colorDefinition.color)} name={colorDefinition.name} />,
    value: { id: colorGroup.details.id, name: colorDefinition.name, alpha: null }
  })

  const colorOptions: OptionGroup<string, ReactElement, ColorReference>[] = colors.map(
    (colorGroup: StyleFor<'color'>) => ({
      label: colorGroup.details.title,
      options: colorGroup.props.colors.map((colorDefinition: ColorDefinition) =>
        toColorOption(colorGroup, colorDefinition)
      )
    })
  )

  const selectedColorOption: Option<ReactElement | string, ColorReference> | {} =
    colorGroup && colorDefinition ? toColorOption(colorGroup, colorDefinition) : null

  const onColorOptionChange = (option: Option<ReactElement, ColorReference>): void => onChange(option.value)

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      <StyledSelect
        placeholder='Choose color'
        options={colorOptions}
        value={selectedColorOption}
        onChange={onColorOptionChange}
      />
    </FormControl>
  )
}

export default ColorField
