import API, { APICollection, APIModel } from '@sitecore-feaas/api'
import { APIContext } from 'providers/APIProvider.js'
import { useContext, useEffect, useState } from 'react'

export function useApi() {
  return useContext(APIContext)
}

type Idx<T, K extends string> = K extends keyof T
  ? T[K]
  : K extends `${number}`
  ? number extends keyof T
    ? T[number]
    : never
  : never

type DeepIndex<T, K extends string> = T extends object
  ? K extends `${infer F}.${infer R}`
    ? DeepIndex<Idx<T, F>, R>
    : Idx<T, K>
  : never

// Generate list of properties
/*
export type APIPaths<T, Prefix = ''> = {
  [K in keyof T]: [Prefix, K, T[K]] extends
    | [any, any, APICollection]
    | ['' | 'library.', K extends 'snapshotted' ? never : any, T[K] extends T ? never : APIModel]
    ? `${string & Prefix}${string & K}` | APIPaths<T[K], `${string & Prefix}${string & K}.`>
    : never
}[keyof T]
 */

export type APIPaths<T = any> =
  | 'editor'
  | 'library'
  | 'library.collections'
  | 'library.datasources'
  | 'library.components'
  | 'library.stylesheet'
  | 'library.stylesheet.styles'
  | 'library.stylesheets'
  | 'library.collections.components'
  | 'library.collections.components.variants'

type APIType<T extends APIModel | APICollection> = T extends APIModel<infer M>
  ? M
  : T extends APICollection<typeof APIModel<infer M>>
  ? M
  : never

// Returns observed data by path
// useApiData('library.collections')
// By default returns proxy of an object. It actually points to the same
// mutable object, but has new identity so hooks can detect change
export function useApiData<T extends APIPaths<API>, V extends DeepIndex<API, T>>(path: T): V {
  const { api } = useContext(APIContext)
  const model: V = path.split('.').reduce((o, bit) => {
    return o?.[bit]
  }, api)

  return useModelObserver(model)
}

export function useModelObserver<T extends APICollection | APIModel>(model: T) {
  if (!model) return

  function getNewIdentity(d: T) {
    return d?.proxy() as T
  }
  const [result, setResult] = useState<T>(() => getNewIdentity(model))
  useEffect(() => {
    if (model != null) {
      const setter = (data) => {
        setResult(getNewIdentity(data))
      }

      model.observe(setter)
      return () => model.unobserve(setter)
    }
  }, [model])

  return result
}
