export type FetchOptions = Parameters<typeof fetch>[1]
type FetchPreflight = (url: string, options?: FetchOptions) => Promise<FetchOptions>
export interface TransportOptions {
  hostname: string
  cdnHostname: string
  accessToken: string
  apiKey: string
  preflight: FetchPreflight
  verbose?: boolean
  onError: (e?: Error, message?: string) => any
}
export interface Transport extends TransportOptions {}

var crypto = typeof window != 'undefined' ? window.crypto : null
if (!crypto) {
  try {
    var { webcrypto } = require('node:crypto')
    crypto = webcrypto
  } catch (e) {
    try {
      import('node:crypto').then(({ webcrypto }) => {
        // @ts-ignore
        Transport.crypto = webcrypto as typeof crypto
      })
    } catch (e) {
      console.log('Crypto fail', e)
    }
  }
}

var fetch = typeof window != 'undefined' ? window.fetch : null
if (!fetch) {
  try {
    var nodeFetch = require('node-fetch')
    fetch = nodeFetch
  } catch (e) {
    try {
      // @ts-ignore
      import('node-fetch')
        .then(({ default: nodeFetch }) => {
          Transport.prototype._fetch = nodeFetch as unknown as typeof fetch
        })
        .catch((e) => {
          console.log(e)
          throw new Error('fetch() is not available. Install `node-fetch` or update node to v17+')
        })
    } catch (e) {
      console.log(e)
      throw new Error('fetch() is not available. Install `node-fetch` or update node to v17+')
    }
  }
}
export let nanoid = (t = 21) =>
  Transport.crypto
    .getRandomValues(new Uint8Array(t))
    .reduce(
      (t: string, e: number) =>
        (t += (e &= 63) < 36 ? e.toString(36) : e < 62 ? (e - 26).toString(36).toUpperCase() : e > 62 ? '-' : '_'),
      ''
    )

export class Transport<T = TransportOptions> implements TransportOptions {
  static crypto: typeof crypto = (typeof window != 'undefined' && window.crypto) || webcrypto

  nanoid: typeof nanoid
  _fetch: typeof fetch

  constructor(options?: Partial<T>) {
    Object.assign(this, options)
  }
  static instance: Transport
  static getInstance() {
    return (Transport.instance ||= new Transport())
  }

  async checkFetchResponse(response: Response) {
    if (!response.ok) {
      const isJsonResponse = response.headers.get('content-type').includes('application/json')
      if (isJsonResponse) {
        const json = await response.json()
        throw new Error(json.message)
      } else {
        throw new Error('Could not parse JSON response')
      }
    }

    return response
  }

  parseObjectDates = (object: any): any => {
    if (object == null || typeof object != 'object') {
      return object
    }
    if (Array.isArray(object)) {
      return object.map(this.parseObjectDates, this)
    }
    var result: any = {}
    for (var property in object) {
      if (property.endsWith('At') && object[property] != null) {
        result[property] = new Date(object[property])
      } else {
        result[property] = this.parseObjectDates(object[property])
      }
    }
    return result
  }

  async fetch(url: string, options: FetchOptions = {}): Promise<any> {
    if (this.preflight) options = await this.preflight(url, options)
    const absoluteUrl = url.startsWith('/') ? this.hostname + url : url
    if (this.verbose) {
      console.log(options.method?.toUpperCase() || 'GET', ' ' + absoluteUrl)
    }
    const apiKeyHeader = this.apiKey ? { 'X-API-Key': this.apiKey } : {}
    const initOptions = {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.accessToken ? `Bearer ${this.accessToken}` : undefined,
        ...apiKeyHeader,
        ...options.headers
      }
    }
    var fetch = this._fetch
    return fetch(absoluteUrl, initOptions)
      .then(this.checkFetchResponse)
      .then((res) => res.json())
      .then(this.parseObjectDates)
      .then((object: Object | Object[]) => {
        if (object && this.verbose) {
          if (Array.isArray(object)) {
            console.log('  > ', object.length, ' items')
          } else {
            console.log(
              '  > #',
              (object as any)['id'],
              (object as any)['version'] ? '(v' + (object as any)['version'] + ')' : ''
            )
          }
        }
        return object
      })
  }

  async proxy(url: string, options: FetchOptions, includeAuth?: boolean) {
    return this.fetch(`/proxy/fetch`, {
      method: 'POST',
      body: JSON.stringify({ url, options, includeAuth })
    })
  }

  async signUpload(category: string) {
    return await this.fetch(`/files/generateSAS`, {
      method: 'POST',
      body: JSON.stringify({ category })
    })
  }

  async uploadBlob(
    category: 'uploads' | 'images' | 'thumbnails' | 'webcomponents',
    name: string,
    blob: Buffer | Blob | ArrayBufferView | ArrayBuffer,
    options: FetchOptions
  ) {
    const { url } = await this.signUpload(category)
    const domain = url.split('?')[0]
    const blobUrl = `${domain}/${category}/${name}`
    const uploadUrl = url.replace('?', `/${category}/${name}?`)

    var fetch = this._fetch
    return await fetch(uploadUrl, {
      method: 'PUT',
      body: blob,
      ...options,
      headers: {
        accept: 'application/xml',
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-version': '2021-08-06',
        ...options.headers
      }
    }).then(() => {
      return {
        url: blobUrl
      }
    })
    //return Transport.fetch(url)
  }

  reportError(e: Error, message?: string) {
    this.onError?.(e, message)
  }
}

Transport.prototype._fetch = fetch
Transport.prototype.nanoid = nanoid

export default Transport
