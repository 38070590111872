import { RuleChoice } from 'models/style/index.js'
import { Keyword } from 'models/style/types/keyword.js'
import { normalizeNumber } from 'models/style/types/number.js'
import { Unformatted } from 'models/style/types/props.js'

export enum BasicFontPlatform {
  'google' = 'google',
  'adobe' = 'adobe',
  'custom' = 'custom'
}

export enum BasicFontVariantStyle {
  'normal' = 'normal',
  'italic' = 'italic'
}

export interface FontVariantReference {
  id: RuleChoice
  name: string
}

const normalizeFontVariant = (variant) => ({
  name: (variant.name as string) || null,
  familyName: (variant.familyName as string) || null,
  weight: normalizeNumber(variant.weight),
  style: Keyword<BasicFontVariantStyle>(variant.style, BasicFontVariantStyle)
})
export type FontVariant = ReturnType<typeof normalizeFontVariant>
const FontVariant = (variant: Unformatted<FontVariant>) => normalizeFontVariant(variant)

export const BasicFont = (props: any = {}) => ({
  familyName: (props.familyName as string) || null,
  platform: Keyword<BasicFontPlatform>(props.platform, BasicFontPlatform),
  variants: (props.variants || []).map(FontVariant) as FontVariant[]
})
export type BasicFont = ReturnType<typeof BasicFont>
