import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Select,
  Switch,
  Text,
  VStack
} from '@sitecore-ui/design-system'
import ButtonGroupSwitch from 'components/ButtonGroupSwitch.js'
import type { Variant } from '@sitecore-feaas/api'
import { useEffect, useRef, useState } from 'react'

import { mdiMagnify } from '@mdi/js'
import { useApiData } from 'hooks/useApiData.js'
import jsx from 'react-syntax-highlighter/dist/cjs/languages/prism/jsx.js'
import markup from 'react-syntax-highlighter/dist/cjs/languages/prism/markup.js'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/prism-light.js'
import dark from 'react-syntax-highlighter/dist/cjs/styles/prism/material-oceanic.js'

SyntaxHighlighter.registerLanguage('jsx', jsx)
SyntaxHighlighter.registerLanguage('markup', markup)

export default function EditorCanvasVariantEmbed({ componentId, refId, view }: Partial<Variant>) {
  const [index, setIndex] = useState(0)
  const [autoUpdate, setAutoUpdate] = useState(true)
  const component = useApiData('library.components').find((c) => c.id == componentId)
  const datasources = useApiData('library.datasources')
  const variants = component.variants.filter((v) => v.refId == refId)

  const variant = variants[0]
  const [version, setVersion] = useState(
    variants.find((v) => v.status == 'published')
      ? 'published'
      : variants.find((v) => v.status == 'staged')
      ? 'staged'
      : 'saved'
  )
  const matchedVariant = variants.find((v) => v.refId == refId && v.status == version)
  const libraryId = useApiData('library').id

  const [code, setCode] = useState('')
  const [bundleData, setBundleData] = useState(true)

  const data = !bundleData
    ? '{"user": {"name": "Alice"}}'
    : JSON.stringify(
        variant.datasourceIds.reduce((data, id) => {
          return Object.assign(data, {
            [id]: datasources.find((d) => d.id == id)?.sample
          })
        }, {})
      )
  const codeRef = useRef<HTMLElement>()

  const getCode = () => {
    const actualVersion = autoUpdate ? version : matchedVariant.version
    const componentName = component.name.length > 60 ? component.name.substring(0, 60) + '...' : component.name
    const variantName = variant.name.length > 60 ? variant.name.substring(0, 60) + '...' : variant.name
    var cdnHostname = `https://${import.meta.env.VITE_AZURE_BLOB_STORAGE_ACCOUNT_NAME}.blob.core.windows.net`
    var appHostname = import.meta.env.VITE_FRONTEND_URL
    if (index == 0) {
      return `
import * as FEAAS from '@sitecore-feaas/sdk/react';

// Component: ${component.name}
// Variant:   ${variantName}
// URL:       ${appHostname}/components/${component.id}
const MyComponent = ({data}) =>
  <FEAAS.Component
    variantId="${refId}"
    componentId="${componentId}"
    version="${actualVersion}"
    hostname="${cdnHostname}"
    data={data} />

// Use it in your app:
<FEAAS.Stylesheet
  libraryId="${libraryId}"
  hostname="${cdnHostname}" />
<MyComponent data={${data}} />`
    }
    if (index == 1) {
      return `
import FEAAS from '@sitecore-feaas/sdk';

// Component: ${component.name}
// Variant:   ${variantName}
// URL:       ${appHostname}/components/${componentName}
const renderMyComponent = (element, data) =>
  FEAAS.renderComponent({
    componentId: '${componentId}',
    variantId: '${refId}',
    version: '${actualVersion}',
    hostname: '${cdnHostname}',
    data: data
  }, element)

// Only need to load stylesheet once
const stylesheet = FEAAS.renderStylesheet({
  libraryId: "${libraryId}"
});
document.head.appendChild(stylesheet);

// Render component initially
const element = renderMyComponent(null, ${data});
document.body.appendChild(element);

// Update component data later:
// renderMyComponent(element, {"user": {"name": "Bob"}})`
    }

    return `
<script
  src="${cdnHostname}/sdk/latest/webcomponent.min.js">
</script>

<!--  Component: ${component.name}
      Variant:   ${variantName}
      URL:       ${appHostname}/components/${componentId} -->
<feaas-component
  component-id='${componentId}'
  variant-id='${refId}'
  version='${version}'
  hostname='${cdnHostname}'
  data='${data.replace(/'/g, '&#39;')}'>
</feaas-component>

<feaas-stylesheet
  hostname='${cdnHostname}'
  library-id='${libraryId}'>
</feaas-stylesheet>

<script>
  // Updating component data later:
  // const component = document.querySelector('[component-id="${componentId}"]');
  // component.setAttribute('data', JSON.stringify({"user": {"name": "Bob"}}))
</script>`
  }

  const createCodePen = () => {
    const div = document.createElement('div')
    const code = getCode()
      .trim()
      .replace(/^\/\/[^\n]+\n/gm, '')
      .replace(/<!--[\s\S]+-->/g, '')
      .trim()
    const bits = code.split(/\n\n/g)
    var css, html, js
    if (index == 2) {
      html = `<script type="module" src="https://cdn.skypack.dev/@sitecore-feaas/sdk"></script>
${bits[1]}
${bits[2]}
${bits[3].replace(/<script>([\s\S]+?)<\/script>/gi, (m, j) => {
  js = j.replace(/^\s+/gm, '')
  return ''
})}`
    } else if (index == 0) {
      js = `import React from 'react';
import ReactDOM from 'react-dom';
${bits[0]}

${bits[1]}

// Stylesheet only needs to be loaded once
const App = () => <>
${bits[2].replace(/^/gm, '  ')}
</>

ReactDOM.render(<App />, document.getElementById('root'));`
      html = `<div id="root"></div>`
    } else {
      js = code
    }

    js = js.replace(/from '([^\']+)'/g, (m, name) => {
      return `from 'https://cdn.skypack.dev/${name}'`
    })

    div.innerHTML = `<form action="https://codepen.io/pen/define" method="POST" target="_blank" style='position: fixedf; opacity: 0; width: 0px; height: 0px; overflow-hidden; top: 0; left: 0;'>
      <input type="hidden" name="data" value=''>

      <input type="submit" value="Create New Pen with Prefilled Data">
    </form>`
    ;(div.firstElementChild.querySelector('input[name="data"]') as HTMLInputElement).value = JSON.stringify({
      title: component.name,
      html: html,
      layout: 'right',
      css: css,
      js: js,
      editors: index == 2 ? '101' : '001',
      js_pre_processor: index == 0 ? 'babel' : null
    })
    document.body.appendChild(div)
    ;(div.firstElementChild as HTMLFormElement).submit()
    requestAnimationFrame(() => {
      document.body.removeChild(div)
    })
  }

  useEffect(() => {
    setCode(getCode)
  }, [version, index, autoUpdate, data])

  const labels = {
    published: (
      <>
        <strong>Published</strong>: What website visitors see
      </>
    ),
    staged: (
      <>
        <strong>Staged</strong>: What page builders see
      </>
    ),
    saved: (
      <>
        <strong>Saved</strong>: What component authors see
      </>
    )
  }
  return (
    <VStack spacing={6} alignItems='flex-start'>
      <HStack alignItems={'flex-start'} width='100%' spacing={6}>
        <Box flexGrow={1}>
          <FormControl>
            <FormLabel>Embed variant with status</FormLabel>
            <Select
              options={[{ value: 'published' }, { value: 'staged' }, { value: 'saved' }]}
              placeholder='Choose version'
              isOptionDisabled={({ value }) => !variants.find((v) => v.status == value)}
              formatOptionLabel={({ value }) => labels[value]}
              value={{ value: version }}
              onChange={({ value }) => setVersion(value)}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl as='label'>
            <FormLabel>Auto-update</FormLabel>
            <Switch isChecked={autoUpdate} onChange={(e) => setAutoUpdate(e.target.checked)} size='lg' />
          </FormControl>
        </Box>
      </HStack>
      <ButtonGroupSwitch index={index} onChange={setIndex}>
        <Button>React</Button>
        <Button>Javascript</Button>
        <Button>Web Component</Button>
        <Button isDisabled={true}>Page Builder</Button>
      </ButtonGroupSwitch>

      <SyntaxHighlighter
        language={index == 2 ? 'markup' : 'jsx'}
        style={dark}
        customStyle={{ fontSize: '14px', width: '100%' }}
      >
        {code.trim()}
      </SyntaxHighlighter>

      <HStack position={'absolute'} bottom={4}>
        <Button variant='secondary' leftIcon={<Icon path={mdiMagnify} />} onClick={() => createCodePen()} mr={4}>
          Preview on Codepen
        </Button>
        <Switch size='sm' onChange={(e) => setBundleData(e.target.checked)} isChecked={bundleData}>
          <Text variant='subtle' as='span'>
            Bundle data sample
          </Text>
        </Switch>
      </HStack>
    </VStack>
  )
}
