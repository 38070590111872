import { Unformatted } from 'models/style/types/props.js'
import { Length } from 'models/style/types/length.js'

export const SceneSpacingPaddings = (props: any = {}) => ({
  paddingTop: Length(props.paddingTop),
  paddingBottom: Length(props.paddingBottom),
  paddingLeft: Length(props.paddingLeft),
  paddingRight: Length(props.paddingRight),
  rowGap: Length(props.rowGap || props.gap),
  columnGap: Length(props.columnGap || props.gap)
})

export type SceneSpacing = ReturnType<typeof SceneSpacingPaddings>
export const SceneSpacing = (props: Unformatted<SceneSpacing> = {}) => SceneSpacingPaddings(props)
export default SceneSpacing
